import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Autocomplete,
  Card,
  CardContent,
  MenuItem,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import "../../App.css";
import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { Colors } from "../../values/Colors.js";
import i18next from "i18next";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import "dayjs/locale/pt";
import "dayjs/locale/en-gb";
import {
  getActivities,
  getCountries,
  getGenders,
  getTrainStations,
  getDistricts,
  getMunicipalities,
  getZones,
} from "../../api/requests/Data.js";
import toast from "react-hot-toast";
import bcrypt from "bcryptjs";
import "dayjs/locale/pt";
import "dayjs/locale/en-gb";
import { addUser, checkEmail } from "../../api/requests/Auth.js";
import moment from "moment";
import { confirmEmail, registerEmail } from "../../api/requests/Emails.js";

function RegisterClient() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  function goBack() {
    navigate("/");
  }
  const [step, setStep] = useState(1);
  const [name, setName] = useState("");
  const [lastname, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [birthDate, setBirthDate] = React.useState(null);
  const [gender, setGender] = useState("");
  const [district, setDistrict] = useState("");
  const [municipality, setMunicipality] = useState("");

  const handleFuelTypeChange = (event, newValue) => {
    setFuelType(newValue);
  };

  // Function to update the gender value
  const handleGenderChange = (event) => {
    setGender(event.target.value);
  };
  const fuelTypes = [
    { id: 1, en: "Gasoline", pt: "Gasolina" },
    { id: 2, en: "Diesel", pt: "Diesel" },
    { id: 3, en: "Electric", pt: "Elétrico" },
    { id: 4, en: "Hybrid", pt: "Híbrido" },
    {
      id: 5,
      en: "LPG (Liquefied Petroleum Gas)",
      pt: "GPL (Gás de Petróleo Liquefeito)",
    },
    {
      id: 6,
      en: "CNG (Compressed Natural Gas)",
      pt: "GNC (Gás Natural Comprimido)",
    },
    { id: 7, en: "Hydrogen", pt: "Hidrogénio" },
  ];
  const [fuelType, setFuelType] = useState(1);
  const [address, setAddress] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [country, setCountry] = useState({
    _id: "66461cd6f36eda15cde8756d",
    id: 158,
    country_code: "PT",
    de: "🇵🇹 Portugal",
    en: "🇵🇹 Portugal",
    es: "🇵🇹 Portugal",
    fr: "🇵🇹 Portugal",
    pt: "🇵🇹 Portugal",
  });
  const [countries, setCountries] = useState("");
  const [districts, setDistricts] = useState("");
  const [municipalities, setMunicipalities] = useState("");
  const [municipalitiesCopy, setMunicipalitiesCopy] = useState("");

  const [genders, setGenders] = useState("");
  const [trainstations, setTrainstations] = useState([]);
  const [culturalActivities, setCulturalActivities] = useState([]);
  const [sportsActivities, setSportsActivities] = useState([]);

  const [timeLeft, setTimeLeft] = useState(60);
  const [isResendActive, setIsResendActive] = useState(false);
  const [zones, setZones] = useState([]);

  useEffect(() => {
    if (step === 4) {
      if (timeLeft > 0) {
        const timer = setTimeout(() => {
          setTimeLeft(timeLeft - 1);
        }, 1000);
        return () => clearTimeout(timer);
      } else {
        setIsResendActive(true);
      }
    }
  }, [timeLeft, step]);

  useEffect(() => {
    if (step !== 4) {
      setTimeLeft(60);
      setIsResendActive(false);
    }
  }, [step]);

  const handleResendCode = () => {
    resendCode();
    setTimeLeft(60);
    setIsResendActive(false);
  };

  useEffect(() => {
    getDistricts()
      .then((res) => {
        if (res.status === 200 && res.success) {
          setDistricts(res.data);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error(t("500"));
      });
    getZones()
      .then((res) => {
        if (res.status === 200 && res.success) {
          setZones(res.data);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error("500");
      });
    getMunicipalities()
      .then((res) => {
        if (res.status === 200 && res.success) {
          setMunicipalities(res.data);
          setMunicipalitiesCopy(res.data);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error(t("500"));
      });
    getCountries()
      .then((res) => {
        if (res.status === 200 && res.success) {
          setCountries(res.data);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error(t("500"));
      });
    getTrainStations()
      .then((res) => {
        if (res.status === 200 && res.success) {
          setTrainstations(res.data);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error(t("500"));
      });
    getActivities()
      .then((res) => {
        if (res.status === 200 && res.success) {
          let cultural = [];
          let sports = [];
          res.data.forEach((element) => {
            if (element.type_id == 1) {
              sports.push(element);
            } else if (element.type_id == 2) {
              cultural.push(element);
            }
          });
          setSportsActivities(sports);
          setCulturalActivities(cultural);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error(t("500"));
      });
    getGenders()
      .then((res) => {
        if (res.status === 200 && res.success) {
          setGenders(res.data);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error(t("500"));
      });
  }, []);

  useEffect(() => {
    if (district != "") {
      setMunicipality("");
      let newMuni = [];
      municipalitiesCopy.forEach((element) => {
        if (element.id_district == district.props.value) {
          newMuni.push(element);
        }
      });
      setMunicipalities(newMuni);
    }
  }, [district]);

  const handleNextStep = () => {
    if (name && lastname && email && password && step === 1) {
      // Regular expression to validate email format
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      // Check if the email is in a valid format
      if (!emailRegex.test(email)) {
        toast.error(t("invalidEmailFormat"));
      } else {
        const regex =
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#^+\-=_])[A-Za-z\d@$!%*?&#^+\-=_]{8,}$/;
        if (!regex.test(password)) {
          toast.error(t("passwordError"));
        } else {
          checkEmail({ email: email })
            .then((res) => {
              if (res.status == 200 && res.success) {
                toast.error(res.data.message);
              } else {
                setStep(step + 1);
              }
            })
            .catch((error) => {
              toast.error(t("500"));
            });
        }
      }
    } else if (
      birthDate &&
      gender &&
      address &&
      postalCode &&
      country &&
      municipality &&
      district &&
      step === 2
    ) {
      const today = new Date();
      const birthDateObj = new Date(birthDate);

      let age = today.getFullYear() - birthDateObj.getFullYear();
      const monthDifference = today.getMonth() - birthDateObj.getMonth();

      // Check if the current month is before the birth month or
      // if it is the birth month but the current day is before the birth day
      if (
        monthDifference < 0 ||
        (monthDifference === 0 && today.getDate() < birthDateObj.getDate())
      ) {
        age--;
      }

      if (age < 18) {
        toast.error(t("minAge"));
      } else {
        const postalCodePattern = /^\d{4}-\d{3}$/;
        if (!postalCodePattern.test(postalCode)) {
          toast.error(t("postalCodeError"));
        } else {
          setStep(step + 1);
        }
      }
    } else {
      toast.error(t("registerError"));
    }
  };
  const handleBackStep = () => {
    if (step === 2) {
      setStep(step - 1);
    }
    if (step === 3) {
      setStep(step - 1);
    }
  };
  const handleName = (event) => {
    setName(event.target.value);
  };
  const handleLastName = (event) => {
    setLastName(event.target.value);
  };
  const handleEmail = (event) => {
    setEmail(event.target.value);
  };
  const handlePwd = (event) => {
    setPassword(event.target.value);
  };

  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleBirthDate = (event) => {
    setBirthDate(event.target.value);
  };

  const handleAddress = (event) => {
    setAddress(event.target.value);
  };
  const handlePostalCode = (event) => {
    setPostalCode(event.target.value);
  };
  const handleCountry = (event) => {
    setCountry(event.target.value);
  };
  const handleSearch = (event) => {
    setSearch(event.target.value);
  };
  const handleSearch2 = (event) => {
    setSearch2(event.target.value);
  };

  const [selectedStation, setSelectedStation] = useState(null);
  const [selectedStations, setSelectedStations] = useState([]);

  const handleStationClick = (station) => {
    if (selectedStations.includes(station)) {
      setSelectedStations(selectedStations.filter((item) => item !== station));
    } else {
      if (selectedStations.length < 3) {
        setSelectedStations([...selectedStations, station]);
      } else {
        toast.error(t("stationError"));
      }
    }
  };

  const handleCountryChange = (event, newValue) => {
    setCountry(newValue);
  };

  const handleDistrictChange = (event, newValue) => {
    setDistrict(newValue);
  };

  const handleMunicipalityChange = (event, newValue) => {
    setMunicipality(newValue);
  };

  const [search, setSearch] = useState("");
  const [search2, setSearch2] = useState("");
  const filteredStations = trainstations.filter(
    (station) =>
      station.designation.toLowerCase().includes(search.toLowerCase()) ||
      station.county.toLowerCase().includes(search.toLowerCase()) ||
      station.district.toLowerCase().includes(search.toLowerCase()) ||
      station.parish.toLowerCase().includes(search.toLowerCase())
  );

  const filteredCultural = culturalActivities.filter(
    (activity) =>
      activity.pt.toLowerCase().includes(search2.toLowerCase()) ||
      activity.es.toLowerCase().includes(search2.toLowerCase()) ||
      activity.en.toLowerCase().includes(search2.toLowerCase()) ||
      activity.fr.toLowerCase().includes(search2.toLowerCase()) ||
      activity.de.toLowerCase().includes(search2.toLowerCase())
  );

  const filteredSports = sportsActivities.filter(
    (activity) =>
      activity.pt.toLowerCase().includes(search2.toLowerCase()) ||
      activity.es.toLowerCase().includes(search2.toLowerCase()) ||
      activity.en.toLowerCase().includes(search2.toLowerCase()) ||
      activity.fr.toLowerCase().includes(search2.toLowerCase()) ||
      activity.de.toLowerCase().includes(search2.toLowerCase())
  );
  const [selectedOption, setSelectedOption] = useState("Cultural");
  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  const [selectedCulturalActivities, setSelectedCulturalActivities] = useState(
    []
  );

  const [selectedSportsActivities, setSelectedSportsActivities] = useState([]);

  const handleCulturalClick = (activity) => {
    setSelectedCulturalActivities((prevActivities) => {
      const existingActivity = prevActivities.find(
        (item) => item.id === activity.id
      );

      if (existingActivity) {
        return prevActivities.filter((item) => item.id !== activity.id);
      } else {
        return [...prevActivities, { id: activity.id, zones: ["8"] }];
      }
    });
  };

  // Seleciona ou deseleciona uma zona para uma atividade cultural
  const handleZoneSelect = (activityId, zoneId) => {
    setSelectedCulturalActivities((prevActivities) => {
      return prevActivities.map((activity) => {
        if (activity.id === activityId) {
          const isZoneSelected = activity.zones.includes(zoneId.toString());
          const updatedZones = isZoneSelected
            ? activity.zones.filter((z) => z !== zoneId.toString())
            : [...activity.zones, zoneId.toString()];
          return { ...activity, zones: updatedZones };
        }
        return activity;
      });
    });
  };

  // Seleciona ou deseleciona uma atividade esportiva
  const handleSportsClick = (activity) => {
    setSelectedSportsActivities((prevActivities) => {
      const existingActivity = prevActivities.find(
        (item) => item.id === activity.id
      );

      if (existingActivity) {
        return prevActivities.filter((item) => item.id !== activity.id);
      } else {
        return [...prevActivities, { id: activity.id, zones: ["8"] }];
      }
    });
  };

  const handleZoneSelectSports = (activityId, zoneId) => {
    setSelectedSportsActivities((prevActivities) => {
      return prevActivities.map((activity) => {
        if (activity.id === activityId) {
          const isZoneSelected = activity.zones.includes(zoneId.toString());
          const updatedZones = isZoneSelected
            ? activity.zones.filter((z) => z !== zoneId.toString())
            : [...activity.zones, zoneId.toString()];

          return { ...activity, zones: updatedZones };
        }
        return activity;
      });
    });
  };

  // const itemsPerRow = 4;

  // const chunkedSportsActivities = [];
  // for (let i = 0; i < sportsActivities.length; i += itemsPerRow) {
  //   chunkedSportsActivities.push(sportsActivities.slice(i, i + itemsPerRow));
  // }

  // const chunkedCulturalActivities = [];
  // for (let i = 0; i < culturalActivities.length; i += itemsPerRow) {
  //   chunkedCulturalActivities.push(
  //     culturalActivities.slice(i, i + itemsPerRow)
  //   );
  // }
  const [idUserCreated, setIdUserCreated] = useState("");

  async function register() {
    if (selectedStations.length != 0) {
      if (!selectedCulturalActivities != 0 || !selectedSportsActivities != 0) {
        toast.error(t("activityError"));
      } else {
        const salt = await bcrypt.genSalt(10);
        const hashedPassword = await bcrypt.hash(password, salt);

        const formattedDate = moment(birthDate).format(
          "YYYY-MM-DDTHH:mm:ss.SSSZ"
        );
        let cultural = [];
        let sports = [];

        const logSelections = () => {
          cultural = selectedCulturalActivities.map((activity) => ({
            id: activity.id,
            zones: activity.zones || [],
          }));

          sports = selectedSportsActivities.map((activity) => ({
            id: activity.id,
            zones: activity.zones || [],
          }));
        };
        logSelections();

        addUser({
          first_name: name,
          last_name: lastname,
          email: email,
          password: hashedPassword,
          salt: salt,
          birth_date: formattedDate,
          gender: gender,
          zip_code: postalCode,
          country: country.id,
          district: district.props.value,
          municipality: municipality.props.value,
          country_code: country.country_code,
          sports_preference: selectedSportsActivities,
          culture_preference: selectedCulturalActivities,
          fuel_type: fuelType.id,
        })
          .then((res) => {
            // console.log(res)
            if (res.status == 200 && res.success) {
              let id = res.data.userId;
              setIdUserCreated(id);
              registerEmail({ id: id })
                .then((res2) => {
                  if (res2.status == 200 && res2.success) {
                    setStep(step + 1);
                  } else {
                    toast.error(res2.error);
                  }
                })
                .catch((error) => {
                  // console.log(error)
                  toast.error(t("500"));
                });
            } else {
              toast.error(res.error);
            }
          })
          .catch((error) => {
            // console.log(error)
            toast.error(t("500"));
          });
      }
    } else {
      toast.error(t("stationError2"));
    }
  }

  function confirmCode() {
    confirmEmail({
      id: idUserCreated,
      code: finalCode,
    })
      .then((res) => {
        if (res.status == 200 && res.success) {
          toast.success(t("registerSuccess"));
          navigate("/loginclient");
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error(t("500"));
      });
  }

  const [code, setCode] = useState(["", "", "", "", "", ""]);
  const [finalCode, setFinalCode] = useState("");

  const handleChange = (value, index) => {
    if (/^[0-9]$/.test(value) || value === "") {
      const newCode = [...code];
      newCode[index] = value;
      setCode(newCode);

      if (value !== "" && index < 5) {
        document.getElementById(`code-input-${index + 1}`).focus();
      }

      if (newCode.every((num) => num !== "")) {
        handleCodeComplete(newCode.join(""));
      }
    }
  };

  const handleKeyDown = (event, index) => {
    if (event.key === "Backspace" && !code[index] && index > 0) {
      document.getElementById(`code-input-${index - 1}`).focus();
    }
  };

  const handleCodeComplete = (completeCode) => {
    setFinalCode(completeCode);
  };

  function resendCode() {
    registerEmail({ id: idUserCreated })
      .then((res2) => {
        if (res2.status == 200 && res2.success) {
          toast.success(t("coderesend"));
        } else {
          toast.error(res2.error);
        }
      })
      .catch((error) => {
        toast.error(t("500"));
      });
  }
  const minDate = dayjs().subtract(16, "years");
  const [checked, setChecked] = useState(false);
  const [open, setOpen] = useState(false);

  const handleCheckboxChange = () => {
    setOpen(true);
  };

  const handleAccept = () => {
    setChecked(true);
    setOpen(false);
  };
  const handleReject = () => {
    setChecked(false);
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    // Define o primeiro distrito como padrão se district for vazio
    if (district == "" && districts.length > 0) {
      setDistrict(districts[0]);
    }

    if (municipality == "" && municipalities.length > 0) {
      setMunicipality(municipalities[0]);
    }
  }, [district, municipality]);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);


  return (
    <div className="background">
      <div className="backgroundImageSmall">
        <div className="topcenter">
          <div className="logo"></div>
          <p className="titlesmall">{t("sameplace")}</p>
        </div>
      </div>
      <div className="bottomcenterregister">
        <div className="topleftreg">
          {step === 1 && (
            <IconButton onClick={goBack}>
              <ArrowBackIcon    style={{ fill: "#044535", zIndex: 99, fontSize:isMobile?"20px":"25px" }}
          />
            </IconButton>
          )}
          {(step === 2 || step === 3) && (
            <IconButton onClick={handleBackStep}>
              <ArrowBackIcon    style={{ fill: "#044535", zIndex: 99, fontSize:isMobile?"20px":"25px" }}
          />
            </IconButton>
          )}
        </div>
        {step === 1 && (
          <div
            style={{
              marginTop: "10vh",
              flexDirection: "column",
              display: "flex",
            }}
          >
            <div className="input-container">
              <input
                className={`input ${name && "has-text"}`}
                type="text"
                id="name"
                onChange={handleName}
                value={name}
                required
              />
              <label className={!name && "visible"}>{t("firstname")}</label>
            </div>
            <div className="input-container">
              <input
                className={`input ${lastname && "has-text"}`}
                type="text"
                id="name"
                onChange={handleLastName}
                value={lastname}
                required
              />
              <label className={!lastname && "visible"}>{t("lastname")}</label>
            </div>
            <div className="input-container">
              <input
                className={`input ${email && "has-text"}`}
                type="email"
                id="email"
                onChange={handleEmail}
                value={email}
                required
              />
              <label className={!email && "visible"}>{t("email")}</label>
            </div>

            <div className="input-container">
              <input
                className={`input ${password && "has-text"}`}
                type={showPassword ? "text" : "password"}
                id="password"
                onChange={handlePwd}
                value={password}
                required
              />
              <label htmlFor="password" className={!password && "visible"}>
                {t("password")}
              </label>
              <button className="toggle-password" onClick={toggleShowPassword}>
                {showPassword ? (
                  <Visibility size={"small"} />
                ) : (
                  <VisibilityOff size={"small"} />
                )}
              </button>
            </div>

            <button className="greenbtn" onClick={handleNextStep}>
              {" "}
              <ArrowForwardIcon size={"big"} />
            </button>
            <Link className="textlinklogin2" to={"/loginclient"}>
              {t("registed")}
            </Link>
          </div>
        )}
        {step === 2 && (
          <>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale={i18next?.language}
              >
                <DatePicker
                  openTo="day"
                  variant="standard"
                  label={""}
                  views={["year", "month", "day"]}
                  disableFuture
                  value={birthDate}
                  maxDate={minDate}
                  format="DD/MM/YYYY"
                  onChange={(e) => {
                    setBirthDate(e);
                  }}
                  slotProps={{
                    textField: {
                      placeholder: t("birthdate"),
                      className: "birthDateLabel",
                    },
                    tabs: {
                      hidden: false,
                    },
                    day: {
                      sx: {
                        "&.MuiPickersDay-root.Mui-selected": {
                          backgroundColor: "#13BB56 !important",
                        },
                      },
                    },
                    desktopPaper: {
                      sx: {
                        borderRadius: "8px",
                        ".MuiPickersYear-yearButton.Mui-selected": {
                          backgroundColor: "#13BB56 !important",
                        },
                        ".MuiPickersMonth-monthButton.Mui-selected": {
                          backgroundColor: "#13BB56 !important",
                        },
                      },
                    },
                  }}
                  sx={{
                    "& .MuiOutlinedInput-input": {
                      color:
                        birthDate === null
                          ? "rgba(15, 17, 17, 0.80) !important"
                          : "rgba(15, 17, 17, 0.90) !important",
                      marginLeft: birthDate === null ? "5px" : "0px",
                      textAlign: "left",
                      fontFamily: "Montserrat",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: 600,
                      padding: "15px 5px",
                    },
                    "& .MuiOutlinedInput-root": {
                      color: "rgba(15, 17, 17, 0.70) !important",
                      textAlign: "left",
                      fontFamily: "Montserrat",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: 600,
                      borderRadius: "0px",
                      background: "transparent",
                      borderBottom: "1px solid #13BB56",
                      width: "330px",
                    },
                    "& .MuiOutlinedInput-notchedOutline ": {
                      border: "1px",
                    },
                  }}
                />
              </LocalizationProvider>
              <div className="input-container-gender">
                <TextField
                  select
                  fullWidth
                  label={t("gender")}
                  placeholder={t("gender")}
                  value={gender}
                  onChange={handleGenderChange}
                  variant="standard"
                  InputLabelProps={{
                    shrink: gender === "" ? true : false,

                    style:
                      gender === ""
                        ? { position: "absolute", top: 35, paddingTop: "0px" }
                        : { display: "none" },
                  }}
                  sx={{
                    "& .MuiInputLabel-root": {
                      color: "rgba(0, 0, 0, 0.4) !important",
                    },
                    "& .MuiInputBase-root::before": {
                      width: "100%",
                      textAlign: "left",
                      fontFamily: "Montserrat !important",
                      background: "transparent",
                      borderBottom: "none !important",
                    },
                    "& .MuiInputBase-root::after": {
                      width: "100%",
                      textAlign: "left",
                      fontFamily: "Montserrat !important",
                      background: "transparent",
                      borderBottom: "none !important",
                    },
                    "& .MuiInputBase-root::hover": {
                      border: "none !important",
                    },
                    "& .MuiInputBase-root": {
                      textAlign: "left",
                      fontFamily: "Montserrat",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: 600,
                      borderRadius: "0px",
                      background: "transparent",
                      borderBottom: "1px solid #13BB56 !important",
                      width: "100%",
                      height: "43px",
                      color: "rgba(15, 17, 17, 0.90) ",
                      padding: "15px 10px",
                    },
                  }}
                >
                  {genders.map((option) => (
                    <MenuItem
                      key={option._id}
                      value={option.id}
                      sx={{
                        color: "rgba(15, 17, 17, 0.90) ",
                        fontFamily: "Montserrat",
                        fontWeight: "600",
                      }}
                    >
                      {option[i18next.language] || option.en}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              <div className="input-container" style={{ marginTop: "2px" }}>
                <input
                  className={`input ${address && "has-text"}`}
                  type="text"
                  id="address"
                  onChange={handleAddress}
                  value={address}
                  required
                />
                <label className={!address && "visible"}>{t("address")}</label>
              </div>
              <div className="input-container">
                <input
                  className={`input ${postalCode && "has-text"}`}
                  type="text"
                  id="postalCode"
                  onChange={handlePostalCode}
                  value={postalCode}
                  required
                />
                <label className={!postalCode && "visible"}>
                  {t("postalcode")}
                </label>
              </div>

              <div style={{ marginTop: "-2px" }}>
                <Autocomplete
                  options={countries}
                  getOptionLabel={(option) =>
                    option[i18next.language] || option.en
                  }
                  value={country}
                  onChange={handleCountryChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label=""
                      placeholder={t("country")}
                      variant="standard"
                      InputProps={{
                        ...params.InputProps,
                        sx: {
                          "&::placeholder": {
                            color: "rgba(15, 17, 17, 0.4)",
                          },
                        },
                      }}
                      InputLabelProps={{
                        shrink: country === "" ? true : false,

                        style:
                          country === ""
                            ? {
                                position: "absolute",
                                top: 35,
                                paddingTop: "0px",
                              }
                            : { display: "none" },
                      }}
                      sx={{
                        "& .MuiInputLabel-root": {
                          color: "rgba(0, 0, 0, 0.4) !important",
                        },

                        marginTop: "16px",
                        "& .MuiInputBase-root": {
                          textAlign: "left",
                          fontFamily: "Montserrat",
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontWeight: 600,
                          borderRadius: "0px",
                          background: "transparent",
                          width: "100%",
                          height: "43px",
                          color: "rgba(15, 17, 17, 0.90)",
                          padding: "15px 10px",
                        },
                        "& .MuiInput-underline:before": {
                          borderBottom: "1px solid #13BB56",
                        },
                        "& .MuiInput-underline:hover:not(.Mui-disabled):before":
                          {
                            borderBottom: "1px solid #13BB56",
                          },
                        "& .MuiInput-underline:after": {
                          borderBottom: "1px solid #13BB56",
                        },
                        "& .Mui-focused .MuiInput-underline:after": {
                          borderBottom: "2px solid #13BB56",
                        },
                      }}
                    />
                  )}
                  renderOption={(props, option) => (
                    <MenuItem
                      {...props}
                      key={option.id}
                      value={option.country_code.toLowerCase()}
                    >
                      {option[i18next.language] || option.en}
                    </MenuItem>
                  )}
                  sx={{
                    "& .MuiAutocomplete-popupIndicator": {
                      display: "none",
                    },
                    "& .MuiAutocomplete-clearIndicator": {
                      display: "none",
                    },
                    "& .MuiOutlinedInput-root": {
                      padding: 0,
                    },
                    "& .MuiAutocomplete-listbox": {
                      fontFamily: "Montserrat",
                      fontSize: "14px",
                    },
                    "& .MuiAutocomplete-inputRoot": {
                      padding: "0 !important",
                    },
                  }}
                />
              </div>
              <div
                className="input-container-gender"
                style={{ marginBottom: "5px" }}
              >
                <TextField
                  fullWidth
                  select
                  label={t("district")}
                  placeholder={t("district")}
                  value={district.id}
                  onChange={handleDistrictChange}
                  variant="standard"
                  InputLabelProps={{
                    shrink: district === "" ? true : false,

                    style:
                      district === ""
                        ? { position: "absolute", top: 35, paddingTop: "0px" }
                        : { display: "none" },
                  }}
                  sx={{
                    "& .MuiInputLabel-root": {
                      color: "rgba(0, 0, 0, 0.4) !important",
                    },
                    "& .MuiInputBase-root::before": {
                      width: "100%",
                      textAlign: "left",
                      fontFamily: "Montserrat !important",
                      background: "transparent",
                      borderBottom: "none !important",
                    },
                    "& .MuiInputBase-root::after": {
                      width: "100%",
                      textAlign: "left",
                      fontFamily: "Montserrat !important",
                      background: "transparent",
                      borderBottom: "none !important",
                    },
                    "& .MuiInputBase-root::hover": {
                      border: "none !important",
                    },
                    "& .MuiInputBase-root": {
                      textAlign: "left",
                      fontFamily: "Montserrat",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: 600,
                      borderRadius: "0px",
                      background: "transparent",
                      borderBottom: "1px solid #13BB56 !important",
                      width: "100%",
                      height: "43px",
                      color: "rgba(15, 17, 17, 0.90) ",
                      padding: "15px 10px",
                    },
                  }}
                >
                  {districts.map((option) => (
                    <MenuItem
                      key={option._id}
                      value={option.id}
                      sx={{
                        color: "rgba(15, 17, 17, 0.90) ",
                        fontFamily: "Montserrat",
                        fontWeight: "600",
                      }}
                    >
                      {option[i18next.language] || option.en}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              <div
                className="input-container-gender"
                style={{ marginTop: "-15.5px" }}
              >
                <TextField
                  select
                  label={t("municipality")}
                  fullWidth
                  placeholder={t("municipality")}
                  value={municipality.id}
                  onChange={handleMunicipalityChange}
                  variant="standard"
                  InputLabelProps={{
                    shrink: municipality === "" ? true : false,

                    style:
                      municipality === ""
                        ? {
                            position: "absolute",
                            top: 35,
                            marginTop: "-20px !important",
                          }
                        : { display: "none" },
                  }}
                  sx={{
                    "& .MuiInputLabel-root": {
                      color:
                        municipality === ""
                          ? "rgba(0, 0, 0, 0.4) !important"
                          : "rgba(0, 0, 0, 0.9) !important",
                    },
                    "& .MuiInputBase-root::before": {
                      width: "100%",
                      textAlign: "left",
                      fontFamily: "Montserrat !important",
                      background: "transparent",
                      borderBottom: "none !important",
                    },
                    "& .MuiInputBase-root::after": {
                      width: "100%",
                      textAlign: "left",
                      fontFamily: "Montserrat !important",
                      background: "transparent",
                      borderBottom: "none !important",
                    },
                    "& .MuiInputBase-root::hover": {
                      border: "none !important",
                    },
                    "& .MuiInputBase-root": {
                      textAlign: "left",
                      fontFamily: "Montserrat",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: 600,
                      borderRadius: "0px",
                      background: "transparent",
                      borderBottom: "1px solid #13BB56 !important",
                      width: "100%",
                      height: "43px",
                      color: "rgba(15, 17, 17, 0.90) ",
                      padding: "15px 10px",
                    },
                  }}
                >
                  {municipalities.map((option) => (
                    <MenuItem
                      key={option._id}
                      value={option.id}
                      sx={{
                        color: "rgba(15, 17, 17, 0.90) ",
                        fontFamily: "Montserrat",
                        fontWeight: "600",
                      }}
                    >
                      {option[i18next.language] || option.en}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              <div
                className="input-container-gender"
                style={{ marginTop: "-15.5px" }}
              >
                <TextField
                  select
                  label={t("fuel_type")}
                  fullWidth
                  placeholder={t("fuel_type")}
                  value={fuelType.id}
                  onChange={handleFuelTypeChange}
                  variant="standard"
                  InputLabelProps={{
                    shrink: fuelType === "" ? true : false,
                    style:
                      fuelType === ""
                        ? {
                            position: "absolute",
                            top: 35,
                            marginTop: "-20px !important",
                          }
                        : { display: "none" },
                  }}
                  sx={{
                    "& .MuiInputLabel-root": {
                      color:
                        fuelType === ""
                          ? "rgba(0, 0, 0, 0.4) !important"
                          : "rgba(0, 0, 0, 0.9) !important",
                    },
                    "& .MuiInputBase-root::before": {
                      width: "100%",
                      textAlign: "left",
                      fontFamily: "Montserrat !important",
                      background: "transparent",
                      borderBottom: "none !important",
                    },
                    "& .MuiInputBase-root::after": {
                      width: "100%",
                      textAlign: "left",
                      fontFamily: "Montserrat !important",
                      background: "transparent",
                      borderBottom: "none !important",
                    },
                    "& .MuiInputBase-root::hover": {
                      border: "none !important",
                    },
                    "& .MuiInputBase-root": {
                      textAlign: "left",
                      fontFamily: "Montserrat",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: 600,
                      borderRadius: "0px",
                      background: "transparent",
                      borderBottom: "1px solid #13BB56 !important",
                      width: "100%",
                      height: "43px",
                      color: "rgba(15, 17, 17, 0.90)",
                      padding: "15px 10px",
                    },
                  }}
                >
                  {fuelTypes.map((option) => (
                    <MenuItem
                      key={option.id}
                      value={option.id}
                      sx={{
                        color: "rgba(15, 17, 17, 0.90)",
                        fontFamily: "Montserrat",
                        fontWeight: "600",
                      }}
                    >
                      {option[i18next.language] || option.en}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            </div>
            <button
              className="greenbtn"
              onClick={handleNextStep}
              style={{ marginTop: "1rem" }}
            >
              <ArrowForwardIcon size={"big"} />
            </button>
            {/* <Link className="textlinklogin2" style={{ textDecoration: "none" }}>
         {t("skip")}
       </Link> */}
          </>
        )}
        {step === 3 && (
          <div
            style={{
              marginTop: "5vh",
              flexDirection: "column",
              display: "flex",
            }}
          >
            <div className="input-container">
              <label style={{ paddingBottom: "30px", left: 0 }}>
                {t("nearstation")}
                <br />
                <span style={{ fontSize: "10px" }}>{t("selectmax3")}</span>
              </label>

              <input
                className={`input ${search && "has-text"}`}
                type="text"
                style={{ paddingLeft: "40px" }}
                id="search"
                onChange={handleSearch}
                value={search}
                required
              />
              <SearchIcon
                className={"searchicon"}
                style={{ color: Colors.green }}
              ></SearchIcon>
            </div>
            <div className="scrollContainer">
              <div className="row">
                {filteredStations.map((station) => (
                  <Card
                    key={station._id}
                    className={
                      selectedStations.includes(station)
                        ? "selectedCardStation"
                        : "cardStation"
                    }
                    onClick={() => handleStationClick(station)}
                  >
                    <CardContent
                      className={
                        selectedStations.includes(station)
                          ? "selectedCardStation"
                          : "cardStation"
                      }
                    >
                      <Typography
                        className={
                          selectedStations.includes(station)
                            ? "selectedTextCard"
                            : "textCard"
                        }
                      >
                        {station.designation}{" "}
                      </Typography>
                    </CardContent>
                  </Card>
                ))}
              </div>
            </div>

            <label className="preflabel" style={{ marginTop: "-5px" }}>
              {t("preferences")}
            </label>
            <div className="row">
              <Card
                className={
                  selectedOption === "Cultural"
                    ? "selectedCardPref"
                    : "cardPref"
                }
                onClick={() => handleOptionClick("Cultural")}
              >
                <CardContent
                  className={
                    selectedOption === "Cultural"
                      ? "selectedCardPref"
                      : "cardPref"
                  }
                >
                  <Typography
                    className={
                      selectedOption === "Cultural"
                        ? "selectedTextCard"
                        : "textCard"
                    }
                  >
                    {t("cultural")}
                  </Typography>
                </CardContent>
              </Card>
              <Card
                className={
                  selectedOption === "Sports" ? "selectedCardPref" : "cardPref"
                }
                onClick={() => handleOptionClick("Sports")}
              >
                <CardContent
                  className={
                    selectedOption === "Sports"
                      ? "selectedCardPref"
                      : "cardPref"
                  }
                >
                  <Typography
                    className={
                      selectedOption === "Sports"
                        ? "selectedTextCard"
                        : "textCard"
                    }
                  >
                    {t("sports")}
                  </Typography>
                </CardContent>
              </Card>
            </div>
            <div className="input-container" style={{ marginTop: "-10px", marginBottom:"5px" }}>
              <input
                className={`input ${search2 && "has-text"}`}
                type="text"
                style={{ paddingLeft: "40px" }}
                id="search"
                onChange={handleSearch2}
                value={search2}
                required
              />
              <SearchIcon
                className={"searchicon"}
                style={{ color: Colors.green }}
              ></SearchIcon>
            </div>
            <div>
              {selectedOption === "Cultural" && (
                <div className="scrollContainerActivities3">
                  <div className="rowActivities">
                    {filteredCultural.map((activity) => {
                      const isSelected = selectedCulturalActivities.some(
                        (selectedActivity) =>
                          selectedActivity.id === activity.id
                      );

                      return (
                        <Card
                          key={activity.id}
                          className={
                            isSelected ? "selectedActivityCard" : "activityCard"
                          }
                          onClick={() => handleCulturalClick(activity)}
                        >
                          <CardContent>
                            <Typography>
                              {activity[i18next.language] || activity.en}
                            </Typography>
                          </CardContent>

                          {isSelected && (
                            <div className="zoneGrid">
                              {zones.map((zone) => {
                                const activityWithZones =
                                  selectedCulturalActivities.find(
                                    (selectedActivity) =>
                                      selectedActivity.id === activity.id
                                  );

                                const isZoneSelected =
                                  activityWithZones?.zones.includes(
                                    zone.id.toString()
                                  );

                                return (
                                  <button
                                    key={zone.id}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleZoneSelect(activity.id, zone.id);
                                    }}
                                    className={`zoneButton ${
                                      isZoneSelected ? "selected" : ""
                                    }`}
                                  >
                                    {zone[i18next.language] || zone.en}{" "}
                                  </button>
                                );
                              })}
                            </div>
                          )}
                        </Card>
                      );
                    })}
                  </div>
                </div>
              )}

              {selectedOption === "Sports" && (
                <div className="scrollContainerActivities3">
                  <div className="rowActivities">
                    {filteredSports.map((activity) => {
                      const isSelected = selectedSportsActivities.some(
                        (selectedActivity) =>
                          selectedActivity.id === activity.id
                      );

                      return (
                        <Card
                          key={activity.id}
                          className={
                            isSelected ? "selectedActivityCard" : "activityCard"
                          }
                          onClick={() => handleSportsClick(activity)}
                        >
                          <CardContent>
                            <Typography>
                              {activity[i18next.language] || activity.en}
                            </Typography>
                          </CardContent>

                          {isSelected && (
                            <div className="zoneGrid">
                              {zones.map((zone) => {
                                const isZoneSelected = selectedSportsActivities
                                  .find(
                                    (selectedActivity) =>
                                      selectedActivity.id === activity.id
                                  )
                                  ?.zones?.includes(zone.id.toString());

                                return (
                                  <button
                                    key={zone.id}
                                    onClick={(e) => {
                                      e.stopPropagation(); // Previne a propagação do clique
                                      handleZoneSelectSports(
                                        activity.id,
                                        zone.id
                                      ); // Chama a função de seleção de zona
                                    }}
                                    className={`zoneButton ${
                                      isZoneSelected ? "selected" : ""
                                    }`}
                                  >
                                    {zone[i18next.language] || zone.en}
                                  </button>
                                );
                              })}
                            </div>
                          )}
                        </Card>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
            <FormControlLabel
              control={
                <Checkbox checked={checked} onClick={handleCheckboxChange} />
              }
              label={
                <span
                  onClick={handleCheckboxChange}
                  className="bodytext"
                  style={{ cursor: "pointer", textDecoration: "underline" }}
                >
                  {t("I agree to the terms and conditions")}
                </span>
              }
            />
            <Dialog open={open} onClose={handleClose}>
              <DialogTitle className="orderTitle">
                {t("Terms and Conditions")}
              </DialogTitle>
              <DialogContent style={{ maxHeight: "400px", overflowY: "auto" }}>
                <Typography className="textCancel">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Integer nec odio. Praesent libero. Sed cursus ante dapibus
                  diam. Sed nisi. Nulla quis sem at nibh elementum imperdiet.
                  Duis sagittis ipsum. Praesent mauris. Fusce nec tellus sed
                  augue semper porta. Mauris massa. Vestibulum lacinia arcu eget
                  nulla.
                  <br />
                  <br />
                  Class aptent taciti sociosqu ad litora torquent per conubia
                  nostra, per inceptos himenaeos. Curabitur sodales ligula in
                  libero. Sed dignissim lacinia nunc. Curabitur tortor.
                  Pellentesque nibh. Aenean quam. In scelerisque sem at dolor.
                  Maecenas mattis. Sed convallis tristique sem. Proin ut ligula
                  vel nunc egestas porttitor. Morbi lectus risus, iaculis vel,
                  suscipit quis, luctus non, massa.
                  <br />
                  <br />
                  Fusce ac turpis quis ligula lacinia aliquet. Mauris ipsum.
                  Nulla metus metus, ullamcorper vel, tincidunt sed, euismod in,
                  nibh. Quisque volutpat condimentum velit. Class aptent taciti
                  sociosqu ad litora torquent per conubia nostra, per inceptos
                  himenaeos. Nam nec ante. Sed lacinia, urna non tincidunt
                  mattis, tortor neque adipiscing diam, a cursus ipsum ante quis
                  turpis. Nulla facilisi.
                  <br />
                  <br />
                  Ut fringilla. Suspendisse potenti. Nunc feugiat mi a tellus
                  consequat imperdiet. Vestibulum sapien. Proin quam. Etiam
                  ultrices. Suspendisse in justo eu magna luctus suscipit. Sed
                  lectus. Integer euismod lacus luctus magna. Quisque cursus,
                  metus vitae pharetra auctor, sem massa mattis sem, at interdum
                  magna augue eget diam. Vestibulum ante ipsum primis in
                  faucibus orci luctus et ultrices posuere cubilia Curae; Morbi
                  lacinia molestie dui. Praesent blandit dolor. Sed non quam. In
                  vel mi sit amet augue congue elementum. Morbi in ipsum sit
                  amet pede facilisis laoreet. Donec lacus nunc, viverra nec,
                  blandit vel, egestas et, augue. Vestibulum tincidunt malesuada
                  tellus. Ut ultrices ultrices enim. Curabitur sit amet mauris.
                  Morbi in dui quis est pulvinar ullamcorper. Nulla facilisi.
                  <br />
                  <br />
                  Integer lacinia sollicitudin massa. Cras metus. Sed aliquet
                  risus a tortor. Integer id quam. Morbi mi. Quisque nisl felis,
                  venenatis tristique, dignissim in, ultrices sit amet, augue.
                  Proin sodales libero eget ante. Nulla quam. Aenean laoreet.
                  Vestibulum nisi lectus, commodo ac, facilisis ac, ultricies
                  eu, pede. Ut orci risus, accumsan porttitor, cursus quis,
                  aliquet eget, justo. Sed pretium blandit orci. Ut eu diam at
                  pede suscipit sodales.
                </Typography>
              </DialogContent>
              <DialogActions
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                {/* <Button onClick={handleReject} className="deleteBtn">
            {t("close")}
          </Button> */}
                <Button onClick={handleAccept} className="cancelBtn">
                  {t("accept")}
                </Button>
              </DialogActions>
            </Dialog>
            <button
              className="registergreenbtn"
              onClick={() => {
                register();
              }}
            >
              {t("register")}
            </button>
          </div>
        )}
        {step === 4 && (
          <div style={{ textAlign: "center", marginBottom: "0px" }}>
            <h3>{t("confirmationEmail")}</h3>
            <p>
              {t("confirmationCode")} <b>{email}</b>
            </p>
            <p>{t("confirmationCode2")}</p>
            <div
              style={{ display: "flex", justifyContent: "center", gap: "10px" }}
            >
              {code.map((num, index) => (
                <input
                  key={index}
                  id={`code-input-${index}`}
                  type="text"
                  value={num}
                  onChange={(e) => handleChange(e.target.value, index)}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                  maxLength="1"
                  style={{
                    width: "40px",
                    height: "40px",
                    textAlign: "center",
                    fontSize: "24px",
                    fontFamily: "Montserrat",
                    borderRadius: "4px",
                    border: "1px solid #13BB56",
                    color: "rgba(15, 17, 17, 0.90)",
                    caretColor: "auto",
                    outline: "none",

                    "&:focus": {
                      outline: "none",
                      border: "1px solid #13BB56",
                    },

                    "::selection": {
                      background: "transparent",
                      color: "inherit",
                    },
                  }}
                />
              ))}
            </div>
            <div>
              <p className="textlinkcode">
                {isResendActive ? (
                  <Link className="textlinkcode" onClick={handleResendCode}>
                    {t("resend")}
                  </Link>
                ) : (
                  `${t("resendCode")} ${timeLeft} ${t("seconds")}`
                )}
              </p>
            </div>
            <button
              className="smallgreenbtn"
              onClick={() => {
                confirmCode();
              }}
            >
              {t("confirm")}
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default RegisterClient;
