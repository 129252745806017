import { Avatar, Menu, MenuItem } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LogoutIcon from "@mui/icons-material/Logout";
import DescriptionIcon from "@mui/icons-material/Description";
import EditIcon from "@mui/icons-material/Edit";
import Cookies from "js-cookie";
import toast from "react-hot-toast";
import { getUser } from "../api/requests/User";

function HeaderClientTransparent() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [name, setName] = useState("");
  const [role, setRole] = useState("");
  const [photo, setPhoto] = useState("");
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    Cookies.remove("id");
    Cookies.remove("role");
    Cookies.remove("email");
    Cookies.remove("token");
    Cookies.remove("first_name");
    Cookies.remove("last_name");
    localStorage.removeItem("photo");
    navigate("/");
    handleMenuClose();
  };

  useEffect(() => {
    const id = Cookies.get("id");
    const first_name = Cookies.get("first_name");
    const last_name = Cookies.get("last_name");
    const role = Cookies.get("role");
    const photo = localStorage.getItem("photo");

    if (id && first_name && last_name && role) {
      setName(first_name + " " + last_name);
      setRole(role);
      setPhoto(photo);

      getUser(id).then((res) => {
        if (res.success && res.status == 200) {
          setName(res.data.first_name + " " + res.data.last_name);
          setPhoto(res.data.photo);
        } else {
          toast.error(res.error);
        }
      });
    }
  }, []);

  const handleTerms = () => {
    navigate("/termsclient");
    handleMenuClose();
  };

  const handleEditData = () => {
    const client = true;
    navigate("/editpersonaldata", {
      state: { isClient: client },
    });
    handleMenuClose();
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <div className="headerTransparent">
        {isMobile ? (
          <span
            className="logonavclient"
            style={{marginLeft:"1.3rem"}}
            onClick={() => {
              navigate("/dashboardclient");
            }}
          ></span>
        ) : null}

        <div className="headerRight" style={{ float: "right" }}>
          <li className="header-toggle" style={{ height: 0 }}>
            <div
              style={{
                marginRight: 30,
                flexDirection: "column",
                display: "flex",
              }}
            >
              <a className="textHeaderWhite">{name}</a>
              <a className="subtextHeaderWhite">{role}</a>
            </div>
            <div style={{ marginRight:isMobile? 10: 40 }}>
              <Avatar
                src={photo}
                sx={{
                  width: 35,
                  height: 35,
                  border: "1px solid #171E24",
                  cursor: "pointer",
                }}
                onClick={handleMenuOpen}
              />
            </div>
          </li>
        </div>
      </div>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        sx={{
          background: "transparent",

          borderColor: "transparent",
          marginTop: "25px",
          width: "254px",
          height: "auto",
        }}
        MenuListProps={{
          style: {
            padding: "8px 0",
          },
        }}
      >
        <MenuItem
          onClick={handleLogout}
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "10px !important",
            fontSize: "14px",
            fontWeight: 500,
            fontFamily: "Montserrat",
            color: "#0F1111",
          }}
        >
          {t("logout")}
          <LogoutIcon
            sx={{ marginLeft: "10px", color: "#0F1111", fontSize: "16px" }}
          />
        </MenuItem>
        <MenuItem
          onClick={handleTerms}
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "10px !important",
            fontSize: "14px",
            fontWeight: 500,
            fontFamily: "Montserrat",
            color: "#0F1111",
          }}
        >
          {t("terms")}
          <DescriptionIcon
            sx={{ marginLeft: "10px", color: "#0F1111", fontSize: "16px" }}
          />
        </MenuItem>
        <MenuItem
          onClick={handleEditData}
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "10px !important",
            fontSize: "14px",
            fontWeight: 500,
            fontFamily: "Montserrat",
            color: "#0F1111",
          }}
        >
          {t("editPersonalData")}
          <EditIcon
            sx={{ marginLeft: "10px", color: "#0F1111", fontSize: "16px" }}
          />
        </MenuItem>
      </Menu>
    </>
  );
}

export default HeaderClientTransparent;
