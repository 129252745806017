import React, { useEffect, useState } from "react";
import logo from "../../images/logo.svg";
import background from "../../images/background.png";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardContent,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  InputAdornment,
  Menu,
  MenuItem,
  TextField,
  Typography,
  Autocomplete,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "../../App.css";
import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";

import i18next from "i18next";
import Header from "../../components/header.js";
import EditIcon from "@mui/icons-material/Edit";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
import { CalendarIcon } from "@mui/x-date-pickers/icons";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import "dayjs/locale/pt";
import "dayjs/locale/en-gb";
import feira from "../../images/example/feira.jpeg";
import {
  parse,
  isAfter,
  isBefore,
  isSameDay,
  differenceInDays,
} from "date-fns";
import Dropzone from "react-dropzone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faPen, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { getTrainStations } from "../../api/requests/Data.js";
import toast from "react-hot-toast";
import HeaderManager from "../../components/headerManager.js";
import HeaderManagerWhite from "../../components/headerManagerWhite.js";
import NavManager from "../../components/navManager.js";
import NavManagerHorizontal from "../../components/navManagerHorizontal.js";

function EventCreateManager() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openDialogRegister, setOpenRegisterDialog] = useState(false);

  const trainStations = ["Lisboa Santa Apolónia", "Porto Campanhã", "Faro"];
  const preferences = ["Teatro", "Cinema", "Futebol"];
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const inputLabelPropsStyle = {
    color: "#044535 !important",
    textAlign: "right",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "22px",
  };

  const inputPropsStyle = {
    color: "#0F111199",
    textAlign: "right",
    fontFamily: "Montserrat",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "22px",
    borderRadius: "8px",
    background: "#0f11110d",
    paddingLeft: "10px",
    width: isMobile?"100%":"60%",
  };

  const inputTrainStyle = {
    color: "#0F1111",
    textAlign: "right",
    fontFamily: "Montserrat",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "22px",
    borderRadius: "8px",
    background: "white",
    width: "60%",
  };

  const DateCell = ({ value }) => {
    const today = new Date();
    const eventDates = value.split(" ");
    const startDate = parse(eventDates[0], "dd/MM/yyyy", new Date());
    const endDate =
      eventDates.length > 1
        ? parse(eventDates[1], "dd/MM/yyyy", new Date())
        : startDate;

    const isPast = isAfter(today, endDate);
    const isFuture = isBefore(today, startDate);
    const isOngoing = !isPast && !isFuture;

    let statusText = "";
    let statusColor = "";

    if (isPast) {
      statusText = t("finished");
      statusColor = "#ED4F2C";
    } else if (isOngoing) {
      statusText = t("live");
      statusColor = "#13BB56";
    } else if (isFuture) {
      const daysToStart = differenceInDays(startDate, today);
      statusText = `${t("in")} ${daysToStart} ${t("days")}`;
      statusColor = "#B0B0B0";
    }

    return (
      <div style={{ display: "flex", alignItems: "center", marginTop: "20px" }}>
        <div
          style={{
            width: "12px",
            height: "12px",
            backgroundColor: statusColor,
            borderRadius: "3px",
          }}
        ></div>
        <a className="dateEventText">{statusText}</a>
      </div>
    );
  };

  const [allowRegister, setAllowRegister] = useState("yes");

  const handleAllowRegisterChange = (event) => {
    setAllowRegister(event.target.value);
  };

  const [allowPublic, setAllowPublic] = useState("no");

  const handleAllowPublicChange = (event) => {
    setAllowPublic(event.target.value);
  };

  const [minimumAge, setMinimumAge] = useState(2);

  const handleMinimumAgeChange = (event) => {
    setMinimumAge(event.target.value);
  };

  const [category, setCategory] = useState("cultural");

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };

  const [promoter, setPromoter] = useState("camaraViana");

  const handlePromoterChange = (event) => {
    setPromoter(event.target.value);
  };

  const today = dayjs();

  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState(today);
  const [startDateregister, setStartDateRegister] = useState(today);
  const [endDateregister, setEndDateRegister] = useState(today);

  const [value, setValue] = React.useState("+351999999999");

  const handleChange = (newValue) => {
    matchIsValidTel(newValue, {
      onlyCountryies: [],
      excludedCountryies: [],
      continents: [],
    });
  };

  const handleDialogRegisterClose = () => {
    setOpenRegisterDialog(false);
  };

  const [preview, setPreview] = useState(null);
  const [preview1, setPreview1] = useState(null);
  const [preview2, setPreview2] = useState(null);
  const [preview3, setPreview3] = useState(null);
  const [preview4, setPreview4] = useState(null);

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreview(reader.result);
    };
    reader.readAsDataURL(file);
  };
  const onDrop1 = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreview1(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const onDrop2 = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreview2(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const onDrop3 = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreview3(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const onDrop4 = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreview4(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const options = [
    { label: t("name"), value: "name" },
    { label: t("age"), value: "age" },
    { label: t("phone"), value: "phone" },
    { label: t("email"), value: "email" },
    { label: t("company"), value: "company" },
  ];

  const icon = <CheckBoxOutlineBlankIcon fontSize="medium" />;
  const checkedIcon = <CheckBoxIcon fontSize="medium" />;

  const [selectedOptions, setSelectedOptions] = useState([
    { label: t("name"), value: "name" },
    { label: t("age"), value: "age" },
  ]);

  const handleChangeOption = (event, value) => {
    setSelectedOptions(value);
  };
  function goBack() {
    navigate("/eventsmanager");
  }
  const [trainstations, setTrainstations] = useState([]);

  useEffect(() => {
    getTrainStations()
      .then((res) => {
        if (res.status === 200 && res.success) {
          setTrainstations(res.data);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error(t("500"));
      });
  }, []);
  const [search, setSearch] = useState("");
  const filteredStations = trainstations.filter(
    (station) =>
      station.designation.toLowerCase().includes(search.toLowerCase()) ||
      station.county.toLowerCase().includes(search.toLowerCase()) ||
      station.district.toLowerCase().includes(search.toLowerCase()) ||
      station.parish.toLowerCase().includes(search.toLowerCase())
  );
  const [selectedStation, setSelectedStation] = useState(null);
  const [selectedStations, setSelectedStations] = useState([]);
  const handleStationChange = (event, value) => {
    setSelectedStation(value);
  };



  return (
    <div className="background">
      <HeaderManagerWhite />
      {isMobile ? <NavManagerHorizontal /> : <NavManager />}
      <div className="centerpage">
        <div className="topLeftBack">
          <IconButton onClick={goBack}>
            <ArrowBackIcon
              size={"small"}
              style={{ fill: "#044535", zIndex: 99 }}
            />
          </IconButton>
        </div>
        <a className="greenTitle">{t("createEvent")}</a>
        <div className="whiteBoard">
          <Dialog
            open={openDialogRegister}
            onClose={handleDialogRegisterClose}
            PaperProps={{
              style: { borderRadius: 15, width: "100%", maxWidth: "800px" },
            }}
          >
            <DialogContent
              style={{
                borderRadius: "15px !important",
                background: "#FFF",
              }}
            >
              <DialogContentText
                style={{
                  background: "#FFF",
                  marginTop: "20px",
                  marginBottom: "20px",

                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <a className="textCancel">{t("subscriptions")}</a>
              </DialogContentText>
              <Grid container spacing={2} padding={2}>
                <Grid item xs={6}>
                  <div
                    style={{
                      marginTop: "30px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <a className="photoLabel">{t("allowRegister")}</a>
                    <TextField
                      fullWidth
                      select
                      InputProps={{
                        disableUnderline: true,
                        style: {
                          ...inputPropsStyle,
                          textAlign: "left",
                          borderRadius: "8px",
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      value={allowRegister}
                      onChange={handleAllowRegisterChange}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                    >
                      <MenuItem value="yes">{t("yes")}</MenuItem>
                      <MenuItem value="no">{t("no")}</MenuItem>
                    </TextField>
                  </div>
                  <div
                    style={{
                      marginTop: "30px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <a className="photoLabel">{t("startdateregister")}</a>
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale={i18next?.language}
                    >
                      <DateTimePicker
                        variant="standard"
                        showTimeSelect
                        value={startDateregister}
                        onChange={(e) => {
                          setStartDateRegister(e);
                        }}
                        slotProps={{
                          tabs: {
                            hidden: false,
                          },

                          day: {
                            sx: {
                              "&.MuiPickersDay-root.Mui-selected": {
                                backgroundColor: "#13BB56 !important",
                              },
                            },
                          },

                          desktopPaper: {
                            sx: {
                              borderRadius: "8px",
                              ".MuiPickersYear-yearButton.Mui-selected": {
                                backgroundColor: "#13BB56 !important",
                              },
                              ".MuiPickersMonth-monthButton.Mui-selected": {
                                backgroundColor: "#13BB56 !important",
                              },
                              "& .css-1e3wlyl-MuiButtonBase-root-MuiMenuItem-root-MuiMultiSectionDigitalClockSection-item.Mui-selected":
                                {
                                  backgroundColor: "#13BB56 !important",
                                },
                              "& .css-y0fr9s-MuiButtonBase-root-MuiTab-root.Mui-selected":
                                {
                                  color: "#13BB56 !important",
                                },
                              "& .css-1aquho2-MuiTabs-indicator": {
                                backgroundColor: "#13BB56 !important",
                              },
                              "& .css-1e6y48t-MuiButtonBase-root-MuiButton-root":
                                {
                                  color: "#13BB56 !important",
                                },
                            },
                          },
                        }}
                        sx={{
                          width: "60%",

                          "& .MuiInputBase-input.Mui-disabled": {
                            backgroundColor: "white",
                          },
                          "& .MuiPickersDay-root.Mui-selected": {
                            backgroundColor: "#44b887 !important",
                            color: "white",
                          },
                          "& .MuiPickersCalendar-root": {
                            borderRadius: "8px !important",
                          },

                          "& .MuiInputLabel-root ": {
                            color: "#044535 !important",

                            fontFamily: "Montserrat",
                            fontSize: "18px !important",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "15px",
                            borderColor: "transparent !important",
                            position: "absolute",
                            marginTop: "-5px",
                            marginLeft: "-10px",
                          },
                          "& .MuiOutlinedInput-root": {
                            color: "#0F111199 !important",
                            textAlign: "right",
                            fontFamily: "Montserrat",
                            fontSize: "18px",
                            fontStyle: "normal",
                            fontWeight: 500,
                            lineHeight: "15px",
                            borderRadius: "8px",
                            background: "#0f11110d",
                            borderColor: "transparent !important",
                            width: "100%",
                            height: "34.88px",
                            padding: "16.5px 10px",
                          },
                          "& .MuiInputLabel-root.Mui-disabled ": {
                            color: "#044535 !important",
                            fontFamily: "Montserrat",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "15px",
                            borderColor: "transparent !important",
                          },
                          "& .MuiOutlinedInput-notchedOutline ": {
                            border: "0px",
                          },
                          "& .MuiOutlinedInput-input": {
                            padding: "16.5px 0px",
                            width: "100%",
                          },
                        }}
                      ></DateTimePicker>
                    </LocalizationProvider>
                  </div>
                  <div
                    style={{
                      marginTop: "30px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <a className="photoLabel">{t("enddateregister")}</a>
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale={i18next?.language}
                    >
                      <DateTimePicker
                        variant="standard"
                        showTimeSelect
                        value={endDateregister}
                        onChange={(e) => {
                          setEndDateRegister(e);
                        }}
                        slotProps={{
                          tabs: {
                            hidden: false,
                          },

                          day: {
                            sx: {
                              "&.MuiPickersDay-root.Mui-selected": {
                                backgroundColor: "#13BB56 !important",
                              },
                            },
                          },

                          desktopPaper: {
                            sx: {
                              borderRadius: "8px",
                              ".MuiPickersYear-yearButton.Mui-selected": {
                                backgroundColor: "#13BB56 !important",
                              },
                              ".MuiPickersMonth-monthButton.Mui-selected": {
                                backgroundColor: "#13BB56 !important",
                              },
                              "& .css-1e3wlyl-MuiButtonBase-root-MuiMenuItem-root-MuiMultiSectionDigitalClockSection-item.Mui-selected":
                                {
                                  backgroundColor: "#13BB56 !important",
                                },
                              "& .css-y0fr9s-MuiButtonBase-root-MuiTab-root.Mui-selected":
                                {
                                  color: "#13BB56 !important",
                                },
                              "& .css-1aquho2-MuiTabs-indicator": {
                                backgroundColor: "#13BB56 !important",
                              },
                              "& .css-1e6y48t-MuiButtonBase-root-MuiButton-root":
                                {
                                  color: "#13BB56 !important",
                                },
                            },
                          },
                        }}
                        sx={{
                          width: "60%",

                          "& .MuiInputBase-input.Mui-disabled": {
                            backgroundColor: "white",
                          },
                          "& .MuiPickersDay-root.Mui-selected": {
                            backgroundColor: "#44b887 !important",
                            color: "white",
                          },
                          "& .MuiPickersCalendar-root": {
                            borderRadius: "8px !important",
                          },

                          "& .MuiInputLabel-root ": {
                            color: "#044535 !important",

                            fontFamily: "Montserrat",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "15px",
                            borderColor: "transparent !important",
                            position: "absolute",
                            marginTop: "-5px",
                            marginLeft: "-10px",
                          },
                          "& .MuiOutlinedInput-root": {
                            color: "#0F111199 !important",
                            textAlign: "right",
                            fontFamily: "Montserrat",
                            fontSize: "18px",
                            fontStyle: "normal",
                            fontWeight: 500,
                            lineHeight: "15px",
                            borderRadius: "8px",
                            background: "#0f11110d",
                            borderColor: "transparent !important",
                            width: "100%",
                            height: "34.88px",
                            padding: "16.5px 10px",
                          },
                          "& .MuiInputLabel-root.Mui-disabled ": {
                            color: "#044535 !important",
                            fontFamily: "Montserrat",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "15px",
                            borderColor: "transparent !important",
                          },
                          "& .MuiOutlinedInput-notchedOutline ": {
                            border: "0px",
                          },
                          "& .MuiOutlinedInput-input": {
                            padding: "16.5px 0px",
                            width: "100%",
                          },
                        }}
                      ></DateTimePicker>
                    </LocalizationProvider>
                  </div>
                  <div
                    style={{
                      marginTop: "30px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <a className="photoLabel">{t("numberParticipants")}</a>
                    <TextField
                      fullWidth
                      type="number"
                      InputProps={{
                        disableUnderline: true,
                        style: inputPropsStyle,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                    />
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div
                    style={{
                      marginTop: "30px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <a className="photoLabel">{t("registerData")}</a>
                    <Autocomplete
                      multiple
                      options={options}
                      variant="standard"
                      sx={{
                        fontFamily: "Montserrat !important",
                        border: "0px",

                        borderBottom: "1px transparent !important",
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                        "& .MuiInputBase-root-MuiInput-root": {
                          fontFamily: "Montserrat !important",
                          border: "0px",
                        },
                      }}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option.label}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{
                              fontFamily: "Montserrat !important",
                            }}
                            checked={selected}
                          />
                          {option.label}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          sx={{
                            fontFamily: "Montserrat !important",
                            borderBottom: "1px transparent !important",
                            "& .Mui-disabled": {
                              backgroundColor: "white !important",
                              color: "#044535 !important",
                            },
                            "& .MuiInputLabel-root ": {
                              color: "#044535 !important",
                            },
                            ".MuiInputBase-root-MuiInput-root": {
                              fontFamily: "Montserrat !important",
                              borderBottom: "0px solid !important",
                            },
                            ".css-953pxc-MuiInputBase-root-MuiInput-root::after":
                              {
                                borderBottom: "0px solid !important",
                              },
                            ".css-953pxc-MuiInputBase-root-MuiInput-root::before":
                              {
                                borderBottom: "0px solid !important",
                              },
                          }}
                          variant="standard"
                        />
                      )}
                      value={selectedOptions}
                      onChange={handleChangeOption}
                    />
                  </div>
                  <div
                    style={{
                      marginTop: "30px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <a className="photoLabel">{t("price")}</a>
                    <TextField
                      fullWidth
                      type="number"
                      InputProps={{
                        disableUnderline: true,
                        style: inputPropsStyle,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                    />
                  </div>
                  <div
                    style={{
                      marginTop: "30px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <a className="photoLabel">{t("discount")}</a>
                    <TextField
                      fullWidth
                      InputProps={{
                        disableUnderline: true,
                        style: inputPropsStyle,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                    />
                    <TextField
                      fullWidth
                      InputProps={{
                        disableUnderline: true,
                        style: inputPropsStyle,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        marginTop: "10px",
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                    />
                  </div>
                </Grid>
              </Grid>
              <div className="bottomRowInfoClient">
                <button
                  className="clientInfoEditButtonShort"
                  onClick={() => {}}
                >
                  {t("create")}
                </button>

                <button className="clientInfoCancelButtonShort">
                  {" "}
                  {t("cancel")}
                </button>
              </div>
            </DialogContent>
          </Dialog>
          <div className="scrollableContentEvent">
            {isMobile?  <div className="photosEvent">
              <div className="photoColumn">
                <a className="photoLabel">{t("poster")}</a>
                <Dropzone onDrop={onDrop} accept="image/*" multiple={false}>
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      <div className="addPoster" {...getRootProps()}>
                        <input {...getInputProps()} />
                        {!preview ? (
                          <div className="addPhoto">
                            <FontAwesomeIcon
                              style={{
                                fontSize: "16px",
                                color: "rgba(15, 17, 17, 0.60)",
                              }}
                              icon={faPlus}
                            />
                          </div>
                        ) : (
                          <div className="addPosterEdit">
                            <img src={preview} className="addPoster" />
                            <div className="overlay">
                              <FontAwesomeIcon
                                className="hoverIcon"
                                icon={faPen}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </section>
                  )}
                </Dropzone>
              </div>

              <div className="morePhotos">
                <a className="photoLabel">{t("photosvideos")}</a>
                <div className="photoRow">
                  <>
                    <div
                      style={{ marginRight: "10px" }}
                      onClick={() => {
                        setPreview1(null);
                      }}
                    >
                      <Dropzone
                        onDrop={onDrop1}
                        disabled={preview1 ? true : false}
                        accept="image/*"
                        multiple={false}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <section>
                            <div className="addPoster" {...getRootProps()}>
                              <input {...getInputProps()} />
                              {!preview1 ? (
                                <div
                                  className="addPhoto"
                                  style={{ marginRight: "10px" }}
                                >
                                  <FontAwesomeIcon
                                    style={{
                                      fontSize: "16px",
                                      color: "rgba(15, 17, 17, 0.60)",
                                    }}
                                    icon={faPlus}
                                  />
                                </div>
                              ) : (
                                <div
                                  className="addPosterEdit"
                                  style={{
                                    marginRight: "10px !important",
                                  }}
                                >
                                  <img src={preview1} className="addPoster" />
                                  <div className="overlay">
                                    <FontAwesomeIcon
                                      className="hoverIcon"
                                      icon={faTrashCan}
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                          </section>
                        )}
                      </Dropzone>
                    </div>
                    <div
                      style={{ marginRight: "10px" }}
                      onClick={() => {
                        setPreview2(null);
                      }}
                    >
                      <Dropzone
                        onDrop={onDrop2}
                        disabled={preview2 ? true : false}
                        accept="image/*"
                        multiple={false}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <section>
                            <div className="addPoster" {...getRootProps()}>
                              <input {...getInputProps()} />
                              {!preview2 ? (
                                <div
                                  className="addPhoto"
                                  style={{ marginRight: "10px" }}
                                >
                                  <FontAwesomeIcon
                                    style={{
                                      fontSize: "16px",
                                      color: "rgba(15, 17, 17, 0.60)",
                                    }}
                                    icon={faPlus}
                                  />
                                </div>
                              ) : (
                                <div
                                  className="addPosterEdit"
                                  style={{
                                    marginRight: "10px !important",
                                  }}
                                >
                                  <img src={preview2} className="addPoster" />
                                  <div className="overlay">
                                    <FontAwesomeIcon
                                      className="hoverIcon"
                                      icon={faTrashCan}
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                          </section>
                        )}
                      </Dropzone>
                    </div>
                    <div
                      style={{ marginRight: "10px" }}
                      onClick={() => {
                        setPreview3(null);
                      }}
                    >
                      <Dropzone
                        onDrop={onDrop3}
                        accept="image/*"
                        disabled={preview3 ? true : false}
                        multiple={false}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <section>
                            <div className="addPoster" {...getRootProps()}>
                              <input {...getInputProps()} />
                              {!preview3 ? (
                                <div
                                  className="addPhoto"
                                  style={{ marginRight: "10px" }}
                                >
                                  <FontAwesomeIcon
                                    style={{
                                      fontSize: "16px",
                                      color: "rgba(15, 17, 17, 0.60)",
                                    }}
                                    icon={faPlus}
                                  />
                                </div>
                              ) : (
                                <div
                                  className="addPosterEdit"
                                  style={{
                                    marginRight: "10px !important",
                                  }}
                                >
                                  <img src={preview3} className="addPoster" />
                                  <div className="overlay">
                                    <FontAwesomeIcon
                                      className="hoverIcon"
                                      icon={faTrashCan}
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                          </section>
                        )}
                      </Dropzone>
                    </div>
                    <div
                      style={{ marginRight: "10px" }}
                      onClick={() => {
                        setPreview4(null);
                      }}
                    >
                      <Dropzone
                        onDrop={onDrop4}
                        accept="image/*"
                        multiple={false}
                        disabled={preview4 ? true : false}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <section>
                            <div className="addPoster" {...getRootProps()}>
                              <input {...getInputProps()} />
                              {!preview4 ? (
                                <div
                                  className="addPhoto"
                                  style={{ marginRight: "10px" }}
                                >
                                  <FontAwesomeIcon
                                    style={{
                                      fontSize: "16px",
                                      color: "rgba(15, 17, 17, 0.60)",
                                    }}
                                    icon={faPlus}
                                  />
                                </div>
                              ) : (
                                <div
                                  className="addPosterEdit"
                                  style={{
                                    marginRight: "10px !important",
                                  }}
                                >
                                  <img src={preview4} className="addPoster" />
                                  <div className="overlay">
                                    <FontAwesomeIcon
                                      className="hoverIcon"
                                      icon={faTrashCan}
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                          </section>
                        )}
                      </Dropzone>
                    </div>
                  </>
                </div>
              </div>
            </div>:null }
          
            <Grid container spacing={2} padding={2}>
              <Grid item xs={6}>
                {isMobile ? null : (
                  <div className="photosEvent">
                    <div className="photoColumn">
                      <a className="photoLabel">{t("poster")}</a>
                      <Dropzone
                        onDrop={onDrop}
                        accept="image/*"
                        multiple={false}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <section>
                            <div className="addPoster" {...getRootProps()}>
                              <input {...getInputProps()} />
                              {!preview ? (
                                <div className="addPhoto">
                                  <FontAwesomeIcon
                                    style={{
                                      fontSize: "16px",
                                      color: "rgba(15, 17, 17, 0.60)",
                                    }}
                                    icon={faPlus}
                                  />
                                </div>
                              ) : (
                                <div className="addPosterEdit">
                                  <img src={preview} className="addPoster" />
                                  <div className="overlay">
                                    <FontAwesomeIcon
                                      className="hoverIcon"
                                      icon={faPen}
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                          </section>
                        )}
                      </Dropzone>
                    </div>

                    <div className="morePhotos">
                      <a className="photoLabel">{t("photosvideos")}</a>
                      <div className="photoRow">
                        <>
                          <div
                            style={{ marginRight: "10px" }}
                            onClick={() => {
                              setPreview1(null);
                            }}
                          >
                            <Dropzone
                              onDrop={onDrop1}
                              disabled={preview1 ? true : false}
                              accept="image/*"
                              multiple={false}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <section>
                                  <div
                                    className="addPoster"
                                    {...getRootProps()}
                                  >
                                    <input {...getInputProps()} />
                                    {!preview1 ? (
                                      <div
                                        className="addPhoto"
                                        style={{ marginRight: "10px" }}
                                      >
                                        <FontAwesomeIcon
                                          style={{
                                            fontSize: "16px",
                                            color: "rgba(15, 17, 17, 0.60)",
                                          }}
                                          icon={faPlus}
                                        />
                                      </div>
                                    ) : (
                                      <div
                                        className="addPosterEdit"
                                        style={{
                                          marginRight: "10px !important",
                                        }}
                                      >
                                        <img
                                          src={preview1}
                                          className="addPoster"
                                        />
                                        <div className="overlay">
                                          <FontAwesomeIcon
                                            className="hoverIcon"
                                            icon={faTrashCan}
                                          />
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </section>
                              )}
                            </Dropzone>
                          </div>
                          <div
                            style={{ marginRight: "10px" }}
                            onClick={() => {
                              setPreview2(null);
                            }}
                          >
                            <Dropzone
                              onDrop={onDrop2}
                              disabled={preview2 ? true : false}
                              accept="image/*"
                              multiple={false}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <section>
                                  <div
                                    className="addPoster"
                                    {...getRootProps()}
                                  >
                                    <input {...getInputProps()} />
                                    {!preview2 ? (
                                      <div
                                        className="addPhoto"
                                        style={{ marginRight: "10px" }}
                                      >
                                        <FontAwesomeIcon
                                          style={{
                                            fontSize: "16px",
                                            color: "rgba(15, 17, 17, 0.60)",
                                          }}
                                          icon={faPlus}
                                        />
                                      </div>
                                    ) : (
                                      <div
                                        className="addPosterEdit"
                                        style={{
                                          marginRight: "10px !important",
                                        }}
                                      >
                                        <img
                                          src={preview2}
                                          className="addPoster"
                                        />
                                        <div className="overlay">
                                          <FontAwesomeIcon
                                            className="hoverIcon"
                                            icon={faTrashCan}
                                          />
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </section>
                              )}
                            </Dropzone>
                          </div>
                          <div
                            style={{ marginRight: "10px" }}
                            onClick={() => {
                              setPreview3(null);
                            }}
                          >
                            <Dropzone
                              onDrop={onDrop3}
                              accept="image/*"
                              disabled={preview3 ? true : false}
                              multiple={false}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <section>
                                  <div
                                    className="addPoster"
                                    {...getRootProps()}
                                  >
                                    <input {...getInputProps()} />
                                    {!preview3 ? (
                                      <div
                                        className="addPhoto"
                                        style={{ marginRight: "10px" }}
                                      >
                                        <FontAwesomeIcon
                                          style={{
                                            fontSize: "16px",
                                            color: "rgba(15, 17, 17, 0.60)",
                                          }}
                                          icon={faPlus}
                                        />
                                      </div>
                                    ) : (
                                      <div
                                        className="addPosterEdit"
                                        style={{
                                          marginRight: "10px !important",
                                        }}
                                      >
                                        <img
                                          src={preview3}
                                          className="addPoster"
                                        />
                                        <div className="overlay">
                                          <FontAwesomeIcon
                                            className="hoverIcon"
                                            icon={faTrashCan}
                                          />
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </section>
                              )}
                            </Dropzone>
                          </div>
                          <div
                            style={{ marginRight: "10px" }}
                            onClick={() => {
                              setPreview4(null);
                            }}
                          >
                            <Dropzone
                              onDrop={onDrop4}
                              accept="image/*"
                              multiple={false}
                              disabled={preview4 ? true : false}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <section>
                                  <div
                                    className="addPoster"
                                    {...getRootProps()}
                                  >
                                    <input {...getInputProps()} />
                                    {!preview4 ? (
                                      <div
                                        className="addPhoto"
                                        style={{ marginRight: "10px" }}
                                      >
                                        <FontAwesomeIcon
                                          style={{
                                            fontSize: "16px",
                                            color: "rgba(15, 17, 17, 0.60)",
                                          }}
                                          icon={faPlus}
                                        />
                                      </div>
                                    ) : (
                                      <div
                                        className="addPosterEdit"
                                        style={{
                                          marginRight: "10px !important",
                                        }}
                                      >
                                        <img
                                          src={preview4}
                                          className="addPoster"
                                        />
                                        <div className="overlay">
                                          <FontAwesomeIcon
                                            className="hoverIcon"
                                            icon={faTrashCan}
                                          />
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </section>
                              )}
                            </Dropzone>
                          </div>
                        </>
                      </div>
                    </div>
                  </div>
                )}

                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("startdate")}</a>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale={i18next?.language}
                  >
                    <DateTimePicker
                      variant="standard"
                      showTimeSelect
                      value={startDate}
                      onChange={(e) => {
                        setStartDate(e);
                      }}
                      slotProps={{
                        tabs: {
                          hidden: false,
                        },

                        day: {
                          sx: {
                            "&.MuiPickersDay-root.Mui-selected": {
                              backgroundColor: "#13BB56 !important",
                            },
                          },
                        },

                        desktopPaper: {
                          sx: {
                            borderRadius: "8px",
                            ".MuiPickersYear-yearButton.Mui-selected": {
                              backgroundColor: "#13BB56 !important",
                            },
                            ".MuiPickersMonth-monthButton.Mui-selected": {
                              backgroundColor: "#13BB56 !important",
                            },
                            "& .css-1e3wlyl-MuiButtonBase-root-MuiMenuItem-root-MuiMultiSectionDigitalClockSection-item.Mui-selected":
                              {
                                backgroundColor: "#13BB56 !important",
                              },
                            "& .css-y0fr9s-MuiButtonBase-root-MuiTab-root.Mui-selected":
                              {
                                color: "#13BB56 !important",
                              },
                            "& .css-1aquho2-MuiTabs-indicator": {
                              backgroundColor: "#13BB56 !important",
                            },
                            "& .css-1e6y48t-MuiButtonBase-root-MuiButton-root":
                              {
                                color: "#13BB56 !important",
                              },
                          },
                        },
                      }}
                      sx={{
                        width: isMobile?"100%":"60%",

                        "& .MuiInputBase-input.Mui-disabled": {
                          backgroundColor: "white",
                        },
                        "& .MuiPickersDay-root.Mui-selected": {
                          backgroundColor: "#44b887 !important",
                          color: "white",
                        },
                        "& .MuiPickersCalendar-root": {
                          borderRadius: "8px !important",
                        },

                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",

                          fontFamily: "Montserrat",
                          fontSize: "18px !important",
                          fontStyle: "normal",
                          fontWeight: 600,
                          lineHeight: "15px",
                          borderColor: "transparent !important",
                          position: "absolute",
                          marginTop: "-5px",
                          marginLeft: "-10px",
                        },
                        "& .MuiOutlinedInput-root": {
                          color: "#0F111199 !important",
                          textAlign: "right",
                          fontFamily: "Montserrat",
                          fontSize: "18px",
                          fontStyle: "normal",
                          fontWeight: 500,
                          lineHeight: "15px",
                          borderRadius: "8px",
                          background: "#0f11110d",
                          borderColor: "transparent !important",
                          width: "100%",
                          height: "34.88px",
                          padding: "16.5px 10px",
                        },
                        "& .MuiInputLabel-root.Mui-disabled ": {
                          color: "#044535 !important",
                          fontFamily: "Montserrat",
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: 600,
                          lineHeight: "15px",
                          borderColor: "transparent !important",
                        },
                        "& .MuiOutlinedInput-notchedOutline ": {
                          border: "0px",
                        },
                        "& .MuiOutlinedInput-input": {
                          padding: "16.5px 0px",
                          width: "100%",
                        },
                      }}
                    ></DateTimePicker>
                  </LocalizationProvider>
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("enddate")}</a>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale={i18next?.language}
                  >
                    <DateTimePicker
                      variant="standard"
                      showTimeSelect
                      value={endDate}
                      onChange={(e) => {
                        setEndDate(e);
                      }}
                      slotProps={{
                        tabs: {
                          hidden: false,
                        },

                        day: {
                          sx: {
                            "&.MuiPickersDay-root.Mui-selected": {
                              backgroundColor: "#13BB56 !important",
                            },
                          },
                        },

                        desktopPaper: {
                          sx: {
                            borderRadius: "8px",
                            ".MuiPickersYear-yearButton.Mui-selected": {
                              backgroundColor: "#13BB56 !important",
                            },
                            ".MuiPickersMonth-monthButton.Mui-selected": {
                              backgroundColor: "#13BB56 !important",
                            },
                            "& .css-1e3wlyl-MuiButtonBase-root-MuiMenuItem-root-MuiMultiSectionDigitalClockSection-item.Mui-selected":
                              {
                                backgroundColor: "#13BB56 !important",
                              },
                            "& .css-y0fr9s-MuiButtonBase-root-MuiTab-root.Mui-selected":
                              {
                                color: "#13BB56 !important",
                              },
                            "& .css-1aquho2-MuiTabs-indicator": {
                              backgroundColor: "#13BB56 !important",
                            },
                            "& .css-1e6y48t-MuiButtonBase-root-MuiButton-root":
                              {
                                color: "#13BB56 !important",
                              },
                          },
                        },
                      }}
                      sx={{
                        width: isMobile?"100%":"60%",

                        "& .MuiInputBase-input.Mui-disabled": {
                          backgroundColor: "white",
                        },
                        "& .MuiPickersDay-root.Mui-selected": {
                          backgroundColor: "#44b887 !important",
                          color: "white",
                        },
                        "& .MuiPickersCalendar-root": {
                          borderRadius: "8px !important",
                        },

                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",

                          fontFamily: "Montserrat",
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: 600,
                          lineHeight: "15px",
                          borderColor: "transparent !important",
                          position: "absolute",
                          marginTop: "-5px",
                          marginLeft: "-10px",
                        },
                        "& .MuiOutlinedInput-root": {
                          color: "#0F111199 !important",
                          textAlign: "right",
                          fontFamily: "Montserrat",
                          fontSize: "18px",
                          fontStyle: "normal",
                          fontWeight: 500,
                          lineHeight: "15px",
                          borderRadius: "8px",
                          background: "#0f11110d",
                          borderColor: "transparent !important",
                          width: "100%",
                          height: "34.88px",
                          padding: "16.5px 10px",
                        },
                        "& .MuiInputLabel-root.Mui-disabled ": {
                          color: "#044535 !important",
                          fontFamily: "Montserrat",
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: 600,
                          lineHeight: "15px",
                          borderColor: "transparent !important",
                        },
                        "& .MuiOutlinedInput-notchedOutline ": {
                          border: "0px",
                        },
                        "& .MuiOutlinedInput-input": {
                          padding: "16.5px 0px",
                          width: "100%",
                        },
                      }}
                    ></DateTimePicker>
                  </LocalizationProvider>
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("allowRegister")}</a>
                  <TextField
                    fullWidth
                    disabled={true}
                    onClick={() => {
                      setOpenRegisterDialog(true);
                    }}
                    select
                    InputProps={{
                      disableUnderline: true,
                      style: {
                        ...inputPropsStyle,
                        textAlign: "left",
                        borderRadius: "8px",
                      },
                    }}
                    InputLabelProps={{
                      shrink: true,
                      style: inputLabelPropsStyle,
                    }}
                    sx={{
                      "& .Mui-disabled": {
                        backgroundColor: "white !important",
                        color: "#044535 !important",
                      },
                      "& .MuiInputLabel-root ": {
                        color: "#044535 !important",
                      },
                    }}
                    variant="standard"
                    // margin="normal"
                    value={allowRegister}
                    onChange={handleAllowRegisterChange}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    <MenuItem value="yes">{t("yes")}</MenuItem>
                    <MenuItem value="no">{t("no")}</MenuItem>
                  </TextField>
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("allowPublic")}</a>
                  <TextField
                    fullWidth
                    select
                    InputProps={{
                      disableUnderline: true,
                      style: {
                        ...inputPropsStyle,
                        textAlign: "left",
                        borderRadius: "8px",
                      },
                    }}
                    InputLabelProps={{
                      shrink: true,
                      style: inputLabelPropsStyle,
                    }}
                    sx={{
                      "& .Mui-disabled": {
                        backgroundColor: "white !important",
                        color: "#044535 !important",
                      },
                      "& .MuiInputLabel-root ": {
                        color: "#044535 !important",
                      },
                    }}
                    variant="standard"
                    value={allowPublic}
                    onChange={handleAllowPublicChange}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    <MenuItem value="yes">{t("yes")}</MenuItem>
                    <MenuItem value="no">{t("no")}</MenuItem>
                  </TextField>
                </div>

                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("minimumAge")}</a>
                  <TextField
                    fullWidth
                    select
                    InputProps={{
                      disableUnderline: true,
                      style: {
                        ...inputPropsStyle,
                        textAlign: "left",
                        borderRadius: "8px",
                      },
                    }}
                    InputLabelProps={{
                      shrink: true,
                      style: inputLabelPropsStyle,
                    }}
                    sx={{
                      
                      "& .Mui-disabled": {
                        backgroundColor: "white !important",
                        color: "#044535 !important",
                      },
                      "& .MuiInputLabel-root ": {
                        color: "#044535 !important",
                      },
                    }}
                    variant="standard"
                    value={minimumAge}
                    onChange={handleMinimumAgeChange}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    {[...Array(100).keys()].map((age) => (
                      <MenuItem key={age + 1} value={age + 1}>
                        {age + 1}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("category")}</a>
                  <TextField
                    fullWidth
                    select
                    InputProps={{
                      disableUnderline: true,
                      style: {
                        ...inputPropsStyle,
                        textAlign: "left",
                        borderRadius: "8px",
                      },
                    }}
                    InputLabelProps={{
                      shrink: true,
                      style: inputLabelPropsStyle,
                    }}
                    sx={{
                      "& .Mui-disabled": {
                        backgroundColor: "white !important",
                        color: "#044535 !important",
                      },
                      "& .MuiInputLabel-root ": {
                        color: "#044535 !important",
                      },
                    }}
                    variant="standard"
                    value={category}
                    onChange={handleCategoryChange}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    <MenuItem value="sports">{t("sports")}</MenuItem>
                    <MenuItem value="cultural">{t("cultural")}</MenuItem>
                  </TextField>
                </div>

                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("observations")}</a>
                  <TextField
                    fullWidth
                    multiline={true}
                    rows={6}
                    InputProps={{
                      disableUnderline: true,
                      style: inputPropsStyle,
                    }}
                    InputLabelProps={{
                      shrink: true,
                      style: inputLabelPropsStyle,
                    }}
                    sx={{
                      "& .Mui-disabled": {
                        backgroundColor: "white !important",
                        color: "#044535 !important",
                      },
                      "& .MuiInputLabel-root ": {
                        color: "#044535 !important",
                      },
                    }}
                    variant="standard"
                  />
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("event")}</a>
                  <TextField
                    fullWidth
                    InputProps={{
                      disableUnderline: true,
                      style: inputPropsStyle,
                    }}
                    InputLabelProps={{
                      shrink: true,
                      style: inputLabelPropsStyle,
                    }}
                    sx={{
                      "& .Mui-disabled": {
                        backgroundColor: "white !important",
                        color: "#044535 !important",
                      },
                      "& .MuiInputLabel-root ": {
                        color: "#044535 !important",
                      },
                    }}
                    variant="standard"
                  />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("promoter")}</a>
                  <TextField
                    fullWidth
                    select
                    InputProps={{
                      disableUnderline: true,
                      style: {
                        ...inputPropsStyle,
                        textAlign: "left",
                        borderRadius: "8px",
                      },
                    }}
                    InputLabelProps={{
                      shrink: true,
                      style: inputLabelPropsStyle,
                    }}
                    sx={{
                      "& .Mui-disabled": {
                        backgroundColor: "white !important",
                        color: "#044535 !important",
                      },
                      "& .MuiInputLabel-root ": {
                        color: "#044535 !important",
                      },
                    }}
                    variant="standard"
                    value={promoter}
                    onChange={handlePromoterChange}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    <MenuItem value="camaraViana">
                      Camara Municipal de Viana do Castelo
                    </MenuItem>
                    <MenuItem value="camaraBraga">
                      Camara Municipal de Braga
                    </MenuItem>
                  </TextField>
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("local")}</a>
                  <TextField
                    fullWidth
                    InputProps={{
                      disableUnderline: true,
                      style: inputPropsStyle,
                    }}
                    InputLabelProps={{
                      shrink: true,
                      style: inputLabelPropsStyle,
                    }}
                    sx={{
                      "& .Mui-disabled": {
                        backgroundColor: "white !important",
                        color: "#044535 !important",
                      },
                      "& .MuiInputLabel-root ": {
                        color: "#044535 !important",
                      },
                    }}
                    variant="standard"
                  />
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("nearStation")}</a>
                  <Autocomplete
                    options={filteredStations}
                    getOptionLabel={(option) => option.designation}
                    value={selectedStation}
                    onChange={handleStationChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                          style: {
                            ...inputPropsStyle,
                            textAlign: "left",
                            borderRadius: "8px",
                          },
                        }}
                        InputLabelProps={{
                          shrink: true,
                          style: inputLabelPropsStyle,
                        }}
                        sx={{
                          "& .Mui-disabled": {
                            backgroundColor: "white !important",
                            color: "#044535 !important",
                          },
                          "& .MuiInputLabel-root": {
                            color: "#044535 !important",
                          },
                        }}
                        variant="standard"
                      />
                    )}
                  />
                </div>

                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("coordinates")}</a>
                  <TextField
                    fullWidth
                    InputProps={{
                      disableUnderline: true,
                      style: inputPropsStyle,
                    }}
                    InputLabelProps={{
                      shrink: true,
                      style: inputLabelPropsStyle,
                    }}
                    sx={{
                      "& .Mui-disabled": {
                        backgroundColor: "white !important",
                        color: "#044535 !important",
                      },
                      "& .MuiInputLabel-root ": {
                        color: "#044535 !important",
                      },
                    }}
                    variant="standard"
                  />
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("local")}</a>
                  <TextField
                    fullWidth
                    InputProps={{
                      disableUnderline: true,
                      style: inputPropsStyle,
                    }}
                    InputLabelProps={{
                      shrink: true,
                      style: inputLabelPropsStyle,
                    }}
                    sx={{
                      "& .Mui-disabled": {
                        backgroundColor: "white !important",
                        color: "#044535 !important",
                      },
                      "& .MuiInputLabel-root ": {
                        color: "#044535 !important",
                      },
                    }}
                    variant="standard"
                  />
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("eventLink")}</a>
                  <TextField
                    fullWidth
                    InputProps={{
                      disableUnderline: true,
                      style: inputPropsStyle,
                    }}
                    InputLabelProps={{
                      shrink: true,
                      style: inputLabelPropsStyle,
                    }}
                    sx={{
                      "& .Mui-disabled": {
                        backgroundColor: "white !important",
                        color: "#044535 !important",
                      },
                      "& .MuiInputLabel-root ": {
                        color: "#044535 !important",
                      },
                    }}
                    variant="standard"
                  />
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("ticketsLink")}</a>
                  <TextField
                    fullWidth
                    InputProps={{
                      disableUnderline: true,
                      style: inputPropsStyle,
                    }}
                    InputLabelProps={{
                      shrink: true,
                      style: inputLabelPropsStyle,
                    }}
                    sx={{
                      "& .Mui-disabled": {
                        backgroundColor: "white !important",
                        color: "#044535 !important",
                      },
                      "& .MuiInputLabel-root ": {
                        color: "#044535 !important",
                      },
                    }}
                    variant="standard"
                  />
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("eventVideo")}</a>
                  <TextField
                    fullWidth
                    InputProps={{
                      disableUnderline: true,
                      style: inputPropsStyle,
                    }}
                    InputLabelProps={{
                      shrink: true,
                      style: inputLabelPropsStyle,
                    }}
                    sx={{
                      "& .Mui-disabled": {
                        backgroundColor: "white !important",
                        color: "#044535 !important",
                      },
                      "& .MuiInputLabel-root ": {
                        color: "#044535 !important",
                      },
                    }}
                    variant="standard"
                  />
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("eventFacebook")}</a>
                  <TextField
                    fullWidth
                    InputProps={{
                      disableUnderline: true,
                      style: inputPropsStyle,
                    }}
                    InputLabelProps={{
                      shrink: true,
                      style: inputLabelPropsStyle,
                    }}
                    sx={{
                      "& .Mui-disabled": {
                        backgroundColor: "white !important",
                        color: "#044535 !important",
                      },
                      "& .MuiInputLabel-root ": {
                        color: "#044535 !important",
                      },
                    }}
                    variant="standard"
                  />
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("eventInstagram")}</a>
                  <TextField
                    fullWidth
                    InputProps={{
                      disableUnderline: true,
                      style: inputPropsStyle,
                    }}
                    InputLabelProps={{
                      shrink: true,
                      style: inputLabelPropsStyle,
                    }}
                    sx={{
                      "& .Mui-disabled": {
                        backgroundColor: "white !important",
                        color: "#044535 !important",
                      },
                      "& .MuiInputLabel-root ": {
                        color: "#044535 !important",
                      },
                    }}
                    variant="standard"
                  />
                </div>
              </Grid>
            </Grid>
          </div>
          <div className="bottomRowEdit">
            <button className="clientInfoEditButton2" onClick={() => {}}>
              {t("create")}
            </button>
            <button className="clientInfoCancelButton2"> {t("cancel")}</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EventCreateManager;
