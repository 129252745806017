import React, { useEffect, useState } from "react";
import logo from "../../images/logo.svg";
import background from "../../images/background.png";
import { Link, useNavigate } from "react-router-dom";
import { TextField } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "../../App.css";
import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";
import toast from "react-hot-toast";
import i18next from "i18next";
import { sendPasswordResetEmail } from "../../api/requests/Emails";

function AskResetPasswordPartner() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  function goBack() {
    navigate("/loginpartner");
  }

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleEmail = (event) => {
    setEmail(event.target.value);
  };

  function resetPassword() {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (email) {
      if (!emailRegex.test(email)) {
        toast.error(t("emailError"));
      } else {
        sendPasswordResetEmail({
          email: email,
          userType: "partner",
        })
          .then((res) => {
            if (res.status == 200 && res.success) {
              toast.success(res.data.message);
              navigate("/loginpartner");
            } else {
              toast.error(res.error);
            }
          })
          .catch((error) => {
            toast.error(t("500"));
          });
      }
    } else {
      toast.error(t("emailError"));
    }
  }


  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);


  return (
    <div className="background">
      <div className="backgroundImage">
        <div className="topcenter">
          <div className="logo"></div>
          <p className="title">{t("sameplace")}</p>
        </div>
      </div>
      <div className="bottomcenter">
        <div className="topleftrel">
          <IconButton onClick={goBack}>
          <ArrowBackIcon
            style={{ fill: "#044535", zIndex: 99, fontSize:isMobile?"20px":"25px" }}
          />
          </IconButton>
        </div>
        <div className="input-container">
          <input
            className={`input ${email && "has-text"}`}
            type="email"
            id="email"
            onChange={handleEmail}
            value={email}
            required
          />
          <label className={!email && "visible"}>{t("email")}</label>
        </div>

        <button
          className="greenbtn"
          onClick={() => {
            resetPassword();
          }}
        >
          {" "}
          {t("sendrequest")}
        </button>
        <Link className="textlinklogin2" to={"/loginadmin"}>
          {t("login")}
        </Link>
      </div>
    </div>
  );
}

export default AskResetPasswordPartner;
