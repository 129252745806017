import React, { useEffect, useState, useRef } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import "../../App.css";
import { useTranslation } from "react-i18next";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";
import Header from "../../components/header";
import NavClient from "../../components/navClient";
import HeaderClientTransparent from "../../components/headerClientTransparent";
import { getTripsById } from "../../api/requests/Trips";
import toast from "react-hot-toast";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton } from "@mui/material";
import {
  AdvancedMarker,
  APIProvider,
  Map,
  useMapsLibrary,
  useMap,
} from "@vis.gl/react-google-maps";
import NavClientHorizontal from "../../components/navClientHorizontal";

function ClientTripPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const { id } = location.state || {};
  const [trip, setTrip] = useState(null);
  const [formattedDepartureDate, setFormattedDepartureDate] = useState("");
  const [formattedReturnDate, setFormattedReturnDate] = useState("");
  const [defaultZoom, setDefaultZoom] = useState(10);
  const [duration, setDuration] = useState("");
  const [distance, setDistance] = useState(0);
  const [co2saved, setco2saved] = useState(0);
  function calculateCo2Savings(distance) {
    const co2PerKmCar = 170; // gramas por km para carro
    const co2PerKmTrain = 6; // gramas por km para comboio

    // CO2 emitido se a viagem fosse feita de carro
    const co2Car = distance * co2PerKmCar;

    // CO2 emitido se a viagem for feita de comboio
    const co2Train = distance * co2PerKmTrain;

    // CO2 poupado (diferença entre carro e comboio)
    const co2Savings = co2Car - co2Train;

    return co2Savings; // Retorna as gramas poupadas
  }

  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371; // Raio da Terra em km
    const dLat = ((lat2 - lat1) * Math.PI) / 180;
    const dLon = ((lon2 - lon1) * Math.PI) / 180;
    const a =
      0.5 -
      Math.cos(dLat) / 2 +
      (Math.cos((lat1 * Math.PI) / 180) *
        Math.cos((lat2 * Math.PI) / 180) *
        (1 - Math.cos(dLon))) /
        2;

    return R * 2 * Math.asin(Math.sqrt(a));
  };

  // Função para determinar o nível de zoom com base na distância
  const getZoomLevel = (distance) => {
    if (distance < 1) return 14;
    if (distance < 5) return 13;
    if (distance < 10) return 12;
    if (distance < 20) return 11;
    if (distance < 50) return 10;
    if (distance < 100) return 9;
    if (distance < 200) return 8;
    if (distance < 500) return 7;
    if (distance < 1000) return 6;
    return 5; // Zoom padrão para longas distâncias
  };

  const formatTripDates = (departureDate, returnDate) => {
    const dateOptions = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };

    const timeOptions = {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    };

    const formattedDepartureDate =
      new Date(departureDate).toLocaleDateString(undefined, dateOptions) +
      " " +
      new Date(departureDate).toLocaleTimeString(undefined, timeOptions) +
      "h";

    const formattedReturnDate =
      new Date(returnDate).toLocaleDateString(undefined, dateOptions) +
      " " +
      new Date(returnDate).toLocaleTimeString(undefined, timeOptions) +
      "h";
    setFormattedDepartureDate(formattedDepartureDate);
    setFormattedReturnDate(formattedReturnDate);
  };

  useEffect(() => {
    getTripsById(id)
      .then((res) => {
        if (res.status === 200 && res.success) {
          formatTripDates(res.data.departure_date, res.data.return_date);
          const distance = calculateDistance(
            res.data.originLat,
            res.data.originLong,
            res.data.destinationLat,
            res.data.destinationLong
          );
          const zoom = getZoomLevel(distance);
          setDefaultZoom(zoom);
          setTrip(res.data);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  function Directions() {
    const map = useMap();
    const routesLibrary = useMapsLibrary("routes");
    const [directionsService, setDirectionsService] = useState(null);
    const [directionsRenderer, setDirectionsRenderer] = useState(null);
    const [routes, setRoutes] = useState([]);
    const [routeIndex, setRouteIndex] = useState(0);
    const selected = routes[routeIndex];
    const leg = selected?.legs[0];

    useEffect(() => {
      if (!routesLibrary || !map) return;
      setDirectionsService(new routesLibrary.DirectionsService());
      setDirectionsRenderer(new routesLibrary.DirectionsRenderer({ map }));
    }, [routesLibrary, map]);

    // Use directions service
    useEffect(() => {
      if (!directionsService || !directionsRenderer) return;

      directionsService
        .route({
          origin: { lat: trip.originLat, lng: trip.originLong },
          destination: { lat: trip.destinationLat, lng: trip.destinationLong },
          travelMode: window.google.maps.TravelMode.TRANSIT,
          transitOptions: {
            modes: ["TRAIN"],
          },
        })
        .then((response) => {
          directionsRenderer.setDirections(response);
          setRoutes(response.routes);
        });

      return () => directionsRenderer.setMap(null);
    }, [directionsService, directionsRenderer]);

    // Update direction route
    useEffect(() => {
      if (!directionsRenderer) return;
      directionsRenderer.setRouteIndex(routeIndex);
    }, [routeIndex, directionsRenderer]);

    if (!leg) return null;

    setDistance(leg.distance?.text);
    
    setco2saved(calculateCo2Savings(parseFloat(leg.distance?.text)));
    // return (
    //   <div className="directions">
    //     <h2>{selected.summary}</h2>
    //     <a>{t("distance")} {leg.distance?.text}</a>
    //     <a>{t("estimatedDuration")} {leg.duration?.text}</a>
    //   </div>
    // );
  }

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="background">
      <HeaderClientTransparent />
      {isMobile ? <NavClientHorizontal /> : <NavClient />}
      <div className="backgroundImageDashboardClient"></div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          paddingTop: "2rem",
          marginLeft: isMobile? "0rem":"3.45rem",
          marginInline:isMobile? "2rem":"0rem"
        }}
      >
        <div className="topLeftBackTrip">
          <IconButton onClick={() => navigate(-1)}>
            <ArrowBackIcon
              size={"small"}
              style={{ fill: "#044535", zIndex: 99 }}
            />
          </IconButton>
        </div>
        {loading ? (
          <></>
        ) : (
          <div className="trip-container">
            <div className="trip-header">
              <h1 className="trip-title">{trip.eventName}</h1>
              <p className="trip-dates">
                {/* <strong>{t("dateTrip")}</strong> */}
                {formattedDepartureDate} - {formattedReturnDate}
              </p>
            </div>

            <div className="trip-stations">
              <div className="trip-station">
                <p>
                  <strong>{t("startStation")}</strong> {trip.originName}
                </p>
              </div>
              <div className="trip-station">
                <p>
                  <strong>{t("endStation")}</strong> {trip.destinationName}
                </p>
              </div>
            </div>

            <div className="trip-co2">
              <p>
                <strong>{t("co2Saved")}:</strong> {trip.co2}g
              </p>
            </div>

            {trip != null ? (
              <>
                <div className="trip-map">
                  <APIProvider
                    apiKey={"AIzaSyAju_Bdgsx_fY_VUJNTJTzKdw5P2z2A9ts"}
                  >
                    <Map
                      defaultCenter={{
                        lat: (trip.originLat + trip.destinationLat) / 2,
                        lng: (trip.originLong + trip.destinationLong) / 2,
                      }}
                      mapId="17c9b3c0488e85ae"
                      defaultZoom={defaultZoom}
                      style={{ borderRadius: "0.675rem !important" }}
                      gestureHandling={"greedy"}
                      disableDefaultUI={true}
                      fullscreenControl={false}
                    >
                      <Directions />
                    </Map>
                  </APIProvider>
                </div>
                <div className="directions">
                  <a>
                    {t("distance")} {distance}
                  </a>
                  {/* <a>
                    {t("estimatedDuration")} {duration}
                  </a> */}
                </div>
              </>
            ) : (
              <></>
            )}

            {/* <button className="back-button" onClick={() => navigate(-1)}>
                {t("back")}
              </button> */}
          </div>
        )}
      </div>
    </div>
  );
}

export default ClientTripPage;
