import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Checkbox,  CircularProgress,
  ThemeProvider,
  createTheme, } from "@mui/material";
import "../../App.css";
import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";
import Header from "../../components/header";
import NavAdmin from "../../components/navAdmin";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Search1 from "../../components/Search1";
import {
  DataGrid,
  GridActionsCellItem,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { esES, ptPT, enUS } from "@mui/x-data-grid/locales";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faPen } from "@fortawesome/free-solid-svg-icons";
import feira from "../../images/example/feira.jpeg";
import hoquei from "../../images/example/hoquei.jpeg";
import volei from "../../images/example/volei.jpeg";
import {
  parseISO,
  parse,
  isAfter,
  isBefore,
  isSameDay,
  differenceInDays,
} from "date-fns";
import Cookies from "js-cookie";
import toast from "react-hot-toast";
import { getEvents } from "../../api/requests/Event";
import NavManager from "../../components/navManager";
import HeaderManager from "../../components/headerManager";
import HeaderManagerWhite from "../../components/headerManagerWhite";
import NavManagerHorizontal from "../../components/navManagerHorizontal";

function EventsManager() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  function goBack() {
    navigate("/dashboardmanager");
  }
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);

  const theme = createTheme({
    components: {
      MuiCircularProgress: {
        styleOverrides: {
          root: {
            color: "#13BB56",
          },
        },
      },
    },
  });
  // const eventsEX = [
  //   {
  //     id: 1,
  //     modality: "Cultura",
  //     event: "Feira Medieval",
  //     local: "Viana do Castelo",
  //     date: "21/05/2024 18/06/2024",
  //     registered: "89 | 200",
  //     image: feira,
  //   },
  //   {
  //     id: 2,
  //     modality: "Hoquei",
  //     event: "AJ Viana vs SL Benfica",
  //     local: "Viana do Castelo",
  //     date: "12/08/2024",
  //     registered: "50 | 350",
  //     image: hoquei,
  //   },
  //   {
  //     id: 3,
  //     modality: "Voleibol",
  //     event: "VCV A x VCV B",
  //     local: "Viana do Castelo",
  //     date: "12/05/2024",
  //     registered: "890 | 2000",
  //     image: volei,
  //   },
  //   {
  //     id: 4,
  //     modality: "Cultura",
  //     event: "Feira Medieval",
  //     local: "Viana do Castelo",
  //     date: "21/05/2024 18/06/2024",
  //     registered: "89 | 200",
  //     image: feira,
  //   },
  //   {
  //     id: 5,
  //     modality: "Hoquei",
  //     event: "AJ Viana vs SL Benfica",
  //     local: "Viana do Castelo",
  //     date: "12/08/2024",
  //     registered: "50 | 350",
  //     image: hoquei,
  //   },
  //   {
  //     id: 6,
  //     modality: "Voleibol",
  //     event: "VCV A x VCV B",
  //     local: "Viana do Castelo",
  //     date: "12/05/2024",
  //     registered: "890 | 2000",
  //     image: volei,
  //   },
  //   {
  //     id: 7,
  //     modality: "Cultura",
  //     event: "Feira Medieval",
  //     local: "Viana do Castelo",
  //     date: "21/05/2024 18/06/2024",
  //     registered: "89 | 200",
  //     image: feira,
  //   },
  //   {
  //     id: 8,
  //     modality: "Hoquei",
  //     event: "AJ Viana vs SL Benfica",
  //     local: "Viana do Castelo",
  //     date: "12/08/2024",
  //     registered: "50 | 350",
  //     image: hoquei,
  //   },
  //   {
  //     id: 9,
  //     modality: "Voleibol",
  //     event: "VCV A x VCV B",
  //     local: "Viana do Castelo",
  //     date: "12/05/2024",
  //     registered: "890 | 2000",
  //     image: volei,
  //   },
  // ];

  const [eventsCopy, setEventsCopy] = useState([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    // const id = Cookies.get("id");
    // const token = Cookies.get("token");

    // //If don't have an ID or token
    // if (!id && !token) {
    //   navigate("/loginadmin");
    //   //Remove the data and forward to the Login page
    //   Cookies.remove("id");
    //   Cookies.remove("email");
    //   Cookies.remove("token");
    //   Cookies.remove("first_name");
    //   Cookies.remove("last_name");
    //   Cookies.remove("photo");
    // }

    getEvents()
      .then((res) => {
        if (res.status === 200 && res.success) {
          setEvents(res.data);
          setEventsCopy(res.data);
          setLoading(false)
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error(t("500"));
      });
  }, []);

  const DateCell = ({ value }) => {
    const today = new Date();
    const startDate = parseISO(value.start_date);
    const endDate = value.end_date ? parseISO(value.end_date) : startDate;

    const isPast = isAfter(today, endDate);
    const isFuture = isBefore(today, startDate);
    const isOngoing = !isPast && !isFuture;

    let statusText = "";
    let statusColor = "";

    if (isPast) {
      statusText = t("finished");
      statusColor = "#ED4F2C";
    } else if (isOngoing) {
      statusText = t("live");
      statusColor = "#13BB56";
    } else if (isFuture) {
      const daysToStart = differenceInDays(startDate, today);
      statusText = t("in") + " " + daysToStart + " " + t("days");
      statusColor = "#B0B0B0";
    }

    const eventDates = [
      startDate.toLocaleDateString("pt-PT"),
      endDate.toLocaleDateString("pt-PT"),
    ];

    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "80px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            textAlign: "left",
            gap: "5px",
          }}
        >
          {eventDates.map((date, index) => (
            <span style={{ lineHeight: "1.2", fontSize: "18px" }} key={index}>
              {date}
            </span>
          ))}
        </div>
        <div
          style={{ display: "flex", alignItems: "center", marginLeft: "25px" }}
        >
          <div
            style={{
              width: "12px",
              height: "12px",
              backgroundColor: statusColor,
              borderRadius: "3px",
            }}
          ></div>
          <a className="dateEventText">{statusText}</a>
        </div>
      </div>
    );
  };
  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + "...";
    }
    return text;
  };

  const EventCell = ({ row }) => {
    const containerRef = useRef(null);
    const [containerWidth, setContainerWidth] = useState(0);

    useEffect(() => {
      const updateWidth = () => {
        if (containerRef.current) {
          setContainerWidth(containerRef.current.offsetWidth);
        }
      };

      updateWidth();
      window.addEventListener("resize", updateWidth);
      return () => window.removeEventListener("resize", updateWidth);
    }, []);

    const maxLength = Math.floor(containerWidth / 3.5);

    return (
      <div
        ref={containerRef}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          margin: 0,
          padding: 0,
        }}
      >
        <img src={row.poster[0]} alt={row.name} className="imageGrid" />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            textAlign: "left",
            gap: "5px",
            width: "100%",
          }}
        >
          <span className="eventGridText">
            {truncateText(row.modality_name, maxLength)}
          </span>
          <span className="eventGridText">
            {truncateText(row.name, maxLength)}
          </span>
        </div>
      </div>
    );
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer
        style={{
          justifyContent: "flex-end",
          marginTop:isMobile?"0px": "-50px",
          marginBottom:isMobile?"0px": "30px",
          marginRight: "0px",
        }}
      >
        <GridToolbarFilterButton
          slotProps={{
            button: {
              style: {
                color: "#044535",
                fontFamily: "Montserrat",
                textTransform: "none",
                fontWeight: 600,
                fontSize: "16px",
              },
            },
          }}
        />
        <GridToolbarExport
          slotProps={{
            button: {
              style: {
                color: "#044535",
                fontFamily: "Montserrat",
                textTransform: "none",
                fontWeight: 600,
                fontSize: "16px",
              },
            },
          }}
        />
      </GridToolbarContainer>
    );
  }

  useEffect(() => {
    if (search) {
      let arrayEvents = eventsCopy;
      arrayEvents = arrayEvents.filter(
        (element) =>
          element.name.toLowerCase().includes(search.toLowerCase()) ||
          element.modality_name.toLowerCase().includes(search.toLowerCase()) ||
          element.district_name.toLowerCase().includes(search.toLowerCase())
      );
      setEvents(arrayEvents);
    } else {
      setEvents(eventsCopy);
    }
  }, [search]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <div className="background">
      <HeaderManagerWhite />
      {isMobile ? <NavManagerHorizontal /> : <NavManager />}

      <div className="topLeftBack">
        <IconButton onClick={goBack}>
        <ArrowBackIcon
            style={{ fill: "#044535", zIndex: 99, fontSize:isMobile?"20px":"25px" }}
          />
        </IconButton>
      </div>
      <div className="centerpage">
        <a className="greenTitle">{t("events")}</a>
        <div className="whiteBoard">
          <div
            className="col-0"
            style={{
              textAlign: "start",
              marginTop: isMobile?15:45,
              marginLeft: isMobile?10:30,
              flexDirection: "row",
              display: "flex",
              zIndex: 99999,
            }}
          >
            <Search1
              onSearch={(value) => {
                setSearch(value);
              }}
            />
          </div>
          <div style={{ height: "75%", maxWidth: "100%" }}>
          <ThemeProvider theme={theme}>
            <DataGrid
              className={"grid"}
              rowHeight={80}
              loading={loading}
              autoPageSize={true}
              columns={[
                {
                  field: "event",
                  headerName: t("event"),
                  type: "string",
                  flex: 1.1,
                  renderCell: (params) => <EventCell row={params.row} />,
                },
                ...(isMobile
                  ? [] 
                  :[
                {
                  field: "district_name",
                  headerName: t("local"),
                  type: "string",
                  flex: 0.2,
                },
                {
                  field: "__v",
                  headerName: t("clicks"),
                  type: "number",
                  flex: 0.2,
                  align: "center",
                },
                {
                  field: "audience_capacity",
                  headerName: t("favorites"),
                  type: "number",
                  flex: 0.2,
                  align: "center",
                },
                {
                  field: "date",
                  headerName: t("date"),
                  flex: 0.5,
                  type: "string",
                  renderCell: (params) => (
                    <DateCell value={params.row} row={params.row} />
                  ),
                },
              ]),

                // {
                //   field: "registered",
                //   headerName: t("registered"),
                //   flex: 0.5,
                //   type: "string",
                // },

                {
                  field: "actions",
                  type: "actions",
                  headerName: t("actions"),
                  flex: 0.2,
                  cellClassName: "actions",
                  getActions: ({ id }) => {
                    return [
                      <GridActionsCellItem
                        icon={
                          <FontAwesomeIcon
                            style={{ fontSize: 18, color: "#0F1111" }}
                            icon={faPen}
                          />
                        }
                        label="Save"
                        onClick={() => {
                          navigate("/eventpagemanager", {
                            state: { eventId: id },
                          });
                        }}
                      />,
                    ];
                  },
                },
              ]}
              rows={events}
              checkboxSelection={false}
              getRowClassName={() => "custom-row"}
              onRowClick={(params) => {}}
              getRowId={(row) => row._id}
              localeText={
                i18n.language == "pt"
                  ? ptPT.components.MuiDataGrid.defaultProps.localeText
                  : i18n.language == "es"
                  ? esES.components.MuiDataGrid.defaultProps.localeText
                  : enUS.components.MuiDataGrid.defaultProps.localeText
              }
              sx={{
                "& .MuiDataGrid-cell": {
                  border: "none",
                  padding: "0 !important",
                  display: "flex",
                  alignItems: "center",
                },
                ".MuiDataGrid-filler": {
                  display: "none",
                },

                "& .MuiDataGrid-row.Mui-hovered": {
                  backgroundColor: "rgba(17, 96, 48, 0.20)",
                },

                "& .MuiDataGrid-row:hover": {
                  backgroundColor: "rgba(17, 96, 48, 0.20)",
                },
                "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
                  outline: "none",
                },
                '& .MuiDataGrid-cell[data-field="local"]': {
                  fontSize: "20px",
                },
                '& .MuiDataGrid-cell[data-field="registered"]': {
                  fontSize: "18px",
                },
                "& .MuiTablePagination-root ": {
                    overflow: "hidden !important",
                  },
              }}
              components={{
                BaseCheckbox: ({ value, ...otherProps }) => (
                  <Checkbox size="small" checked={value} {...otherProps} />
                ),
                LoadingOverlay: <CircularProgress />,
              }}
              slots={{
                toolbar: CustomToolbar,
              }}
            />
              </ThemeProvider>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <button
              className="clientInfoEditButton"
              onClick={() => {
                navigate("/eventcreatemanager");
              }}
            >
              {t("addEvent")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EventsManager;
