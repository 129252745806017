import React, { useEffect, useState, useRef } from "react";

import { useNavigate } from "react-router-dom";
import "../../App.css";
import { useTranslation } from "react-i18next";
import { Grid, Box, Typography, Skeleton } from "@mui/material";
import Header from "../../components/header";
import NavClient from "../../components/navClient";
import EventsBtn from "../../images/clientDashboardIcons/EventsClear.svg";
import StationsBtn from "../../images/clientDashboardIcons/StationsBtn.svg";
import PartnersBtn from "../../images/clientDashboardIcons/PartnersClear.svg";
import bag from "../../images/clientDashboardIcons/bag.svg";
import portugal from "../../images/example/portugalfut.jpeg";
import feira from "../../images/example/feira.jpeg";
import hoquei from "../../images/example/hoquei.jpeg";
import amalia from "../../images/example/amalia.jpeg";
import viana from "../../images/example/viana.png";
import locationIconSmall from "../../images/eventIcons/locationIconSmall.svg";
import calendarIconSmall from "../../images/eventIcons/calendarIconSmall.svg";
import { createRoot } from "react-dom/client";
import {
  AdvancedMarker,
  APIProvider,
  Map,
  Marker,
  Pin,
} from "@vis.gl/react-google-maps";
import HeaderClientTransparent from "../../components/headerClientTransparent";
import Search1 from "../../components/Search1";
import Cookies from "js-cookie";
import { getTrainStations } from "../../api/requests/Data.js";
import toast from "react-hot-toast";
import NavClientHorizontal from "../../components/navClientHorizontal.js";

function StationsList() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [name, setName] = useState(0);

  const [search, setSearch] = useState("");
  const [trainstations, setTrainstations] = useState([]);
  const [trainstationsCopy, setTrainstationsCopy] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const id = Cookies.get("id");
    const token = Cookies.get("token");
    const nameU = Cookies.get("first_name");

    setName(nameU);

    //If don't have an ID or token
    if (!id && !token) {
      navigate("/loginclient");
      //Remove the data and forward to the Login page
      Cookies.remove("id");
      Cookies.remove("email");
      Cookies.remove("token");
      Cookies.remove("first_name");
      Cookies.remove("last_name");
      Cookies.remove("photo");
    }

    const favoriteStations = Cookies.get("favorite_station")
      ? Cookies.get("favorite_station").split(",").map(Number)
      : [];
    getTrainStations()
      .then((res) => {
        if (res.status === 200 && res.success) {
          let fetchedStations = res.data;

          const isFavorite = (station) => {
            return favoriteStations.includes(station.id);
          };

          const sortedStations = fetchedStations.sort((a, b) => {
            const isFavoriteA = isFavorite(a);
            const isFavoriteB = isFavorite(b);

            if (isFavoriteA && !isFavoriteB) return -1;
            if (!isFavoriteA && isFavoriteB) return 1;

            return 0;
          });

          setTrainstations(sortedStations);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error("500");
      });
  }, []);

  useEffect(() => {
    if (search) {
      let arrayTrainstations = trainstationsCopy;
      arrayTrainstations = arrayTrainstations.filter((element) =>
        element.designation.toLowerCase().includes(search.toLowerCase())
      );
      setTrainstations(arrayTrainstations);
      setLoading(false);
    } else {
      setTrainstations(trainstationsCopy);
    }
  }, [search]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <div className="background">
      <HeaderClientTransparent />
      {isMobile ? <NavClientHorizontal /> : <NavClient />}

      <div className="backgroundImageDashboardClient">
        <div className="leftcenter">
          <a className="titleDashboardClient">{t("hi")}</a>
          <a className="titleDashboardClient">{name}</a>
          <a className="subtitleDashboardClient">{t("whereto")}</a>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "80px",
            paddingTop: "15vh",
          }}
        >
          <button
            className="btnDashboardClient"
            style={{ backgroundImage: `url(${EventsBtn})` }}
            onClick={() => {
              navigate("/eventslist");
            }}
          ></button>
          <button
            className="btnDashboardClient"
            style={{ backgroundImage: `url(${StationsBtn})` }}
          ></button>
          <button
            className="btnDashboardClient"
            style={{ backgroundImage: `url(${PartnersBtn})` }}
            onClick={() => {
              navigate("/partnerslist");
            }}
          ></button>
        </div>
        <div className="centerContent2">
          <div
            className="col-0"
            style={{
              textAlign: "start",
              marginTop: 45,
              marginLeft: 10,
              flexDirection: "row",
              display: "flex",
              zIndex: 99999,
            }}
          >
            <Search1
              onSearch={(value) => {
                setSearch(value);
              }}
            />
            <p className="EventsTitle">{t("allStations")}</p>
          </div>
          <Box sx={{ width: "100%", height: "55vh", overflowY: "auto", marginLeft:isMobile?"7%":"0rem"  }}>
            <Grid container spacing={2}>
              {loading == false
                ? Array.from(new Array(18)).map((_, index) => (
                    <Grid item xs={12} sm={6} md={4} lg={4} xl={4} key={index}>
                       <Skeleton
                        variant="rectangular"
                        height="10.625rem"
                        sx={{
                          borderRadius: "0.9375rem",
                          width: { xs: "92%", sm: "22.5625rem" }, 
                          margin: "0 auto",
                        }}
                      />
                    </Grid>
                  ))
                : trainstations.map((trainstation, index) => (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      lg={4}
                      xl={4}
                      key={trainstation._id}
                    >
                      <div className="event-container">
                        <div className="trainstationmap">
                          <APIProvider
                            apiKey={"AIzaSyAju_Bdgsx_fY_VUJNTJTzKdw5P2z2A9ts"}
                          >
                            <Map
                              defaultCenter={{
                                lat: trainstation.latitude,
                                lng: trainstation.longitude,
                              }}
                              mapId="17c9b3c0488e85ae"
                              defaultZoom={12}
                              style={{
                                borderRadius: "0.675rem !important",
                              }}
                              gestureHandling={"greedy"}
                              disableDefaultUI={true}
                              fullscreenControl={false}
                            >
                              <AdvancedMarker
                                position={{
                                  lat: trainstation.latitude,
                                  lng: trainstation.longitude,
                                }}
                              >
                                <Pin
                                  background={"#fd7000"}
                                  borderColor={"#D56102"}
                                  glyphColor={"#D56102"}
                                />
                              </AdvancedMarker>
                            </Map>
                          </APIProvider>
                        </div>
                        <div className="trainstation-details">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              flexDirection: "row",
                              width: "100%",
                            }}
                          >
                            <div className="event-title-name">
                              {trainstation.designation}
                            </div>
                          </div>
                          <div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                flexDirection: "row",
                                marginTop: "2.6rem",
                              }}
                            >
                              <span
                                style={{
                                  width: 11,
                                  height: 16,
                                  backgroundImage: `url(${locationIconSmall})`,
                                }}
                              />
                              <div
                                className="cardContent"
                                style={{ paddingLeft: 10 }}
                              >
                                <a className="eventTextSmall">
                                  {trainstation.line_designation}
                                </a>

                                <a className="eventTextSmall">
                                  {trainstation.district}, Portugal
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  ))}
            </Grid>
          </Box>
        </div>
      </div>
    </div>
  );
}

export default StationsList;
