import React, { useEffect, useState } from "react";
import logo from "../../images/logo.svg";
import background from "../../images/background.png";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardContent,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  InputAdornment,
  Menu,
  MenuItem,
  TextField,
  Typography,
  Autocomplete,
  Checkbox,
  FormControlLabel,
  Switch,
  Skeleton,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "../../App.css";
import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";

import i18next from "i18next";
import Header from "../../components/header.js";
import NavAdmin from "../../components/navAdmin.js";
import EditIcon from "@mui/icons-material/Edit";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
import { CalendarIcon } from "@mui/x-date-pickers/icons";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import "dayjs/locale/pt";
import "dayjs/locale/en-gb";
import feira from "../../images/example/feira.jpeg";
import {
  parse,
  isAfter,
  isBefore,
  isSameDay,
  differenceInDays,
} from "date-fns";
import Dropzone from "react-dropzone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faPen, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import {
  getActivities,
  getDistricts,
  getServicesTypes,
  getSubActivities,
  getTrainStations,
  getTypes,
} from "../../api/requests/Data.js";
import toast from "react-hot-toast";
import { getPromoters } from "../../api/requests/Promoters.js";
import { getCouncils } from "../../api/requests/Council.js";
import { getEquipments } from "../../api/requests/Equipment.js";
import { postEvent } from "../../api/requests/Event.js";
import { useLocation } from "react-router-dom";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { postPartner } from "../../api/requests/Partner.js";
import { getOffers } from "../../api/requests/Offers.js";
import { getPlans } from "../../api/requests/PaymentPlans.js";
import NavAdminMobile from "../../components/navAdminHorizontal.js";

function PartnerCreateAdmin() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [editSubs, setEditSubs] = useState(true);
  const [trainstations, setTrainstations] = useState([]);
  const location = useLocation();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  function goBack() {
    navigate("/partnersadmin");
  }
  const [openDialog, setOpenDialog] = useState(false);

  const [openDialogRegister, setOpenRegisterDialog] = useState(false);

  const trainStations = ["Lisboa Santa Apolónia", "Porto Campanhã", "Faro"];
  const preferences = [
    { id: 1, designation: "atletismo" },
    { id: 2, designation: "futebol" },
  ];

  const inputLabelPropsStyle = {
    color: "#044535 !important",
    textAlign: "right",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "22px",
  };

  const inputPropsStyle = {
    color: "#0F111199",
    textAlign: "right",
    fontFamily: "Montserrat",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "22px",
    borderRadius: "8px",
    background: "#0f11110d",
    paddingLeft: "10px",
    width:isMobile?"100%":"60%",
  };

  const inputTrainStyle = {
    color: "#0F1111",
    textAlign: "right",
    fontFamily: "Montserrat",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "22px",
    borderRadius: "8px",
    background: "white",
    width:isMobile?"100%":"60%",
  };

  const today = dayjs();

  const [audience, setAudience] = useState(true);
  const [audienceCapacity, setAudienceCapacity] = useState(0);
  const [audienceUrl, setAudienceUrl] = useState([]);
  const [booking, setBooking] = useState(true);
  const [partnerUrl, setPartnerUrl] = useState("");
  const [bookingLimit, setBookingLimit] = useState(0);
  const [cancel, setCancel] = useState(false);
  const [cancelObs, setCancelObs] = useState("");
  const [district, setDistrict] = useState("");
  const [co2, setCo2] = useState([]);
  const [coordinates, setCoordinates] = useState([]);

  const [email, setEmail] = useState([]);
  const [postalCode, setPostalCode] = useState("");

  const [name, setName] = useState("");
  const [contact, setContact] = useState("");
  const [address, setAddress] = useState("");
  const [councilId, setCouncilId] = useState("");
  const [typeId, setTypeId] = useState("");
  const [tourLink, setTourLink] = useState("");
  const [urlFacebook, setUrlFacebook] = useState("");
  const [urlInstagram, setUrlInstagram] = useState("");
  const [urlBooking, setUrlBooking] = useState("");
  const [urlTripAdvisor, setUrlTripAdvisor] = useState("");
  const [urlYoutube, setUrlYoutube] = useState("");
  const [visible, setVisible] = useState(true);
  const [districts, setDistricts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [schedules, setSchedules] = useState([]);
  const [discount, setDiscount] = useState([]);
  const [types, setTypes] = useState([]);
  const [cityCouncils, setCityCouncils] = useState([]);
  const [isLoadingDistricts, setLoadingDistricts] = useState(true);
  const [isLoadingTrainStations, setLoadingTrainStations] = useState(true);
  const [photo, setPhoto] = useState("");
  const [certification, setCertification] = useState("");
  const [avatarData, setAvatarData] = useState(null);
  const [status, setStatus] = useState(0);
  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState(today);
  const [plan, setPlan] = useState("");
  const [offer, setOffer] = useState("");
  const [offers, setOffers] = useState([]);
  const [plans, setPlans] = useState([]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  const handleChange = (newValue) => {
    matchIsValidTel(newValue, {
      onlyCountryies: [],
      excludedCountryies: [],
      continents: [],
    });
  };

  const handleChangeType = (event) => {
    setTypeId(event.target.value);
  };

  const handleChangeOffer = (event) => {
    setOffer(event.target.value);
  };

  const handleChangePlan = (event) => {
    setPlan(event.target.value);
  };

  const handleSwitchChange = (event) => {
    setVisible(event.target.checked);
  };
  const handleBookingChange = (event) => {
    setBooking(event.target.checked);
  };

  const [preview, setPreview] = useState("");

  const handleDistrictChange = (event, newValue) => {
    setDistrict(newValue);
  };

  useEffect(() => {
    getTrainStations()
      .then((res) => {
        if (res.status === 200 && res.success) {
          setTrainstations(res.data);
          setLoadingTrainStations(false);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error(t("500"));
      });
    getDistricts()
      .then((res) => {
        if (res.status === 200 && res.success) {
          setDistricts(res.data);
          setLoadingDistricts(false);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error("500");
      });
    getCouncils()
      .then((res) => {
        if (res.status === 200 && res.success) {
          setCityCouncils(res.data);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error("500");
      });
    getServicesTypes()
      .then((res) => {
        if (res.status === 200 && res.success) {
          setTypes(res.data);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error("500");
      });
    getOffers()
      .then((res) => {
        if (res.status === 200 && res.success) {
          setOffers(res.data);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error("500");
      });
    getPlans()
      .then((res) => {
        if (res.status === 200 && res.success) {
          setPlans(res.data);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error("500");
      });
  }, []);

  useEffect(() => {
    const avatar = {
      sx: {
        bgcolor: "#11bb55",
      },
      children: `Teste`,
    };
    setAvatarData(avatar);
  }, []);

  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  const [file, setFile] = useState(null);
  const [contract, setContract] = useState(null);
  const [fileType, setFileType] = useState("");
  const [contractType, setContractType] = useState("");
  const [fileUrl, setFileUrl] = useState(null);
  const [contractUrl, setContractUrl] = useState(null);

  const onDropFile = (acceptedFiles) => {
    const file = acceptedFiles[0];
    setFile(file);

    // Determine o tipo do arquivo
    const fileType = file.type.includes("pdf") ? "pdf" : "image";
    setFileType(fileType);
  };

  const onDropFile2 = (acceptedFiles) => {
    const file = acceptedFiles[0];
    setContract(file);

    // Determine o tipo do arquivo
    const fileType = file.type.includes("pdf") ? "pdf" : "image";
    setContractType(fileType);
  };

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();

    reader.onload = () => {
      const base64String = reader.result;
      setPhoto(base64String);
    };

    reader.readAsDataURL(file);
  };

  useEffect(() => {
    if (file) {
      // Converte o arquivo para base64
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result;
        setFileUrl(base64String); // Armazena o base64String em fileUrl
      };

      reader.readAsDataURL(file); // Inicia a leitura do arquivo
    }
  }, [file]);

  useEffect(() => {
    if (contract) {
      // Converte o arquivo para base64
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result;
        setContractUrl(base64String); // Armazena o base64String em fileUrl
      };

      reader.readAsDataURL(contract); // Inicia a leitura do arquivo
    }
  }, [contract]);

  const dropzoneStyle = {
    border: "2px dashed #0087F7",
    borderRadius: "5px",
    padding: "20px",
    textAlign: "center",
    cursor: "pointer",
    width:isMobile?"100%":"60%",
  };

  const imageStyle = {
    maxWidth: "60%",
    height: "100px",
  };

  function create() {
    if (!photo) {
      toast.error(t("photoValidation"));
      return;
    }
    if (!name) {
      toast.error(t("nameEqValidation"));
      return;
    }
    if (!contact) {
      toast.error(t("contactEqValidation"));
      return;
    }
    if (!email) {
      toast.error(t("emailValidation"));
      return;
    }
    if (!certification) {
      toast.error(t("certificationValidation"));
      return;
    }
    if (!address) {
      toast.error(t("addressValidation"));
      return;
    }
    if (!coordinates) {
      toast.error(t("coordinatesValidation"));
      return;
    }
    if (!typeId) {
      toast.error(t("typeIdEqValidation"));
      return;
    }
    if (!fileUrl) {
      toast.error(t("schedulesEqValidation"));
      return;
    }
    if (!district) {
      toast.error(t("districtValidation"));
      return;
    }
    if (!startDate) {
      toast.error(t("startDateValidation"));
      return;
    }
    if (!endDate) {
      toast.error(t("endDateValidation"));
      return;
    }
    if (!contractUrl) {
      toast.error(t("contractValidation"));
      return;
    }
    if (!plan) {
      toast.error(t("planValidation"));
      return;
    }
    if (!offer) {
      toast.error(t("offerValidation"));
      return;
    }

    postPartner({
      name: name,
      contact: contact,
      email: email,
      service_type: typeId,
      schedules: fileUrl,
      url_partner: partnerUrl,
      url_booking: urlBooking,
      url_tripadvisor: urlTripAdvisor,
      url_facebook: urlFacebook,
      url_instagram: urlInstagram,
      url_youtube: urlYoutube,
      start_contract: startDate,
      end_contract: endDate,
      contract: contractUrl,
      plan_id: plan,
      permanent_certificate: certification,
      offer_id: offer[0],
      reservation: booking,
      district_id: district.id,
      zip_code: postalCode,
      coordinates: coordinates,
      visible: visible,
      address: address,
      photo: photo,
    })
      .then((res) => {
        if (res.success && res.status === 200) {
          toast.success(t("successMessage"));
          navigate("/partnersadmin");
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error(t("500"));
      });
  }
  
  return (
    <div className="background">
      <Header />
      {isMobile?<NavAdminMobile/>:<NavAdmin />}
      <div className="centerpage">
        <div className="topLeftBack">
          <IconButton onClick={goBack}>
          <ArrowBackIcon
            style={{ fill: "#044535", zIndex: 99, fontSize:isMobile?"20px":"25px" }}
          />
          </IconButton>
        </div>
        <a className="greenTitle">{t("addPartner")}</a>
        <div className="whiteBoard">
          <div className="scrollableContentEvent">
            <Grid container spacing={2} padding={2}>
              <Grid item xs={6}>
                <div className="photoColumn">
                  <a className="photoLabel">{t("mainPhoto")}*</a>
                  <Dropzone onDrop={onDrop} accept="image/*" multiple={false}>
                    {({ getRootProps, getInputProps }) => (
                      <section>
                        <div className="addPoster" {...getRootProps()}>
                          <input {...getInputProps()} />
                          {!preview ? (
                            <div className="addPhoto">
                              <FontAwesomeIcon
                                style={{
                                  fontSize: "16px",
                                  color: "rgba(15, 17, 17, 0.60)",
                                }}
                                icon={faPlus}
                              />
                            </div>
                          ) : (
                            <div className="addPosterEdit">
                              <img src={preview} className="addPoster" />
                              <div className="overlay">
                                <FontAwesomeIcon
                                  className="hoverIcon"
                                  icon={faPen}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </section>
                    )}
                  </Dropzone>
                  {photo != "" && (
                    <div className="profileColumn">
                      <div className="addProfile">
                        <img src={photo} className="addProfile" />
                      </div>
                    </div>
                  )}
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("name")}*</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width={isMobile?"100%":"60%"}
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      InputProps={{
                        disableUnderline: true,
                        style: inputPropsStyle,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                      value={name}
                    />
                  )}
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("contact")}*</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width={isMobile?"100%":"60%"}
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      InputProps={{
                        disableUnderline: true,
                        style: inputPropsStyle,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      onChange={(e) => {
                        setContact(e.target.value);
                      }}
                      value={contact}
                    />
                  )}
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("email")}*</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width={isMobile?"100%":"60%"}
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      InputProps={{
                        disableUnderline: true,
                        style: inputPropsStyle,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      value={email}
                    />
                  )}
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("certificationP")}*</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width={isMobile?"100%":"60%"}
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      InputProps={{
                        disableUnderline: true,
                        style: inputPropsStyle,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      onChange={(e) => {
                        setCertification(e.target.value);
                      }}
                      value={certification}
                    />
                  )}
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("postalcode")}</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width={isMobile?"100%":"60%"}
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      InputProps={{
                        disableUnderline: true,
                        style: inputPropsStyle,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      onChange={(e) => {
                        setPostalCode(e.target.value);
                      }}
                      value={postalCode}
                    />
                  )}
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("address")}*</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width={isMobile?"100%":"60%"}
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      InputProps={{
                        disableUnderline: true,
                        style: inputPropsStyle,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      onChange={(e) => {
                        setAddress(e.target.value);
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      value={address}
                    />
                  )}
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("coordinates")}*</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width={isMobile?"100%":"60%"}
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      InputProps={{
                        disableUnderline: true,
                        style: inputPropsStyle,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      value={coordinates}
                      onChange={(e) => {
                        setCoordinates(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("type")}*</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width={isMobile?"100%":"60%"}
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      select
                      InputProps={{
                        disableUnderline: true,
                        style: {
                          ...inputPropsStyle,
                          textAlign: "left",
                          borderRadius: "8px",
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      value={typeId}
                      onChange={handleChangeType}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                    >
                      {types.map((type) => (
                        <MenuItem key={type._id} value={type.id}>
                          {type[i18next.language]}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("schedule")}*</a>

                  <Dropzone
                    onDrop={onDropFile}
                    accept="image/*"
                    multiple={false}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div
                        {...getRootProps()}
                        style={{
                          border: "2px dashed #cccccc",
                          borderRadius: "10px",
                          padding: "20px",
                          textAlign: "center",
                          color: "#666666",
                          backgroundColor: "#f9f9f9",
                          cursor: "pointer",
                          transition: "background-color 0.3s ease",
                          width: "54%",
                        }}
                      >
                        <input {...getInputProps()} />
                        <p>{t("selectImg")}</p>
                      </div>
                    )}
                  </Dropzone>
                  {fileType === "image" && fileUrl && (
                    <img
                      src={fileUrl}
                      alt="Preview"
                      style={{ maxWidth: "100px", maxHeight: "100px" }}
                    />
                  )}
                  {fileType === "pdf" && fileUrl && (
                    <object
                      data={fileUrl}
                      type="application/pdf"
                      width="100%"
                      height="500px"
                    >
                      <p>{t("pdfPreviewNotAvailable")}</p>
                    </object>
                  )}
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("district")}*</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width={isMobile?"100%":"60%"}
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <Autocomplete
                      options={districts}
                      getOptionLabel={(option) =>
                        option[i18next.language] || option.en
                      }
                      value={district}
                      onChange={handleDistrictChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          InputProps={{
                            ...params.InputProps,
                            disableUnderline: true,
                            style: {
                              ...inputPropsStyle,
                              textAlign: "left",
                              borderRadius: "8px",
                            },
                          }}
                          InputLabelProps={{
                            shrink: true,
                            style: inputLabelPropsStyle,
                          }}
                          sx={{
                            "& .Mui-disabled": {
                              backgroundColor: "white !important",
                              color: "#044535 !important",
                            },
                            "& .MuiInputLabel-root": {
                              color: "#044535 !important",
                            },
                          }}
                          variant="standard"
                        />
                      )}
                      renderOption={(props, option) => (
                        <li {...props} key={option.id}>
                          {option[i18next.language] || option.en}
                        </li>
                      )}
                      sx={{
                        "& .MuiAutocomplete-popupIndicator": {
                          display: "none",
                        },
                        "& .MuiAutocomplete-clearIndicator": {
                          display: "none",
                        },
                        "& .MuiOutlinedInput-root": {
                          padding: 0,
                        },
                        "& .MuiAutocomplete-listbox": {
                          fontFamily: "Montserrat",
                          fontSize: "14px",
                        },
                        "& .MuiAutocomplete-inputRoot": {
                          padding: "0 !important",
                        },
                      }}
                    />
                  )}
                </div>
              </Grid>
              <Grid item xs={6}>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("visibility")}</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width={isMobile?"100%":"60%"}
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <FormControlLabel
                      control={
                        <Switch
                          checked={visible}
                          onChange={handleSwitchChange}
                          color="primary"
                          sx={{
                            "& .MuiSwitch-switchBase.Mui-checked": {
                              color: "#FFFFFF !important",
                            },
                            "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                              {
                                backgroundColor: "#13BB56 !important",
                                opacity: "1 !important",
                              },
                          }}
                        />
                      }
                      label={t("visible")}
                      style={{ marginTop: "20px" }}
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          flexGrow: 1,
                          textAlign: "left",
                          marginLeft: "10px",
                          color: "#0F1111",
                          fontFamily: "Montserrat",
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: 600,
                          lineHeight: "normal",
                        },
                      }}
                    />
                  )}
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("booking")}</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width={isMobile?"100%":"60%"}
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <FormControlLabel
                      control={
                        <Switch
                          checked={booking}
                          onChange={handleBookingChange}
                          color="primary"
                          sx={{
                            "& .MuiSwitch-switchBase.Mui-checked": {
                              color: "#FFFFFF !important",
                            },
                            "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                              {
                                backgroundColor: "#13BB56 !important",
                                opacity: "1 !important",
                              },
                          }}
                        />
                      }
                      label={t("booking")}
                      style={{ marginTop: "20px" }}
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          flexGrow: 1,
                          textAlign: "left",
                          marginLeft: "10px",
                          color: "#0F1111",
                          fontFamily: "Montserrat",
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: 600,
                          lineHeight: "normal",
                        },
                      }}
                    />
                  )}
                </div>

                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("partnerLink")}</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width={isMobile?"100%":"60%"}
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      InputProps={{
                        disableUnderline: true,
                        style: inputPropsStyle,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      onChange={(e) => {
                        setPartnerUrl(e.target.value);
                      }}
                      value={partnerUrl}
                    />
                  )}
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("equipmentBooking")}</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width={isMobile?"100%":"60%"}
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      InputProps={{
                        disableUnderline: true,
                        style: inputPropsStyle,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      onChange={(e) => {
                        setUrlBooking(e.target.value);
                      }}
                      value={urlBooking}
                    />
                  )}
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("equipmentTripAdvisor")}</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width={isMobile?"100%":"60%"}
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      InputProps={{
                        disableUnderline: true,
                        style: inputPropsStyle,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      onChange={(e) => {
                        setUrlTripAdvisor(e.target.value);
                      }}
                      value={urlTripAdvisor}
                    />
                  )}
                </div>

                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("equipmentFacebook")}</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width={isMobile?"100%":"60%"}
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      InputProps={{
                        disableUnderline: true,
                        style: inputPropsStyle,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      onChange={(e) => {
                        setUrlFacebook(e.target.value);
                      }}
                      value={urlFacebook}
                    />
                  )}
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("equipmentInstagram")}</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width={isMobile?"100%":"60%"}
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      InputProps={{
                        disableUnderline: true,
                        style: inputPropsStyle,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      onChange={(e) => {
                        setUrlInstagram(e.target.value);
                      }}
                      value={urlInstagram}
                    />
                  )}
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("equipmentYoutube")}</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width={isMobile?"100%":"60%"}
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      InputProps={{
                        disableUnderline: true,
                        style: inputPropsStyle,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      onChange={(e) => {
                        setUrlYoutube(e.target.value);
                      }}
                      value={urlYoutube}
                    />
                  )}
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">
                    {t("startdate")} - {t("contract")}*
                  </a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width={isMobile?"100%":"60%"}
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale={i18next?.language}
                    >
                      <DateTimePicker
                        variant="standard"
                        showTimeSelect
                        value={startDate}
                        onChange={(e) => {
                          setStartDate(e);
                        }}
                        slotProps={{
                          tabs: {
                            hidden: false,
                          },

                          day: {
                            sx: {
                              "&.MuiPickersDay-root.Mui-selected": {
                                backgroundColor: "#13BB56 !important",
                              },
                            },
                          },

                          desktopPaper: {
                            sx: {
                              borderRadius: "8px",
                              ".MuiPickersYear-yearButton.Mui-selected": {
                                backgroundColor: "#13BB56 !important",
                              },
                              ".MuiPickersMonth-monthButton.Mui-selected": {
                                backgroundColor: "#13BB56 !important",
                              },
                              "& .css-1e3wlyl-MuiButtonBase-root-MuiMenuItem-root-MuiMultiSectionDigitalClockSection-item.Mui-selected":
                                {
                                  backgroundColor: "#13BB56 !important",
                                },
                              "& .css-y0fr9s-MuiButtonBase-root-MuiTab-root.Mui-selected":
                                {
                                  color: "#13BB56 !important",
                                },
                              "& .css-1aquho2-MuiTabs-indicator": {
                                backgroundColor: "#13BB56 !important",
                              },
                              "& .css-1e6y48t-MuiButtonBase-root-MuiButton-root":
                                {
                                  color: "#13BB56 !important",
                                },
                            },
                          },
                        }}
                        sx={{
                          width: isMobile?"100%":"60%",

                          "& .MuiInputBase-input.Mui-disabled": {
                            backgroundColor: "white",
                          },
                          "& .MuiPickersDay-root.Mui-selected": {
                            backgroundColor: "#44b887 !important",
                            color: "white",
                          },
                          "& .MuiPickersCalendar-root": {
                            borderRadius: "8px !important",
                          },

                          "& .MuiInputLabel-root ": {
                            color: "#044535 !important",

                            fontFamily: "Montserrat",
                            fontSize: "18px !important",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "15px",
                            borderColor: "transparent !important",
                            position: "absolute",
                            marginTop: "-5px",
                            marginLeft: "-10px",
                          },
                          "& .MuiOutlinedInput-root": {
                            color: "#0F111199 !important",
                            textAlign: "right",
                            fontFamily: "Montserrat",
                            fontSize: "18px",
                            fontStyle: "normal",
                            fontWeight: 500,
                            lineHeight: "15px",
                            borderRadius: "8px",
                            // background: !edicao ? "#0f11110d" : "white",
                            borderColor: "transparent !important",
                            width: "100%",
                            height: "34.88px",
                            padding: "16.5px 10px",
                          },
                          "& .MuiInputLabel-root.Mui-disabled ": {
                            color: "#044535 !important",
                            fontFamily: "Montserrat",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "15px",
                            borderColor: "transparent !important",
                          },
                          "& .MuiOutlinedInput-notchedOutline ": {
                            border: "0px",
                          },
                          "& .MuiOutlinedInput-input": {
                            padding: "16.5px 0px",
                            width: "100%",
                          },
                        }}
                      ></DateTimePicker>
                    </LocalizationProvider>
                  )}
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">
                    {t("enddate")} - {t("contract")}*
                  </a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width={isMobile?"100%":"60%"}
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale={i18next?.language}
                    >
                      <DateTimePicker
                        variant="standard"
                        showTimeSelect
                        value={endDate}
                        onChange={(e) => {
                          setEndDate(e);
                        }}
                        slotProps={{
                          tabs: {
                            hidden: false,
                          },

                          day: {
                            sx: {
                              "&.MuiPickersDay-root.Mui-selected": {
                                backgroundColor: "#13BB56 !important",
                              },
                            },
                          },

                          desktopPaper: {
                            sx: {
                              borderRadius: "8px",
                              ".MuiPickersYear-yearButton.Mui-selected": {
                                backgroundColor: "#13BB56 !important",
                              },
                              ".MuiPickersMonth-monthButton.Mui-selected": {
                                backgroundColor: "#13BB56 !important",
                              },
                              "& .css-1e3wlyl-MuiButtonBase-root-MuiMenuItem-root-MuiMultiSectionDigitalClockSection-item.Mui-selected":
                                {
                                  backgroundColor: "#13BB56 !important",
                                },
                              "& .css-y0fr9s-MuiButtonBase-root-MuiTab-root.Mui-selected":
                                {
                                  color: "#13BB56 !important",
                                },
                              "& .css-1aquho2-MuiTabs-indicator": {
                                backgroundColor: "#13BB56 !important",
                              },
                              "& .css-1e6y48t-MuiButtonBase-root-MuiButton-root":
                                {
                                  color: "#13BB56 !important",
                                },
                            },
                          },
                        }}
                        sx={{
                          width:isMobile?"100%": "60%",

                          "& .MuiInputBase-input.Mui-disabled": {
                            backgroundColor: "white",
                          },
                          "& .MuiPickersDay-root.Mui-selected": {
                            backgroundColor: "#44b887 !important",
                            color: "white",
                          },
                          "& .MuiPickersCalendar-root": {
                            borderRadius: "8px !important",
                          },

                          "& .MuiInputLabel-root ": {
                            color: "#044535 !important",

                            fontFamily: "Montserrat",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "15px",
                            borderColor: "transparent !important",
                            position: "absolute",
                            marginTop: "-5px",
                            marginLeft: "-10px",
                          },
                          "& .MuiOutlinedInput-root": {
                            color: "#0F111199 !important",
                            textAlign: "right",
                            fontFamily: "Montserrat",
                            fontSize: "18px",
                            fontStyle: "normal",
                            fontWeight: 500,
                            lineHeight: "15px",
                            borderRadius: "8px",
                            // background: !edicao ? "#0f11110d" : "white",
                            borderColor: "transparent !important",
                            width: "100%",
                            height: "34.88px",
                            padding: "16.5px 10px",
                          },
                          "& .MuiInputLabel-root.Mui-disabled ": {
                            color: "#044535 !important",
                            fontFamily: "Montserrat",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "15px",
                            borderColor: "transparent !important",
                          },
                          "& .MuiOutlinedInput-notchedOutline ": {
                            border: "0px",
                          },
                          "& .MuiOutlinedInput-input": {
                            padding: "16.5px 0px",
                            width: "100%",
                          },
                        }}
                      ></DateTimePicker>
                    </LocalizationProvider>
                  )}
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("contract")}*</a>

                  <Dropzone
                    onDrop={onDropFile2}
                    accept="image/*"
                    multiple={false}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div
                        {...getRootProps()}
                        style={{
                          border: "2px dashed #cccccc",
                          borderRadius: "10px",
                          padding: "20px",
                          textAlign: "center",
                          color: "#666666",
                          backgroundColor: "#f9f9f9",
                          cursor: "pointer",
                          transition: "background-color 0.3s ease",
                          width: "54%",
                        }}
                      >
                        <input {...getInputProps()} />
                        <p>{t("selectImg")}</p>
                      </div>
                    )}
                  </Dropzone>

                  {contractType === "image" && contractUrl && (
                    <img
                      src={contractUrl}
                      alt="Preview"
                      style={{ maxWidth: "100px", maxHeight: "100px" }}
                    />
                  )}
                  {contractType === "pdf" && contractUrl && (
                    <object
                      data={contractUrl}
                      type="application/pdf"
                      width="100%"
                      height="500px"
                    >
                      <p>{t("pdfPreviewNotAvailable")}</p>
                    </object>
                  )}
                  {/* {file && fileType === "pdf" && (
                    <div>
                      <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js">
                        <Viewer
                          fileUrl={fileUrl}
                          plugins={[defaultLayoutPluginInstance]}
                        />
                      </Worker>
                    </div>
                  )} */}
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("plan")}*</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width={isMobile?"100%":"60%"}
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      select
                      InputProps={{
                        disableUnderline: true,
                        style: {
                          ...inputPropsStyle,
                          textAlign: "left",
                          borderRadius: "8px",
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      value={plan}
                      onChange={handleChangePlan}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                    >
                      {plans.map((plan) => (
                        <MenuItem key={plan._id} value={plan.uuid}>
                          {plan[i18next.language]}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("offer")}*</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width={isMobile?"100%":"60%"}
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      select
                      InputProps={{
                        disableUnderline: true,
                        style: {
                          ...inputPropsStyle,
                          textAlign: "left",
                          borderRadius: "8px",
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      value={offer}
                      onChange={handleChangeOffer}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                    >
                      {offers.map((offer) => (
                        <MenuItem key={offer._id} value={offer.uuid}>
                          {offer.name} - {offer.discount}%
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                </div>
              </Grid>
            </Grid>
          </div>
          <div className="bottomRowEdit">
            <button
              className="clientInfoEditButton2"
              onClick={() => {
                create();
              }}
            >
              {t("create")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PartnerCreateAdmin;
