import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../App.css";
import { useTranslation } from "react-i18next";
import fest from "../../images/example/fest.jpeg";
import card from "../../images/example/card.jpeg";
import NavClient from "../../components/navClient";
import HeaderClientTransparent from "../../components/headerClientTransparent";
import locationIcon from "../../images/eventIcons/locationIcon.svg";
import calendarIcon from "../../images/eventIcons/calendarIcon.svg";
import x from "../../images/eventIcons/x.svg";
import booking from "../../images/eventIcons/booking.svg";
import tripadvisor from "../../images/eventIcons/tripadvisor.svg";
import youtube from "../../images/eventIcons/youtube.svg";
import instagram from "../../images/eventIcons/instagram.svg";
import facebook from "../../images/eventIcons/facebook.svg";
import { Modal, Backdrop, IconButton, Skeleton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Cookies from "js-cookie";
import { getPartner, getPartners } from "../../api/requests/Partner";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  AdvancedMarker,
  APIProvider,
  Map,
  Pin,
} from "@vis.gl/react-google-maps";
import NavClientHorizontal from "../../components/navClientHorizontal";

function PartnerPageClient() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [name, setName] = useState(0);
  const [partner, setPartners] = useState([]);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const { partnerId } = location.state || {};

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleBackdropClick = (event) => {
    if (event.target === event.currentTarget) {
      handleClose();
    }
  };

  useEffect(() => {
    const id = Cookies.get("id");
    const token = Cookies.get("token");
    const nameU = Cookies.get("first_name");
    setName(nameU);

    getPartner(partnerId)
      .then((res) => {
        if (res.status === 200 && res.success) {
          setPartners([res.data]);
          // console.log(res.data.coordinates);
          if (partner != []) {
            setLoading(false);
          }
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error(t("500"));
      });
    if (!id && !token) {
      navigate("/loginclient");
      Cookies.remove("id");
      Cookies.remove("email");
      Cookies.remove("token");
      Cookies.remove("first_name");
      Cookies.remove("last_name");
      Cookies.remove("photo");
    }
  }, []);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <div className="background">
      <HeaderClientTransparent />
      {isMobile ? <NavClientHorizontal /> : <NavClient />}
      <div className="backgroundImageDashboardClient"></div>
      <div className="topLeftBackTrip">
        <IconButton
          onClick={() => {
            navigate(-1);
          }}
        >
   <ArrowBackIcon
            style={{ fill: "#044535", zIndex: 99, fontSize:isMobile?"20px":"25px" }}
          />
        </IconButton>
      </div>
      {loading ? (
        <div className="skeletonDiv">
          <Skeleton
            height={"100%"}
            width={"100%"}
            sx={{ borderRadius: "1.0625rem 1.0625rem 0.5rem 0.5rem;" }}
            // className="eventbigcardloading"
          />
        </div>
      ) : (
        <>
          <div className="scrollableTransparentDiv">
            <div className="eventbigcard">
              <img src={partner[0].photo} className="eventbigcardImage" />
              <div className="fullWidthCard">
                <div className="cardContent">
                  <a className="eventTitle">{partner[0].name}</a>
                  <div className="infoRow">
                    <span
                      style={{
                        width: 20.53,
                        height: 30,
                        backgroundImage: `url(${locationIcon})`,
                      }}
                    />
                    <div className="cardContent">
                      <a className="eventText">
                        {partner[0].district[0].pt}, Portugal
                      </a>
                    </div>

                    <div className="cardContentRight">
                      <div
                        style={{
                          flexDirection: "row",
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          marginTop: "80px",
                          marginRight: "10px",
                        }}
                      >
                        <span
                          style={{
                            width: 22,
                            height: 22,
                            backgroundImage: `url(${instagram})`,
                            marginInline: "4px",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            window.open(`${partner[0].url_instagram}`, "_blank")
                          }
                        />
                        <span
                          style={{
                            width: 22,
                            height: 22,
                            backgroundImage: `url(${facebook})`,
                            marginInline: "4px",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            window.open(`${partner[0].url_facebook}`, "_blank")
                          }
                        />

                        <span
                          style={{
                            width: 22,
                            height: 22,
                            backgroundImage: `url(${youtube})`,
                            marginInline: "4px",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            window.open(`${partner[0].url_youtube}`, "_blank")
                          }
                        />
                        <span
                          style={{
                            width: 22,
                            height: 22,
                            backgroundImage: `url(${tripadvisor})`,
                            marginInline: "4px",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            window.open(
                              `${partner[0].url_tripadvisor}`,
                              "_blank"
                            )
                          }
                        />
                        <span
                          style={{
                            width: 22,
                            height: 22,
                            backgroundImage: `url(${booking})`,
                            marginInline: "4px",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            window.open(`${partner[0].url_booking}`, "_blank")
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="infoEventCard">
              <img
                src={partner[0].schedules}
                className="eventcardSmall"
                onClick={handleOpen}
              />
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="event-card-modal"
                aria-describedby="event-card-description"
                BackdropComponent={Backdrop}
                BackdropProps={{ timeout: 500, onClick: handleBackdropClick }}
              >
                <div className="modal-wrapper">
                  <div className="modal-content">
                    <IconButton
                      onClick={handleClose}
                      sx={{ position: "absolute", right: 10, top: 10 }}
                    >
                      <CloseIcon style={{ color: "white", borderRadius: 20 }} />
                    </IconButton>
                    <div className="image-container">
                      <img src={partner[0].schedules} className="modal-image" />
                    </div>
                  </div>
                </div>
              </Modal>

              <div className="cardContentInfo" style={{ marginLeft: 40 }}>
                <div className="textRow">
                  <div className="cardContentInfo">
                    <a className="eventInfoTitle">{t("local")}</a>
                    <a className="eventInfoText">{partner[0].address}</a>
                    
                  </div>
                  <div className="cardContentInfo">
                    <a className="eventInfoTitle">{t("contact")}</a>
                    <a className="eventInfoText">{partner[0].contact}</a>
                  </div>
                </div>
                <div className="partnermap">
                      <APIProvider
                        apiKey={"AIzaSyAju_Bdgsx_fY_VUJNTJTzKdw5P2z2A9ts"}
                      >
                        <Map
                          defaultCenter={{
                            lat: partner[0].coordinates[0],
                            lng: partner[0].coordinates[1],
                          }}
                          mapId="17c9b3c0488e85ae"
                          defaultZoom={12}
                          style={{
                            borderRadius: "0.675rem !important",
                          }}
                          gestureHandling={"greedy"}
                          disableDefaultUI={true}
                          fullscreenControl={false}
                        >
                          <AdvancedMarker
                            position={{
                              lat: partner[0].coordinates[0],
                              lng: partner[0].coordinates[1],
                            }}
                          >
                            <Pin
                              background={"#fd7000"}
                              borderColor={"#D56102"}
                              glyphColor={"#D56102"}
                            />
                          </AdvancedMarker>
                        </Map>
                      </APIProvider>
                    </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
export default PartnerPageClient;
