import React, { useEffect, useState, useRef } from "react";

import { useNavigate } from "react-router-dom";
import "../../App.css";
import { useTranslation } from "react-i18next";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";
import Header from "../../components/header";
import NavClient from "../../components/navClient";
import { APIProvider, Map, Marker } from "@vis.gl/react-google-maps";
import HeaderClientTransparent from "../../components/headerClientTransparent";
import { Fade, Grid, Typography } from "@mui/material";
import { getUser } from "../../api/requests/User";
import Cookies from "js-cookie";
import toast from "react-hot-toast";
import { getTripsById } from "../../api/requests/Trips";
import { useMap, useMapsLibrary } from "@vis.gl/react-google-maps";
import TrainIcon from "@mui/icons-material/Train";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import DirectionsTransitIcon from "@mui/icons-material/DirectionsTransit";
import DirectionsRailwayIcon from "@mui/icons-material/DirectionsRailway";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import NavClientHorizontal from "../../components/navClientHorizontal";

function ClientTrips() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const id = Cookies.get("id");
  const [data, setData] = useState([]);
  const [trips, setTrips] = useState([]);
  const [totalCo2, setTotalCo2] = useState(0);
  const [totalDistance, setTotalDistance] = useState(0);
  const [totalTrips, setTotalTrips] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000); // 1 segundo para exibir o esqueleto

    return () => clearTimeout(timer);
  }, []);

  const formatTripDates = (departureDate, returnDate) => {
    const dateOptions = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    };

    const timeOptions = {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    };

    const formattedDepartureDate =
      new Date(departureDate).toLocaleDateString(undefined, dateOptions) +
      " " +
      new Date(departureDate).toLocaleTimeString(undefined, timeOptions) +
      "h";

    const formattedReturnDate =
      new Date(returnDate).toLocaleDateString(undefined, dateOptions) +
      " " +
      new Date(returnDate).toLocaleTimeString(undefined, timeOptions) +
      "h";

    return {
      formattedDepartureDate,
      formattedReturnDate,
    };
  };

  const Trip = ({ event, departureDate, returnDate, start, end, id }) => {
    return (
      <div className="trip">
        <h3 className="two-line-text">{event}</h3>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "5px",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <CalendarTodayIcon
              style={{ color: "#13BB56", marginRight: "5px" }}
            />
            <span style={{ fontSize: "0.8rem" }}>
              <strong>Ida:</strong> {departureDate}
            </span>
          </div>
          <CompareArrowsIcon style={{ color: "#444", margin: "0 10px" }} />
          <div style={{ display: "flex", alignItems: "center" }}>
            <CalendarTodayIcon
              style={{ color: "#13BB56", marginRight: "5px" }}
            />
            <span style={{ fontSize: "0.8rem" }}>
              <strong>Volta:</strong> {returnDate}
            </span>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "0 10px",
            height: "38px",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <TrainIcon style={{ color: "#13BB56", marginRight: "5px" }} />
            <span style={{ fontSize: "0.8rem" }}>{start}</span>
          </div>
          <ArrowForwardIcon style={{ color: "#444", margin: "0 10px" }} />
          <div style={{ display: "flex", alignItems: "center" }}>
            <TrainIcon style={{ color: "#13BB56", marginRight: "5px" }} />
            <span style={{ fontSize: "0.8rem" }}>{end}</span>
          </div>
        </div>

        <button
          className="details-button"
          onClick={() =>
            navigate("/tripinfo", {
              state: { id: id },
            })
          }
        >
          {t("seemore")}
        </button>
      </div>
    );
  };

  useEffect(() => {
    getUser(id)
      .then(async (res) => {
        if (res.status === 200 && res.success) {
          let data = res.data;
          setData(data);

          if (data.trips.length > 0) {
            const tripsPromises = data.trips.map((element) =>
              getTripsById(element)
                .then((res) => {
                  if (res.status === 200 && res.success) {
                    return res.data;
                  } else {
                    toast.error(res.error);
                    return null;
                  }
                })
                .catch((error) => {
                  toast.error(error.message);
                  return null;
                })
            );

            const results = await Promise.all(tripsPromises);
            const validTrips = results.filter((result) => result !== null);

            const totalCo2 = validTrips.reduce(
              (acc, trip) => acc + parseFloat(trip.co2 || 0),
              0
            );

            const totalDistance = validTrips.reduce(
              (acc, trip) => acc + parseFloat(trip.distance || 0),
              0
            );

            const totalTrips = validTrips.length; // Total de viagens válidas

            // Atualizar estados
            setTotalCo2(totalCo2);
            setTotalDistance(totalDistance);
            setTotalTrips(totalTrips);

            // Console log dos totais
            // console.log("Total Trips:", totalTrips);
            // console.log("Total CO2:", totalCo2);
            // console.log("Total Distance:", totalDistance);

            setTrips(validTrips);
          }

          setLoading(false);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error("500");
      });
  }, [id]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);


  
  useEffect(() => {
    const timer = setTimeout(() => setLoading(false), 1000); 
    return () => clearTimeout(timer); 
  }, []);


  return (
    <div className="background">
      <HeaderClientTransparent />
      {isMobile ? <NavClientHorizontal /> : <NavClient />}
      <div className="backgroundImageDashboardClient"></div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          paddingTop: "2rem",
          width: "100%",
          flexDirection: "column",
        }}
      >
        <a className="greenTitleTrip">{t("myTrips")}</a>
        {loading ? (
        <Skeleton
          variant="rectangular"
          height="150px" 
          sx={{
            padding: { xs: "10px", sm: "20px" },
            borderRadius: "16px",
            width: { xs: "80%", sm: "60%" },
            margin: "10px auto",
          }}
        />
      ) : (
        <Box
          sx={{
            padding: { xs: "20px", sm: "30px" },
            borderRadius: "16px",
            boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
            backgroundColor: "white",
            textAlign: "center",
            maxWidth: { xs: "80%", sm: "60%" },
            margin: "10px auto",
            animation: "pulse 1.5s infinite",
            "@keyframes pulse": {
              "0%": {
                transform: "scale(1)",
              },
              "50%": {
                transform: "scale(1.02)",
              },
              "100%": {
                transform: "scale(1)",
              },
            },
          }}
        >
          <Typography
            variant="h6"
            gutterBottom
            sx={{
              fontFamily: "Montserrat, sans-serif",
              fontWeight: "bold",
              fontSize: { xs: "1rem", sm: "1.25rem" }, // Tamanho menor para fontes em XS
              color: "#044535",
            }}
          >
            🚆 {t("differenceTrips")}
          </Typography>

          <Typography
            variant="body1"
            sx={{
              fontFamily: "Montserrat, sans-serif",
              fontSize: { xs: "0.9rem", sm: "1.1rem" }, // Texto menor para XS
              color: "#004d40",
            }}
          >
            {t("withyours")} <strong>{totalTrips}</strong> {t("tripsand")}{" "}
            <strong>{totalDistance}</strong> {t("kmcomma")}{" "}
            <strong style={{ color: "#13BB56" }}>{t("savings")}</strong>{" "}
            {t("approximately")} <strong>{totalCo2}</strong> {t("CO2g")}
          </Typography>
        </Box>
      )}
        <div className="scroll-trips">
          <Grid
            container
            spacing={1}
            justifyContent="center"
            style={{
              marginBottom: "20px",
              marginTop: "20px",
            }}
          >
             {loading
          ? // Render Skeletons enquanto estiver carregando
            Array.from({ length: 6 }).map((_, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  height="200px"
                  sx={{
                    borderRadius: "8px",
                  }}
                />
              </Grid>
            ))
          : trips.map((viagem, index) => {
              const { formattedDepartureDate, formattedReturnDate } =
                formatTripDates(viagem.departure_date, viagem.return_date);

              return (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Trip
                    event={viagem.eventName}
                    departureDate={formattedDepartureDate}
                    returnDate={formattedReturnDate}
                    start={viagem.originName}
                    end={viagem.destinationName}
                    id={viagem._id}
                  />
                </Grid>
              );
            })}
          </Grid>
        </div>
      </div>
    </div>
  );
}

export default ClientTrips;
