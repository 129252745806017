import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Checkbox,
  CircularProgress,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import "../../App.css";
import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";
import Header from "../../components/header";
import NavAdmin from "../../components/navAdmin";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Search1 from "../../components/Search1";
import { useMediaQuery } from "@mui/material";
import {
  DataGrid,
  GridActionsCellItem,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { esES, ptPT, enUS } from "@mui/x-data-grid/locales";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { getUsers } from "../../api/requests/User";
import toast from "react-hot-toast";
import Cookies from "js-cookie";
import NavAdminMobile from "../../components/navAdminHorizontal";

function ClientsAdmin() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  function goBack() {
    navigate("/dashboardadmin");
  }
  const [clients, setClients] = useState([]);
  const [clientsCopy, setClientsCopy] = useState([]);
  const [loading, setLoading] = useState(true);

  const theme = createTheme({
    components: {
      MuiCircularProgress: {
        styleOverrides: {
          root: {
            color: "#13BB56",
          },
        },
      },
    },
  });

  useEffect(() => {
    const id = Cookies.get("id");
    const token = Cookies.get("token");

    if (!id && !token) {
      navigate("/loginadmin");

      Cookies.remove("id");
      Cookies.remove("email");
      Cookies.remove("token");
      Cookies.remove("first_name");
      Cookies.remove("last_name");
      Cookies.remove("photo");
    }

    getUsers()
      .then((res) => {
        if (res.status === 200 && res.success) {
          setClients(res.data);

          setClientsCopy(res.data);
          setLoading(false);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error(t("500"));
      });
  }, []);

  const [search, setSearch] = useState("");

  function CustomToolbar() {
    return (
      <GridToolbarContainer
        style={{
          justifyContent: "flex-end",
          marginTop:isMobile?"0px": "-50px",
          marginBottom: isMobile?"0px":"30px",
          marginRight: "0px",
        }}
      >
        <GridToolbarFilterButton
          slotProps={{
            button: {
              style: {
                color: "#044535",
                fontFamily: "Montserrat",
                textTransform: "none",
                fontWeight: 600,
                fontSize: "clamp(12px, 2vw, 16px)",
              },
            },
          }}
        />
        <GridToolbarExport
          slotProps={{
            button: {
              style: {
                color: "#044535",
                fontFamily: "Montserrat",
                textTransform: "none",
                fontWeight: 600,
                fontSize: "clamp(12px, 2vw, 16px)",
              },
            },
          }}
        />
      </GridToolbarContainer>
    );
  }

  useEffect(() => {
    if (search) {
      let arrayClients = clientsCopy;
      arrayClients = arrayClients.filter(
        (element) =>
          element.full_name.toLowerCase().includes(search.toLowerCase()) ||
          element.district_name.toLowerCase().includes(search.toLowerCase()) ||
          element.email.toLowerCase().includes(search.toLowerCase())
      );
      setClients(arrayClients);
    } else {
      setClients(clientsCopy);
    }
  }, [search]);

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1000);
  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({
    _id: !isSmallScreen,
    email: !isSmallScreen,
    __v: !isSmallScreen,
    district_name: !isSmallScreen,
  });

  const handleResize = () => {
    const smallScreen = window.innerWidth < 1000;
    setIsSmallScreen(smallScreen);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    setColumnVisibilityModel({
      _id: !isSmallScreen,
      email: !isSmallScreen,
      __v: !isSmallScreen,
      district_name: !isSmallScreen,
    });
  }, [isSmallScreen]);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="background">
      <Header />
      {isMobile?<NavAdminMobile/>:<NavAdmin />}
      <div className="topLeftBack">
        <IconButton onClick={goBack}>
          <ArrowBackIcon
            style={{ fill: "#044535", zIndex: 99, fontSize:isMobile?"20px":"25px" }}
          />
        </IconButton>
      </div>
      <div className="centerpage">
        <a className="greenTitle">{t("clients")}</a>
        <div className="whiteBoard">
          <div
            className="col-0"
            style={{
              textAlign: "start",
              marginTop: isMobile?15:45,
              marginLeft: isMobile?10:30,
              flexDirection: "row",
              display: "flex",
              zIndex: 99999,
            }}
          >
            <Search1
              onSearch={(value) => {
                setSearch(value);
              }}
            />

          </div>
          <div style={{ height: "75%", maxWidth: "100%" }}>
            <ThemeProvider theme={theme}>
              <DataGrid
                className="grid"
                autoPageSize={true}
                columnVisibilityModel={columnVisibilityModel}
                loading={loading}
                columns={[
                  {
                    field: "_id",
                    headerName: t("id"),
                    type: "number",
                    maxWidth: 70,
                    flex: 1,
                  },
                  {
                    field: "full_name",
                    headerName: t("name"),
                    type: "string",
                    flex: 1,
                  },
                  {
                    field: "email",
                    headerName: t("email"),
                    type: "string",
                    flex: 1,
                  },
                  {
                    field: "__v",
                    headerName: t("numberUses"),
                    type: "string",
                    flex: 1,
                    align: "center",
                  },
                  {
                    field: "district_name",
                    headerName: t("district"),
                    flex: 1,
                    type: "string",
                  },
                  // {
                  //   field: "tickets",
                  //   headerName: t("tickets"),
                  //   flex: 1,
                  //   type: "number",
                  // },

                  {
                    field: "actions",
                    type: "actions",
                    headerName: t("actions"),
                    flex: 1,
                    cellClassName: "actions",
                    getActions: ({ id }) => {
                      return [
                        <GridActionsCellItem
                          icon={
                            <FontAwesomeIcon
                              style={{
                                fontSize: "clamp(14px, 1.5vw, 18px)",
                                color: "#0F1111",
                              }}
                              icon={faPen}
                            />
                          }
                          label="Save"
                          onClick={() => {
                            navigate("/clientpageadmin", {
                              state: { clientId: id },
                            });
                          }}
                        />,
                      ];
                    },
                  },
                ]}
                sx={{
                  "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus":
                    {
                      outline: "none",
                    },
                  "& .MuiDataGrid-cell": {
                    fontSize: "clamp(12px, 1.5vw, 16px)",
                  },
                  "& .MuiDataGrid-columnHeaders": {
                    fontSize: "clamp(14px, 2vw, 18px)",
                  },
                  "& .MuiDataGrid-footerContainer": {
                    fontSize: "clamp(12px, 1.5vw, 16px)",
                  },
                  "& .MuiTablePagination-root ": {
                    overflow: "hidden !important",
                  },
                }}
                rows={clients}
                checkboxSelection={true}
                onRowClick={(params) => {}}
                getRowId={(row) => row._id}
                //Idioma
                localeText={
                  i18n.language == "pt"
                    ? ptPT.components.MuiDataGrid.defaultProps.localeText
                    : i18n.language == "es"
                    ? esES.components.MuiDataGrid.defaultProps.localeText
                    : enUS.components.MuiDataGrid.defaultProps.localeText
                }
                components={{
                  BaseCheckbox: ({ value, ...otherProps }) => (
                    <Checkbox size="small" checked={value} {...otherProps} />
                  ),
                  LoadingOverlay: <CircularProgress />,
                }}
                slots={{
                  toolbar: CustomToolbar,
                }}
              />
            </ThemeProvider>
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <button
                className="clientInfoEditButton"
                onClick={() => {
                  navigate("/addclientadmin");
                }}
              >
                {t("addClient")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClientsAdmin;
