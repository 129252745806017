import { makeRequestGet, makeRequestGet2, makeRequestPost, makeRequestPost2, makeRequestPut2 } from "../api";
import Cookies from "js-cookie";

function getToken() {
  return new Promise((resolve) => {
    const checkToken = () => {
      let token = Cookies.get("token");
      if (token) {
        resolve(token);
      } else {
        setTimeout(checkToken, 100); // Check again after 100ms
      }
    };
    checkToken();
  });
}

export async function getPlans() {
  let token = await getToken();
  return makeRequestGet2(`payment-plans/`, token);
}

export function getPlansAll() {
  return makeRequestGet(`payment-plans1/`);
}

export async function getPlan(id) {
  let token = await getToken();
  return makeRequestGet2(`payment-plans/${id}`, token);
}

export async function postPlan(data) {
  let token = await getToken();
  return makeRequestPost2(`payment-plans/`, data, token);
}


export async function putPlan(id, data) {
  let token = await getToken();
  return makeRequestPut2(`payment-plans/${id}`, data, token);
}
