import React, { useEffect } from "react";
import logo from "../../images/logo.svg";
import background from "../../images/background.png";
import { useNavigate } from "react-router-dom";

import "../../App.css";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

function Start() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  function goLogin() {
    navigate("/loginclient");
  }
  function goRegister() {
    navigate("/registerclient");
  }
  

  return (
    <div className="background">
   <div className="backgroundImage">
   <div className="topcenter">
   <div className="logo"></div>
   <p className="title">{t("sameplace")}</p>
   </div>
   
   </div>
   <div className="bottomcenter">
    <p className="bodytext">{t("intro")}</p>
    <div className="columnbtns">
    <button className="greenbtn" onClick={goLogin}> {t("login")}</button>
    <button className="whitebtn" onClick={goRegister}> {t("register")}</button>
    </div>
    </div>
    </div>
  );
}

export default Start;
