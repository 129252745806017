import React from "react";
import { createRoot } from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Link,
  Outlet,
  createRoutesFromElements,
  useNavigate,
} from "react-router-dom";
import "./App.css";
import "./i18next";
import "./index.css";
import App from "./App";
import { useTranslation } from "react-i18next";
import Start from "../src/routes/start/start.js";
import reportWebVitals from "./reportWebVitals";
import "./i18next";
import LoginClient from "./routes/start/loginClient.js";
import RegisterClient from "./routes/start/RegisterClient.js";
import AskResetPasswordClient from "./routes/start/AskResetPasswordClient.js";
import DashboardAdmin from "./routes/admin/dashboardAdmin.js";
import ClientsAdmin from "./routes/admin/clientsAdmin.js";
import CouncilAdmin from "./routes/admin/councilAdmin.js";
import EquipmentsAdmin from "./routes/admin/equipmentsAdmin.js";
import EventsAdmin from "./routes/admin/eventsAdmin.js";
import FederationAdmin from "./routes/admin/federationAdmin.js";
import OrdersAdmin from "./routes/admin/ordersAdmin.js";
import PartnersAdmin from "./routes/admin/partnersAdmin.js";
import PaymentsAdmin from "./routes/admin/paymentsAdmin.js";
import PromoterAdmin from "./routes/admin/promoterAdmin.js";
import StatsAdmin from "./routes/admin/statsAdmin.js";
import HelpAdmin from "./routes/admin/helpAdmin.js";
import ClientPageAdmin from "./routes/admin/clientpageadmin.js";
import LoginAdmin from "./routes/admin/loginAdmin.js";
import { Toaster } from "react-hot-toast";
import DashboardClient from "./routes/client/dashboardClient.js";
import TicketsClient from "./routes/client/ticketsclient.js";
import ClientMap from "./routes/client/clientmap.js";
import ResetPassword from "./routes/start/ResetPassword.js";
import EventPageAdmin from "./routes/admin/eventpageadmin.js";
import EventCreateAdmin from "./routes/admin/eventcreateadmin.js";
import ClientPage from "./routes/client/clientpage.js";
import EventsList from "./routes/client/eventsList.js";
import EventPageClient from "./routes/client/eventsPageClient.js";
import TicketsPage from "./routes/client/ticketsPage.js";
import AskResetPasswordAdmin from "./routes/admin/AskResetPasswordAdmin.js";
import LoginCommercial from "./routes/commercial/loginCommercial.js";
import AskResetPasswordCommercial from "./routes/commercial/AskResetPasswordCommercial.js";
import LoginManager from "./routes/manager/loginManager.js";
import AskResetPasswordManager from "./routes/manager/AskResetPasswordManager.js";
import LoginOwner from "./routes/owner/loginOwner.js";
import AskResetPasswordOwner from "./routes/owner/AskResetPasswordOwner.js";
import LoginPartner from "./routes/partner/loginPartner.js";
import AskResetPasswordPartner from "./routes/partner/AskResetPasswordPartner.js";
import LoginPromoter from "./routes/promoter/loginPromoter.js";
import AskResetPasswordPromoter from "./routes/promoter/AskResetPasswordPromoter.js";
import DashboardManager from "./routes/manager/dashboardManager.js";
import EventsManager from "./routes/manager/eventsManager.js";
import PromotersManager from "./routes/manager/promoterManager.js";
import PartnersManager from "./routes/manager/partnersManager.js";
import EquipmentsManager from "./routes/manager/equipmentsManager.js";
import EventPageManager from "./routes/manager/eventpagemanager.js";
import HelpManager from "./routes/manager/helpManager.js";
import StatsManager from "./routes/manager/statsManager.js";
import StationsList from "./routes/client/stationsList.js";
import PartnersList from "./routes/client/partnersList.js";
import PartnerPageClient from "./routes/client/partnerPageClient.js";
import HelpClient from "./routes/client/helpClient.js";
import EventCreateManager from "./routes/manager/eventcreatemanager.js";
import PaymentsManager from "./routes/manager/paymentsManager.js";
import OrdersManager from "./routes/manager/ordersManager.js";
import PromoterPageAdmin from "./routes/admin/promoterPageAdmin.js";
import PartnerPageAdmin from "./routes/admin/partnerPageAdmin.js";
import EquipmentPageAdmin from "./routes/admin/EquipmentPageAdmin.js";
import PromoterPageManager from "./routes/manager/promoterPageManager.js";
import PartnerPageManager from "./routes/manager/partnerPageManager.js";
import EquipmentPageManager from "./routes/manager/EquipmentPageManager.js";
import FederationPageAdmin from "./routes/admin/federationPageAdmin.js";
import CouncilPageAdmin from "./routes/admin/CouncilPageAdmin.js";
import EquipmentCreateAdmin from "./routes/admin/equipmentcreateadmin.js";
import PartnerCreateAdmin from "./routes/admin/partnercreateadmin.js";
import PromoterCreateAdmin from "./routes/admin/promotercreateadmin.js";
import FederationCreateAdmin from "./routes/admin/federationcreateadmin.js";
import CouncilCreateAdmin from "./routes/admin/councilcreateadmin.js";
import PaymentPageAdmin from "./routes/admin/paymentPageAdmin.js";
import PaymentPageManager from "./routes/manager/paymentPageManager.js";
import Terms from "./routes/manager/Terms.js";
import TermsAdmin from "./routes/admin/Terms.js";
import TermsClient from "./routes/client/Terms.js";
import ClientAddPageAdmin from "./routes/admin/clientaddpageadmin.js";
import ClientPersonal from "./routes/client/personaldata.js";
import PromoterCreateManager from "./routes/manager/promotercreatemanager.js";
import PartnerCreateManager from "./routes/manager/partnercreatemanager.js";
import EquipmentCreateManager from "./routes/manager/equipmentcreatemanager.js";
import VerifyEmail from "./routes/admin/VerifyEmail.js";
import VerifyEmailPartner from "./routes/admin/VerifyEmailPartner.js";
import ClientTrips from "./routes/client/clienttrips.js";
import ClientTripPage from "./routes/client/clienttrippage.js";
import VerifyEmailOwner from "./routes/admin/VerifyEmailOwner.js";
import PartnerPlansAdmin from "./routes/admin/partnerPlansAdmin.js";
import PartnerPlanPageAdmin from "./routes/admin/partnerPlanPageAdmin.js";
import PartnerPlanCreateAdmin from "./routes/admin/partnerPlanCreateAdmin.js";
import OfferPage from "./routes/client/offerPage.js";
import VerifyEmailPromoter from "./routes/admin/VerifyEmailPromoter.js";
import PartnerOffersAdmin from "./routes/admin/partnerOffersAdmin.js";
import PartnerOfferCreateAdmin from "./routes/admin/partnerOfferCreateAdmin.js";
import PartnerOfferPageAdmin from "./routes/admin/partnerOfferPageAdmin.js";
import RegisterPromoter from "./routes/promoter/registerPromoter.js";
import RegisterPartners from "./routes/partner/registerPartner.js";
import PromotersByOwner from "./routes/promoter/promoterOwnerRegister.js";

const AppLayout = () => {
  //Traduções
  const { t, i18n } = useTranslation();
  //Navigator
  const navigate = useNavigate();

  return (
    <>
      <Outlet />
      <Toaster position="top-right" />
    </>
  );
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<AppLayout />}>
      <Route path="/" element={<Start />} />
      <Route path="/loginclient" element={<LoginClient />} />
      <Route path="/registerclient" element={<RegisterClient />} />
      <Route
        path="/askresetpasswordclient"
        element={<AskResetPasswordClient />}
      />
      <Route
        path="/askresetpasswordadmin"
        element={<AskResetPasswordAdmin />}
      />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/dashboardadmin" element={<DashboardAdmin />} />
      <Route path="/clientsadmin" element={<ClientsAdmin />} />
      <Route path="/counciladmin" element={<CouncilAdmin />} />
      <Route path="/equipmentsadmin" element={<EquipmentsAdmin />} />
      <Route path="/eventsadmin" element={<EventsAdmin />} />
      <Route path="/federationadmin" element={<FederationAdmin />} />
      <Route path="/ordersadmin" element={<OrdersAdmin />} />
      <Route path="/partnersadmin" element={<PartnersAdmin />} />
      <Route path="/paymentsadmin" element={<PaymentsAdmin />} />
      <Route path="/promoteradmin" element={<PromoterAdmin />} />
      <Route path="/statsadmin" element={<StatsAdmin />} />
      <Route path="/helpadmin" element={<HelpAdmin />} />
      <Route path="/clientpageadmin" element={<ClientPageAdmin />} />
      <Route path="/eventpageadmin" element={<EventPageAdmin />} />
      <Route path="/eventcreateadmin" element={<EventCreateAdmin />} />
      <Route path="/loginadmin" element={<LoginAdmin />} />
      <Route path="/clientpageadmin" element={<ClientPageAdmin />} />
      <Route path="/dashboardclient" element={<DashboardClient />} />
      <Route path="/ticketsclient" element={<TicketsClient />} />
      <Route path="/clientmap" element={<ClientMap />} />
      <Route path="/ticketsclient" element={<TicketsClient />} />
      <Route path="/clientmap" element={<ClientMap />} />
      <Route path="/clientpage" element={<ClientPage />} />
      <Route path="/eventslist" element={<EventsList />} />
      <Route path="/eventpage" element={<EventPageClient />} />
      <Route path="/tickets" element={<TicketsPage />} />
      <Route path="/logincommercial" element={<LoginCommercial />} />
      <Route
        path="/askresetpasswordcommercial"
        element={<AskResetPasswordCommercial />}
      />
      <Route path="/loginmanager" element={<LoginManager />} />
      <Route
        path="/askresetpasswordmanager"
        element={<AskResetPasswordManager />}
      />
      <Route path="/loginowner" element={<LoginOwner />} />
      <Route
        path="/askresetpasswordowner"
        element={<AskResetPasswordOwner />}
      />
      <Route path="/loginpartner" element={<LoginPartner />} />
      <Route
        path="/askresetpasswordpartner"
        element={<AskResetPasswordPartner />}
      />
      <Route path="/loginpromoter" element={<LoginPromoter />} />
      <Route
        path="/askresetpasswordpromoter"
        element={<AskResetPasswordPromoter />}
      />
      <Route path="/dashboardmanager" element={<DashboardManager />} />
      <Route path="/eventsmanager" element={<EventsManager />} />
      <Route path="/promotersmanager" element={<PromotersManager />} />
      <Route path="/partnersmanager" element={<PartnersManager />} />
      <Route path="/equipmentsmanager" element={<EquipmentsManager />} />
      <Route path="/eventpagemanager" element={<EventPageManager />} />
      <Route path="/helpmanager" element={<HelpManager />} />
      <Route path="/statsmanager" element={<StatsManager />} />
      <Route path="/stationslist" element={<StationsList />} />
      <Route path="/partnerslist" element={<PartnersList />} />
      <Route path="/partnerpage" element={<PartnerPageClient />} />
      <Route path="/helpclient" element={<HelpClient />} />
      <Route path="/eventcreatemanager" element={<EventCreateManager />} />
      <Route path="/paymentsmanager" element={<PaymentsManager />} />
      <Route path="/ordersmanager" element={<OrdersManager />} />
      <Route path="/promoterpageadmin" element={<PromoterPageAdmin />} />
      <Route path="/partnerpageadmin" element={<PartnerPageAdmin />} />
      <Route path="/equipmentpageadmin" element={<EquipmentPageAdmin />} />
      <Route path="/promoterpagemanager" element={<PromoterPageManager />} />
      <Route path="/partnerpagemanager" element={<PartnerPageManager />} />
      <Route path="/equipmentpagemanager" element={<EquipmentPageManager />} />
      <Route path="/federationpageadmin" element={<FederationPageAdmin />} />
      <Route path="/councilpageadmin" element={<CouncilPageAdmin />} />
      <Route path="/equipmentcreateadmin" element={<EquipmentCreateAdmin />} />
      <Route path="/partnercreateadmin" element={<PartnerCreateAdmin />} />
      <Route path="/promotercreateadmin" element={<PromoterCreateAdmin />} />
      <Route
        path="/federationcreateadmin"
        element={<FederationCreateAdmin />}
      />
      <Route path="/councilcreateadmin" element={<CouncilCreateAdmin />} />
      <Route path="/paymentpageadmin" element={<PaymentPageAdmin />} />
      <Route path="/paymentpagemanager" element={<PaymentPageManager />} />
      <Route path="/termsmanager" element={<Terms />} />
      <Route path="/termsadmin" element={<TermsAdmin />} />
      <Route path="/termsclient" element={<TermsClient />} />
      <Route path="/addclientadmin" element={<ClientAddPageAdmin />} />
      <Route path="/editpersonaldata" element={<ClientPersonal />} />
      <Route
        path="/equipmentcreatemanager"
        element={<EquipmentCreateManager />}
      />
      <Route path="/partnercreatemanager" element={<PartnerCreateManager />} />
      <Route
        path="/promotercreatemanager"
        element={<PromoterCreateManager />}
      />
      <Route path="/verify-email" element={<VerifyEmail />} />
      <Route path="/verify-email-partner" element={<VerifyEmailPartner />} />
      <Route path="/verify-email-owner" element={<VerifyEmailOwner />} />
      <Route path="/verify-email-federation" element={<VerifyEmailOwner />} />
      <Route path="/verify-email-promoter" element={<VerifyEmailPromoter />} />
      <Route path="/editpersonaldata" element={<ClientPersonal />} />
      <Route
        path="/equipmentcreatemanager"
        element={<EquipmentCreateManager />}
      />
      <Route path="/partnercreatemanager" element={<PartnerCreateManager />} />
      <Route
        path="/promotercreatemanager"
        element={<PromoterCreateManager />}
      />
      <Route path="/trips" element={<ClientTrips />} />
      <Route path="/tripinfo" element={<ClientTripPage />} />
      <Route path="/partneroffersadmin" element={<PartnerPlansAdmin />} />
      <Route path="/partnerplanedit" element={<PartnerPlanPageAdmin />} />
      <Route path="/plancreateadmin" element={<PartnerPlanCreateAdmin />} />
      <Route path="/offerpage" element={<PartnerOffersAdmin />} />
      <Route path="/offercreatepage" element={<PartnerOfferCreateAdmin />} />
      <Route path="/offerinfopage" element={<PartnerOfferPageAdmin />} />
      <Route path="/offerpageclient" element={<OfferPage />} />
      <Route path="/registerpromoter" element={<RegisterPromoter />} />
      <Route path="/registerpartner" element={<RegisterPartners />} />
      <Route path="/promotersbyowner/:ownerId" element={<PromotersByOwner />} />

    </Route>
  )
);

createRoot(document.getElementById("root")).render(
  <RouterProvider router={router} />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
