import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Checkbox } from "@mui/material";
import "../../App.css";
import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";
import Header from "../../components/header";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Search1 from "../../components/Search1";
import {
  DataGrid,
  GridActionsCellItem,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { esES, ptPT, enUS } from "@mui/x-data-grid/locales";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { getPayments } from "../../api/requests/Payments";
import Cookies from "js-cookie";
import toast from "react-hot-toast";
import { getStatus } from "../../api/requests/Status";
import i18next from "i18next";
import NavManager from "../../components/navManager";
import HeaderManagerTransparent from "../../components/headerManagerTransparent";
import HeaderManager from "../../components/headerManager";
import HeaderManagerWhite from "../../components/headerManagerWhite";
import { 
  CircularProgress,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import NavManagerHorizontal from "../../components/navManagerHorizontal";

function PaymentsManager() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  function goBack() {
    navigate("/dashboardmanager");
  }
  const [payments, setPayments] = useState([]);
  const [status, setStatus] = useState([]);
  const [loading, setLoading] = useState(true);

  const theme = createTheme({
    components: {
      MuiCircularProgress: {
        styleOverrides: {
          root: {
            color: "#13BB56",
          },
        },
      },
    },
  });

  useEffect(() => {
    const id = Cookies.get("id");
    const token = Cookies.get("token");

    //If don't have an ID or token
    // if (!id && !token) {
    //   navigate("/loginadmin");
    //   //Remove the data and forward to the Login page
    //   Cookies.remove("id");
    //   Cookies.remove("email");
    //   Cookies.remove("token");
    //   Cookies.remove("first_name");
    //   Cookies.remove("last_name");
    //   Cookies.remove("photo");
    // }
    getPayments()
      .then((res) => {
        if (res.status === 200 && res.success) {
          setPayments(res.data);
          setPaymentsCopy(res.data);
          setLoading(false)
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error(t("500"));
      });
    getStatus()
      .then((res) => {
        if (res.status === 200 && res.success) {
          setStatus(res.data);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error(t("500"));
      });
  }, []);

  const [paymentsCopy, setPaymentsCopy] = useState([]);
  const [search, setSearch] = useState("");

  const StateCell = ({ value }) => {
    const isPaid = value.status == 4;
  
    return (
      <div className="state-cell-container">
        <a
          className={`state-cell ${isPaid ? "paid" : "unpaid"}`}
        >
          {status.find((element) => element.id === value.status)?.[
            i18next.language
          ] || ""}
        </a>
      </div>
    );
  };
  
  const DateCell = ({ value, row }) => {
    const isPaid = row.payment_date != null ? "paid" : "notpaid";
    const date = new Date(row.limit_date);

    const day = String(date.getUTCDate()).padStart(2, "0");
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const year = date.getUTCFullYear();

    const formattedDate = `${day}/${month}/${year}`;
    return (
      <div
        style={{
          color: isPaid ? "black" : "#ED4F2C",
        }}
      >
        {formattedDate}
      </div>
    );
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer
        style={{
          justifyContent: "flex-end",
          marginTop:isMobile?"0px": "-50px",
          marginBottom: isMobile?"0px":"30px",
          marginRight: "0px",
        }}
      >
        {/* <GridToolbarFilterButton
          slotProps={{
            button: {
              style: {
                color: "#044535",
                fontFamily: "Montserrat",
                textTransform: "none",
                fontWeight: 600,
                fontSize: "16px",
              },
            },
          }}
        />  */}
        <GridToolbarExport
          slotProps={{
            button: {
              style: {
                color: "#044535",
                fontFamily: "Montserrat",
                textTransform: "none",
                fontWeight: 600,
                fontSize: "16px",
              },
            },
          }}
        />
      </GridToolbarContainer>
    );
  }

  useEffect(() => {
    if (search) {
      let arrayPayments = paymentsCopy;
      arrayPayments = arrayPayments.filter((element) =>
        // Elements to search for
        element.owner_name.toLowerCase().includes(search.toLowerCase())
      );
      setPayments(arrayPayments);
    } else {
      setPayments(paymentsCopy);
    }
  }, [search]);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="background">
      <HeaderManagerWhite />
      {isMobile ? <NavManagerHorizontal /> : <NavManager />}
      <div className="topLeftBack">
        <IconButton onClick={goBack}>
        <ArrowBackIcon
            style={{ fill: "#044535", zIndex: 99, fontSize:isMobile?"20px":"25px" }}
          />
        </IconButton>
      </div>
      <div className="centerpage">
        <a className="greenTitle">{t("payments")}</a>
        <div className="whiteBoard">
          <div
            className="col-0"
            style={{
              textAlign: "start",
              marginTop: isMobile?15:45,
              marginLeft: isMobile?10:30,
              flexDirection: "row",
              display: "flex",
              zIndex: 99999,
            }}
          >
            <Search1
              onSearch={(value) => {
                setSearch(value);
              }}
            />
          </div>
          <div style={{ height:isMobile?"90%": "75%", maxWidth: "100%" }}>
          <ThemeProvider theme={theme}>
            <DataGrid
              className={"grid"}
              autoPageSize={true}
              loading={loading}
              columns={[
                // {
                //   field: "_id",
                //   headerName: t("id"),
                //   type: "number",
                //   maxWidth: 70,
                //   flex: 1,
                // },
                {
                  field: "owner_name",
                  headerName: t("name"),
                  type: "string",
                  flex: 1,
               
                },

                {
                  field: "state",
                  headerName: t("state"),
                  flex: 1,
             
                  type: "string",
                  renderCell: (params) => <StateCell value={params.row} />,
                },

                ...(isMobile
                  ? [] 
                  : [
                {
                  field: "limit_date",
                  headerName: t("date"),
                  flex: 1,
                  type: "string",
                  renderCell: (params) => (
                    <DateCell value={params.value} row={params.row} />
                  ),
                },

                {
                  field: "price",
                  headerName: t("amount"),
                  flex: 1,
                  type: "string",
                  renderCell: (params) => {
                    const value = params.value || 0; // Default to 0 if no value
                    return `€ ${value.toFixed(2)}`; // Format value with two decimal places
                  },
                }]),

                {
                  field: "actions",
                  type: "actions",
                  headerName: t("actions"),
                  flex: 1,
                  cellClassName: "actions",
                  getActions: ({ id }) => {
                    return [
                      <GridActionsCellItem
                        icon={
                          <FontAwesomeIcon
                            style={{ fontSize: 18, color: "#0F1111" }}
                            icon={faMagnifyingGlass}
                          />
                        }
                        label="Save"
                        onClick={() => {
                          navigate("/paymentpagemanager");
                        }}
                      />,
                    ];
                  },
                },
              ]}
              sx={{
                "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
                  outline: "none",
                },
                "& .MuiTablePagination-root ": {
                    overflow: "hidden !important",
                  },
              }}
              rows={payments}
              checkboxSelection={!isMobile} 
              onRowClick={(params) => {}}
              getRowId={(row) => row._id}
              //Idioma
              localeText={
                i18n.language == "pt"
                  ? ptPT.components.MuiDataGrid.defaultProps.localeText
                  : i18n.language == "es"
                  ? esES.components.MuiDataGrid.defaultProps.localeText
                  : enUS.components.MuiDataGrid.defaultProps.localeText
              }
              components={{
                BaseCheckbox: ({ value, ...otherProps }) => (
                  <Checkbox size="small" checked={value} {...otherProps} />
                ),
                LoadingOverlay: <CircularProgress />,
              }}
              slots={{
                toolbar: CustomToolbar,
              }}
            />
            </ThemeProvider>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentsManager;
