import React, { useEffect, useState, useRef } from "react";

import { useNavigate } from "react-router-dom";
import "../../App.css";
import { useTranslation } from "react-i18next";
import { Grid, Box, Typography, Skeleton } from "@mui/material";
import Header from "../../components/header";
import NavClient from "../../components/navClient";
import EventsBtn from "../../images/clientDashboardIcons/EventsBtn.svg";
import StationsBtn from "../../images/clientDashboardIcons/StationsClear.svg";
import PartnersBtn from "../../images/clientDashboardIcons/PartnersClear.svg";
import bag from "../../images/clientDashboardIcons/bag.svg";
import portugal from "../../images/example/portugalfut.jpeg";
import feira from "../../images/example/feira.jpeg";
import hoquei from "../../images/example/hoquei.jpeg";
import amalia from "../../images/example/amalia.jpeg";
import viana from "../../images/example/viana.png";
import locationIconSmall from "../../images/eventIcons/locationIconSmall.svg";
import calendarIconSmall from "../../images/eventIcons/calendarIconSmall.svg";
import { createRoot } from "react-dom/client";
import { APIProvider, Map, Marker } from "@vis.gl/react-google-maps";
import HeaderClientTransparent from "../../components/headerClientTransparent";
import Search1 from "../../components/Search1";
import Cookies from "js-cookie";
import { getEvents } from "../../api/requests/Event";
import { getUser } from "../../api/requests/User";
import toast from "react-hot-toast";
import { getZones } from "../../api/requests/Data";
import NavClientHorizontal from "../../components/navClientHorizontal";

function EventsList() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [name, setName] = useState(0);

  const [search, setSearch] = useState("");
  const [events, setEvents] = useState([]);
  const [eventsCopy, setEventsCopy] = useState([]);
  useEffect(() => {
    if (search) {
      let arrayEvents = eventsCopy;
      arrayEvents = arrayEvents.filter((element) =>
        element.eventName.toLowerCase().includes(search.toLowerCase())
      );
      setEvents(arrayEvents);
    } else {
      setEvents(eventsCopy);
    }
  }, [search]);
  const [loading, setLoading] = useState(true);
  const [sportsPreference, setSportsPreference] = useState([]);
  const [culturePreference, setCulturePreference] = useState([]);
  const [zones, setZones] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const id = Cookies.get("id");
      const token = Cookies.get("token");
      const nameU = Cookies.get("first_name");

      if (!id || !token) {
        navigate("/loginclient");
        Cookies.remove("id");
        Cookies.remove("email");
        Cookies.remove("token");
        Cookies.remove("first_name");
        Cookies.remove("last_name");
        Cookies.remove("photo");
        return;
      }

      setName(nameU);

      try {
        const zonesRes = await getZones();
        if (zonesRes.status === 200 && zonesRes.success) {
          setZones(zonesRes.data);
        } else {
          toast.error(zonesRes.error);
        }

        const userRes = await getUser(id);
        if (userRes.success && userRes.status === 200) {
          const sportsPref = userRes.data.sports_preference || [];
          const culturePref = userRes.data.culture_preference || [];
          setSportsPreference(sportsPref);
          setCulturePreference(culturePref);

          const eventsRes = await getEvents();
          if (eventsRes.status === 200 && eventsRes.success) {
            const fetchedEvents = eventsRes.data;
            console.log("Eventos antes da ordenação:", fetchedEvents);

            const isInPreferences = (event, preferenceArray) => {
              return preferenceArray.some((pref) =>
                event.modality.includes(pref)
              );
            };

            const sortedEvents = fetchedEvents.sort((a, b) => {
              const inSportsA = isInPreferences(a, sportsPref);
              const inSportsB = isInPreferences(b, sportsPref);
              const inCultureA = isInPreferences(a, culturePref);
              const inCultureB = isInPreferences(b, culturePref);

              if (inSportsA && !inSportsB) return -1;
              if (!inSportsA && inSportsB) return 1;

              if (inCultureA && !inCultureB) return -1;
              if (!inCultureA && inCultureB) return 1;

              return 0;
            });

            console.log("Eventos depois da ordenação:", sortedEvents);

            setEvents(sortedEvents);
          } else {
            toast.error(eventsRes.error);
          }
        } else {
          toast.error(userRes.error);
        }
      } catch (error) {
        toast.error("500");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <div className="background">
      <HeaderClientTransparent />
      {isMobile ? <NavClientHorizontal /> : <NavClient />}

      <div className="backgroundImageDashboardClient">
        <div className="leftcenter">
          <a className="titleDashboardClient">{t("hi")}</a>
          <a className="titleDashboardClient">{name}</a>
          <a className="subtitleDashboardClient">{t("whereto")}</a>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "80px",
            paddingTop: "15vh",
          }}
        >
          <button
            className="btnDashboardClient"
            style={{ backgroundImage: `url(${EventsBtn})` }}
          ></button>
          <button
            className="btnDashboardClient"
            style={{ backgroundImage: `url(${StationsBtn})` }}
            onClick={() => {
              navigate("/stationslist");
            }}
          ></button>
          <button
            className="btnDashboardClient"
            style={{ backgroundImage: `url(${PartnersBtn})` }}
            onClick={() => {
              navigate("/partnerslist");
            }}
          ></button>
        </div>
        <div className="centerContent2">
          <div
            className="col-0"
            style={{
              textAlign: "start",
              marginTop: 45,
              marginLeft: 10,
              flexDirection: "row",
              display: "flex",
              zIndex: 99999,
            }}
          >
            <Search1
              onSearch={(value) => {
                setSearch(value);
              }}
            />
            <p className="EventsTitle">{t("allEvents")}</p>
          </div>
          <Box
            sx={{
              width: "100%",
              height: "55vh",
              overflowY: "auto",
              marginLeft: isMobile ? "7%" : "0rem",
            }}
          >
            <Grid container spacing={2} style={{ width: "100%" }}>
              {loading
                ? Array.from(new Array(18)).map((_, index) => (
                    <Grid item xs={12} sm={6} md={4} lg={4} xl={4} key={index}>
                      <Skeleton
                        variant="rectangular"
                        height="10.625rem"
                        sx={{
                          borderRadius: "0.9375rem",
                          width: { xs: "92%", sm: "22.5625rem" }, 
                          margin: "0 auto",
                        }}
                      />
                    </Grid>
                  ))
                : events.map((event, index) => (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      lg={4}
                      xl={4}
                      key={event._id}
                    >
                      <div
                        className="event-container"
                        onClick={() =>
                          navigate("/eventpage", {
                            state: { eventId: event._id },
                          })
                        }
                      >
                        <div
                          className="event-image2"
                          style={{
                            backgroundImage: `url(${event.poster[0]})`,
                          }}
                        ></div>
                        <div className="event-details">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              flexDirection: "row",
                              width: "100%",
                            }}
                          >
                            <div className="event-title-name">{event.name}</div>
                            <div className="event-date">
                              <div className="event-day-dark">
                                {new Date(event.start_date).getDate()}
                              </div>
                              <div className="event-month-dark">
                                {new Date(event.start_date).toLocaleString(
                                  "default",
                                  { month: "short" }
                                )}
                              </div>
                            </div>
                          </div>
                          <div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                flexDirection: "row",
                                width: "100%",
                                marginTop: "2.6rem",
                              }}
                            >
                              <span
                                style={{
                                  width: 11,
                                  height: 16,
                                  backgroundImage: `url(${locationIconSmall})`,
                                }}
                              />
                              <div
                                className="cardContent"
                                style={{ marginLeft: 10 }}
                              >
                                {/* <a className="eventTextSmall">
                                  {event.station}
                                </a> */}
                                <a className="eventTextSmall">
                                  {event.district_name}, Portugal
                                </a>
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                flexDirection: "row",
                                width: "100%",
                                marginTop: 10,
                              }}
                            >
                              <span
                                style={{
                                  width: 14,
                                  height: 16,
                                  backgroundImage: `url(${calendarIconSmall})`,
                                }}
                              />
                              <div
                                className="cardContent"
                                style={{ marginLeft: 10 }}
                              >
                                <a className="eventTextSmall">
                                  {new Date(
                                    event.start_date
                                  ).toLocaleDateString()}{" "}
                                  |{" "}
                                  {new Date(
                                    event.end_date
                                  ).toLocaleDateString()}
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  ))}
            </Grid>
          </Box>
        </div>
      </div>
    </div>
  );
}

export default EventsList;
