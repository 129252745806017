import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Checkbox,
  CircularProgress,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import "../../App.css";
import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";
import Header from "../../components/header";
import NavAdmin from "../../components/navAdmin";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Search1 from "../../components/Search1";
import {
  DataGrid,
  GridActionsCellItem,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { esES, ptPT, enUS } from "@mui/x-data-grid/locales";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { getPayments } from "../../api/requests/Payments";
import Cookies from "js-cookie";
import toast from "react-hot-toast";
import { getStatus } from "../../api/requests/Status";
import i18next from "i18next";
import NavAdminMobile from "../../components/navAdminHorizontal";

function PaymentsAdmin() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  function goBack() {
    navigate("/dashboardadmin");
  }
  const [payments, setPayments] = useState([]);
  const [status, setStatus] = useState([]);
  const [loading, setLoading] = useState(true);

  const theme = createTheme({
    components: {
      MuiCircularProgress: {
        styleOverrides: {
          root: {
            color: "#13BB56",
          },
        },
      },
    },
  });

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1000);
  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({
    limit_date: !isSmallScreen,
    price: !isSmallScreen,
  });

  const handleResize = () => {
    const smallScreen = window.innerWidth < 1000;
    setIsSmallScreen(smallScreen);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    setColumnVisibilityModel({
      limit_date: !isSmallScreen,
      price: !isSmallScreen,
    });
  }, [isSmallScreen]);

  useEffect(() => {
    const id = Cookies.get("id");
    const token = Cookies.get("token");

    if (!id && !token) {
      navigate("/loginadmin");
      Cookies.remove("id");
      Cookies.remove("email");
      Cookies.remove("token");
      Cookies.remove("first_name");
      Cookies.remove("last_name");
      Cookies.remove("photo");
    }
    getPayments()
      .then((res) => {
        if (res.status === 200 && res.success) {
          setPayments(res.data);
          setPaymentsCopy(res.data);
          setLoading(false);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error(t("500"));
      });
    getStatus()
      .then((res) => {
        if (res.status === 200 && res.success) {
          setStatus(res.data);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error(t("500"));
      });
  }, []);

  const [paymentsCopy, setPaymentsCopy] = useState([]);
  const [search, setSearch] = useState("");

  const StateCell = ({ value }) => {
    const isPaid = value.status == 4 ? true : false;

    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "left",
          height: "100%",
        }}
      >
        <a
          style={{
            backgroundColor: isPaid ? "#13BB56" : "#ED4F2C",
            color: "#FFF",
            borderRadius: "5px",
            textAlign: "center",
            fontFamily: "Montserrat",
            fontSize: "clamp(10px, 1.5vw, 14px)",
            fontStyle: "normal",
            fontWeight: 500,
            width: isSmallScreen ? "100%" : "50%",
            height: "40px",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
        >
          {status.find((element) => element.id === value.status)?.[
            i18next.language
          ] || ""}
        </a>
      </div>
    );
  };

  const DateCell = ({ value, row }) => {
    const isPaid = row.payment_date != null ? "paid" : "notpaid";
    const date = new Date(row.limit_date);

    const day = String(date.getUTCDate()).padStart(2, "0");
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const year = date.getUTCFullYear();

    const formattedDate = `${day}/${month}/${year}`;
    return (
      <div
        style={{
          color: isPaid ? "black" : "#ED4F2C",
          fontSize: "clamp(12px, 1.5vw, 14px)",
        }}
      >
        {formattedDate}
      </div>
    );
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer
        style={{
          justifyContent: "flex-end",
          marginTop: isMobile ? "0px" : "-50px",
          marginBottom: isMobile ? "0px" : "30px",
          marginRight: "0px",
        }}
      >
        {/* <GridToolbarFilterButton
          slotProps={{
            button: {
              style: {
                color: "#044535",
                fontFamily: "Montserrat",
                textTransform: "none",
                fontWeight: 600,
                fontSize: "16px",
              },
            },
          }}
        />  */}
        <GridToolbarExport
          slotProps={{
            button: {
              style: {
                color: "#044535",
                fontFamily: "Montserrat",
                textTransform: "none",
                fontWeight: 600,
                fontSize: "clamp(12px, 2vw, 16px)",
              },
            },
          }}
        />
      </GridToolbarContainer>
    );
  }

  useEffect(() => {
    if (search) {
      let arrayPayments = paymentsCopy;
      arrayPayments = arrayPayments.filter((element) =>
        element.owner_name.toLowerCase().includes(search.toLowerCase())
      );
      setPayments(arrayPayments);
    } else {
      setPayments(paymentsCopy);
    }
  }, [search]);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="background">
      <Header />
      {isMobile ? <NavAdminMobile /> : <NavAdmin />}
      <div className="topLeftBack">
        <IconButton onClick={goBack}>
        <ArrowBackIcon
            style={{ fill: "#044535", zIndex: 99, fontSize:isMobile?"20px":"25px" }}
          />
        </IconButton>
      </div>
      <div className="centerpage">
        <a className="greenTitle">{t("payments")}</a>
        <div className="whiteBoard">
          <div
            className="col-0"
            style={{
              textAlign: "start",
              marginTop: isMobile ? 15 : 45,
              marginLeft: isMobile ? 10 : 30,
              flexDirection: "row",
              display: "flex",
              zIndex: 99999,
            }}
          >
            <Search1
              onSearch={(value) => {
                setSearch(value);
              }}
            />
          </div>
          <div style={{ height: "75%", maxWidth: "100%" }}>
            <ThemeProvider theme={theme}>
              <DataGrid
                className={"grid"}
                autoPageSize={true}
                columnVisibilityModel={columnVisibilityModel}
                loading={loading}
                columns={[
                  // {
                  //   field: "_id",
                  //   headerName: t("id"),
                  //   type: "number",
                  //   maxWidth: 70,
                  //   flex: 1,
                  // },
                  {
                    field: "owner_name",
                    headerName: t("name"),
                    type: "string",
                    flex: 1.5,
                  },

                  {
                    field: "state",
                    headerName: t("state"),
                    flex: 1,
                    type: "string",
                    renderCell: (params) => <StateCell value={params.row} />,
                  },
                  {
                    field: "limit_date",
                    headerName: t("date"),
                    flex: 0.7,
                    type: "string",
                    renderCell: (params) => (
                      <DateCell value={params.value} row={params.row} />
                    ),
                  },

                  {
                    field: "price",
                    headerName: t("amount"),
                    flex: 0.4,
                    type: "string",
                    renderCell: (params) => {
                      const value = params.value || 0;
                      return `€ ${value.toFixed(2)}`;
                    },
                  },

                  {
                    field: "actions",
                    type: "actions",
                    headerName: t("actions"),
                    flex: 0.3,
                    cellClassName: "actions",
                    getActions: ({ id }) => {
                      return [
                        <GridActionsCellItem
                          icon={
                            <FontAwesomeIcon
                              style={{
                                fontSize: "clamp(12px, 2vw, 18px)",
                                color: "#0F1111",
                              }}
                              icon={faMagnifyingGlass}
                            />
                          }
                          label="Save"
                          onClick={() => {
                            navigate("/paymentpageadmin", {
                              state: { id: id },
                            });
                          }}
                        />,
                      ];
                    },
                  },
                ]}
                sx={{
                  "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus":
                    {
                      outline: "none",
                    },
                  "& .MuiDataGrid-columnHeaders": {
                    fontSize: "clamp(14px, 2vw, 18px)",
                  
                  },
                  "& .MuiDataGrid-footerContainer": {
                    fontSize: "clamp(10px, 1.5vw, 16px)",
                  },
                "& .MuiTablePagination-root ": {
                    overflow: "hidden !important",
                  },
                }}
                rows={payments}
                checkboxSelection={true}
                onRowClick={(params) => {}}
                getRowId={(row) => row._id}
                localeText={
                  i18n.language == "pt"
                    ? ptPT.components.MuiDataGrid.defaultProps.localeText
                    : i18n.language == "es"
                    ? esES.components.MuiDataGrid.defaultProps.localeText
                    : enUS.components.MuiDataGrid.defaultProps.localeText
                }
                components={{
                  BaseCheckbox: ({ value, ...otherProps }) => (
                    <Checkbox size="small" checked={value} {...otherProps} />
                  ),
                  LoadingOverlay: <CircularProgress />,
                }}
                slots={{
                  toolbar: CustomToolbar,
                }}
              />
            </ThemeProvider>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentsAdmin;
