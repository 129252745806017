import React from "react";
import {
  BottomNavigation,
  BottomNavigationAction,
} from "@mui/material";
import { useNavigate } from "react-router";
import homeIcon from "../images/navIcons/home60.svg";
import homeIconWhite from "../images/navIcons/home100.svg";
import peopleIcon from "../images/navIcons/people60.svg";
import peopleIconWhite from "../images/navIcons/people100.svg";
import partyIcon from "../images/navIcons/party60.svg";
import partyIconWhite from "../images/navIcons/party100.svg";
import stadiumIcon from "../images/navIcons/stadium60.svg";
import stadiumIconWhite from "../images/navIcons/stadium100.svg";
import handsIcon from "../images/navIcons/hands60.svg";
import handsIconWhite from "../images/navIcons/hands100.svg";
import notesIcon from "../images/navIcons/notes60.svg";
import notesIconWhite from "../images/navIcons/notes100.svg";
import cupIcon from "../images/navIcons/cup60.svg";
import cupIconWhite from "../images/navIcons/cup100.svg";
import buildingIcon from "../images/navIcons/building60.svg";
import buildingIconWhite from "../images/navIcons/building100.svg";
import bagIcon from "../images/navIcons/bag60.svg";
import bagIconWhite from "../images/navIcons/bag100.svg";
import statsIcon from "../images/navIcons/stats60.svg";
import statsIconWhite from "../images/navIcons/stats100.svg";
import bookIcon from "../images/navIcons/book60.svg";
import bookIconWhite from "../images/navIcons/book100.svg";
import walletIcon from "../images/navIcons/wallet60.svg";
import walletIconWhite from "../images/navIcons/wallet100.svg";
import helpIcon from "../images/navIcons/help60.svg";
import helpIconWhite from "../images/navIcons/help100.svg";
// Adicione os outros ícones aqui...

function NavAdminMobile() {
  const navigate = useNavigate();

  function getPageIndex(route) {
    switch (route) {
      case "/dashboardadmin":
        return 0;
      case "/clientsadmin":
        return 1;
      case "/eventsadmin":
        return 2;
      case "/equipmentsadmin":
        return 3;
      case "/partnersadmin":
        return 4;
      case "/paymentsadmin":
        return 5;
      case "/federationadmin":
        return 6;
      case "/counciladmin":
        return 7;
      case "/promoteradmin":
        return 8;
      case "/statsadmin":
        return 9;
      case "/ordersadmin":
        return 10;
      case "/partneroffersadmin":
        return 11;
      case "/helpadmin":
        return 12;

      default:
        return 12;
    }
  }

  const value = getPageIndex(window.location.pathname);

  const handleChange = (event, newValue) => {
    switch (newValue) {
      case 0:
        navigate("/dashboardadmin");
        break;
      case 1:
        navigate("/clientsadmin");
        break;
      case 2:
        navigate("/eventsadmin");
        break;
      case 3:
        navigate("/equipmentsadmin");
        break;
      case 4:
        navigate("/partnersadmin");
        break;
      case 5:
        navigate("/paymentsadmin");
        break;
      case 6:
        navigate("/federationadmin");
        break;
      case 7:
        navigate("/counciladmin");
        break;
      case 8:
        navigate("/promoteradmin");
        break;
      case 9:
        navigate("/statsadmin");
        break;
      case 10:
        navigate("/ordersadmin");
        break;
      case 11:
        navigate("/partneroffersadmin");
        break;
      case 12:
        navigate("/helpadmin");
        break;
      default:
        navigate("/helpadmin");
        break;
    }
  };

  return (
    <div className="navmanagerhorizontal">
      <BottomNavigation
            disableRipple
            value={value}
            style={{
              backgroundColor: "transparent",
              flexDirection: "row",
              // position: "absolute",
              flexWrap: "wrap",
              justifyContent: "center",
              marginBottom: "45px",
            }}
  
            onChange={handleChange}
            showLabels
          >
            <BottomNavigationAction
              disableRipple
              icon={
                <span
                  style={{
                    width: 28,
                    height: 30,
                    backgroundImage:
                      value != 0 ? `url(${homeIcon})` : `url(${homeIconWhite})`,
                  }}
                />
              }
              classes={{
                root: "bottomNavigationAction",
              }}
            />
            <BottomNavigationAction
              disableRipple
              icon={
                <span
                  style={{
                    width: 30,
                    height: 21,
                    backgroundImage:
                      value != 1
                        ? `url(${peopleIcon})`
                        : `url(${peopleIconWhite})`,
                  }}
                />
              }
              classes={{
                root: "bottomNavigationAction",
              }}
            />
            <BottomNavigationAction
              disableRipple
              icon={
                <span
                  style={{
                    width: 30,
                    height: 26,
                    backgroundImage:
                      value != 2
                        ? `url(${partyIcon})`
                        : `url(${partyIconWhite})`,
                  }}
                />
              }
              classes={{
                root: "bottomNavigationAction",
              }}
            />
            <BottomNavigationAction
              disableRipple
              icon={
                <span
                  style={{
                    width: 30,
                    height: 30,
                    backgroundImage:
                      value != 3
                        ? `url(${stadiumIcon})`
                        : `url(${stadiumIconWhite})`,
                  }}
                />
              }
              classes={{
                root: "bottomNavigationAction",
              }}
            />
            <BottomNavigationAction
              disableRipple
              icon={
                <span
                  style={{
                    width: 30,
                    height: 28,
                    backgroundImage:
                      value != 4
                        ? `url(${handsIcon})`
                        : `url(${handsIconWhite})`,
                  }}
                />
              }
              classes={{
                root: "bottomNavigationAction",
              }}
            />
            <BottomNavigationAction
              disableRipple
              icon={
                <span
                  style={{
                    width: 30,
                    height: 21,
                    backgroundImage:
                      value != 5
                        ? `url(${notesIcon})`
                        : `url(${notesIconWhite})`,
                  }}
                />
              }
              classes={{
                root: "bottomNavigationAction",
              }}
            />
            <BottomNavigationAction
              disableRipple
              icon={
                <span
                  style={{
                    width: 30,
                    height: 30,
                    backgroundImage:
                      value != 6 ? `url(${cupIcon})` : `url(${cupIconWhite})`,
                  }}
                />
              }
              classes={{
                root: "bottomNavigationAction",
              }}
            />
            <BottomNavigationAction
              disableRipple
              icon={
                <span
                  style={{
                    width: 28,
                    height: 30,
                    backgroundImage:
                      value != 7
                        ? `url(${buildingIcon})`
                        : `url(${buildingIconWhite})`,
                  }}
                />
              }
              classes={{
                root: "bottomNavigationAction",
              }}
            />
            <BottomNavigationAction
              disableRipple
              icon={
                <span
                  style={{
                    width: 30,
                    height: 28,
                    backgroundImage:
                      value != 8 ? `url(${bagIcon})` : `url(${bagIconWhite})`,
                  }}
                />
              }
              classes={{
                root: "bottomNavigationAction",
              }}
            />
            <BottomNavigationAction
              disableRipple
              icon={
                <span
                  style={{
                    width: 30,
                    height: 30,
                    backgroundImage:
                      value != 9
                        ? `url(${statsIcon})`
                        : `url(${statsIconWhite})`,
                  }}
                />
              }
              classes={{
                root: "bottomNavigationAction",
              }}
            />
            <BottomNavigationAction
              disableRipple
              icon={
                <span
                  style={{
                    width: 30,
                    height: 21,
                    backgroundImage:
                      value != 10
                        ? `url(${bookIcon})`
                        : `url(${bookIconWhite})`,
                  }}
                />
              }
              classes={{
                root: "bottomNavigationAction",
              }}
            />
            <BottomNavigationAction
              disableRipple
              icon={
                <span
                  style={{
                    width: 30,
                    height: 30,
                    backgroundImage:
                      value != 11
                        ? `url(${walletIcon})`
                        : `url(${walletIconWhite})`,
                  }}
                />
              }
              classes={{
                root: "bottomNavigationAction",
              }}
            />

            <BottomNavigationAction
              disableRipple
              icon={
                <span
                  style={{
                    width: 22,
                    height: 22,
                    backgroundImage:
                      value != 12
                        ? `url(${helpIcon})`
                        : `url(${helpIconWhite})`,
                  }}
                />
              }
              style={{
                position: "fixed",
                bottom: 10,
              }}
            />
          </BottomNavigation>
    </div>
  );
}

export default NavAdminMobile;
