import React, { useEffect, useState } from "react";
import logo from "../../images/logo.svg";
import background from "../../images/background.png";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardContent,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  Menu,
  MenuItem,
  Skeleton,
  TextField,
  Typography,
  Autocomplete,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "../../App.css";
import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";
import { useLocation } from "react-router-dom";
import i18next from "i18next";
import Header from "../../components/header.js";
import NavAdmin from "../../components/navAdmin.js";
import client from "../../images/example/client.png";
import EditIcon from "@mui/icons-material/Edit";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { CalendarIcon } from "@mui/x-date-pickers/icons";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import "dayjs/locale/pt";
import "dayjs/locale/en-gb";
import { deleteUser, getUser, putUser } from "../../api/requests/User.js";
import toast from "react-hot-toast";
import {
  getActivities,
  getCountries,
  getDistricts,
  getGenders,
  getMunicipalities,
  getTrainStations,
  getZones,
} from "../../api/requests/Data.js";
import { checkRegistration } from "../../api/requests/Auth.js";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import Dropzone from "react-dropzone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SearchIcon from "@mui/icons-material/Search";
import { Colors } from "../../values/Colors.js";
import HeaderClient from "../../components/headerClient.js";
import HeaderManagerWhite from "../../components/headerManagerWhite.js";
import NavManager from "../../components/navManager.js";
import NavClient from "../../components/navClient.js";
import Cookies from "js-cookie";
import { sendPasswordResetEmail } from "../../api/requests/Emails.js";
import { getAdmin, putAdmin } from "../../api/requests/Admin.js";
import { getManager, putManager } from "../../api/requests/Managers.js";
import NavClientHorizontal from "../../components/navClientHorizontal.js";
import NavManagerHorizontal from "../../components/navManagerHorizontal.js";

function ClientPersonal() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const id = Cookies.get("id");
  const [edicao, setEdicao] = useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const location = useLocation();

  const { clientId } = location.state || {};
  const { user, type } = location.state || {};
  const { isManager } = location.state || {};
  const { isClient } = location.state || {};
  const { isAdmin } = location.state || {};

  const handleMenuOpen = (event) => {
    if (!edicao) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const [openDialog, setOpenDialog] = useState(false);
  const [openDialog2, setOpenDialog2] = useState(false);
  const [openDialog3, setOpenDialog3] = useState(false);

  const [userData, setUserData] = useState([]);

  const inputLabelPropsStyle = {
    color: "#044535 !important",
    textAlign: "right",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "22px",
  };

  const inputPropsStyle = {
    color: "#0F111199",
    textAlign: "right",
    fontFamily: "Montserrat",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "22px",
    borderRadius: "8px",
    background: "#0f11110d",
    paddingLeft: "10px",
    width: "60%",
  };

  const inputTrainStyle = {
    color: "#0F1111",
    textAlign: "right",
    fontFamily: "Montserrat",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "22px",
    borderRadius: "8px",
    background: "white",
    width: "60%",
  };

  const [value, setValue] = React.useState("+351999999999");

  const handleChange = (newValue) => {
    matchIsValidTel(newValue, {
      onlyCountryies: [],
      excludedCountryies: [],
      continents: [],
    });
  };

  const handleDelete = () => {
    handleMenuClose();
    setOpenDialog(true);
  };

  const handleSuspend = () => {
    handleMenuClose();
    setOpenDialog2(true);
  };

  const handleActivate = () => {
    handleMenuClose();
    setOpenDialog3(true);
  };

  const handleDialogClose = () => {
    // if (reason) {
    deleteUser(clientId)
      .then((res) => {
        if (res.success && res.status === 200) {
          navigate("/clientsadmin");
          toast.success(t("deleted"));
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error(t("500"));
      });
    // } else {
    //   toast.error(t("reasonError"));
    // }
  };
  const handleDialogClose2 = () => {
    if (reason) {
      putUser(clientId, {
        status: 2,
        obs: reason,
      })
        .then((res) => {
          if (res.success && res.status === 200) {
            toast.success(t("successMessage"));
            setOpenDialog2(false);
            setStatus(2);
            setEdicao(!edicao);
          } else {
            toast.error(res.error);
          }
        })
        .catch((error) => {
          toast.error(t("500"));
        });
    } else {
      toast.error(t("reasonError"));
    }
  };

  const handleDialogClose3 = () => {
    if (reason) {
      putUser(clientId, {
        status: 1,
        obs: reason,
      })
        .then((res) => {
          if (res.success && res.status === 200) {
            toast.success(t("successMessage"));
            setOpenDialog3(false);
            setStatus(1);
            setEdicao(!edicao);
          } else {
            toast.error(res.error);
          }
        })
        .catch((error) => {
          toast.error(t("500"));
        });
    } else {
      toast.error(t("reasonError"));
    }
  };

  const confirmDelete = () => {
    setOpenDialog(false);
    setOpenDialog2(false);
    setOpenDialog3(false);
  };

  const [age, setAge] = useState(0);
  const [name, setName] = useState("");
  const [lastname, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [birthDate, setBirthDate] = React.useState(null);
  const [gender, setGender] = useState("");
  const [district, setDistrict] = useState("");
  const [municipality, setMunicipality] = useState("");
  const [address, setAddress] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [country, setCountry] = useState({});
  const [countries, setCountries] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [municipalities, setMunicipalities] = useState([]);
  const [municipalitiesCopy, setMunicipalitiesCopy] = useState("");
  const [photo, setPhoto] = useState("");
  const [genders, setGenders] = useState([]);
  const [trainstations, setTrainstations] = useState([]);
  const [trainstationsUser, setTrainstationsUser] = useState([]);
  const [culturalActivities, setCulturalActivities] = useState([]);
  const [sportsActivities, setSportsActivities] = useState([]);
  const [avatarData, setAvatarData] = useState(null);
  const [preferences, setPreferences] = useState([]);
  const [occupation, setOccupation] = useState("");
  const [status, setStatus] = useState(0);
  const [reason, setReason] = useState("");
  const [isLoading, setLoading] = useState(true);
  const [isLoadingCountries, setLoadingCountries] = useState(true);
  const [isLoadingDistricts, setLoadingDistricts] = useState(true);
  const [isLoadingMun, setLoadingMun] = useState(true);
  const [isLoadingTrainStations, setLoadingTrainStations] = useState(true);
  const [isLoadingActivities, setLoadingActivities] = useState(true);
  const [isLoadingGenders, setLoadingGenders] = useState(true);
  const [data, setData] = useState([]);
  const [zones, setZones] = useState([]);

  const SkeletonTextField = () => (
    <Skeleton
      variant="text"
      width="50%"
      height={50}
      style={{ marginTop: "0px", marginLeft: "10px" }}
    />
  );

  const handleCountryChange = (event, newValue) => {
    setCountry(newValue);
  };

  const handleDistrictChange = (event, newValue) => {
    setDistrict(newValue);
  };

  const handleMunChange = (event, newValue) => {
    setMunicipality(newValue);
  };

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();

    reader.onload = () => {
      const base64String = reader.result;
      setPhoto(base64String);
    };

    reader.readAsDataURL(file);
  };

  useEffect(() => {
    if (birthDate != null) {
      const calculateAge = (birthDate) => {
        // Check if birthDate is a dayjs object, if not, convert it
        const birthDay = dayjs.isDayjs(birthDate)
          ? birthDate
          : dayjs(birthDate);
        const today = dayjs();
        let age = today.year() - birthDay.year();

        // Check if the birthday has already passed this year
        if (
          today.month() < birthDay.month() ||
          (today.month() === birthDay.month() && today.date() < birthDay.date())
        ) {
          age--;
        }
        return age;
      };

      setAge(calculateAge(birthDate));
    }
  }, [birthDate]);

  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */
    return color;
  }

  useEffect(() => {
    getDistricts()
      .then((res) => {
        if (res.status === 200 && res.success) {
          setDistricts(res.data);
          setLoadingDistricts(false);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error("500");
      });

    getMunicipalities()
      .then((res) => {
        if (res.status === 200 && res.success) {
          setMunicipalities(res.data);
          setMunicipalitiesCopy(res.data);
          setLoadingMun(false);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error("500");
      });

    getCountries()
      .then((res) => {
        if (res.status === 200 && res.success) {
          setCountries(res.data);
          setLoadingCountries(false);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error("500");
      });

    getTrainStations()
      .then((res) => {
        if (res.status === 200 && res.success) {
          setTrainstations(res.data);
          setLoadingTrainStations(false);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error("500");
      });

    getActivities()
      .then((res) => {
        if (res.status === 200 && res.success) {
          let cultural = [];
          let sports = [];
          res.data.forEach((element) => {
            if (element.type_id === 1) {
              sports.push(element);
            } else if (element.type_id === 2) {
              cultural.push(element);
            }
          });
          setSportsActivities(sports);
          setCulturalActivities(cultural);
          setLoadingActivities(false);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error("500");
      });
    getZones()
      .then((res) => {
        if (res.status === 200 && res.success) {
          setZones(res.data);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error("500");
      });
    getGenders()
      .then((res) => {
        if (res.status === 200 && res.success) {
          setGenders(res.data);
          setLoadingGenders(false);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error("500");
      });
  }, []);

  useEffect(() => {
    if (
      !isLoadingCountries &&
      !isLoadingDistricts &&
      !isLoadingMun &&
      !isLoadingTrainStations &&
      !isLoadingActivities &&
      !isLoadingGenders
    ) {
      if (isClient) {
        getUser(id)
          .then((res) => {
            if (res.status === 200 && res.success) {
              let data = res.data;
              setData(data);
              setUserData(data);
              setName(data.first_name);
              setLastName(data.last_name);
              if (data.first_name !== "" && data.last_name !== "") {
                const avatar = {
                  sx: {
                    bgcolor: stringToColor(data.first_name),
                  },
                  children: `${data.first_name[0].toUpperCase()}`,
                };
                setAvatarData(avatar);
              }
              if (data.occupation) {
                setOccupation(data.occupation);
              }
              setBirthDate(dayjs(data.birth_date));
              setGender(data.gender);
              setEmail(data.email);
              let trainStationsArray = [];
              data.train_station.forEach((element2) => {
                trainstations.forEach((element) => {
                  if (String(element.id) === element2) {
                    trainStationsArray.push(element);
                  }
                });
              });
              setTrainstationsUser(trainStationsArray);
              setSelectedStations(trainStationsArray);
              let cultureP = data.culture_preference
                .map((pref) => {
                  const prefId = pref.id;
                  const activity = culturalActivities.find((activity) => {
                    return activity.id === prefId;
                  });

                  if (activity) {
                    return {
                      ...activity,
                      zones: pref.zones || [],
                    };
                  }
                  return null;
                })
                .filter(Boolean);

              let sportsP = data.sports_preference
                .map((pref) => {
                  const prefId = pref.id;

                  const activity = sportsActivities.find((activity) => {
                    return activity.id === prefId;
                  });

                  if (activity) {
                    return {
                      ...activity,
                      zones: pref.zones || [],
                    };
                  }
                  return null;
                })
                .filter(Boolean);

              setSelectedCulturalActivities(cultureP);
              setSelectedSportsActivities(sportsP);

              if (data.photo && data.photo !== "") {
                setPhoto(data.photo);
              }

              setPostalCode(data.zip_code);

              const selectedCountry = countries.find(
                (c) => c.id.toString() === data.country
              );
              setCountry(selectedCountry);
              const selectedDistrict = districts.find(
                (c) => c.id.toString() === data.district
              );
              setDistrict(selectedDistrict);

              const selectedMun = municipalities.find(
                (c) => c.id.toString() === data.municipality
              );
              setMunicipality(selectedMun);
              setStatus(data.status);
              setLoading(false);
            } else {
              toast.error(res.error);
            }
          })
          .catch((error) => {
            toast.error("500");
          });
      } else if (isAdmin) {
        getAdmin(id)
          .then((res) => {
            if (res.status === 200 && res.success) {
              let data = res.data;
              setData(data);
              setUserData(data);
              setName(data.first_name);
              setLastName(data.last_name);

              if (data.first_name !== "" && data.last_name !== "") {
                const avatar = {
                  sx: {
                    bgcolor: stringToColor(data.first_name),
                  },
                  children: `${data.first_name[0].toUpperCase()}`,
                };
                setAvatarData(avatar);
              }
              if (data.occupation) {
                setOccupation(data.occupation);
              }
              setBirthDate(dayjs(data.birth_date));
              setGender(data.gender);
              setEmail(data.email);
              if (data.photo && data.photo !== "") {
                setPhoto(data.photo);
              }

              setPostalCode(data.zip_code);

              const selectedCountry = countries.find(
                (c) => c.id.toString() === data.country
              );
              setCountry(selectedCountry);
              const selectedDistrict = districts.find(
                (c) => c.id.toString() === data.district
              );
              setDistrict(selectedDistrict);

              const selectedMun = municipalities.find(
                (c) => c.id.toString() === data.municipality
              );
              setMunicipality(selectedMun);
              setLoading(false);
            } else {
              toast.error(res.error);
            }
          })
          .catch((error) => {
            toast.error("500");
          });
      } else if (isManager) {
        getManager(id)
          .then((res) => {
            if (res.status === 200 && res.success) {
              let data = res.data;
              setData(data);
              setUserData(data);
              setName(data.first_name);
              setLastName(data.last_name);

              if (data.first_name !== "" && data.last_name !== "") {
                const avatar = {
                  sx: {
                    bgcolor: stringToColor(data.first_name),
                  },
                  children: `${data.first_name[0].toUpperCase()}`,
                };
                setAvatarData(avatar);
              }
              if (data.occupation) {
                setOccupation(data.occupation);
              }
              setBirthDate(dayjs(data.birth_date));
              setGender(data.gender);
              setEmail(data.email);
              if (data.photo && data.photo !== "") {
                setPhoto(data.photo);
              }

              setPostalCode(data.zip_code);

              const selectedCountry = countries.find(
                (c) => c.id.toString() === data.country
              );
              setCountry(selectedCountry);
              const selectedDistrict = districts.find(
                (c) => c.id.toString() === data.district
              );
              setDistrict(selectedDistrict);

              const selectedMun = municipalities.find(
                (c) => c.id.toString() === data.municipality
              );
              setMunicipality(selectedMun);
              setLoading(false);
            } else {
              toast.error(res.error);
            }
          })
          .catch((error) => {
            toast.error("500");
          });
      }
    }
  }, [
    isLoadingCountries,
    isLoadingDistricts,
    isLoadingMun,
    isLoadingTrainStations,
    isLoadingActivities,
    isLoadingGenders,
  ]);

  useEffect(() => {
    if (name != "") {
      const avatar = {
        sx: {
          bgcolor: stringToColor(name),
        },
        children: `${name[0].toUpperCase()}`,
      };
      setAvatarData(avatar);
    }
  }, [name]);

  function saveData() {
    if (isClient) {
      if (!name) {
        toast.error(t("nameValidation"));
        return;
      }
      if (!lastname) {
        toast.error(t("lastNameValidation"));
        return;
      }
      if (!birthDate) {
        toast.error(t("birthDateValidation"));
        return;
      } else {
        const birthDay = dayjs(birthDate);
        if (!birthDay.isValid()) {
          toast.error(t("invalidBirthDate"));
          return;
        }
      }
      if (!gender) {
        toast.error(t("genderValidation"));
        return;
      }
      if (!postalCode) {
        toast.error(t("postalCodeValidation"));
        return;
      }
      if (!country) {
        toast.error(t("countryValidation"));
        return;
      }
      if (!district) {
        toast.error(t("districtValidation"));
        return;
      }
      if (!municipality) {
        toast.error(t("municipalityValidation"));
        return;
      }
      if (!email) {
        toast.error(t("emailValidation"));
        return;
      } else {
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        if (!emailPattern.test(email)) {
          toast.error(t("invalidEmail"));
          return;
        }
      }

      let errorOccurred = false;

      const checkEmailRegistration = async () => {
        try {
          const res = await checkRegistration({ email: email });
          if (res.status === 200 && res.success) {
            errorOccurred = true;
            toast.error(res.data.message);
          }
        } catch (error) {
          toast.error(error);
        }
      };

      const validatePostalCode = () => {
        const postalCodeRegex = /^[0-9]{4}-[0-9]{3}$/;
        if (!postalCodeRegex.test(postalCode)) {
          errorOccurred = true;
          toast.error(t("invalidPostalCode"));
        }
      };

      if (userData.email !== email) {
        checkEmailRegistration().then(() => {
          if (!errorOccurred) {
            validatePostalCode();

            if (!errorOccurred) {
              let arrayTrainStations = [];
              selectedStations.forEach((element) => {
                arrayTrainStations.push(element.id);
              });
              let cultural = [];
              let sports = [];

              const logSelections = () => {
                cultural = selectedCulturalActivities.map((activity) => ({
                  id: activity.id,
                  zones: activity.zones || [],
                }));

                sports = selectedSportsActivities.map((activity) => ({
                  id: activity.id,
                  zones: activity.zones || [],
                }));
              };
              logSelections();

              putUser(id, {
                first_name: name,
                last_name: lastname,
                birth_date: birthDate,
                gender: gender,
                postal_code: postalCode,
                country: country.id.toString(),
                district: district.id.toString(),
                municipality: municipality.id.toString(),
                occupation: occupation,
                email: email,
                photo: photo,
                status: status,
                train_station: arrayTrainStations,
                culture_preference: cultural,
                sports_preference: sports,
                zip_code: postalCode,
              })
                .then((res) => {
                  if (res.success && res.status === 200) {
                    toast.success(t("successMessage"));
                    setEdicao(!edicao);
                  } else {
                    toast.error(res.error);
                  }
                })
                .catch((error) => {
                  toast.error(t("500"));
                });
            }
          }
        });
      } else {
        validatePostalCode();

        if (!errorOccurred) {
          let arrayTrainStations = [];
          selectedStations.forEach((element) => {
            arrayTrainStations.push(element.id);
          });

          let cultural = [];
          let sports = [];

          const logSelections = () => {
            cultural = selectedCulturalActivities.map((activity) => ({
              id: activity.id,
              zones: activity.zones || [],
            }));

            sports = selectedSportsActivities.map((activity) => ({
              id: activity.id,
              zones: activity.zones || [],
            }));
          };
          logSelections();
          putUser(id, {
            first_name: name,
            last_name: lastname,
            birth_date: birthDate,
            gender: gender,
            postal_code: postalCode,
            country: country.id.toString(),
            district: district.id.toString(),
            municipality: municipality.id.toString(),
            occupation: occupation,
            email: email,
            photo: photo,
            status: status,
            train_station: arrayTrainStations,
            culture_preference: cultural,
            sports_preference: sports,
            zip_code: postalCode,
          })
            .then((res) => {
              if (res.success && res.status === 200) {
                toast.success(t("successMessage"));
                setEdicao(!edicao);
              } else {
                toast.error(res.error);
              }
            })
            .catch((error) => {
              toast.error(t("500"));
            });
        }
      }
    } else if (isAdmin) {
      // Validations
      if (!name) {
        toast.error(t("nameValidation"));
        return;
      }
      if (!lastname) {
        toast.error(t("lastNameValidation"));
        return;
      }
      if (!birthDate) {
        toast.error(t("birthDateValidation"));
        return;
      } else {
        const birthDay = dayjs(birthDate);
        if (!birthDay.isValid()) {
          toast.error(t("invalidBirthDate"));
          return;
        }
      }
      if (!gender) {
        toast.error(t("genderValidation"));
        return;
      }
      if (!email) {
        toast.error(t("emailValidation"));
        return;
      } else {
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        if (!emailPattern.test(email)) {
          toast.error(t("invalidEmail"));
          return;
        }
      }

      let errorOccurred = false;

      // Function to check email registration asynchronously
      const checkEmailRegistration = async () => {
        try {
          const res = await checkRegistration({ email: email });
          if (res.status === 200 && res.success) {
            errorOccurred = true;
            toast.error(res.data.message);
          }
        } catch (error) {
          toast.error(error);
        }
      };

      // Check if email needs validation
      if (userData.email !== email) {
        checkEmailRegistration().then(() => {
          // After checkEmailRegistration completes, proceed to check other conditions
          if (!errorOccurred) {
            putAdmin(id, {
              first_name: name,
              last_name: lastname,
              birth_date: birthDate,
              gender: gender,
              postal_code: postalCode,
              email: email,
              photo: photo,
            })
              .then((res) => {
                if (res.success && res.status === 200) {
                  toast.success(t("successMessage"));
                  setEdicao(!edicao);
                } else {
                  toast.error(res.error);
                }
              })
              .catch((error) => {
                toast.error(t("500"));
              });
          }
        });
      } else {
        putAdmin(id, {
          first_name: name,
          last_name: lastname,
          birth_date: birthDate,
          gender: gender,
          postal_code: postalCode,
          email: email,
          photo: photo,
        })
          .then((res) => {
            if (res.success && res.status === 200) {
              toast.success(t("successMessage"));
              setEdicao(!edicao);
            } else {
              toast.error(res.error);
            }
          })
          .catch((error) => {
            toast.error(t("500"));
          });
      }
    } else if (isManager) {
      // Validations
      if (!name) {
        toast.error(t("nameValidation"));
        return;
      }
      if (!lastname) {
        toast.error(t("lastNameValidation"));
        return;
      }
      if (!birthDate) {
        toast.error(t("birthDateValidation"));
        return;
      } else {
        const birthDay = dayjs(birthDate);
        if (!birthDay.isValid()) {
          toast.error(t("invalidBirthDate"));
          return;
        }
      }
      if (!gender) {
        toast.error(t("genderValidation"));
        return;
      }
      if (!email) {
        toast.error(t("emailValidation"));
        return;
      } else {
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        if (!emailPattern.test(email)) {
          toast.error(t("invalidEmail"));
          return;
        }
      }

      let errorOccurred = false;

      // Function to check email registration asynchronously
      const checkEmailRegistration = async () => {
        try {
          const res = await checkRegistration({ email: email });
          if (res.status === 200 && res.success) {
            errorOccurred = true;
            toast.error(res.data.message);
          }
        } catch (error) {
          toast.error(error);
        }
      };

      // Check if email needs validation
      if (userData.email !== email) {
        checkEmailRegistration().then(() => {
          // After checkEmailRegistration completes, proceed to check other conditions
          if (!errorOccurred) {
            putManager(id, {
              first_name: name,
              last_name: lastname,
              birth_date: birthDate,
              gender: gender,
              postal_code: postalCode,
              email: email,
              photo: photo,
            })
              .then((res) => {
                if (res.success && res.status === 200) {
                  toast.success(t("successMessage"));
                  setEdicao(!edicao);
                } else {
                  toast.error(res.error);
                }
              })
              .catch((error) => {
                toast.error(t("500"));
              });
          }
        });
      } else {
        putManager(id, {
          first_name: name,
          last_name: lastname,
          birth_date: birthDate,
          gender: gender,
          postal_code: postalCode,
          email: email,
          photo: photo,
        })
          .then((res) => {
            if (res.success && res.status === 200) {
              toast.success(t("successMessage"));
              setEdicao(!edicao);
            } else {
              toast.error(res.error);
            }
          })
          .catch((error) => {
            toast.error(t("500"));
          });
      }
    }
  }
  const [editStationsDialogOpen, setEditStationsDialogOpen] = useState(false);
  const [editPreferencesDialogOpen, setEditPreferencesDialogOpen] =
    useState(false);

  const handleEditStationsClick = () => {
    setSelectedStations(trainstationsUser);
    // handleStationClick()
    setEditStationsDialogOpen(true);
  };

  const handleEditPreferencesClick = () => {
    setEditPreferencesDialogOpen(true);
  };

  const handleCloseStationsDialog = () => {
    setEditStationsDialogOpen(false);
  };

  const handleClosePreferencesDialog = () => {
    setEditPreferencesDialogOpen(false);
  };

  const [selectedStations, setSelectedStations] = useState([]);
  const [search, setSearch] = useState("");
  const [search2, setSearch2] = useState("");
  const handleSearch = (event) => {
    setSearch(event.target.value);
  };
  const handleSearch2 = (event) => {
    setSearch2(event.target.value);
  };

  const filteredStations = trainstations.filter(
    (station) =>
      station.designation.toLowerCase().includes(search.toLowerCase()) ||
      station.county.toLowerCase().includes(search.toLowerCase()) ||
      station.district.toLowerCase().includes(search.toLowerCase()) ||
      station.parish.toLowerCase().includes(search.toLowerCase())
  );
  const filteredCultural = culturalActivities.filter(
    (activity) =>
      activity.pt.toLowerCase().includes(search2.toLowerCase()) ||
      activity.es.toLowerCase().includes(search2.toLowerCase()) ||
      activity.en.toLowerCase().includes(search2.toLowerCase()) ||
      activity.fr.toLowerCase().includes(search2.toLowerCase()) ||
      activity.de.toLowerCase().includes(search2.toLowerCase())
  );

  const filteredSports = sportsActivities.filter(
    (activity) =>
      activity.pt.toLowerCase().includes(search2.toLowerCase()) ||
      activity.es.toLowerCase().includes(search2.toLowerCase()) ||
      activity.en.toLowerCase().includes(search2.toLowerCase()) ||
      activity.fr.toLowerCase().includes(search2.toLowerCase()) ||
      activity.de.toLowerCase().includes(search2.toLowerCase())
  );
  const [selectedOption, setSelectedOption] = useState("Cultural");
  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  const [selectedCulturalActivities, setSelectedCulturalActivities] = useState(
    []
  );

  const [selectedSportsActivities, setSelectedSportsActivities] = useState([]);

  // Seleciona ou deseleciona uma atividade cultural
  const handleCulturalClick = (activity) => {
    setSelectedCulturalActivities((prevActivities) => {
      const existingActivity = prevActivities.find(
        (item) => item.id === activity.id
      );

      if (existingActivity) {
        return prevActivities.filter((item) => item.id !== activity.id);
      } else {
        return [...prevActivities, { id: activity.id, zones: ["8"] }];
      }
    });
  };

  const handleZoneSelect = (activityId, zoneId) => {
    setSelectedCulturalActivities((prevActivities) => {
      return prevActivities.map((activity) => {
        if (activity.id === activityId) {
          const isZoneSelected = activity.zones.includes(zoneId.toString());
          const updatedZones = isZoneSelected
            ? activity.zones.filter((z) => z !== zoneId.toString())
            : [...activity.zones, zoneId.toString()];
          return { ...activity, zones: updatedZones };
        }
        return activity;
      });
    });
  };

  const handleSportsClick = (activity) => {
    setSelectedSportsActivities((prevActivities) => {
      const existingActivity = prevActivities.find(
        (item) => item.id === activity.id
      );

      if (existingActivity) {
        return prevActivities.filter((item) => item.id !== activity.id);
      } else {
        return [...prevActivities, { id: activity.id, zones: ["8"] }];
      }
    });
  };

  const handleZoneSelectSports = (activityId, zoneId) => {
    setSelectedSportsActivities((prevActivities) => {
      return prevActivities.map((activity) => {
        if (activity.id === activityId) {
          const isZoneSelected = activity.zones.includes(zoneId.toString());
          const updatedZones = isZoneSelected
            ? activity.zones.filter((z) => z !== zoneId.toString())
            : [...activity.zones, zoneId.toString()];

          return { ...activity, zones: updatedZones };
        }
        return activity;
      });
    });
  };

  const handleStationClick = (station) => {
    if (selectedStations.includes(station)) {
      setSelectedStations(selectedStations.filter((item) => item !== station));
    } else {
      if (selectedStations.length < 3) {
        setSelectedStations([...selectedStations, station]);
      } else {
        toast.error(t("stationError"));
      }
    }
  };

  function resetPassword() {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (email) {
      if (!emailRegex.test(email)) {
        toast.error(t("emailError"));
      } else {
        sendPasswordResetEmail({
          email: email,
          userType: isClient ? "customer" : isAdmin ? "admin" : null,
        })
          .then((res) => {
            if (res.status == 200 && res.success) {
              toast.success(t("emailSuccess"));
            } else {
              toast.error(res.error);
            }
          })
          .catch((error) => {
            toast.error(t("500"));
          });
      }
    } else {
      toast.error(t("emailError"));
    }
  }

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 950);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 950);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <div className="background">
      {isAdmin ? (
        <Header />
      ) : isManager ? (
        <HeaderManagerWhite />
      ) : isClient ? (
        <HeaderClient />
      ) : (
        <></>
      )}
      {isAdmin ? (
        <NavAdmin />
      ) : isManager ? (
        <>{isMobile ? <NavManagerHorizontal /> : <NavManager />}</>
      ) : isClient ? (
        <>{isMobile ? <NavClientHorizontal /> : <NavClient />}</>
      ) : (
        <></>
      )}
      {/* <div className="topLeftBack">
        <IconButton onClick={goBack}>
          <ArrowBackIcon
            size={"small"}
            style={{ fill: "#044535", zIndex: 99 }}
          />
        </IconButton>
      </div> */}
      <div className="centerpage">
        <a className="greenTitleClientData">{t("personaldata")}</a>

        <div className="whiteInfoCard">
          {isLoading ? (
            <Skeleton
              className="imgClientCard"
              variant="rectangular"
              width={210}
              height={160}
            />
          ) : photo !== "" ? (
            edicao ? (
              <div className="profileColumn">
                <div className="addProfile">
                  <img src={photo} className="addProfile" />
                </div>
              </div>
            ) : (
              <div className="profileColumn">
                <Dropzone onDrop={onDrop} accept="image/*" multiple={false}>
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      <div className="addProfile" {...getRootProps()}>
                        <input {...getInputProps()} />
                        {!photo ? (
                          <div className="addProfilePhoto">
                            <FontAwesomeIcon
                              style={{
                                fontSize: "16px",
                                color: "rgba(15, 17, 17, 0.60)",
                              }}
                              icon={faPen}
                            />
                          </div>
                        ) : (
                          <div className="addProfileEdit">
                            <img src={photo} className="addProfile" />
                            <div className="overlay">
                              <FontAwesomeIcon
                                className="hoverIcon"
                                icon={faPen}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </section>
                  )}
                </Dropzone>
              </div>
            )
          ) : avatarData ? (
            <div className="profileColumn">
              <Dropzone onDrop={onDrop} accept="image/*" multiple={false}>
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div className="addProfile" {...getRootProps()}>
                      <input {...getInputProps()} />
                      {!photo && !edicao ? (
                        <div className="addProfilePhoto">
                          <FontAwesomeIcon
                            style={{
                              fontSize: "16px",
                              color: "rgba(15, 17, 17, 0.60)",
                            }}
                            icon={faPen}
                          />
                        </div>
                      ) : (
                        <div className="addProfileEdit">
                          <Avatar className="imgClientCard" sx={avatarData.sx}>
                            {avatarData.children}
                          </Avatar>
                          {/* <div className="overlay">
                            <FontAwesomeIcon
                              className="hoverIcon"
                              icon={faPen}
                            />
                          </div> */}
                        </div>
                      )}
                    </div>
                  </section>
                )}
              </Dropzone>
            </div>
          ) : (
            <Avatar className="imgClientCard" sx={avatarData.sx}>
              {avatarData.children}
            </Avatar>
          )}
          <div style={{ flexDirection: isMobile ? "column" : "row", marginTop:isMobile?15:0 }}>
            <div className="mainInfoClient">
              {isLoading ? (
                <>
                  <Skeleton width="50%" />
                  <Skeleton width="45%" style={{ marginTop: "5%" }} />
                  <Skeleton width="70%" style={{ marginTop: "5%" }} />
                  <Skeleton width="30%" style={{ marginTop: "5%" }} />
                </>
              ) : (
                <>
                  <a className="infoClientName">
                    {name} {lastname}
                  </a>
                  {districts
                    .filter((district_) => district_.id == district)
                    .map((districtF) => (
                      <a className="infoClientText">
                        {districtF[i18next.language]}
                      </a>
                    ))}

                  <a className="infoClientText" style={{ marginTop: "5%" }}>
                    {email}
                  </a>
                  <a className="infoClientText">
                    {age} {t("anos")}
                  </a>
                </>
              )}
            </div>
            {isClient ? (
              <div className="mainInfoClient">
                <a className="infoClientTitleCard">{t("stations")}</a>
                <div className="row" style={{ marginTop: 5 }}>
                  {isLoading ? (
                    <>
                      <Skeleton
                        className="infoClientGreenCard"
                        variant="rectangular"
                        width="60px"
                        height={20}
                      />
                      <Skeleton
                        className="infoClientGreenCard"
                        variant="rectangular"
                        width="60px"
                        height={20}
                      />
                      <Skeleton
                        className="infoClientGreenCard"
                        variant="rectangular"
                        width="60px"
                        height={20}
                      />
                    </>
                  ) : (
                    selectedStations.slice(0, 3).map((station) => (
                      <div className="infoClientGreenCard" key={station.id}>
                        <a className="infoClientGreenCardText">
                          {station.designation}
                        </a>
                      </div>
                    ))
                  )}
                  {!edicao && (
                    <IconButton onClick={handleEditStationsClick}>
                      <EditIcon style={{ fontSize: 18 }} />
                    </IconButton>
                  )}
                </div>
                <a className="infoClientTitleCard" style={{ marginTop: "5%" }}>
                  {t("preferences")}
                </a>
                <div className="row" style={{ marginTop: 5 }}>
                  {isLoading ? (
                    <>
                      {[...Array(3)].map((_, index) => (
                        <Skeleton
                          className="infoClientGreenCard"
                          variant="rectangular"
                          width="60px"
                          height={20}
                        />
                      ))}
                      {[...Array(3)].map((_, index) => (
                        <Skeleton
                          className="infoClientGreenCard"
                          variant="rectangular"
                          width="60px"
                          height={20}
                        />
                      ))}
                    </>
                  ) : (
                    <>
                      {selectedCulturalActivities
                        .slice(0, 3)
                        .map((cultural, index) => {
                          // Encontre a atividade correspondente no culturalActivities
                          const activity = culturalActivities.find(
                            (activity) => activity.id === cultural.id
                          );

                          return (
                            <div
                              key={`cultural-${index}`}
                              className="infoClientGreenCard"
                            >
                              <a className="infoClientGreenCardText">
                                {activity
                                  ? activity[i18next.language]
                                  : "Atividade não encontrada"}{" "}
                              </a>
                              {/* <div>Zonas: {cultural.zones.join(", ")}</div> */}
                            </div>
                          );
                        })}

                      {selectedSportsActivities
                        .slice(0, 3)
                        .map((sports, index) => {
                          const activity = sportsActivities.find(
                            (activity) => activity.id === sports.id
                          );

                          return (
                            <div
                              key={`sports-${index}`}
                              className="infoClientGreenCard"
                            >
                              <a className="infoClientGreenCardText">
                                {activity
                                  ? activity[i18next.language]
                                  : "Atividade não encontrada"}{" "}
                              </a>
                              {/* <div>Zonas: {sports.zones.join(", ")}</div> */}
                            </div>
                          );
                        })}

                      {!edicao && (
                        <IconButton onClick={handleEditPreferencesClick}>
                          <EditIcon style={{ fontSize: 18 }} />
                        </IconButton>
                      )}
                    </>
                  )}
                  <Dialog
                    open={editStationsDialogOpen}
                    onClose={handleCloseStationsDialog}
                  >
                    <DialogTitle className="orderTitle">
                      {t("stations")}
                    </DialogTitle>
                    <DialogContent>
                      {/* Conteúdo do diálogo para editar estações */}
                      <div className="input-container">
                        <label style={{ paddingBottom: "30px", left: 0 }}>
                          {t("nearstation")}
                          <br />
                          <span style={{ fontSize: "10px" }}>
                            {t("selectmax3")}
                          </span>
                        </label>

                        <input
                          className={`input ${search && "has-text"}`}
                          type="text"
                          style={{ paddingLeft: "40px" }}
                          id="search"
                          onChange={handleSearch}
                          value={search}
                          required
                        />
                        <SearchIcon
                          className={"searchicon"}
                          style={{ color: Colors.green }}
                        ></SearchIcon>
                      </div>
                      <div className="scrollContainerCustom">
                        <div className="rowCustom">
                          {filteredStations.map((station) => (
                            <Card
                              key={station._id}
                              className={
                                selectedStations.includes(station)
                                  ? "selectedCardCustom"
                                  : "cardCustom"
                              }
                              onClick={() => handleStationClick(station)}
                            >
                              <CardContent
                                className={
                                  selectedStations.includes(station)
                                    ? "selectedCardCustom"
                                    : "cardCustom"
                                }
                              >
                                <Typography
                                  className={
                                    selectedStations.includes(station)
                                      ? "selectedTextCustom"
                                      : "textCustom"
                                  }
                                >
                                  {station.designation}{" "}
                                </Typography>
                              </CardContent>
                            </Card>
                          ))}
                        </div>
                      </div>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={handleCloseStationsDialog}
                        className="activateBtn"
                      >
                        {t("save")}
                      </Button>
                      <Button
                        onClick={handleCloseStationsDialog}
                        className="cancelBtn"
                      >
                        {t("cancel")}
                      </Button>
                    </DialogActions>
                  </Dialog>

                  {/* Diálogo para editar preferências */}
                  <Dialog
                    open={editPreferencesDialogOpen}
                    onClose={handleClosePreferencesDialog}
                  >
                    <DialogTitle className="orderTitle">
                      {t("preferences")}
                    </DialogTitle>
                    <DialogContent
                      style={{
                        overflow: "hidden",
                        justifyContent: "center",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <div className="row">
                        <Card
                          className={
                            selectedOption === "Cultural"
                              ? "selectedCardPref"
                              : "cardPref"
                          }
                          onClick={() => handleOptionClick("Cultural")}
                        >
                          <CardContent
                            className={
                              selectedOption === "Cultural"
                                ? "selectedCardPref"
                                : "cardPref"
                            }
                          >
                            <Typography
                              className={
                                selectedOption === "Cultural"
                                  ? "selectedTextCard"
                                  : "textCard"
                              }
                            >
                              {t("cultural")}
                            </Typography>
                          </CardContent>
                        </Card>
                        <Card
                          className={
                            selectedOption === "Sports"
                              ? "selectedCardPref"
                              : "cardPref"
                          }
                          onClick={() => handleOptionClick("Sports")}
                        >
                          <CardContent
                            className={
                              selectedOption === "Sports"
                                ? "selectedCardPref"
                                : "cardPref"
                            }
                          >
                            <Typography
                              className={
                                selectedOption === "Sports"
                                  ? "selectedTextCard"
                                  : "textCard"
                              }
                            >
                              {t("sports")}
                            </Typography>
                          </CardContent>
                        </Card>
                      </div>
                      <div
                        className="input-container"
                        style={{ marginTop: "-10px", marginBottom: "10px" }}
                      >
                        <input
                          className={`input ${search2 && "has-text"}`}
                          type="text"
                          style={{ paddingLeft: "20px" }}
                          id="search"
                          onChange={handleSearch2}
                          value={search2}
                          required
                        />
                        <SearchIcon
                          className={"searchicon"}
                          style={{ color: Colors.green }}
                        ></SearchIcon>
                      </div>
                      {selectedOption === "Cultural" && (
                        <div className="scrollContainerActivities2">
                          <div className="rowActivities">
                            {filteredCultural.map((activity) => {
                              const isSelected =
                                selectedCulturalActivities.some(
                                  (selectedActivity) =>
                                    selectedActivity.id === activity.id
                                );

                              return (
                                <Card
                                  key={activity.id}
                                  className={
                                    isSelected
                                      ? "selectedActivityCard"
                                      : "activityCard"
                                  }
                                  onClick={() => handleCulturalClick(activity)}
                                >
                                  <CardContent>
                                    <Typography>
                                      {activity[i18next.language] ||
                                        activity.en}
                                    </Typography>
                                  </CardContent>

                                  {isSelected && (
                                    <div className="zoneGrid">
                                      {zones.map((zone) => {
                                        const activityWithZones =
                                          selectedCulturalActivities.find(
                                            (selectedActivity) =>
                                              selectedActivity.id ===
                                              activity.id
                                          );

                                        const isZoneSelected =
                                          activityWithZones?.zones.includes(
                                            zone.id.toString()
                                          );

                                        return (
                                          <button
                                            key={zone.id}
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              handleZoneSelect(
                                                activity.id,
                                                zone.id
                                              );
                                            }}
                                            className={`zoneButton ${
                                              isZoneSelected ? "selected" : ""
                                            }`}
                                          >
                                            {zone[i18next.language] || zone.en}{" "}
                                          </button>
                                        );
                                      })}
                                    </div>
                                  )}
                                </Card>
                              );
                            })}
                          </div>
                        </div>
                      )}

                      {selectedOption === "Sports" && (
                        <div className="scrollContainerActivities2">
                          <div className="rowActivities">
                            {filteredSports.map((activity) => {
                              const isSelected = selectedSportsActivities.some(
                                (selectedActivity) =>
                                  selectedActivity.id === activity.id
                              );

                              return (
                                <Card
                                  key={activity.id}
                                  className={
                                    isSelected
                                      ? "selectedActivityCard"
                                      : "activityCard"
                                  }
                                  onClick={() => handleSportsClick(activity)}
                                >
                                  <CardContent>
                                    <Typography>
                                      {activity[i18next.language] ||
                                        activity.en}
                                    </Typography>
                                  </CardContent>

                                  {isSelected && (
                                    <div className="zoneGrid">
                                      {zones.map((zone) => {
                                        const isZoneSelected =
                                          selectedSportsActivities
                                            .find(
                                              (selectedActivity) =>
                                                selectedActivity.id ===
                                                activity.id
                                            )
                                            ?.zones?.includes(
                                              zone.id.toString()
                                            );

                                        return (
                                          <button
                                            key={zone.id}
                                            onClick={(e) => {
                                              e.stopPropagation(); // Previne a propagação do clique
                                              handleZoneSelectSports(
                                                activity.id,
                                                zone.id
                                              ); // Chama a função de seleção de zona
                                            }}
                                            className={`zoneButton ${
                                              isZoneSelected ? "selected" : ""
                                            }`}
                                          >
                                            {zone[i18next.language] || zone.en}
                                          </button>
                                        );
                                      })}
                                    </div>
                                  )}
                                </Card>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={handleClosePreferencesDialog}
                        className="activateBtn"
                      >
                        {t("save")}
                      </Button>
                      <Button
                        onClick={handleClosePreferencesDialog}
                        className="cancelBtn"
                      >
                        {t("cancel")}
                      </Button>
                    </DialogActions>
                  </Dialog>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="infoClientTopRight">
            {/* <a className="infoIdText">id:03</a> */}
          </div>
          {isLoading ? (
            <div className="infoClientBottomRight">
              <Skeleton variant="text" width={80} height={40} />
            </div>
          ) : (
            <div className="infoClientBottomRight">
              <button
                className="personalDataPwd"
                onClick={() => {
                  resetPassword();
                }}
              >
                {t("replacepwd")}
              </button>
            </div>
          )}
          {/* <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            sx={{
              background: "transparent",

              borderColor: "transparent",
              marginTop: "25px",
              width: "214px",
              height: "auto",
              borderRadius: "8px !important",
            }}
            MenuListProps={{
              style: {
                padding: "8px 0",
                backgroundColor: "#F3F3F3",
                borderRadius: "8px !important",
              },
            }}
          >
            {status === 1 && (
              <>
                <MenuItem
                  onClick={handleSuspend}
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    marginBottom: "5px !important",
                    fontSize: "18px",
                    fontWeight: 600,
                    fontStyle: "normal",
                    fontFamily: "Montserrat",
                    color: "#044535",
                  }}
                >
                  {t("suspend")}
                </MenuItem>
                <MenuItem
                  onClick={handleDelete}
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    marginBottom: "5px !important",
                    fontSize: "18px",
                    fontWeight: 600,
                    fontStyle: "normal",
                    fontFamily: "Montserrat",
                    color: "#044535",
                  }}
                >
                  {t("delete")}
                </MenuItem>
              </>
            )}
            {status === 2 && (
              <>
                <MenuItem
                  onClick={handleActivate}
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    marginBottom: "5px !important",
                    fontSize: "18px",
                    fontWeight: 600,
                    fontStyle: "normal",
                    fontFamily: "Montserrat",
                    color: "#044535",
                  }}
                >
                  {t("activate")}
                </MenuItem>
                <MenuItem
                  onClick={handleDelete}
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    marginBottom: "5px !important",
                    fontSize: "18px",
                    fontWeight: 600,
                    fontStyle: "normal",
                    fontFamily: "Montserrat",
                    color: "#044535",
                  }}
                >
                  {t("delete")}
                </MenuItem>
              </>
            )}
          </Menu>
          <Dialog
            open={openDialog}
            onClose={handleDialogClose}
            PaperProps={{ style: { borderRadius: 15 } }}
          >
            <DialogContent
              style={{
                borderRadius: "15px !important",
                background: "#FFF",
              }}
            >
              <DialogContentText
                style={{
                  background: "#FFF",
                  marginTop: "20px",
                  marginBottom: "10px",

                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <a className="textCancel"> {t("deleteClient")}</a>
              </DialogContentText>
              <TextField
                required
                variant="outlined"
                placeholder={t("reason")}
                multiline
                rows={2}
                style={{
                  marginTop: "10px",
                  marginBottom: "20px",
                  width: "100%",
                  alignSelf: "center",
                }}
                onChange={(e) => {
                  setReason(e.target.value);
                }}
                inputProps={{ maxLength: 255 }}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Button onClick={handleDialogClose} className="deleteBtn">
                  {t("delete")}
                </Button>
                <Button onClick={confirmDelete} className="cancelBtn">
                  {t("cancel")}
                </Button>
              </div>
            </DialogContent>
          </Dialog>
          <Dialog
            open={openDialog2}
            onClose={handleDialogClose2}
            PaperProps={{ style: { borderRadius: 15 } }}
          >
            <DialogContent
              style={{
                borderRadius: "15px !important",
                background: "#FFF",
              }}
            >
              <DialogContentText
                style={{
                  background: "#FFF",
                  marginTop: "20px",
                  marginBottom: "10px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <a className="textCancel"> {t("suspendClient")}</a>
              </DialogContentText>

              <TextField
                required
                variant="outlined"
                placeholder={t("reason")}
                multiline
                rows={2}
                style={{
                  marginTop: "10px",
                  marginBottom: "20px",
                  width: "100%",
                  alignSelf: "center",
                }}
                onChange={(e) => {
                  setReason(e.target.value);
                }}
                inputProps={{ maxLength: 255 }}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Button onClick={handleDialogClose2} className="suspendBtn">
                  {t("suspend")}
                </Button>
                <Button onClick={confirmDelete} className="cancelBtn">
                  {t("cancel")}
                </Button>
              </div>
            </DialogContent>
          </Dialog>
          <Dialog
            open={openDialog3}
            onClose={handleDialogClose3}
            PaperProps={{ style: { borderRadius: 15 } }}
          >
            <DialogContent
              style={{
                borderRadius: "15px !important",
                background: "#FFF",
              }}
            >
              <DialogContentText
                style={{
                  background: "#FFF",
                  marginTop: "20px",
                  marginBottom: "10px",

                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <a className="textCancel"> {t("activateClient")}</a>
              </DialogContentText>
              <TextField
                required
                variant="outlined"
                placeholder={t("reason")}
                multiline
                rows={2}
                style={{
                  marginTop: "10px",
                  marginBottom: "20px",
                  width: "100%",
                  alignSelf: "center",
                }}
                onChange={(e) => {
                  setReason(e.target.value);
                }}
                inputProps={{ maxLength: 255 }}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Button onClick={handleDialogClose3} className="activateBtn">
                  {t("activate")}
                </Button>
                <Button onClick={confirmDelete} className="cancelBtn">
                  {t("cancel")}
                </Button>
              </div>
            </DialogContent>
          </Dialog> */}
        </div>
        <div className="whiteEditInfoCard" >
          <Container
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              flexDirection: "column",
              maxWidth: "none",
              maxHeight: isClient?"70%":"55%",
              overflow: "auto",
            }}
          >
            {isClient ? (
              <Grid container spacing={2} padding={2}>
                <Grid item xs={6}>
                  {isLoading ? (
                    <>
                      <FormControl fullWidth margin="normal">
                        <InputLabel shrink style={inputLabelPropsStyle}>
                          {t("firstname")}
                        </InputLabel>
                        <SkeletonTextField />
                      </FormControl>
                      <FormControl fullWidth margin="normal">
                        <InputLabel shrink style={inputLabelPropsStyle}>
                          {t("lastname")}
                        </InputLabel>
                        <SkeletonTextField />
                      </FormControl>
                      <FormControl fullWidth margin="normal">
                        <InputLabel shrink style={inputLabelPropsStyle}>
                          {t("birthdate")}
                        </InputLabel>
                        <SkeletonTextField />
                      </FormControl>
                      <FormControl fullWidth margin="normal">
                        <InputLabel shrink style={inputLabelPropsStyle}>
                          {t("gender")}
                        </InputLabel>
                        <SkeletonTextField />
                      </FormControl>
                      <FormControl fullWidth margin="normal">
                        <InputLabel shrink style={inputLabelPropsStyle}>
                          {t("postalcode")}
                        </InputLabel>
                        <SkeletonTextField />
                      </FormControl>
                    </>
                  ) : (
                    <>
                      <TextField
                        fullWidth
                        disabled={edicao}
                        InputProps={{
                          disableUnderline: true,
                          style: inputPropsStyle,
                        }}
                        InputLabelProps={{
                          shrink: true,
                          style: inputLabelPropsStyle,
                        }}
                        sx={{
                          "& .Mui-disabled": {
                            backgroundColor: "white !important",
                            color: "#044535 !important",
                          },
                          "& .MuiInputLabel-root ": {
                            color: "#044535 !important",
                          },
                        }}
                        label={t("firstname")}
                        variant="standard"
                        margin="normal"
                        placeholder={t("firstname")}
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                        value={name}
                      />
                      <TextField
                        fullWidth
                        disabled={edicao}
                        InputProps={{
                          disableUnderline: true,
                          style: inputPropsStyle,
                        }}
                        InputLabelProps={{
                          shrink: true,
                          style: inputLabelPropsStyle,
                        }}
                        sx={{
                          "& .Mui-disabled": {
                            backgroundColor: "white !important",
                            color: "#044535 !important",
                          },
                          "& .MuiInputLabel-root ": {
                            color: "#044535 !important",
                          },
                        }}
                        label={t("lastname")}
                        variant="standard"
                        margin="normal"
                        placeholder={t("lastname")}
                        onChange={(e) => {
                          setLastName(e.target.value);
                        }}
                        value={lastname}
                      />
                      <div style={{ marginTop: "20px" }}>
                        <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          adapterLocale={i18next?.language}
                        >
                          <DatePicker
                            openTo="day"
                            variant="standard"
                            label={t("birthdate")}
                            views={["day", "month", "year"]}
                            disableFuture
                            value={birthDate}
                            format="DD/MM/YYYY"
                            onChange={(e) => {
                              setBirthDate(e);
                            }}
                            disabled={edicao}
                            slotProps={{
                              tabs: {
                                hidden: false,
                              },

                              day: {
                                sx: {
                                  "&.MuiPickersDay-root.Mui-selected": {
                                    backgroundColor: "#13BB56 !important",
                                  },
                                },
                              },

                              desktopPaper: {
                                sx: {
                                  borderRadius: "8px",
                                  ".MuiPickersYear-yearButton.Mui-selected": {
                                    backgroundColor: "#13BB56 !important",
                                  },
                                  ".MuiPickersMonth-monthButton.Mui-selected": {
                                    backgroundColor: "#13BB56 !important",
                                  },
                                },
                              },
                            }}
                            sx={{
                              width: "60%",

                              "& .MuiInputBase-input.Mui-disabled": {
                                backgroundColor: "white",
                              },
                              "& .MuiPickersDay-root.Mui-selected": {
                                backgroundColor: "#44b887 !important",
                                color: "white",
                              },
                              "& .MuiPickersCalendar-root": {
                                borderRadius: "8px !important",
                              },

                              "& .MuiInputLabel-root ": {
                                color: "#044535 !important",

                                fontFamily: "Montserrat",
                                fontSize: "16px",
                                fontStyle: "normal",
                                fontWeight: 600,
                                lineHeight: "15px",
                                borderColor: "transparent !important",
                                position: "absolute",
                                marginTop: "-5px",
                                marginLeft: "-10px",
                              },
                              "& .MuiOutlinedInput-root": {
                                color: "#0F111199 !important",
                                textAlign: "right",
                                fontFamily: "Montserrat",
                                fontSize: "18px",
                                fontStyle: "normal",
                                fontWeight: 500,
                                lineHeight: "15px",
                                borderRadius: "8px",
                                background: !edicao ? "#0f11110d" : "white",
                                borderColor: "transparent !important",
                                width: "100%",
                                height: "34.88px",
                                padding: "16.5px 10px",
                              },
                              "& .MuiInputLabel-root.Mui-disabled ": {
                                color: "#044535 !important",
                                fontFamily: "Montserrat",
                                fontSize: "16px",
                                fontStyle: "normal",
                                fontWeight: 600,
                                lineHeight: "15px",
                                borderColor: "transparent !important",
                              },
                              "& .MuiOutlinedInput-notchedOutline ": {
                                border: "0px",
                              },
                              "& .MuiOutlinedInput-input": {
                                padding: "16.5px 0px",
                                width: "100%",
                              },
                            }}
                          ></DatePicker>
                        </LocalizationProvider>
                      </div>
                      <TextField
                        fullWidth
                        disabled={edicao}
                        select
                        InputProps={{
                          disableUnderline: true,
                          style: {
                            ...inputPropsStyle,
                            textAlign: "left",
                            borderRadius: "8px",
                          },
                        }}
                        InputLabelProps={{
                          shrink: true,
                          style: inputLabelPropsStyle,
                        }}
                        sx={{
                          "& .Mui-disabled": {
                            backgroundColor: "white !important",
                            color: "#044535 !important",
                          },
                          "& .MuiInputLabel-root ": {
                            color: "#044535 !important",
                          },
                        }}
                        label={t("gender")}
                        variant="standard"
                        margin="normal"
                        placeholder={t("gender")}
                        value={gender}
                        onChange={(e) => {
                          setGender(e.target.value);
                        }}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}
                      >
                        {genders.map((genderOption) => (
                          <MenuItem
                            key={genderOption.id}
                            value={genderOption.id}
                          >
                            {t(genderOption[i18next.language])}
                          </MenuItem>
                        ))}
                      </TextField>
                      <TextField
                        fullWidth
                        disabled={edicao}
                        InputProps={{
                          disableUnderline: true,
                          style: inputPropsStyle,
                        }}
                        InputLabelProps={{
                          shrink: true,
                          style: inputLabelPropsStyle,
                        }}
                        sx={{
                          "& .Mui-disabled": {
                            backgroundColor: "white !important",
                            color: "#044535 !important",
                          },
                          "& .MuiInputLabel-root ": {
                            color: "#044535 !important",
                          },
                        }}
                        label={t("postalcode")}
                        variant="standard"
                        margin="normal"
                        placeholder={t("postalcode")}
                        onChange={(e) => {
                          setPostalCode(e.target.value);
                        }}
                        value={postalCode}
                      />
                    </>
                  )}
                </Grid>
                <Grid item xs={6}>
                  {isLoading ? (
                    <>
                      <FormControl fullWidth margin="normal">
                        <InputLabel shrink style={inputLabelPropsStyle}>
                          {t("country")}
                        </InputLabel>
                        <SkeletonTextField />
                      </FormControl>
                      <FormControl fullWidth margin="normal">
                        <InputLabel shrink style={inputLabelPropsStyle}>
                          {t("district")}
                        </InputLabel>
                        <SkeletonTextField />
                      </FormControl>
                      <FormControl fullWidth margin="normal">
                        <InputLabel shrink style={inputLabelPropsStyle}>
                          {t("municipality")}
                        </InputLabel>
                        <SkeletonTextField />
                      </FormControl>
                      <FormControl fullWidth margin="normal">
                        <InputLabel shrink style={inputLabelPropsStyle}>
                          {t("occupation")}
                        </InputLabel>
                        <SkeletonTextField />
                      </FormControl>
                      <FormControl fullWidth margin="normal">
                        <InputLabel shrink style={inputLabelPropsStyle}>
                          {t("email")}
                        </InputLabel>
                        <SkeletonTextField />
                      </FormControl>
                    </>
                  ) : (
                    <>
                      <Autocomplete
                        disabled={edicao}
                        options={countries}
                        getOptionLabel={(option) =>
                          option[i18next.language] || option.en
                        }
                        value={country}
                        onChange={handleCountryChange}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            disabled={edicao}
                            InputProps={{
                              ...params.InputProps,
                              disableUnderline: true,
                              style: {
                                textAlign: "left",
                                borderRadius: "8px",
                                color: "#0F111199",
                                fontFamily: "Montserrat",
                                fontSize: "18px",
                                fontStyle: "normal",
                                fontWeight: 500,
                                lineHeight: "22px",
                                borderRadius: "8px",
                                background: "#0f11110d",
                                paddingLeft: "20px",
                                width: "60%",
                              },
                            }}
                            InputLabelProps={{
                              shrink: true,
                              style: inputLabelPropsStyle,
                            }}
                            sx={{
                              "& .Mui-disabled": {
                                backgroundColor: "white !important",
                                color: "#044535 !important",
                              },
                              "& .MuiInputLabel-root": {
                                color: "#044535 !important",
                              },
                            }}
                            label={t("country")}
                            variant="standard"
                            margin="normal"
                            placeholder={t("country")}
                          />
                        )}
                        renderOption={(props, option) => (
                          <li {...props} key={option.id}>
                            {option[i18next.language] || option.en}
                          </li>
                        )}
                        sx={{
                          "& .MuiAutocomplete-popupIndicator": {
                            display: "none",
                          },
                          "& .MuiAutocomplete-clearIndicator": {
                            display: "none",
                          },
                          "& .MuiOutlinedInput-root": {
                            padding: 0,
                          },
                          "& .MuiAutocomplete-listbox": {
                            fontFamily: "Montserrat",
                            fontSize: "14px",
                          },
                          "& .MuiAutocomplete-inputRoot": {
                            padding: "0 !important",
                          },
                        }}
                      />
                      <Autocomplete
                        disabled={edicao}
                        options={districts}
                        getOptionLabel={(option) =>
                          option[i18next.language] || option.en
                        }
                        value={district}
                        onChange={handleDistrictChange}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            disabled={edicao}
                            InputProps={{
                              ...params.InputProps,
                              disableUnderline: true,
                              style: {
                                textAlign: "left",
                                borderRadius: "8px",
                                color: "#0F111199",
                                fontFamily: "Montserrat",
                                fontSize: "18px",
                                fontStyle: "normal",
                                fontWeight: 500,
                                lineHeight: "22px",
                                borderRadius: "8px",
                                background: "#0f11110d",
                                paddingLeft: "20px",
                                width: "60%",
                              },
                            }}
                            InputLabelProps={{
                              shrink: true,
                              style: inputLabelPropsStyle,
                            }}
                            sx={{
                              "& .Mui-disabled": {
                                backgroundColor: "white !important",
                                color: "#044535 !important",
                              },
                              "& .MuiInputLabel-root": {
                                color: "#044535 !important",
                              },
                            }}
                            label={t("district")}
                            variant="standard"
                            margin="normal"
                            placeholder={t("district")}
                          />
                        )}
                        renderOption={(props, option) => (
                          <li {...props} key={option.id}>
                            {option[i18next.language] || option.en}
                          </li>
                        )}
                        sx={{
                          "& .MuiAutocomplete-popupIndicator": {
                            display: "none",
                          },
                          "& .MuiAutocomplete-clearIndicator": {
                            display: "none",
                          },
                          "& .MuiOutlinedInput-root": {
                            padding: 0,
                          },
                          "& .MuiAutocomplete-listbox": {
                            fontFamily: "Montserrat",
                            fontSize: "14px",
                          },
                          "& .MuiAutocomplete-inputRoot": {
                            padding: "0 !important",
                          },
                        }}
                      />
                      <Autocomplete
                        disabled={edicao}
                        options={municipalities}
                        getOptionLabel={(option) =>
                          option[i18next.language] || option.en
                        }
                        value={municipality}
                        onChange={handleMunChange}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            disabled={edicao}
                            InputProps={{
                              ...params.InputProps,
                              disableUnderline: true,
                              style: {
                                textAlign: "left",
                                borderRadius: "8px",
                                color: "#0F111199",
                                fontFamily: "Montserrat",
                                fontSize: "18px",
                                fontStyle: "normal",
                                fontWeight: 500,
                                lineHeight: "22px",
                                borderRadius: "8px",
                                background: "#0f11110d",
                                paddingLeft: "20px",
                                width: "60%",
                              },
                            }}
                            InputLabelProps={{
                              shrink: true,
                              style: inputLabelPropsStyle,
                            }}
                            sx={{
                              "& .Mui-disabled": {
                                backgroundColor: "white !important",
                                color: "#044535 !important",
                              },
                              "& .MuiInputLabel-root": {
                                color: "#044535 !important",
                              },
                            }}
                            label={t("municipality")}
                            variant="standard"
                            margin="normal"
                            placeholder={t("municipality")}
                          />
                        )}
                        renderOption={(props, option) => (
                          <li {...props} key={option.id}>
                            {option[i18next.language] || option.en}
                          </li>
                        )}
                        sx={{
                          "& .MuiAutocomplete-popupIndicator": {
                            display: "none",
                          },
                          "& .MuiAutocomplete-clearIndicator": {
                            display: "none",
                          },
                          "& .MuiOutlinedInput-root": {
                            padding: 0,
                          },
                          "& .MuiAutocomplete-listbox": {
                            fontFamily: "Montserrat",
                            fontSize: "14px",
                          },
                          "& .MuiAutocomplete-inputRoot": {
                            padding: "0 !important",
                          },
                        }}
                      />

                      <TextField
                        fullWidth
                        disabled={edicao}
                        InputProps={{
                          disableUnderline: true,
                          style: inputPropsStyle,
                        }}
                        InputLabelProps={{
                          shrink: true,
                          style: inputLabelPropsStyle,
                        }}
                        sx={{
                          "& .Mui-disabled": {
                            backgroundColor: "white !important",
                            color: "#044535 !important",
                          },
                          "& .MuiInputLabel-root ": {
                            color: "#044535 !important",
                          },
                        }}
                        label={t("occupation")}
                        variant="standard"
                        margin="normal"
                        placeholder={t("occupation")}
                        onChange={(e) => {
                          setOccupation(e.target.value);
                        }}
                        value={occupation}
                      />
                      {/* <MuiTelInput
                disabled={edicao}
                fullWidth
                defaultCountry="PT"
                onChange={handleChange}
                InputProps={{
                  disableUnderline: true,
                  style: inputPropsStyle,
                }}
                sx={{
                  "& .Mui-disabled": {
                    backgroundColor: "white !important",
                    color: "#044535 !important",
                  },
                  "& .MuiInputLabel-root ": {
                    color: "#044535 !important",
                  },
                }}
                variant="standard"
                InputLabelProps={{
                  shrink: true,
                  style: inputLabelPropsStyle,
                }}
                label={t("phone")}
                value={value}
                margin="normal"
              /> */}
                      <TextField
                        fullWidth
                        disabled={edicao}
                        InputProps={{
                          disableUnderline: true,
                          style: inputPropsStyle,
                        }}
                        InputLabelProps={{
                          shrink: true,
                          style: inputLabelPropsStyle,
                        }}
                        sx={{
                          "& .Mui-disabled": {
                            backgroundColor: "white !important",
                            color: "#044535 !important",
                          },
                          "& .MuiInputLabel-root ": {
                            color: "#044535 !important",
                          },
                        }}
                        label={t("email")}
                        variant="standard"
                        margin="normal"
                        placeholder="example@email.com"
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        value={email}
                      />
                    </>
                  )}
                </Grid>
              </Grid>
            ) : isAdmin ? (
              <Grid container spacing={2} padding={2}>
                <Grid item xs={6}>
                  {isLoading ? (
                    <>
                      <FormControl fullWidth margin="normal">
                        <InputLabel shrink style={inputLabelPropsStyle}>
                          {t("firstname")}
                        </InputLabel>
                        <SkeletonTextField />
                      </FormControl>
                      <FormControl fullWidth margin="normal">
                        <InputLabel shrink style={inputLabelPropsStyle}>
                          {t("lastname")}
                        </InputLabel>
                        <SkeletonTextField />
                      </FormControl>
                      <FormControl fullWidth margin="normal">
                        <InputLabel shrink style={inputLabelPropsStyle}>
                          {t("birthdate")}
                        </InputLabel>
                        <SkeletonTextField />
                      </FormControl>
                      <FormControl fullWidth margin="normal">
                        <InputLabel shrink style={inputLabelPropsStyle}>
                          {t("gender")}
                        </InputLabel>
                        <SkeletonTextField />
                      </FormControl>
                    </>
                  ) : (
                    <>
                      <TextField
                        fullWidth
                        disabled={edicao}
                        InputProps={{
                          disableUnderline: true,
                          style: inputPropsStyle,
                        }}
                        InputLabelProps={{
                          shrink: true,
                          style: inputLabelPropsStyle,
                        }}
                        sx={{
                          "& .Mui-disabled": {
                            backgroundColor: "white !important",
                            color: "#044535 !important",
                          },
                          "& .MuiInputLabel-root ": {
                            color: "#044535 !important",
                          },
                        }}
                        label={t("firstname")}
                        variant="standard"
                        margin="normal"
                        placeholder={t("firstname")}
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                        value={name}
                      />
                      <TextField
                        fullWidth
                        disabled={edicao}
                        InputProps={{
                          disableUnderline: true,
                          style: inputPropsStyle,
                        }}
                        InputLabelProps={{
                          shrink: true,
                          style: inputLabelPropsStyle,
                        }}
                        sx={{
                          "& .Mui-disabled": {
                            backgroundColor: "white !important",
                            color: "#044535 !important",
                          },
                          "& .MuiInputLabel-root ": {
                            color: "#044535 !important",
                          },
                        }}
                        label={t("lastname")}
                        variant="standard"
                        margin="normal"
                        placeholder={t("lastname")}
                        onChange={(e) => {
                          setLastName(e.target.value);
                        }}
                        value={lastname}
                      />
                      <div style={{ marginTop: "20px" }}>
                        <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          adapterLocale={i18next?.language}
                        >
                          <DatePicker
                            openTo="day"
                            variant="standard"
                            label={t("birthdate")}
                            views={["day", "month", "year"]}
                            disableFuture
                            value={birthDate}
                            format="DD/MM/YYYY"
                            onChange={(e) => {
                              setBirthDate(e);
                            }}
                            disabled={edicao}
                            slotProps={{
                              tabs: {
                                hidden: false,
                              },

                              day: {
                                sx: {
                                  "&.MuiPickersDay-root.Mui-selected": {
                                    backgroundColor: "#13BB56 !important",
                                  },
                                },
                              },

                              desktopPaper: {
                                sx: {
                                  borderRadius: "8px",
                                  ".MuiPickersYear-yearButton.Mui-selected": {
                                    backgroundColor: "#13BB56 !important",
                                  },
                                  ".MuiPickersMonth-monthButton.Mui-selected": {
                                    backgroundColor: "#13BB56 !important",
                                  },
                                },
                              },
                            }}
                            sx={{
                              width: "60%",

                              "& .MuiInputBase-input.Mui-disabled": {
                                backgroundColor: "white",
                              },
                              "& .MuiPickersDay-root.Mui-selected": {
                                backgroundColor: "#44b887 !important",
                                color: "white",
                              },
                              "& .MuiPickersCalendar-root": {
                                borderRadius: "8px !important",
                              },

                              "& .MuiInputLabel-root ": {
                                color: "#044535 !important",

                                fontFamily: "Montserrat",
                                fontSize: "16px",
                                fontStyle: "normal",
                                fontWeight: 600,
                                lineHeight: "15px",
                                borderColor: "transparent !important",
                                position: "absolute",
                                marginTop: "-5px",
                                marginLeft: "-10px",
                              },
                              "& .MuiOutlinedInput-root": {
                                color: "#0F111199 !important",
                                textAlign: "right",
                                fontFamily: "Montserrat",
                                fontSize: "18px",
                                fontStyle: "normal",
                                fontWeight: 500,
                                lineHeight: "15px",
                                borderRadius: "8px",
                                background: !edicao ? "#0f11110d" : "white",
                                borderColor: "transparent !important",
                                width: "100%",
                                height: "34.88px",
                                padding: "16.5px 10px",
                              },
                              "& .MuiInputLabel-root.Mui-disabled ": {
                                color: "#044535 !important",
                                fontFamily: "Montserrat",
                                fontSize: "16px",
                                fontStyle: "normal",
                                fontWeight: 600,
                                lineHeight: "15px",
                                borderColor: "transparent !important",
                              },
                              "& .MuiOutlinedInput-notchedOutline ": {
                                border: "0px",
                              },
                              "& .MuiOutlinedInput-input": {
                                padding: "16.5px 0px",
                                width: "100%",
                              },
                            }}
                          ></DatePicker>
                        </LocalizationProvider>
                      </div>
                      <TextField
                        fullWidth
                        disabled={edicao}
                        select
                        InputProps={{
                          disableUnderline: true,
                          style: {
                            ...inputPropsStyle,
                            textAlign: "left",
                            borderRadius: "8px",
                          },
                        }}
                        InputLabelProps={{
                          shrink: true,
                          style: inputLabelPropsStyle,
                        }}
                        sx={{
                          "& .Mui-disabled": {
                            backgroundColor: "white !important",
                            color: "#044535 !important",
                          },
                          "& .MuiInputLabel-root ": {
                            color: "#044535 !important",
                          },
                        }}
                        label={t("gender")}
                        variant="standard"
                        margin="normal"
                        placeholder={t("gender")}
                        value={gender}
                        onChange={(e) => {
                          setGender(e.target.value);
                        }}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}
                      >
                        {genders.map((genderOption) => (
                          <MenuItem
                            key={genderOption.id}
                            value={genderOption.id}
                          >
                            {t(genderOption[i18next.language])}
                          </MenuItem>
                        ))}
                      </TextField>
                    </>
                  )}
                </Grid>
                <Grid item xs={6}>
                  {isLoading ? (
                    <>
                      <FormControl fullWidth margin="normal">
                        <InputLabel shrink style={inputLabelPropsStyle}>
                          {t("phone")}
                        </InputLabel>
                        <SkeletonTextField />
                      </FormControl>
                      <FormControl fullWidth margin="normal">
                        <InputLabel shrink style={inputLabelPropsStyle}>
                          {t("email")}
                        </InputLabel>
                        <SkeletonTextField />
                      </FormControl>
                    </>
                  ) : (
                    <>
                      <MuiTelInput
                        disabled={edicao}
                        fullWidth
                        defaultCountry="PT"
                        onChange={handleChange}
                        InputProps={{
                          disableUnderline: true,
                          style: inputPropsStyle,
                        }}
                        sx={{
                          "& .Mui-disabled": {
                            backgroundColor: "white !important",
                            color: "#044535 !important",
                          },
                          "& .MuiInputLabel-root ": {
                            color: "#044535 !important",
                          },
                        }}
                        variant="standard"
                        InputLabelProps={{
                          shrink: true,
                          style: inputLabelPropsStyle,
                        }}
                        label={t("phone")}
                        value={value}
                        margin="normal"
                      />
                      <TextField
                        fullWidth
                        disabled={edicao}
                        InputProps={{
                          disableUnderline: true,
                          style: inputPropsStyle,
                        }}
                        InputLabelProps={{
                          shrink: true,
                          style: inputLabelPropsStyle,
                        }}
                        sx={{
                          "& .Mui-disabled": {
                            backgroundColor: "white !important",
                            color: "#044535 !important",
                          },
                          "& .MuiInputLabel-root ": {
                            color: "#044535 !important",
                          },
                        }}
                        label={t("email")}
                        variant="standard"
                        margin="normal"
                        placeholder="example@email.com"
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        value={email}
                      />
                    </>
                  )}
                </Grid>
              </Grid>
            ) : isManager ? (
              <Grid container spacing={2} padding={2}>
                <Grid item xs={6}>
                  {isLoading ? (
                    <>
                      <FormControl fullWidth margin="normal">
                        <InputLabel shrink style={inputLabelPropsStyle}>
                          {t("firstname")}
                        </InputLabel>
                        <SkeletonTextField />
                      </FormControl>
                      <FormControl fullWidth margin="normal">
                        <InputLabel shrink style={inputLabelPropsStyle}>
                          {t("lastname")}
                        </InputLabel>
                        <SkeletonTextField />
                      </FormControl>
                      <FormControl fullWidth margin="normal">
                        <InputLabel shrink style={inputLabelPropsStyle}>
                          {t("birthdate")}
                        </InputLabel>
                        <SkeletonTextField />
                      </FormControl>
                      <FormControl fullWidth margin="normal">
                        <InputLabel shrink style={inputLabelPropsStyle}>
                          {t("gender")}
                        </InputLabel>
                        <SkeletonTextField />
                      </FormControl>
                    </>
                  ) : (
                    <>
                      <TextField
                        fullWidth
                        disabled={edicao}
                        InputProps={{
                          disableUnderline: true,
                          style: inputPropsStyle,
                        }}
                        InputLabelProps={{
                          shrink: true,
                          style: inputLabelPropsStyle,
                        }}
                        sx={{
                          "& .Mui-disabled": {
                            backgroundColor: "white !important",
                            color: "#044535 !important",
                          },
                          "& .MuiInputLabel-root ": {
                            color: "#044535 !important",
                          },
                        }}
                        label={t("firstname")}
                        variant="standard"
                        margin="normal"
                        placeholder={t("firstname")}
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                        value={name}
                      />
                      <TextField
                        fullWidth
                        disabled={edicao}
                        InputProps={{
                          disableUnderline: true,
                          style: inputPropsStyle,
                        }}
                        InputLabelProps={{
                          shrink: true,
                          style: inputLabelPropsStyle,
                        }}
                        sx={{
                          "& .Mui-disabled": {
                            backgroundColor: "white !important",
                            color: "#044535 !important",
                          },
                          "& .MuiInputLabel-root ": {
                            color: "#044535 !important",
                          },
                        }}
                        label={t("lastname")}
                        variant="standard"
                        margin="normal"
                        placeholder={t("lastname")}
                        onChange={(e) => {
                          setLastName(e.target.value);
                        }}
                        value={lastname}
                      />
                      <div style={{ marginTop: "20px" }}>
                        <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          adapterLocale={i18next?.language}
                        >
                          <DatePicker
                            openTo="day"
                            variant="standard"
                            label={t("birthdate")}
                            views={["day", "month", "year"]}
                            disableFuture
                            value={birthDate}
                            format="DD/MM/YYYY"
                            onChange={(e) => {
                              setBirthDate(e);
                            }}
                            disabled={edicao}
                            slotProps={{
                              tabs: {
                                hidden: false,
                              },

                              day: {
                                sx: {
                                  "&.MuiPickersDay-root.Mui-selected": {
                                    backgroundColor: "#13BB56 !important",
                                  },
                                },
                              },

                              desktopPaper: {
                                sx: {
                                  borderRadius: "8px",
                                  ".MuiPickersYear-yearButton.Mui-selected": {
                                    backgroundColor: "#13BB56 !important",
                                  },
                                  ".MuiPickersMonth-monthButton.Mui-selected": {
                                    backgroundColor: "#13BB56 !important",
                                  },
                                },
                              },
                            }}
                            sx={{
                              width: "60%",

                              "& .MuiInputBase-input.Mui-disabled": {
                                backgroundColor: "white",
                              },
                              "& .MuiPickersDay-root.Mui-selected": {
                                backgroundColor: "#44b887 !important",
                                color: "white",
                              },
                              "& .MuiPickersCalendar-root": {
                                borderRadius: "8px !important",
                              },

                              "& .MuiInputLabel-root ": {
                                color: "#044535 !important",

                                fontFamily: "Montserrat",
                                fontSize: "16px",
                                fontStyle: "normal",
                                fontWeight: 600,
                                lineHeight: "15px",
                                borderColor: "transparent !important",
                                position: "absolute",
                                marginTop: "-5px",
                                marginLeft: "-10px",
                              },
                              "& .MuiOutlinedInput-root": {
                                color: "#0F111199 !important",
                                textAlign: "right",
                                fontFamily: "Montserrat",
                                fontSize: "18px",
                                fontStyle: "normal",
                                fontWeight: 500,
                                lineHeight: "15px",
                                borderRadius: "8px",
                                background: !edicao ? "#0f11110d" : "white",
                                borderColor: "transparent !important",
                                width: "100%",
                                height: "34.88px",
                                padding: "16.5px 10px",
                              },
                              "& .MuiInputLabel-root.Mui-disabled ": {
                                color: "#044535 !important",
                                fontFamily: "Montserrat",
                                fontSize: "16px",
                                fontStyle: "normal",
                                fontWeight: 600,
                                lineHeight: "15px",
                                borderColor: "transparent !important",
                              },
                              "& .MuiOutlinedInput-notchedOutline ": {
                                border: "0px",
                              },
                              "& .MuiOutlinedInput-input": {
                                padding: "16.5px 0px",
                                width: "100%",
                              },
                            }}
                          ></DatePicker>
                        </LocalizationProvider>
                      </div>
                      <TextField
                        fullWidth
                        disabled={edicao}
                        select
                        InputProps={{
                          disableUnderline: true,
                          style: {
                            ...inputPropsStyle,
                            textAlign: "left",
                            borderRadius: "8px",
                          },
                        }}
                        InputLabelProps={{
                          shrink: true,
                          style: inputLabelPropsStyle,
                        }}
                        sx={{
                          "& .Mui-disabled": {
                            backgroundColor: "white !important",
                            color: "#044535 !important",
                          },
                          "& .MuiInputLabel-root ": {
                            color: "#044535 !important",
                          },
                        }}
                        label={t("gender")}
                        variant="standard"
                        margin="normal"
                        placeholder={t("gender")}
                        value={gender}
                        onChange={(e) => {
                          setGender(e.target.value);
                        }}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}
                      >
                        {genders.map((genderOption) => (
                          <MenuItem
                            key={genderOption.id}
                            value={genderOption.id}
                          >
                            {t(genderOption[i18next.language])}
                          </MenuItem>
                        ))}
                      </TextField>
                    </>
                  )}
                </Grid>
                <Grid item xs={6}>
                  {isLoading ? (
                    <>
                      <FormControl fullWidth margin="normal">
                        <InputLabel shrink style={inputLabelPropsStyle}>
                          {t("phone")}
                        </InputLabel>
                        <SkeletonTextField />
                      </FormControl>
                      <FormControl fullWidth margin="normal">
                        <InputLabel shrink style={inputLabelPropsStyle}>
                          {t("email")}
                        </InputLabel>
                        <SkeletonTextField />
                      </FormControl>
                    </>
                  ) : (
                    <>
                      <MuiTelInput
                        disabled={edicao}
                        fullWidth
                        defaultCountry="PT"
                        onChange={handleChange}
                        InputProps={{
                          disableUnderline: true,
                          style: inputPropsStyle,
                        }}
                        sx={{
                          "& .Mui-disabled": {
                            backgroundColor: "white !important",
                            color: "#044535 !important",
                          },
                          "& .MuiInputLabel-root ": {
                            color: "#044535 !important",
                          },
                        }}
                        variant="standard"
                        InputLabelProps={{
                          shrink: true,
                          style: inputLabelPropsStyle,
                        }}
                        label={t("phone")}
                        value={value}
                        margin="normal"
                      />
                      <TextField
                        fullWidth
                        disabled={edicao}
                        InputProps={{
                          disableUnderline: true,
                          style: inputPropsStyle,
                        }}
                        InputLabelProps={{
                          shrink: true,
                          style: inputLabelPropsStyle,
                        }}
                        sx={{
                          "& .Mui-disabled": {
                            backgroundColor: "white !important",
                            color: "#044535 !important",
                          },
                          "& .MuiInputLabel-root ": {
                            color: "#044535 !important",
                          },
                        }}
                        label={t("email")}
                        variant="standard"
                        margin="normal"
                        placeholder="example@email.com"
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        value={email}
                      />
                    </>
                  )}
                </Grid>
              </Grid>
            ) : (
              <></>
            )}

            {/* <Grid container spacing={2} padding={2}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  disabled={edicao}
                  InputProps={{
                    disableUnderline: true,
                    style: inputPropsStyle,
                    readOnly: true,
                    endAdornment: !edicao && (
                      <IconButton>
                        <EditIcon />
                      </IconButton>
                    ),
                  }}
                  InputLabelProps={{
                    shrink: true,
                    style: inputLabelPropsStyle,
                  }}
                  sx={{
                    "& .Mui-disabled": {
                      backgroundColor: "white !important",
                      color: "#044535 !important",
                    },
                    "& .MuiInputLabel-root ": {
                      color: "#044535 !important",
                    },
                  }}
                  label={t("stations")}
                  variant="standard"
                  margin="normal"
                  placeholder={t("stations")}
                  value={trainstations}
                />

                <TextField
                  fullWidth
                  disabled
                  multiline
                  rows={3}
                  InputProps={{
                    disableUnderline: true,
                    style: inputTrainStyle,
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                    style: inputLabelPropsStyle,
                  }}
                  sx={{
                    "& .Mui-disabled": {
                      backgroundColor: "white !important",
                      color: "#044535 !important",
                    },
                    "& .MuiInputLabel-root ": {
                      color: "#044535 !important",
                    },
                  }}
                  label={t("lasttrips")}
                  variant="standard"
                  margin="normal"
                  placeholder={t("lasttrips")}
                  value={`Barcelos-Braga\nBraga-Viana do Castelo\nPorto-Lisboa`}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  disabled={edicao}
                  InputProps={{
                    disableUnderline: true,
                    style: inputPropsStyle,
                    readOnly: true,
                    endAdornment: !edicao && (
                      <IconButton>
                        <EditIcon />
                      </IconButton>
                    ),
                  }}
                  InputLabelProps={{
                    shrink: true,
                    style: inputLabelPropsStyle,
                  }}
                  sx={{
                    "& .Mui-disabled": {
                      backgroundColor: "white !important",
                      color: "#044535 !important",
                    },
                    "& .MuiInputLabel-root ": {
                      color: "#044535 !important",
                    },
                  }}
                  label={t("preferences")}
                  variant="standard"
                  margin="normal"
                  placeholder={t("preferences")}
                  value={preferences}
                />
              </Grid>
            </Grid> */}
          </Container>
          <div className="bottomRowInfoClient">
            <button
              className="clientInfoEditButton"
              onClick={() => {
                if (edicao) {
                  setEdicao(!edicao);
                } else {
                  saveData();
                }
              }}
            >
              {edicao ? t("edit") : t("save")}
            </button>
            {!edicao ? (
              <button
                className="clientInfoCancelButton"
                onClick={() => {
                  setName(data.first_name);
                  setLastName(data.last_name);

                  if (data.first_name !== "" && data.last_name !== "") {
                    const avatar = {
                      sx: {
                        bgcolor: stringToColor(data.first_name),
                      },
                      children: `${data.first_name[0].toUpperCase()}`,
                    };
                    setAvatarData(avatar);
                  }
                  if (data.occupation) {
                    setOccupation(data.occupation);
                  }
                  setBirthDate(dayjs(data.birth_date));
                  setGender(data.gender);
                  setEmail(data.email);
                  let trainStationsArray = [];
                  data.train_station.forEach((element2) => {
                    trainstations.forEach((element) => {
                      if (String(element.id) === element2) {
                        trainStationsArray.push(element);
                      }
                    });
                  });
                  setTrainstationsUser(trainStationsArray);
                  setSelectedStations(trainStationsArray);
                  let cultureP = [];
                  let sportsP = [];
                  data.culture_preference.forEach((element) => {
                    culturalActivities.forEach((element2) => {
                      if (element === element2.id) {
                        cultureP.push(element2);
                      }
                    });
                  });
                  data.sports_preference.forEach((element) => {
                    sportsActivities.forEach((element2) => {
                      if (element === element2.id) {
                        sportsP.push(element2);
                      }
                    });
                  });

                  setSelectedCulturalActivities(cultureP);
                  setSelectedSportsActivities(sportsP);
                  if (data.photo && data.photo !== "") {
                    setPhoto(data.photo);
                  }

                  setPostalCode(data.zip_code);

                  const selectedCountry = countries.find(
                    (c) => c.id.toString() === data.country
                  );
                  setCountry(selectedCountry);
                  const selectedDistrict = districts.find(
                    (c) => c.id.toString() === data.district
                  );
                  setDistrict(selectedDistrict);

                  const selectedMun = municipalities.find(
                    (c) => c.id.toString() === data.municipality
                  );
                  setMunicipality(selectedMun);
                  setStatus(data.status);
                  setEdicao(!edicao);
                }}
              >
                {" "}
                {t("cancel")}
              </button>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClientPersonal;
