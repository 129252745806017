import Cookies from "js-cookie";
import {
  makeRequestDelete,
  makeRequestDelete2,
  makeRequestGet2,
  makeRequestGet,
  makeRequestPost,
  makeRequestPost2,
  makeRequestPut2,
} from "../api";

function getToken() {
  return new Promise((resolve) => {
    const checkToken = () => {
      let token = Cookies.get("token");
      if (token) {
        resolve(token);
      } else {
        setTimeout(checkToken, 100); // Check again after 100ms
      }
    };
    checkToken();
  });
}

export async function getOwners() {
  let token = await getToken();
  return makeRequestGet2(`owners/`, token);
}

export async function getOwnerLicenses(id) {
  return makeRequestGet(`owners1/licenses/${id}`,id);
}

