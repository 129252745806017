import {
  Avatar,
  BottomNavigation,
  BottomNavigationAction,
} from "@mui/material";
import { useNavigate } from "react-router";
import homeIcon from "../images/navIcons/home60.svg";
import homeIconWhite from "../images/navIcons/home100.svg";
import peopleIcon from "../images/navIcons/people60.svg";
import peopleIconWhite from "../images/navIcons/people100.svg";
import partyIcon from "../images/navIcons/party60.svg";
import partyIconWhite from "../images/navIcons/party100.svg";
import stadiumIcon from "../images/navIcons/stadium60.svg";
import stadiumIconWhite from "../images/navIcons/stadium100.svg";
import handsIcon from "../images/navIcons/hands60.svg";
import handsIconWhite from "../images/navIcons/hands100.svg";
import notesIcon from "../images/navIcons/notes60.svg";
import notesIconWhite from "../images/navIcons/notes100.svg";
import cupIcon from "../images/navIcons/cup60.svg";
import cupIconWhite from "../images/navIcons/cup100.svg";
import buildingIcon from "../images/navIcons/building60.svg";
import buildingIconWhite from "../images/navIcons/building100.svg";
import bagIcon from "../images/navIcons/bag60.svg";
import bagIconWhite from "../images/navIcons/bag100.svg";
import statsIcon from "../images/navIcons/stats60.svg";
import statsIconWhite from "../images/navIcons/stats100.svg";
import bookIcon from "../images/navIcons/book60.svg";
import bookIconWhite from "../images/navIcons/book100.svg";
import helpIcon from "../images/navIcons/help60.svg";
import helpIconWhite from "../images/navIcons/help100.svg";

function NavManagerHorizontal() {
  const navigate = useNavigate();

  function getPageIndex(route) {
    switch (route) {
      case "/dashboardmanager":
        return 0;
      case "/eventsmanager":
        return 1;
      case "/equipmentsmanager":
        return 2;
      case "/partnersmanager":
        return 3;
      case "/paymentsmanager":
        return 4;
      case "/promotersmanager":
        return 5;
      case "/statsmanager":
        return 6;
      case "/ordersmanager":
        return 7;
      case "/helpmanager":
        return 8;

      default:
        return 0;
    }
  }

  const value = getPageIndex(window.location.pathname);

  const handleChange = (event, newValue) => {
    if (newValue == 0) {
      navigate("/dashboardmanager");
    } else if (newValue == 1) {
      navigate("/eventsmanager");
    } else if (newValue == 2) {
      navigate("/equipmentsmanager");
    } else if (newValue == 3) {
      navigate("/partnersmanager");
    } else if (newValue == 4) {
      navigate("/paymentsmanager");
    } else if (newValue == 5) {
      navigate("/promotersmanager");
    } else if (newValue == 6) {
      navigate("/statsmanager");
    } else if (newValue == 7) {
      navigate("/ordersmanager");
    } else if (newValue == 8) {
      navigate("/helpmanager");
    }
  };

  return (
    <div className="navmanagerhorizontal">
      <BottomNavigation
        value={value}
        onChange={handleChange}
        style={{
          backgroundColor: "transparent",
          flexDirection: "row",
          // position: "absolute",
          flexWrap: "wrap",
          justifyContent: "center",
          marginBottom: "45px",
        }}
      >
        <BottomNavigationAction
          disableRipple
          icon={
            <span
              style={{
                width: 28,
                height: 30,
                backgroundImage:
                  value != 0 ? `url(${homeIcon})` : `url(${homeIconWhite})`,
              }}
            />
          }
          classes={{
            root: "compactNavigationAction",
          }}
        />

        <BottomNavigationAction
          disableRipple
          icon={
            <span
              style={{
                width: 30,
                height: 26,
                backgroundImage:
                  value != 1 ? `url(${partyIcon})` : `url(${partyIconWhite})`,
              }}
            />
          }
          classes={{
            root: "compactNavigationAction",
          }}
        />
        <BottomNavigationAction
          disableRipple
          icon={
            <span
              style={{
                width: 30,
                height: 30,
                backgroundImage:
                  value != 2
                    ? `url(${stadiumIcon})`
                    : `url(${stadiumIconWhite})`,
              }}
            />
          }
          classes={{
            root: "compactNavigationAction",
          }}
        />
        <BottomNavigationAction
          disableRipple
          icon={
            <span
              style={{
                width: 30,
                height: 28,
                backgroundImage:
                  value != 3 ? `url(${handsIcon})` : `url(${handsIconWhite})`,
              }}
            />
          }
          classes={{
            root: "compactNavigationAction",
          }}
        />
        <BottomNavigationAction
          disableRipple
          icon={
            <span
              style={{
                width: 30,
                height: 21,
                backgroundImage:
                  value != 4 ? `url(${notesIcon})` : `url(${notesIconWhite})`,
              }}
            />
          }
          classes={{
            root: "compactNavigationAction",
          }}
        />

        <BottomNavigationAction
          disableRipple
          icon={
            <span
              style={{
                width: 30,
                height: 28,
                backgroundImage:
                  value != 5 ? `url(${bagIcon})` : `url(${bagIconWhite})`,
              }}
            />
          }
          classes={{
            root: "compactNavigationAction",
          }}
        />
        <BottomNavigationAction
          disableRipple
          icon={
            <span
              style={{
                width: 30,
                height: 30,
                backgroundImage:
                  value != 6 ? `url(${statsIcon})` : `url(${statsIconWhite})`,
              }}
            />
          }
          classes={{
            root: "compactNavigationAction",
          }}
        />
        <BottomNavigationAction
          disableRipple
          icon={
            <span
              style={{
                width: 30,
                height: 21,
                backgroundImage:
                  value != 7 ? `url(${bookIcon})` : `url(${bookIconWhite})`,
              }}
            />
          }
          classes={{
            root: "compactNavigationAction",
          }}
        />

        <BottomNavigationAction
          disableRipple
          icon={
            <span
              style={{
                width: 22,
                height: 22,
                backgroundImage:
                  value != 8 ? `url(${helpIcon})` : `url(${helpIconWhite})`,
              }}
            />
          }
          // style={{
          //   position: "fixed",
          //   bottom: 10,
          // }}
          classes={{
            root: "compactNavigationAction",
          }}
        />
      </BottomNavigation>
    </div>
  );
}
export default NavManagerHorizontal;
