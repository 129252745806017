import React, { useEffect, useState } from "react";
import logo from "../../images/logo.svg";
import background from "../../images/background.png";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardContent,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  InputAdornment,
  Menu,
  MenuItem,
  TextField,
  Typography,
  Autocomplete,
  Checkbox,
  FormControlLabel,
  Switch,
  Skeleton,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "../../App.css";
import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";
import i18next from "i18next";
import Header from "../../components/header.js";
import NavAdmin from "../../components/navAdmin.js";
import dayjs from "dayjs";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import "dayjs/locale/pt";
import "dayjs/locale/en-gb";
import feira from "../../images/example/mmachado.jpeg";
import {
  parse,
  isAfter,
  isBefore,
  isSameDay,
  differenceInDays,
  isEqual,
  startOfDay,
} from "date-fns";
import Dropzone from "react-dropzone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faPen, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import {
  getActivities,
  getDistricts,
  getMunicipalities,
  getSubActivities,
  getTrainStations,
  getTypes,
} from "../../api/requests/Data.js";
import toast from "react-hot-toast";
import { getEvent, putEvent } from "../../api/requests/Event.js";
import { useLocation } from "react-router-dom";
import moment from "moment";
import {
  deletePromoters,
  getPromoter,
  getPromoters,
  putPromoters,
} from "../../api/requests/Promoters.js";
import {
  deleteCouncil,
  getCouncil,
  getCouncils,
  putCouncil,
} from "../../api/requests/Council.js";
import { getEquipments } from "../../api/requests/Equipment.js";
import Avatar from "@mui/material/Avatar";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { getManagers } from "../../api/requests/Managers.js";
import { getPlans } from "../../api/requests/PaymentPlans.js";
import { getCommercials } from "../../api/requests/Commercials.js";
import NavAdminMobile from "../../components/navAdminHorizontal.js";

function PromoterPageAdmin() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [edicao, setEdicao] = useState(true);
  const [editSubs, setEditSubs] = useState(true);
  const [trainstations, setTrainstations] = useState([]);
  const location = useLocation();
  
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const { promoterId } = location.state || {};

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenuOpen = (event) => {
    if (!edicao) {
      setAnchorEl(event.currentTarget);
    }
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  function goBack() {
    navigate("/promoteradmin");
  }
  const [openDialog, setOpenDialog] = useState(false);

  const [openDialogRegister, setOpenRegisterDialog] = useState(false);

  const trainStations = ["Lisboa Santa Apolónia", "Porto Campanhã", "Faro"];
  const preferences = [
    { id: 1, designation: "atletismo" },
    { id: 2, designation: "futebol" },
  ];

  const inputLabelPropsStyle = {
    color: "#044535 !important",
    textAlign: "right",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "22px",
  };

  const inputPropsStyle = {
    color: "#0F111199",
    textAlign: "right",
    fontFamily: "Montserrat",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "22px",
    borderRadius: "8px",
    background: "#0f11110d",
    paddingLeft: "10px",
    width:isMobile?"100%":"60%",
  };

  const inputTrainStyle = {
    color: "#0F1111",
    textAlign: "right",
    fontFamily: "Montserrat",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "22px",
    borderRadius: "8px",
    background: "white",
    width:isMobile?"100%":"60%",
  };

  const handleAllowRegisterChange = (promoter) => {
    setBooking(!booking);
  };

  const handleAllowPublicChange = (promoter) => {
    setAudience(!audience);
  };

  // const handleCouncilChange = (promoter) => {
  //   setCouncilId(promoter.target.value);
  // };

  const handleManagerChange = (promoter, newValue) => {
    if (newValue.length <= managerLimit) {
      setManagerId(newValue);
    }
  };

  const today = dayjs();

  const [audience, setAudience] = useState(true);
  const [audienceCapacity, setAudienceCapacity] = useState(0);
  const [audienceUrl, setAudienceUrl] = useState([]);
  const [booking, setBooking] = useState(true);
  const [bookingLimit, setBookingLimit] = useState(0);
  const [cancel, setCancel] = useState(false);
  const [cancelObs, setCancelObs] = useState("");
  const [district, setDistrict] = useState("");
  const [co2, setCo2] = useState([]);
  const [coordinates, setCoordinates] = useState([]);

  const [equipmentUrl, setEquipmentUrl] = useState("");
  const [free, setFree] = useState("");
  const [gallery, setGallery] = useState([]);
  const [licenses, setLicenses] = useState(0);
  const [planId, setPlanId] = useState("");
  const [plans, setPlans] = useState([]);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [VAT, setVAT] = useState("");
  const [postalCode, setPostalCode] = useState("");

  const [obs, setObs] = useState("");
  const [managerId, setManagerId] = useState([]);
  const [managerLimit, setManagerLimit] = useState("");

  const [typeId, setTypeId] = useState("");
  const [startRegDate, setStartRegDate] = useState(Date);
  const [modality, setModality] = useState("0");
  const [modalities, setModalities] = useState([]);
  const [modalitiesSelected, setModalitiesSelected] = useState([]);
  const [councilUrl, setCouncilUrl] = useState("");

  const [urlFacebook, setUrlFacebook] = useState("");
  const [urlInstagram, setUrlInstagram] = useState("");
  const [urlLinkedin, setUrlLinkedin] = useState("");
  const [urlTwitter, setUrlTwitter] = useState("");
  const [urlYoutube, setUrlYoutube] = useState("");
  const [url, setUrl] = useState("");
  const [video, setVideo] = useState([]);
  const [visible, setVisible] = useState(true);
  const [districts, setDistricts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [promoters, setPromoters] = useState([]);
  const [managers, setManagers] = useState([]);
  const [types, setTypes] = useState([]);
  const [cityCouncils, setCityCouncils] = useState([]);
  const [municipalities, setMunicipalities] = useState([]);
  const [municipalitiesCopy, setMunicipalitiesCopy] = useState([]);
  const [municipality, setMunicipality] = useState(null);
  const [culturalActivities, setCulturalActivities] = useState([]);
  const [sportsActivities, setSportsActivities] = useState([]);
  const [isLoadingDistricts, setLoadingDistricts] = useState(true);
  const [isLoadingTrainStations, setLoadingTrainStations] = useState(true);
  const [isLoadingManagers, setLoadingManagers] = useState(true);
  const [isLoadingTypes, setLoadingTypes] = useState(true);
  const [isLoadingModalities, setLoadingModalities] = useState(true);
  const [loadingMunicipalities, setLoadingMunicipalities] = useState(true);
  const [photo, setPhoto] = useState("");
  const [avatarData, setAvatarData] = useState(null);
  const [endDate, setEndDate] = useState(today);
  const [data, setData] = useState([]);
  const [status, setStatus] = useState(0);
  const [openDialog2, setOpenDialog2] = useState(false);
  const [openDialog3, setOpenDialog3] = useState(false);
  const [reason, setReason] = useState("");
  const [activities, setActivities] = useState([]);

  const handleChange = (newValue) => {
    matchIsValidTel(newValue, {
      onlyCountryies: [],
      excludedCountryies: [],
      continents: [],
    });
  };

  const handleChangeType = (event, newValue) => {
    setTypeId(newValue);
  };

  const handleSwitchChange = (event) => {
    setVisible(event.target.checked);
  };

  const handleModalityChange = (event, newValue) => {
    setModality(newValue);
  };
  const handleModalitiesChange = (event, newValue) => {
    setModalities(newValue);
  };

  const handleSuspend = () => {
    handleMenuClose();
    setOpenDialog2(true);
  };

  const handleActivate = () => {
    handleMenuClose();
    setOpenDialog3(true);
  };

  const handleDelete = () => {
    handleMenuClose();
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    // if (reason) {
    deletePromoters(promoterId)
      .then((res) => {
        if (res.success && res.status === 200) {
          navigate("/promoteradmin");
          toast.success(t("deleted"));
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error(t("500"));
      });
    // } else {
    //   toast.error(t("reasonError"));
    // }
  };

  const handleDialogRegisterClose = () => {
    setOpenRegisterDialog(false);
  };

  const confirmDelete = () => {
    setOpenDialog(false);
  };

  const [preview, setPreview] = useState(feira);
  const [preview1, setPreview1] = useState(feira);
  const [preview2, setPreview2] = useState(feira);
  const [preview3, setPreview3] = useState(feira);
  const [preview4, setPreview4] = useState(null);
  const [contract, setContract] = useState(null);
  const [contractType, setContractType] = useState("");
  const [contractUrl, setContractUrl] = useState(null);

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      const base64String = reader.result;
      setPhoto(base64String);
    };

    reader.readAsDataURL(file);
  };

  const onDropFile2 = (acceptedFiles) => {
    const file = acceptedFiles[0];
    setContract(file);

    // Determine o tipo do arquivo
    const fileType = file.type.includes("pdf") ? "pdf" : "image";
    setContractType(fileType);
  };

  useEffect(() => {
    if (contract) {
      // Converte o arquivo para base64
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result;
        setContractUrl(base64String);
      };

      reader.readAsDataURL(contract);
    }
  }, [contract]);

  const onDrop1 = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreview1(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const onDrop2 = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreview2(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const onDrop3 = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreview3(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const onDrop4 = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreview4(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleDistrictChange = (event, newValue) => {
    setDistrict(newValue);
  };
  const handleMunicipalityChange = (event, newValue) => {
    setMunicipality(newValue);
  };

  useEffect(() => {
    getTrainStations()
      .then((res) => {
        if (res.status === 200 && res.success) {
          setTrainstations(res.data);
          setLoadingTrainStations(false);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error(t("500"));
      });
    getDistricts()
      .then((res) => {
        if (res.status === 200 && res.success) {
          setDistricts(res.data);
          setLoadingDistricts(false);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error("500");
      });
    getPromoters()
      .then((res) => {
        if (res.status === 200 && res.success) {
          setPromoters(res.data);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error("500");
      });
    getManagers()
      .then((res) => {
        if (res.status === 200 && res.success) {
          setManagers(res.data);
          setLoadingManagers(false);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error("500");
      });
    getPlans()
      .then((res) => {
        if (res.status === 200 && res.success) {
          setPlans(res.data);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error("500");
      });
    getCouncils()
      .then((res) => {
        if (res.status === 200 && res.success) {
          setCityCouncils(res.data);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error("500");
      });
    getTypes()
      .then((res) => {
        if (res.status === 200 && res.success) {
          setTypes(res.data);
          setLoadingTypes(false);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error("500");
      });
      getMunicipalities()
      .then((res) => {
        if (res.status === 200 && res.success) {
          setMunicipalities(res.data);
          setMunicipalitiesCopy(res.data);
          setLoadingMunicipalities(false);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error(t("500"));
      });
    getActivities()
      .then((res) => {
        if (res.status === 200 && res.success) {
          let cultural = [];
          let sports = [];
          res.data.forEach((element) => {
            if (element.type_id == 1) {
              sports.push(element);
            } else if (element.type_id == 2) {
              cultural.push(element);
            }
          });
          setSportsActivities(sports);
          setCulturalActivities(cultural);
          setActivities(sports, cultural);
          setLoadingModalities(false);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error(t("500"));
      });
  }, []);

  useEffect(() => {
    // if (
    //   !isLoadingDistricts &&
    //   !isLoadingTrainStations &&
    //   !isLoadingManagers &&
    //   !isLoadingTypes &&
    //   !isLoadingModalities &&
    //   !loadingMunicipalities
    // ) {
      getPromoter(promoterId)
        .then((res) => {
          if (res.status === 200 && res.success) {
            
            let data = res.data;
            setData(data);
            setName(data.name);
            setEmail(data.email);
            setPostalCode(data.zip_code);
            setVAT(data.vat);
            setCoordinates(data.coordinates);
            setMunicipality(data.municipality[0]);
            setDistrict(data.district[0]);
            const managersF = [];
            managers.forEach((element1) => {
              data.manager.forEach((element2) => {
                if (element1._id == element2._id) {
                  managersF.push(element1);
                }
              });
            });
            setManagerId(managersF);
            const typesF = [];
            types.forEach((element1) => {
              data.type_id.forEach((element2) => {
                if (element1.id == element2) {
                  typesF.push(element1);
                }
              });
            });
            setTypeId(typesF);
            let modalitiesF = [];
            activities.forEach((element1) => {
              data.modalities.forEach((element2) => {
                if (element1.id == element2) {
                  modalitiesF.push(element1);
                }
              });
            });
            setModalities(modalitiesF);
            setManagerLimit(data.manager_limit);
            if (data.photo != "") {
              setPhoto(data.photo);
            }
            setPreview1(data.gallery[0]);
            setPreview2(data.gallery[1]);
            setPreview3(data.gallery[2]);
            setPreview4(data.gallery[3]);
            setUrlFacebook(data.url_facebook);
            setUrlInstagram(data.url_instagram);
            setUrlLinkedin(data.url_linkedin);
            setUrlTwitter(data.url_twitter);
            setCouncilUrl(data.url_council);
            setLicenses(data.licenses);
            setPlanId(data.plan_id);
            setContractUrl(data.contract);
            if (data.contract.includes("pdf")) {
              setContractType("pdf");
            } else {
              setContractType("image");
            }
            setVisible(data.visible);
            setStatus(data.status);
            setEndDate(dayjs(data.expired_date));
            setLoading(false);
          } else {
            toast.error(res.error);
            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);

          toast.error("500");
        });
    },
  // }, 
  [
    // isLoadingDistricts,
    // isLoadingTrainStations,
    // isLoadingManagers,
    // isLoadingTypes,
    // isLoadingModalities,
    // loadingMunicipalities
  ]);

  useEffect(() => {
    const avatar = {
      sx: {
        bgcolor: "#11bb55",
      },
      children: `Teste`,
    };
    setAvatarData(avatar);
  }, []);

  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  const [file, setFile] = useState(null);
  const [fileType, setFileType] = useState("");
  const [fileUrl, setFileUrl] = useState(null);

  const onDropFile = (acceptedFiles) => {
    const file = acceptedFiles[0];
    setFile(file);

    // Determine o tipo do arquivo
    const fileType = file.type.includes("pdf") ? "pdf" : "image";
    setFileType(fileType);
  };
  // useEffect(() => {
  //   if (file) {
  //     setFileUrl(URL.createObjectURL(file));
  //   }
  //   return () => {
  //     if (fileUrl) {
  //       URL.revokeObjectURL(fileUrl);
  //     }
  //   };
  // }, [file]);
  const dropzoneStyle = {
    border: "2px dashed #0087F7",
    borderRadius: "5px",
    padding: "20px",
    textAlign: "center",
    cursor: "pointer",
    width: "60%",
  };

  const imageStyle = {
    maxWidth: "60%",
    height: "100px",
  };

  function saveData() {
    if (!name) {
      toast.error(t("nameEqValidation"));
      return;
    }
    if (!email) {
      toast.error(t("emailValidation"));
      return;
    }
    if (!coordinates) {
      toast.error(t("coordinatesValidation"));
      return;
    }
    if (!managerId) {
      toast.error(t("managerValidation"));
      return;
    }
    if (!managerLimit) {
      toast.error(t("managerLimitValidation"));
      return;
    }
    if (!planId) {
      toast.error(t("planValidation"));
      return;
    }
    if (!licenses) {
      toast.error(t("licenseValidation"));
      return;
    }
    if (!contractUrl) {
      toast.error(t("contractValidation"));
      return;
    }
    if (!endDate) {
      toast.error(t("endDateValidation"));
      return;
    }
    if (typeId.length == 0) {
      toast.error(t("typeIdEqValidation"));
      return;
    }
    if (modalities.length == 0) {
      toast.error(t("modalityValidation"));
      return;
    }

    let typeF = [];
    typeId.forEach((element) => {
      typeF.push(element.id);
    });
    let modalitiesF = [];
    modalities.forEach((element) => {
      modalitiesF.push(element.id);
    });

    putPromoters(promoterId, {
      name: name,
      email: email,
      zip_code: postalCode,
      vat: VAT,
      coordinates: coordinates,
      district_id: district ? district.id : "",
      manager: managerId,
      manager_limit: managerLimit,
      photo: photo,
      gallery: [preview1, preview2, preview3, preview4],
      url_facebook: urlFacebook,
      url_instagram: urlInstagram,
      url_linkedin: urlLinkedin,
      url_twitter: urlTwitter,
      url_council: councilUrl,
      licenses: licenses,
      plan_id: planId,
      contract: contractUrl,
      municipality_id:municipality? municipality.id : "",
      visible: visible,
      status: status,
      expired_date: endDate ? dayjs(endDate).format("YYYY-MM-DD") : null,
      modalities: modalitiesF,
      type_id: typeF,
    })
      .then((res) => {
        if (res.success && res.status === 200) {
          toast.success(t("successMessage"));
          setEdicao(!edicao);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error(t("500"));
      });
  }

  function getActivitiesTypes(typeId) {
    let activities = [];

    const ids = Array.isArray(typeId) ? typeId.map((item) => item.id) : [];

    if (ids.includes("1")) {
      activities = activities.concat(sportsActivities);
    }

    if (ids.includes("2")) {
      activities = activities.concat(culturalActivities);
    }

    return activities;
  }

  const handleDialogClose2 = () => {
    if (reason) {
      putPromoters(promoterId, {
        status: 2,
        obs: reason,
      })
        .then((res) => {
          if (res.success && res.status === 200) {
            toast.success(t("successMessage"));
            setOpenDialog2(false);
            setStatus(2);
            setEdicao(!edicao);
          } else {
            toast.error(res.error);
          }
        })
        .catch((error) => {
          toast.error(t("500"));
        });
    } else {
      toast.error(t("reasonError"));
    }
  };

  const handleDialogClose3 = () => {
    if (reason) {
      putPromoters(promoterId, {
        status: 1,
        obs: reason,
      })
        .then((res) => {
          if (res.success && res.status === 200) {
            toast.success(t("successMessage"));
            setOpenDialog3(false);
            setStatus(1);
            setEdicao(!edicao);
          } else {
            toast.error(res.error);
          }
        })
        .catch((error) => {
          toast.error(t("500"));
        });
    } else {
      toast.error(t("reasonError"));
    }
  };

  function originData() {
    setName(data.name);
    setEmail(data.email);
    setPostalCode(data.zip_code);
    setVAT(data.vat);
    setMunicipality(data.municipality_id)
    setCoordinates(data.coordinates);
    setDistrict(data.district[0]);
    const managersF = [];
    managers.forEach((element1) => {
      data.manager.forEach((element2) => {
        if (element1._id == element2._id) {
          managersF.push(element1);
        }
      });
    });
    setManagerId(managersF);
    const typesF = [];
    types.forEach((element1) => {
      data.type_id.forEach((element2) => {
        if (element1.id == element2) {
          typesF.push(element1);
        }
      });
    });
    setTypeId(typesF);
    let modalitiesF = [];
    activities.forEach((element1) => {
      data.modalities.forEach((element2) => {
        if (element1.id == element2) {
          modalitiesF.push(element1);
        }
      });
    });
    setModalities(modalitiesF);
    setManagerLimit(data.manager_limit);
    if (data.photo != "") {
      setPhoto(data.photo);
    }
    setPreview1(data.gallery[0]);
    setPreview2(data.gallery[1]);
    setPreview3(data.gallery[2]);
    setPreview4(data.gallery[3]);
    setUrlFacebook(data.url_facebook);
    setUrlInstagram(data.url_instagram);
    setUrlLinkedin(data.url_linkedin);
    setUrlTwitter(data.url_twitter);
    setCouncilUrl(data.url_council);
    setLicenses(data.licenses);
    setPlanId(data.plan_id);
    setContractUrl(data.contract);
    if (data.contract.includes("pdf")) {
      setContractType("pdf");
    } else {
      setContractType("image");
    }
    setVisible(data.visible);
    setStatus(data.status);
    setEndDate(dayjs(data.expired_date));
  }
  
 
  return (
    <div className="background">
      <Header />
      {isMobile?<NavAdminMobile/>:<NavAdmin />}
      <div className="topLeftBack">
        <IconButton onClick={goBack}>
        <ArrowBackIcon
            style={{ fill: "#044535", zIndex: 99, fontSize:isMobile?"20px":"25px" }}
          />
        </IconButton>
      </div>
      <div className="centerpage">
        <a className="greenTitleClientData">{t("promoter")}</a>
        <div className="whiteInfoCard">
          {loading ? (
            <>
              <Skeleton
                variant="rectangular"
                width={"15%"}
                height={"80%"}
                sx={{ margin: "1%", borderRadius: "0.9375rem" }}
              />
              <div className="mainInfoClient" style={{ paddingTop: "10px" }}>
                <Skeleton
                  variant="text"
                  width={"70%"}
                  height={40}
                  sx={{ margin: "1%" }}
                />
                <Skeleton
                  variant="text"
                  width={150}
                  height={20}
                  sx={{ margin: "1%" }}
                />
                <Skeleton
                  variant="text"
                  width={150}
                  height={20}
                  sx={{ margin: "1%" }}
                />
                <Skeleton
                  variant="text"
                  width={150}
                  height={20}
                  sx={{ margin: "1%" }}
                />
              </div>
              <div
                className="mainInfoClient"
                style={{ paddingTop: "10px", width: "400px" }}
              >
                <Skeleton
                  variant="text"
                  width={100}
                  height={20}
                  sx={{ marginBottom: "1%" }}
                />
                <Skeleton variant="text" width={210} height={20} />
                <Skeleton
                  variant="text"
                  width={100}
                  height={20}
                  sx={{ marginTop: "1%" }}
                />
                <Skeleton variant="text" width={210} height={20} />
              </div>
            </>
          ) : (
            <>
              {loading ? (
                <Skeleton
                  className="imgClientCard"
                  variant="rectangular"
                  width={"15%"}
                  height={"80%"}
                />
              ) : photo !== "" ? (
                edicao ? (
                  <div className="profileColumn">
                    <div className="addProfile">
                      <img src={photo} className="addProfile" />
                    </div>
                  </div>
                ) : (
                  <div className="profileColumn">
                    <Dropzone onDrop={onDrop} accept="image/*" multiple={false}>
                      {({ getRootProps, getInputProps }) => (
                        <section>
                          <div className="addProfile" {...getRootProps()}>
                            <input {...getInputProps()} />
                            {!feira ? (
                              <div className="addProfilePhoto">
                                <FontAwesomeIcon
                                  style={{
                                    fontSize: "16px",
                                    color: "rgba(15, 17, 17, 0.60)",
                                  }}
                                  icon={faPen}
                                />
                              </div>
                            ) : (
                              <div className="addProfileEdit">
                                <img src={photo} className="addProfile" />
                                <div className="overlay">
                                  <FontAwesomeIcon
                                    className="hoverIcon"
                                    icon={faPen}
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        </section>
                      )}
                    </Dropzone>
                  </div>
                )
              ) : avatarData ? (
                <div className="profileColumn">
                  {!photo && !edicao ? (
                    <Dropzone onDrop={onDrop} accept="image/*" multiple={false}>
                      {({ getRootProps, getInputProps }) => (
                        <section>
                          <div className="addProfile" {...getRootProps()}>
                            <input {...getInputProps()} />

                            <div className="addProfilePhoto">
                              <FontAwesomeIcon
                                style={{
                                  fontSize: "16px",
                                  color: "rgba(15, 17, 17, 0.60)",
                                }}
                                icon={faPen}
                              />
                            </div>
                          </div>
                        </section>
                      )}
                    </Dropzone>
                  ) : (
                    <div className="addProfileEdit">
                      <Avatar className="imgClientCard" sx={avatarData.sx}>
                        {avatarData.children}
                      </Avatar>
                      {/* <div className="overlay">
                  <FontAwesomeIcon
                    className="hoverIcon"
                    icon={faPen}
                  />
                </div> */}
                    </div>
                  )}
                </div>
              ) : (
                <Avatar className="imgClientCard" sx={avatarData.sx}>
                  {avatarData.children}
                </Avatar>
              )}
              <div className="mainInfoClient" style={{ paddingTop: "10px" }}>
                <a className="infoClientName">{name}</a>
                <a className="infoEventText">{email}</a>
                <a className="infoEventText">
                  {district && district[i18next.language]}
                </a>
              </div>

              {/* <div className="infoClientTopRight">
            <a className="infoIdText">id:03</a>
          </div> */}
              <div className="infoClientBottomRight">
                <a
                  className={edicao ? "activeText" : "activeTextEdit"}
                  onClick={handleMenuOpen}
                >
                  {status == 1 ? t("active") : t("suspended")}
                  {!edicao ? <a className="arrowDown">▼</a> : null}
                </a>
              </div>
              <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                sx={{
                  background: "transparent",

                  borderColor: "transparent",
                  marginTop: "25px",
                  width: "214px",
                  height: "auto",
                  borderRadius: "8px !important",
                }}
                MenuListProps={{
                  style: {
                    padding: "8px 0",
                    backgroundColor: "#F3F3F3",
                    borderRadius: "8px !important",
                  },
                }}
              >
                {status === 1 && (
                  <>
                    <MenuItem
                      onClick={handleSuspend}
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        marginBottom: "5px !important",
                        fontSize: "18px",
                        fontWeight: 600,
                        fontStyle: "normal",
                        fontFamily: "Montserrat",
                        color: "#044535",
                      }}
                    >
                      {t("suspend")}
                    </MenuItem>
                    <MenuItem
                      onClick={handleDelete}
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        marginBottom: "5px !important",
                        fontSize: "18px",
                        fontWeight: 600,
                        fontStyle: "normal",
                        fontFamily: "Montserrat",
                        color: "#044535",
                      }}
                    >
                      {t("delete")}
                    </MenuItem>
                  </>
                )}
                {status === 2 && (
                  <>
                    <MenuItem
                      onClick={handleActivate}
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        marginBottom: "5px !important",
                        fontSize: "18px",
                        fontWeight: 600,
                        fontStyle: "normal",
                        fontFamily: "Montserrat",
                        color: "#044535",
                      }}
                    >
                      {t("activate")}
                    </MenuItem>
                    <MenuItem
                      onClick={handleDelete}
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        marginBottom: "5px !important",
                        fontSize: "18px",
                        fontWeight: 600,
                        fontStyle: "normal",
                        fontFamily: "Montserrat",
                        color: "#044535",
                      }}
                    >
                      {t("delete")}
                    </MenuItem>
                  </>
                )}
              </Menu>
              <Dialog
                open={openDialog}
                onClose={handleDialogClose}
                PaperProps={{ style: { borderRadius: 15 } }}
              >
                <DialogContent
                  style={{
                    borderRadius: "15px !important",
                    background: "#FFF",
                  }}
                >
                  <DialogContentText
                    style={{
                      background: "#FFF",
                      marginTop: "20px",
                      marginBottom: "10px",

                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <a className="textCancel"> {t("deletePromoter")}</a>
                  </DialogContentText>
                  {/* <TextField
                    required
                    variant="outlined"
                    placeholder={t("reason")}
                    multiline
                    rows={2}
                    style={{
                      marginTop: "10px",
                      marginBottom: "20px",
                      width: "100%",
                      alignSelf: "center",
                    }}
                    onChange={(e) => {
                      setReason(e.target.value);
                    }}
                    inputProps={{ maxLength: 255 }}
                  /> */}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Button onClick={handleDialogClose} className="deleteBtn">
                      {t("delete")}
                    </Button>
                    <Button
                      onClick={() => {
                        setOpenDialog(false);
                      }}
                      className="cancelBtn"
                    >
                      {t("cancel")}
                    </Button>
                  </div>
                </DialogContent>
              </Dialog>
              <Dialog
                open={openDialog2}
                onClose={handleDialogClose2}
                PaperProps={{ style: { borderRadius: 15 } }}
              >
                <DialogContent
                  style={{
                    borderRadius: "15px !important",
                    background: "#FFF",
                  }}
                >
                  <DialogContentText
                    style={{
                      background: "#FFF",
                      marginTop: "20px",
                      marginBottom: "10px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <a className="textCancel"> {t("suspendPromoter")}</a>
                  </DialogContentText>

                  <TextField
                    required
                    variant="outlined"
                    placeholder={t("reason")}
                    multiline
                    rows={2}
                    style={{
                      marginTop: "10px",
                      marginBottom: "20px",
                      width: "100%",
                      alignSelf: "center",
                    }}
                    onChange={(e) => {
                      setReason(e.target.value);
                    }}
                    inputProps={{ maxLength: 255 }}
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Button onClick={handleDialogClose2} className="suspendBtn">
                      {t("suspend")}
                    </Button>
                    <Button
                      onClick={() => {
                        setOpenDialog2(false);
                      }}
                      className="cancelBtn"
                    >
                      {t("cancel")}
                    </Button>
                  </div>
                </DialogContent>
              </Dialog>
              <Dialog
                open={openDialog3}
                onClose={handleDialogClose3}
                PaperProps={{ style: { borderRadius: 15 } }}
              >
                <DialogContent
                  style={{
                    borderRadius: "15px !important",
                    background: "#FFF",
                  }}
                >
                  <DialogContentText
                    style={{
                      background: "#FFF",
                      marginTop: "20px",
                      marginBottom: "10px",

                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <a className="textCancel"> {t("activatePromoter")}</a>
                  </DialogContentText>
                  <TextField
                    required
                    variant="outlined"
                    placeholder={t("reason")}
                    multiline
                    rows={2}
                    style={{
                      marginTop: "10px",
                      marginBottom: "20px",
                      width: "100%",
                      alignSelf: "center",
                    }}
                    onChange={(e) => {
                      setReason(e.target.value);
                    }}
                    inputProps={{ maxLength: 255 }}
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Button
                      onClick={handleDialogClose3}
                      className="activateBtn"
                    >
                      {t("activate")}
                    </Button>
                    <Button
                      onClick={() => {
                        setOpenDialog3(false);
                      }}
                      className="cancelBtn"
                    >
                      {t("cancel")}
                    </Button>
                  </div>
                </DialogContent>
              </Dialog>
            </>
          )}
        </div>
        <div className="whiteEditInfoCard">
          <Container
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              flexDirection: "column",
              maxWidth: "none",
              maxHeight: "80%",
              overflow: "auto",
            }}
          >
            {isMobile?<div className="photosEvent">
                  <div>
                    <a className="photoLabel">{t("photos")}</a>
                    <div className="photoRow">
                      {edicao ? (
                        <>
                          {loading ? (
                            <Skeleton
                              variant="rectangular"
                              width={"4.0625rem"}
                              height={"4.0625rem"}
                              sx={{
                                borderRadius: "0.625rem",
                                marginRight: "10px",
                              }}
                            />
                          ) : preview1 != null ? (
                            <div
                              className="addPoster"
                              style={{ marginRight: "10px" }}
                            >
                              <img src={preview1} className="addPoster" />
                            </div>
                          ) : null}
                          {loading ? (
                            <Skeleton
                              variant="rectangular"
                              width={"4.0625rem"}
                              height={"4.0625rem"}
                              sx={{
                                borderRadius: "0.625rem",
                                marginRight: "10px",
                              }}
                            />
                          ) : preview2 != null ? (
                            <div
                              className="addPoster"
                              style={{ marginRight: "10px" }}
                            >
                              <img src={preview2} className="addPoster" />
                            </div>
                          ) : null}
                          {loading ? (
                            <Skeleton
                              variant="rectangular"
                              width={"4.0625rem"}
                              height={"4.0625rem"}
                              sx={{
                                borderRadius: "0.625rem",
                                marginRight: "10px",
                              }}
                            />
                          ) : preview3 != null ? (
                            <div
                              className="addPoster"
                              style={{ marginRight: "10px" }}
                            >
                              <img src={preview3} className="addPoster" />
                            </div>
                          ) : null}
                          {loading ? (
                            <Skeleton
                              variant="rectangular"
                              width={"4.0625rem"}
                              height={"4.0625rem"}
                              sx={{
                                borderRadius: "0.625rem",
                                marginRight: "10px",
                              }}
                            />
                          ) : preview4 != null ? (
                            <div
                              className="addPoster"
                              style={{ marginRight: "10px" }}
                            >
                              <img src={preview4} className="addPoster" />
                            </div>
                          ) : null}
                        </>
                      ) : (
                        <>
                          <div
                            style={{ marginRight: "10px" }}
                            onClick={() => {
                              setPreview1(null);
                            }}
                          >
                            <Dropzone
                              onDrop={onDrop1}
                              disabled={preview1 ? true : false}
                              accept="image/*"
                              multiple={false}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <section>
                                  <div
                                    className="addPoster"
                                    {...getRootProps()}
                                  >
                                    <input {...getInputProps()} />
                                    {!preview1 ? (
                                      <div
                                        className="addPhoto"
                                        style={{ marginRight: "10px" }}
                                      >
                                        <FontAwesomeIcon
                                          style={{
                                            fontSize: "16px",
                                            color: "rgba(15, 17, 17, 0.60)",
                                          }}
                                          icon={faPlus}
                                        />
                                      </div>
                                    ) : (
                                      <div
                                        className="addPosterEdit"
                                        style={{
                                          marginRight: "10px !important",
                                        }}
                                      >
                                        <img
                                          src={preview1}
                                          className="addPoster"
                                        />
                                        <div className="overlay">
                                          <FontAwesomeIcon
                                            className="hoverIcon"
                                            icon={faTrashCan}
                                          />
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </section>
                              )}
                            </Dropzone>
                          </div>
                          <div
                            style={{ marginRight: "10px" }}
                            onClick={() => {
                              setPreview2(null);
                            }}
                          >
                            <Dropzone
                              onDrop={onDrop2}
                              disabled={preview2 ? true : false}
                              accept="image/*"
                              multiple={false}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <section>
                                  <div
                                    className="addPoster"
                                    {...getRootProps()}
                                  >
                                    <input {...getInputProps()} />
                                    {!preview2 ? (
                                      <div
                                        className="addPhoto"
                                        style={{ marginRight: "10px" }}
                                      >
                                        <FontAwesomeIcon
                                          style={{
                                            fontSize: "16px",
                                            color: "rgba(15, 17, 17, 0.60)",
                                          }}
                                          icon={faPlus}
                                        />
                                      </div>
                                    ) : (
                                      <div
                                        className="addPosterEdit"
                                        style={{
                                          marginRight: "10px !important",
                                        }}
                                      >
                                        <img
                                          src={preview2}
                                          className="addPoster"
                                        />
                                        <div className="overlay">
                                          <FontAwesomeIcon
                                            className="hoverIcon"
                                            icon={faTrashCan}
                                          />
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </section>
                              )}
                            </Dropzone>
                          </div>
                          <div
                            style={{ marginRight: "10px" }}
                            onClick={() => {
                              setPreview3(null);
                            }}
                          >
                            <Dropzone
                              onDrop={onDrop3}
                              accept="image/*"
                              disabled={preview3 ? true : false}
                              multiple={false}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <section>
                                  <div
                                    className="addPoster"
                                    {...getRootProps()}
                                  >
                                    <input {...getInputProps()} />
                                    {!preview3 ? (
                                      <div
                                        className="addPhoto"
                                        style={{ marginRight: "10px" }}
                                      >
                                        <FontAwesomeIcon
                                          style={{
                                            fontSize: "16px",
                                            color: "rgba(15, 17, 17, 0.60)",
                                          }}
                                          icon={faPlus}
                                        />
                                      </div>
                                    ) : (
                                      <div
                                        className="addPosterEdit"
                                        style={{
                                          marginRight: "10px !important",
                                        }}
                                      >
                                        <img
                                          src={preview3}
                                          className="addPoster"
                                        />
                                        <div className="overlay">
                                          <FontAwesomeIcon
                                            className="hoverIcon"
                                            icon={faTrashCan}
                                          />
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </section>
                              )}
                            </Dropzone>
                          </div>
                          <div
                            style={{ marginRight: "10px" }}
                            onClick={() => {
                              setPreview4(null);
                            }}
                          >
                            <Dropzone
                              onDrop={onDrop4}
                              accept="image/*"
                              multiple={false}
                              disabled={preview4 ? true : false}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <section>
                                  <div
                                    className="addPoster"
                                    {...getRootProps()}
                                  >
                                    <input {...getInputProps()} />
                                    {!preview4 ? (
                                      <div
                                        className="addPhoto"
                                        style={{ marginRight: "10px" }}
                                      >
                                        <FontAwesomeIcon
                                          style={{
                                            fontSize: "16px",
                                            color: "rgba(15, 17, 17, 0.60)",
                                          }}
                                          icon={faPlus}
                                        />
                                      </div>
                                    ) : (
                                      <div
                                        className="addPosterEdit"
                                        style={{
                                          marginRight: "10px !important",
                                        }}
                                      >
                                        <img
                                          src={preview4}
                                          className="addPoster"
                                        />
                                        <div className="overlay">
                                          <FontAwesomeIcon
                                            className="hoverIcon"
                                            icon={faTrashCan}
                                          />
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </section>
                              )}
                            </Dropzone>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>:null}
            <Grid container spacing={2} padding={2}>
              <Grid item xs={6}>
                {isMobile?null:<div className="photosEvent">
                  <div>
                    <a className="photoLabel">{t("photos")}</a>
                    <div className="photoRow">
                      {edicao ? (
                        <>
                          {loading ? (
                            <Skeleton
                              variant="rectangular"
                              width={"4.0625rem"}
                              height={"4.0625rem"}
                              sx={{
                                borderRadius: "0.625rem",
                                marginRight: "10px",
                              }}
                            />
                          ) : preview1 != null ? (
                            <div
                              className="addPoster"
                              style={{ marginRight: "10px" }}
                            >
                              <img src={preview1} className="addPoster" />
                            </div>
                          ) : null}
                          {loading ? (
                            <Skeleton
                              variant="rectangular"
                              width={"4.0625rem"}
                              height={"4.0625rem"}
                              sx={{
                                borderRadius: "0.625rem",
                                marginRight: "10px",
                              }}
                            />
                          ) : preview2 != null ? (
                            <div
                              className="addPoster"
                              style={{ marginRight: "10px" }}
                            >
                              <img src={preview2} className="addPoster" />
                            </div>
                          ) : null}
                          {loading ? (
                            <Skeleton
                              variant="rectangular"
                              width={"4.0625rem"}
                              height={"4.0625rem"}
                              sx={{
                                borderRadius: "0.625rem",
                                marginRight: "10px",
                              }}
                            />
                          ) : preview3 != null ? (
                            <div
                              className="addPoster"
                              style={{ marginRight: "10px" }}
                            >
                              <img src={preview3} className="addPoster" />
                            </div>
                          ) : null}
                          {loading ? (
                            <Skeleton
                              variant="rectangular"
                              width={"4.0625rem"}
                              height={"4.0625rem"}
                              sx={{
                                borderRadius: "0.625rem",
                                marginRight: "10px",
                              }}
                            />
                          ) : preview4 != null ? (
                            <div
                              className="addPoster"
                              style={{ marginRight: "10px" }}
                            >
                              <img src={preview4} className="addPoster" />
                            </div>
                          ) : null}
                        </>
                      ) : (
                        <>
                          <div
                            style={{ marginRight: "10px" }}
                            onClick={() => {
                              setPreview1(null);
                            }}
                          >
                            <Dropzone
                              onDrop={onDrop1}
                              disabled={preview1 ? true : false}
                              accept="image/*"
                              multiple={false}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <section>
                                  <div
                                    className="addPoster"
                                    {...getRootProps()}
                                  >
                                    <input {...getInputProps()} />
                                    {!preview1 ? (
                                      <div
                                        className="addPhoto"
                                        style={{ marginRight: "10px" }}
                                      >
                                        <FontAwesomeIcon
                                          style={{
                                            fontSize: "16px",
                                            color: "rgba(15, 17, 17, 0.60)",
                                          }}
                                          icon={faPlus}
                                        />
                                      </div>
                                    ) : (
                                      <div
                                        className="addPosterEdit"
                                        style={{
                                          marginRight: "10px !important",
                                        }}
                                      >
                                        <img
                                          src={preview1}
                                          className="addPoster"
                                        />
                                        <div className="overlay">
                                          <FontAwesomeIcon
                                            className="hoverIcon"
                                            icon={faTrashCan}
                                          />
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </section>
                              )}
                            </Dropzone>
                          </div>
                          <div
                            style={{ marginRight: "10px" }}
                            onClick={() => {
                              setPreview2(null);
                            }}
                          >
                            <Dropzone
                              onDrop={onDrop2}
                              disabled={preview2 ? true : false}
                              accept="image/*"
                              multiple={false}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <section>
                                  <div
                                    className="addPoster"
                                    {...getRootProps()}
                                  >
                                    <input {...getInputProps()} />
                                    {!preview2 ? (
                                      <div
                                        className="addPhoto"
                                        style={{ marginRight: "10px" }}
                                      >
                                        <FontAwesomeIcon
                                          style={{
                                            fontSize: "16px",
                                            color: "rgba(15, 17, 17, 0.60)",
                                          }}
                                          icon={faPlus}
                                        />
                                      </div>
                                    ) : (
                                      <div
                                        className="addPosterEdit"
                                        style={{
                                          marginRight: "10px !important",
                                        }}
                                      >
                                        <img
                                          src={preview2}
                                          className="addPoster"
                                        />
                                        <div className="overlay">
                                          <FontAwesomeIcon
                                            className="hoverIcon"
                                            icon={faTrashCan}
                                          />
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </section>
                              )}
                            </Dropzone>
                          </div>
                          <div
                            style={{ marginRight: "10px" }}
                            onClick={() => {
                              setPreview3(null);
                            }}
                          >
                            <Dropzone
                              onDrop={onDrop3}
                              accept="image/*"
                              disabled={preview3 ? true : false}
                              multiple={false}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <section>
                                  <div
                                    className="addPoster"
                                    {...getRootProps()}
                                  >
                                    <input {...getInputProps()} />
                                    {!preview3 ? (
                                      <div
                                        className="addPhoto"
                                        style={{ marginRight: "10px" }}
                                      >
                                        <FontAwesomeIcon
                                          style={{
                                            fontSize: "16px",
                                            color: "rgba(15, 17, 17, 0.60)",
                                          }}
                                          icon={faPlus}
                                        />
                                      </div>
                                    ) : (
                                      <div
                                        className="addPosterEdit"
                                        style={{
                                          marginRight: "10px !important",
                                        }}
                                      >
                                        <img
                                          src={preview3}
                                          className="addPoster"
                                        />
                                        <div className="overlay">
                                          <FontAwesomeIcon
                                            className="hoverIcon"
                                            icon={faTrashCan}
                                          />
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </section>
                              )}
                            </Dropzone>
                          </div>
                          <div
                            style={{ marginRight: "10px" }}
                            onClick={() => {
                              setPreview4(null);
                            }}
                          >
                            <Dropzone
                              onDrop={onDrop4}
                              accept="image/*"
                              multiple={false}
                              disabled={preview4 ? true : false}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <section>
                                  <div
                                    className="addPoster"
                                    {...getRootProps()}
                                  >
                                    <input {...getInputProps()} />
                                    {!preview4 ? (
                                      <div
                                        className="addPhoto"
                                        style={{ marginRight: "10px" }}
                                      >
                                        <FontAwesomeIcon
                                          style={{
                                            fontSize: "16px",
                                            color: "rgba(15, 17, 17, 0.60)",
                                          }}
                                          icon={faPlus}
                                        />
                                      </div>
                                    ) : (
                                      <div
                                        className="addPosterEdit"
                                        style={{
                                          marginRight: "10px !important",
                                        }}
                                      >
                                        <img
                                          src={preview4}
                                          className="addPoster"
                                        />
                                        <div className="overlay">
                                          <FontAwesomeIcon
                                            className="hoverIcon"
                                            icon={faTrashCan}
                                          />
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </section>
                              )}
                            </Dropzone>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>}
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("name")}*</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width={isMobile?"100%":"60%"}
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      disabled={edicao}
                      InputProps={{
                        disableUnderline: true,
                        style: inputPropsStyle,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                      value={name}
                    />
                  )}
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("email")}*</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width={isMobile?"100%":"60%"}
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      disabled={edicao}
                      InputProps={{
                        disableUnderline: true,
                        style: inputPropsStyle,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      value={email}
                    />
                  )}
                </div>

                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("district")}</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width={isMobile?"100%":"60%"}
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <Autocomplete
                      disabled={edicao}
                      options={districts}
                      getOptionLabel={(option) =>
                        option[i18next.language] || option.en
                      }
                      value={district}
                      onChange={handleDistrictChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          disabled={edicao}
                          InputProps={{
                            ...params.InputProps,
                            disableUnderline: true,
                            style: {
                              ...inputPropsStyle,
                              textAlign: "left",
                              borderRadius: "8px",
                            },
                          }}
                          InputLabelProps={{
                            shrink: true,
                            style: inputLabelPropsStyle,
                          }}
                          sx={{
                            "& .Mui-disabled": {
                              backgroundColor: "white !important",
                              color: "#044535 !important",
                            },
                            "& .MuiInputLabel-root": {
                              color: "#044535 !important",
                            },
                          }}
                          variant="standard"
                        />
                      )}
                      renderOption={(props, option) => (
                        <li {...props} key={option.id}>
                          {option[i18next.language] || option.en}
                        </li>
                      )}
                      sx={{
                        "& .MuiAutocomplete-popupIndicator": {
                          display: "none",
                        },
                        "& .MuiAutocomplete-clearIndicator": {
                          display: "none",
                        },
                        "& .MuiOutlinedInput-root": {
                          padding: 0,
                        },
                        "& .MuiAutocomplete-listbox": {
                          fontFamily: "Montserrat",
                          fontSize: "14px",
                        },
                        "& .MuiAutocomplete-inputRoot": {
                          padding: "0 !important",
                        },
                      }}
                    />
                  )}
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("municipality")}</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width={isMobile?"100%":"60%"}
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <Autocomplete
                      disabled={edicao}
                      options={municipalities}
                      getOptionLabel={(option) =>
                        option[i18next.language] || option.en
                      }
                      value={municipality}
                      onChange={handleMunicipalityChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          disabled={edicao}
                          InputProps={{
                            ...params.InputProps,
                            disableUnderline: true,
                            style: {
                              ...inputPropsStyle,
                              textAlign: "left",
                              borderRadius: "8px",
                            },
                          }}
                          InputLabelProps={{
                            shrink: true,
                            style: inputLabelPropsStyle,
                          }}
                          sx={{
                            "& .Mui-disabled": {
                              backgroundColor: "white !important",
                              color: "#044535 !important",
                            },
                            "& .MuiInputLabel-root": {
                              color: "#044535 !important",
                            },
                          }}
                          variant="standard"
                        />
                      )}
                      renderOption={(props, option) => (
                        <li {...props} key={option.id}>
                          {option[i18next.language] || option.en}
                        </li>
                      )}
                      sx={{
                        "& .MuiAutocomplete-popupIndicator": {
                          display: "none",
                        },
                        "& .MuiAutocomplete-clearIndicator": {
                          display: "none",
                        },
                        "& .MuiOutlinedInput-root": {
                          padding: 0,
                        },
                        "& .MuiAutocomplete-listbox": {
                          fontFamily: "Montserrat",
                          fontSize: "14px",
                        },
                        "& .MuiAutocomplete-inputRoot": {
                          padding: "0 !important",
                        },
                      }}
                    />
                  )}
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("postalCode")}</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width={isMobile?"100%":"60%"}
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      disabled={edicao}
                      InputProps={{
                        disableUnderline: true,
                        style: inputPropsStyle,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      onChange={(e) => {
                        setPostalCode(e.target.value);
                      }}
                      value={postalCode}
                    />
                  )}
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("coordinates")}*</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width={isMobile?"100%":"60%"}
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      disabled={edicao}
                      InputProps={{
                        disableUnderline: true,
                        style: inputPropsStyle,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      value={coordinates}
                      onChange={(e) => {
                        setCoordinates(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("VAT")}</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width={isMobile?"100%":"60%"}
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      disabled={edicao}
                      InputProps={{
                        disableUnderline: true,
                        style: inputPropsStyle,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      onChange={(e) => {
                        setVAT(e.target.value);
                      }}
                      value={VAT}
                    />
                  )}
                </div>

                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("managers")}*</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width={isMobile?"100%":"60%"}
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <Autocomplete
                      multiple
                      disabled={edicao}
                      options={managers}
                      getOptionLabel={(option) =>
                        option.first_name + " " + option.last_name
                      }
                      value={Array.isArray(managerId) ? managerId : []} // managerIds agora é um array
                      onChange={handleManagerChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          disabled={edicao}
                          InputProps={{
                            ...params.InputProps,
                            disableUnderline: true,
                            style: {
                              textAlign: "left",
                              borderRadius: "8px",
                              color: "#0F111199",
                              fontFamily: "Montserrat",
                              fontSize: "18px",
                              fontStyle: "normal",
                              fontWeight: 500,
                              lineHeight: "22px",
                              borderRadius: "8px",
                              background: "#0f11110d",
                              paddingLeft: "20px",
                              width:isMobile?"100%": "60%",
                            },
                          }}
                          InputLabelProps={{
                            shrink: true,
                            style: inputLabelPropsStyle,
                          }}
                          sx={{
                            "& .Mui-disabled": {
                              backgroundColor: "white !important",
                              color: "#044535 !important",
                            },
                            "& .MuiInputLabel-root": {
                              color: "#044535 !important",
                            },
                          }}
                          variant="standard"
                          margin="normal"
                          // placeholder={t("manager")}
                        />
                      )}
                      renderOption={(props, option) => (
                        <li {...props} key={option._id}>
                          {option.first_name} {option.last_name}
                        </li>
                      )}
                      sx={{
                        "& .MuiAutocomplete-popupIndicator": {
                          display: "none",
                        },
                        "& .MuiAutocomplete-clearIndicator": {
                          display: "none",
                        },
                        "& .MuiOutlinedInput-root": {
                          padding: 0,
                        },
                        "& .MuiAutocomplete-listbox": {
                          fontFamily: "Montserrat",
                          fontSize: "14px",
                        },
                        "& .MuiAutocomplete-inputRoot": {
                          padding: "0 !important",
                        },
                      }}
                    />
                  )}
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("managerLimit")}*</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width={isMobile?"100%":"60%"}
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      disabled={edicao}
                      type="number"
                      InputProps={{
                        disableUnderline: true,
                        style: {
                          ...inputPropsStyle,
                          textAlign: "left",
                          borderRadius: "8px",
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      value={managerLimit}
                      onChange={(e) => {
                        setManagerLimit(e.target.value);
                      }}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                    />
                  )}
                </div>
              </Grid>
              <Grid item xs={6}>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("visibility")}</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width={isMobile?"100%":"60%"}
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <FormControlLabel
                      control={
                        <Switch
                          disabled={edicao}
                          checked={visible}
                          onChange={handleSwitchChange}
                          color="primary"
                          sx={{
                            "& .MuiSwitch-switchBase.Mui-checked": {
                              color: "#FFFFFF !important",
                            },
                            "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                              {
                                backgroundColor: "#13BB56 !important",
                                opacity: "1 !important",
                              },
                          }}
                        />
                      }
                      label={t("visible")}
                      style={{ marginTop: "20px" }}
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          flexGrow: 1,
                          textAlign: "left",
                          marginLeft: "10px",
                          color: "#0F1111",
                          fontFamily: "Montserrat",
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: 600,
                          lineHeight: "normal",
                        },
                      }}
                    />
                  )}
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("plan")}*</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width={isMobile?"100%":"60%"}
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      disabled={edicao}
                      select
                      InputProps={{
                        disableUnderline: true,
                        style: {
                          ...inputPropsStyle,
                          textAlign: "left",
                          borderRadius: "8px",
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      value={planId}
                      onChange={(e) => {
                        setPlanId(e.target.value);
                      }}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                    >
                      {plans.map((plan) => (
                        <MenuItem key={plan._id} value={plan.uuid}>
                          {plan[i18next.language]}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("licenses")}*</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width={isMobile?"100%":"60%"}
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      disabled={edicao}
                      type="number"
                      InputProps={{
                        disableUnderline: true,
                        style: {
                          ...inputPropsStyle,
                          textAlign: "left",
                          borderRadius: "8px",
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      value={licenses}
                      onChange={(e) => {
                        setLicenses(e.target.value);
                      }}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                    />
                  )}
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("urlCouncil")}</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width={isMobile?"100%":"60%"}
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      disabled={edicao}
                      InputProps={{
                        disableUnderline: true,
                        style: inputPropsStyle,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      onChange={(e) => {
                        setCouncilUrl(e.target.value);
                      }}
                      value={councilUrl}
                    />
                  )}
                </div>

                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("equipmentFacebook")}</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width={isMobile?"100%":"60%"}
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      disabled={edicao}
                      InputProps={{
                        disableUnderline: true,
                        style: inputPropsStyle,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      onChange={(e) => {
                        setUrlFacebook(e.target.value);
                      }}
                      value={urlFacebook}
                    />
                  )}
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("equipmentInstagram")}</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width={isMobile?"100%":"60%"}
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      disabled={edicao}
                      InputProps={{
                        disableUnderline: true,
                        style: inputPropsStyle,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      onChange={(e) => {
                        setUrlInstagram(e.target.value);
                      }}
                      value={urlInstagram}
                    />
                  )}
                </div>

                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("equipmentLinkedin")}</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width={isMobile?"100%":"60%"}
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      disabled={edicao}
                      InputProps={{
                        disableUnderline: true,
                        style: inputPropsStyle,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      onChange={(e) => {
                        setUrlLinkedin(e.target.value);
                      }}
                      value={urlLinkedin}
                    />
                  )}
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("equipmentX")}</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width={isMobile?"100%":"60%"}
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      disabled={edicao}
                      InputProps={{
                        disableUnderline: true,
                        style: inputPropsStyle,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      onChange={(e) => {
                        setUrlTwitter(e.target.value);
                      }}
                      value={urlTwitter}
                    />
                  )}
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("contract")}*</a>
                  {!edicao && (
                    <Dropzone
                      onDrop={onDropFile2}
                      accept="image/*"
                      multiple={false}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div
                          {...getRootProps()}
                          style={{
                            border: "2px dashed #cccccc",
                            borderRadius: "10px",
                            padding: "20px",
                            textAlign: "center",
                            color: "#666666",
                            backgroundColor: "#f9f9f9",
                            cursor: "pointer",
                            transition: "background-color 0.3s ease",
                            width: "54%",
                          }}
                        >
                          <input {...getInputProps()} />
                          <p>{t("selectImg")}</p>
                        </div>
                      )}
                    </Dropzone>
                  )}
                  {contractType === "image" && contractUrl && (
                    <img
                      src={contractUrl}
                      alt="Preview"
                      style={{ maxWidth: "100px", maxHeight: "100px" }}
                    />
                  )}
                  {contractType === "pdf" && contractUrl && (
                    <object
                      data={contractUrl}
                      type="application/pdf"
                      width="100%"
                      height="500px"
                    >
                      <p>{t("pdfPreviewNotAvailable")}</p>
                    </object>
                  )}
                  {/* {file && fileType === "pdf" && (
                    <div>
                      <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js">
                        <Viewer
                          fileUrl={fileUrl}
                          plugins={[defaultLayoutPluginInstance]}
                        />
                      </Worker>
                    </div>
                  )} */}
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">
                    {t("enddate")} - {t("contract")}*
                  </a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width={isMobile?"100%":"60%"}
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale={i18next?.language}
                    >
                      <DateTimePicker
                        variant="standard"
                        showTimeSelect
                        value={endDate}
                        onChange={(e) => {
                          setEndDate(e);
                        }}
                        disabled={edicao}
                        slotProps={{
                          tabs: {
                            hidden: false,
                          },

                          day: {
                            sx: {
                              "&.MuiPickersDay-root.Mui-selected": {
                                backgroundColor: "#13BB56 !important",
                              },
                            },
                          },

                          desktopPaper: {
                            sx: {
                              borderRadius: "8px",
                              ".MuiPickersYear-yearButton.Mui-selected": {
                                backgroundColor: "#13BB56 !important",
                              },
                              ".MuiPickersMonth-monthButton.Mui-selected": {
                                backgroundColor: "#13BB56 !important",
                              },
                              "& .css-1e3wlyl-MuiButtonBase-root-MuiMenuItem-root-MuiMultiSectionDigitalClockSection-item.Mui-selected":
                                {
                                  backgroundColor: "#13BB56 !important",
                                },
                              "& .css-y0fr9s-MuiButtonBase-root-MuiTab-root.Mui-selected":
                                {
                                  color: "#13BB56 !important",
                                },
                              "& .css-1aquho2-MuiTabs-indicator": {
                                backgroundColor: "#13BB56 !important",
                              },
                              "& .css-1e6y48t-MuiButtonBase-root-MuiButton-root":
                                {
                                  color: "#13BB56 !important",
                                },
                            },
                          },
                        }}
                        sx={{
                          width:isMobile?"100%": "60%",

                          "& .MuiInputBase-input.Mui-disabled": {
                            backgroundColor: "white",
                          },
                          "& .MuiPickersDay-root.Mui-selected": {
                            backgroundColor: "#44b887 !important",
                            color: "white",
                          },
                          "& .MuiPickersCalendar-root": {
                            borderRadius: "8px !important",
                          },

                          "& .MuiInputLabel-root ": {
                            color: "#044535 !important",

                            fontFamily: "Montserrat",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "15px",
                            borderColor: "transparent !important",
                            position: "absolute",
                            marginTop: "-5px",
                            marginLeft: "-10px",
                          },
                          "& .MuiOutlinedInput-root": {
                            color: "#0F111199 !important",
                            textAlign: "right",
                            fontFamily: "Montserrat",
                            fontSize: "18px",
                            fontStyle: "normal",
                            fontWeight: 500,
                            lineHeight: "15px",
                            borderRadius: "8px",
                            background: !edicao ? "#0f11110d" : "white",
                            borderColor: "transparent !important",
                            width: "100%",
                            height: "34.88px",
                            padding: "16.5px 10px",
                          },
                          "& .MuiInputLabel-root.Mui-disabled ": {
                            color: "#044535 !important",
                            fontFamily: "Montserrat",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "15px",
                            borderColor: "transparent !important",
                          },
                          "& .MuiOutlinedInput-notchedOutline ": {
                            border: "0px",
                          },
                          "& .MuiOutlinedInput-input": {
                            padding: "16.5px 0px",
                            width: "100%",
                          },
                        }}
                      ></DateTimePicker>
                    </LocalizationProvider>
                  )}
                </div>

                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("type")}*</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width={isMobile?"100%":"60%"}
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <Autocomplete
                      multiple
                      disabled={edicao}
                      options={types}
                      getOptionLabel={(option) =>
                        option[i18next.language] || option.en
                      }
                      value={Array.isArray(typeId) ? typeId : []}
                      onChange={handleChangeType}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          InputProps={{
                            ...params.InputProps,
                            disableUnderline: true,
                            style: {
                              textAlign: "left",
                              borderRadius: "8px",
                              color: "#0F111199",
                              fontFamily: "Montserrat",
                              fontSize: "18px",
                              fontStyle: "normal",
                              fontWeight: 500,
                              lineHeight: "22px",
                              borderRadius: "8px",
                              background: "#0f11110d",
                              paddingLeft: "20px",
                              width:isMobile?"100%": "60%",
                            },
                          }}
                          InputLabelProps={{
                            shrink: true,
                            style: inputLabelPropsStyle,
                          }}
                          sx={{
                            "& .Mui-disabled": {
                              backgroundColor: "white !important",
                              color: "#044535 !important",
                            },
                            "& .MuiInputLabel-root": {
                              color: "#044535 !important",
                            },
                          }}
                          variant="standard"
                          margin="normal"
                          // placeholder={t("type")}
                        />
                      )}
                      renderOption={(props, option) => (
                        <li {...props} key={option.id}>
                          {option[i18next.language] || option.en}
                        </li>
                      )}
                      sx={{
                        "& .MuiAutocomplete-popupIndicator": {
                          display: "none",
                        },
                        "& .MuiAutocomplete-clearIndicator": {
                          display: "none",
                        },
                        "& .MuiOutlinedInput-root": {
                          padding: 0,
                        },
                        "& .MuiAutocomplete-listbox": {
                          fontFamily: "Montserrat",
                          fontSize: "14px",
                        },
                        "& .MuiAutocomplete-inputRoot": {
                          padding: "0 !important",
                        },
                      }}
                    />
                  )}
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("modalities")}*</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width={isMobile?"100%":"60%"}
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <Autocomplete
                      multiple
                      options={getActivitiesTypes(typeId)}
                      getOptionLabel={(option) =>
                        option[i18next.language] || option.en
                      }
                      value={Array.isArray(modalities) ? modalities : []}
                      onChange={handleModalitiesChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          InputProps={{
                            ...params.InputProps,
                            disableUnderline: true,
                            style: {
                              textAlign: "left",
                              borderRadius: "8px",
                              color: "#0F111199",
                              fontFamily: "Montserrat",
                              fontSize: "18px",
                              fontStyle: "normal",
                              fontWeight: 500,
                              lineHeight: "22px",
                              borderRadius: "8px",
                              background: "#0f11110d",
                              paddingLeft: "20px",
                              width:isMobile?"100%": "60%",
                            },
                          }}
                          InputLabelProps={{
                            shrink: true,
                            style: inputLabelPropsStyle,
                          }}
                          sx={{
                            "& .Mui-disabled": {
                              backgroundColor: "white !important",
                              color: "#044535 !important",
                            },
                            "& .MuiInputLabel-root": {
                              color: "#044535 !important",
                            },
                          }}
                          variant="standard"
                          margin="normal"
                          // placeholder={t("modality")}
                        />
                      )}
                      renderOption={(props, option) => (
                        <li {...props} key={option.id}>
                          {option[i18next.language] || option.en}
                        </li>
                      )}
                      sx={{
                        "& .MuiAutocomplete-popupIndicator": {
                          display: "none",
                        },
                        "& .MuiAutocomplete-clearIndicator": {
                          display: "none",
                        },
                        "& .MuiOutlinedInput-root": {
                          padding: 0,
                        },
                        "& .MuiAutocomplete-listbox": {
                          fontFamily: "Montserrat",
                          fontSize: "14px",
                        },
                        "& .MuiAutocomplete-inputRoot": {
                          padding: "0 !important",
                        },
                      }}
                    />
                  )}
                </div>
              </Grid>
            </Grid>
          </Container>
          <div className="bottomRowEdit">
            <button
              className="clientInfoEditButton2"
              onClick={() => {
                if (edicao) {
                  originData();
                  setEdicao(!edicao);
                } else {
                  saveData();
                }
              }}
            >
              {edicao ? t("edit") : t("save")}
            </button>
            {!edicao ? (
              <button
                className="clientInfoCancelButton2"
                onClick={() => {
                  originData();
                  setEdicao(!edicao);
                }}
              >
                {t("cancel")}
              </button>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PromoterPageAdmin;
