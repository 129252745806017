import React, { useEffect, useState } from "react";
import logo from "../../images/logo.svg";
import background from "../../images/background.png";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardContent,
  Skeleton,
  Step,
  StepLabel,
  MenuItem,
  Stepper,
  TextField,
  Typography,
  Autocomplete,
  InputAdornment,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "../../App.css";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faPen, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import IconButton from "@mui/material/IconButton";
import i18next from "i18next";
import bcrypt from "bcryptjs";
import {
  getSaltAdmin,
  getSaltPromoter,
  loginAdmin,
  loginPromoter,
} from "../../api/requests/Auth";
import toast from "react-hot-toast";
import Cookies from "js-cookie";
import { getPlans, getPlansAll } from "../../api/requests/PaymentPlans";
import Dropzone from "react-dropzone";
import {
  getActivities,
  getDistricts,
  getMunicipalities,
  getSubActivities,
  getTrainStations,
  getTypes,
} from "../../api/requests/Data.js";
import { useLocation } from "react-router-dom";
import {
  getPromoters,
  registerPromoter,
} from "../../api/requests/Promoters.js";
import { getCouncils } from "../../api/requests/Council.js";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
import { CalendarIcon } from "@mui/x-date-pickers/icons";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import "dayjs/locale/pt";
import "dayjs/locale/en-gb";

function RegisterPromoter() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [plans, setPlans] = useState([]);
  const [activeStep, setActiveStep] = useState(0); // Stepper step control
  const today = dayjs();
  const [municipality, setMunicipality] = useState(null);
  const [endDate, setEndDate] = useState(today);
  const [selectedPlan, setSelectedPlan] = useState(null); // Selected plan state
  const steps = [t("selectPlan"), t("promoterInfo"), t("confirmation")];
  const [visible, setVisible] = useState(true);
  const [districts, setDistricts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [promoters, setPromoters] = useState([]);
  const [managers, setManagers] = useState([]);
  const [types, setTypes] = useState([]);
  const [partnerUrl, setPartnerUrl] = useState("");
  const [typeId, setTypeId] = useState("");
  const [VAT, setVAT] = useState("");
  const [postalCode, setPostalCode] = useState([]);
  const [urlFacebook, setUrlFacebook] = useState("");
  const [urlInstagram, setUrlInstagram] = useState("");
  const [urlLinkedin, setUrlLinkedin] = useState("");
  const [urlTwitter, setUrlTwitter] = useState("");
  const [urlYoutube, setUrlYoutube] = useState("");
  const [managerId, setManagerId] = useState("");
  const [name, setName] = useState("");
  const [coordinates, setCoordinates] = useState([]);
  const [councilId, setCouncilId] = useState("");
  const [municipalities, setMunicipalities] = useState([]);
  const [municipalitiesCopy, setMunicipalitiesCopy] = useState([]);
  const [district, setDistrict] = useState({
    _id: "667bddedfd5c20e9661cf7c2",
    id: 1,
    id_country: 1,
    pt: "Aveiro",
    en: "Aveiro",
    es: "Aveiro",
    fr: "Aveiro",
    zones: [8, 2],
    __v: 0,
  });
  const handleCouncilChange = (equipment) => {
    setCouncilId(equipment.target.value);
  };

  const handleManagerChange = (equipment) => {
    setManagerId(equipment.target.value);
  };

  const handleChangeType = (event) => {
    setTypeId(event.target.value);
  };

  const handleDistrictChange = (event, newValue) => {
    setDistrict(newValue);
  };
  const handleMunicipalityChange = (event, newValue) => {
    setMunicipality(newValue);
  };

  const handleModalitiesChange = (event, newValue) => {
    setModalities(newValue);
  };

  const [councilUrl, setCouncilUrl] = useState("");
  const [cityCouncils, setCityCouncils] = useState([]);
  const [culturalActivities, setCulturalActivities] = useState([]);
  const [sportsActivities, setSportsActivities] = useState([]);
  const [isLoadingDistricts, setLoadingDistricts] = useState(true);
  const [isLoadingTrainStations, setLoadingTrainStations] = useState(true);
  const [modalities, setModalities] = useState([]);

  function goBack() {
    navigate("/");
  }

  const [trainstations, setTrainstations] = useState([]);
  const location = useLocation();

  const [openDialog, setOpenDialog] = useState(false);

  const [openDialogRegister, setOpenRegisterDialog] = useState(false);

  const trainStations = ["Lisboa Santa Apolónia", "Porto Campanhã", "Faro"];
  const preferences = [
    { id: 1, designation: "atletismo" },
    { id: 2, designation: "futebol" },
  ];

  const inputLabelPropsStyle = {
    color: "#044535 !important",
    textAlign: "right",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "22px",
  };

  const inputPropsStyle = {
    color: "#0F111199",
    textAlign: "right",
    fontFamily: "Montserrat",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "22px",
    borderRadius: "8px",
    background: "#0f11110d",
    paddingLeft: "10px",
    // width: "60%",
  };

  const inputTrainStyle = {
    color: "#0F1111",
    textAlign: "right",
    fontFamily: "Montserrat",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "22px",
    borderRadius: "8px",
    background: "white",
    // width: "60%",
  };

  const handleNext = () => setActiveStep((prevStep) => prevStep + 1);
  const handleBack = () => setActiveStep((prevStep) => prevStep - 1);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleEmail = (event) => {
    setEmail(event.target.value);
  };
  const handlePwd = (event) => {
    setPassword(event.target.value);
  };

  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    getPlansAll()
      .then((res) => {
        if (res.status === 200 && res.success) {
          setPlans(res.data);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error("500");
      });
  }, []);

  const [preview, setPreview] = useState("");
  const [preview1, setPreview1] = useState("");
  const [preview2, setPreview2] = useState("");
  const [preview3, setPreview3] = useState("");
  const [preview4, setPreview4] = useState("");

  const [contract, setContract] = useState(null);
  const [contractType, setContractType] = useState("");
  const [contractUrl, setContractUrl] = useState(null);

  const onDropFile2 = (acceptedFiles) => {
    const file = acceptedFiles[0];
    setContract(file);

    // Determine o tipo do arquivo e atualiza a URL de visualização
    const fileType = file.type.includes("pdf") ? "pdf" : "image";
    setContractType(fileType);

    const fileUrl = URL.createObjectURL(file);
    setContractUrl(fileUrl);
  };

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreview(reader.result);
    };
    reader.readAsDataURL(file);
  };
  const onDrop1 = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreview1(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const onDrop2 = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreview2(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const onDrop3 = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreview3(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const onDrop4 = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreview4(reader.result);
    };
    reader.readAsDataURL(file);
  };
  useEffect(() => {
    getTrainStations()
      .then((res) => {
        if (res.status === 200 && res.success) {
          setTrainstations(res.data);
          setLoadingTrainStations(false);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error(t("500"));
      });
    getDistricts()
      .then((res) => {
        if (res.status === 200 && res.success) {
          setDistricts(res.data);
          setLoadingDistricts(false);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error("500");
      });
    getPromoters()
      .then((res) => {
        if (res.status === 200 && res.success) {
          setPromoters(res.data);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error("500");
      });
    getCouncils()
      .then((res) => {
        if (res.status === 200 && res.success) {
          setCityCouncils(res.data);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error("500");
      });
    getTypes()
      .then((res) => {
        if (res.status === 200 && res.success) {
          setTypes(res.data);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error("500");
      });
    getMunicipalities()
      .then((res) => {
        if (res.status === 200 && res.success) {
          setMunicipalities(res.data);
          setMunicipalitiesCopy(res.data);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error(t("500"));
      });
    getActivities()
      .then((res) => {
        if (res.status === 200 && res.success) {
          let cultural = [];
          let sports = [];
          res.data.forEach((element) => {
            if (element.type_id == 1) {
              sports.push(element);
            } else if (element.type_id == 2) {
              cultural.push(element);
            }
          });
          setSportsActivities(sports);
          setCulturalActivities(cultural);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error(t("500"));
      });
  }, []);

  // function getActivitiesTypes(typeId) {
  //   let activities = [];

  //   const ids = Array.isArray(typeId) ? typeId.map((item) => item.id) : [];

  //   if (ids.includes("1")) {
  //     activities = activities.concat(sportsActivities);
  //   }

  //   if (ids.includes("2")) {
  //     activities = activities.concat(culturalActivities);
  //   }

  //   return activities;
  // }

  function getActivitiesTypes() {
    // Combine sportsActivities e culturalActivities em um único array
    return [...sportsActivities, ...culturalActivities];
  }

  function create() {
    console.log("Create function called");

    if (!preview) {
      toast.error(t("photoValidation"));
      return;
    }
    if (!name) {
      toast.error(t("nameEqValidation"));
      return;
    }
    if (!email) {
      toast.error(t("emailValidation"));
      return;
    }
    if (!coordinates) {
      toast.error(t("coordinatesValidation"));
      return;
    }
    if (!password) {
      toast.error(t("passwordValidation"));
      return;
    }
    // if (!managerId) {
    //   toast.error(t("managerValidation"));
    //   return;
    // }
    // if (!managerLimit) {
    //   toast.error(t("managerLimitValidation"));
    //   return;
    // }
    if (!selectedPlan) {
      toast.error(t("planValidation"));
      return;
    }
    // if (!licenses) {
    //   toast.error(t("licenseValidation"));
    //   return;
    // }
    if (!contractUrl) {
      toast.error(t("contractValidation"));
      return;
    }
    if (!endDate) {
      toast.error(t("endDateValidation"));
      return;
    }
    if (typeId.length == 0) {
      toast.error(t("typeIdEqValidation"));
      return;
    }
    if (modalities.length == 0) {
      toast.error(t("modalityValidation"));
      return;
    }

    // let typeF = [];
    // typeId.forEach((element) => {
    //   typeF.push(element.id);
    // });
    let modalitiesF = [];
    modalities.forEach((element) => {
      modalitiesF.push(element.id);
    });
    console.log(selectedPlan)

    registerPromoter({
      name: name,
      email: email,
      zip_code: postalCode,
      vat: VAT,
      coordinates: coordinates,
      district_id: district ? district.id : "",
      municipality_id: municipality ? municipality.id : "",
      password:password,
      // manager: managerId,
      // manager_limit: managerLimit,
      photo: preview,
      gallery: [preview1, preview2, preview3, preview4],
      url_facebook: urlFacebook,
      url_instagram: urlInstagram,
      url_linkedin: urlLinkedin,
      url_twitter: urlTwitter,
      url_council: councilUrl,
      // licenses: licenses,
      plan_id: selectedPlan,
      contract: contractUrl,
      visible: visible,
      expired_date: endDate ? dayjs(endDate).format("YYYY-MM-DD") : null,
      modalities: modalitiesF,
      type_id: typeId.id,
      city_council: false,
      promoter: true,
    })
      .then((res) => {
      
        if (res.success && res.status === 200) {
          toast.success(t("successMessage"));
          navigate("/loginpromoter");
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
     
        toast.error(t("500"));
      });

    
  }

  return (
    <div className="background">
      <div className="backgroundImageSmall">
        <div className="topcenter">
          <div className="logo"></div>
          <p className="titlesmall">{t("promoter")}</p>
        </div>
      </div>
      <div className="bottomcenterregister">
        <div
          style={{
            overflowY: "auto",
            maxHeight: "50vh",
            padding: "10px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: activeStep === 0 ? "100%" : "21%",
          }}
        >
          {activeStep === 0 && (
            <div className="plan-selection">
              {plans.length > 0 ? (
                plans.map((plan) => (
                  <Card
                    key={plan.id}
                    className={`plan-card ${
                      selectedPlan === plan._id ? "selected" : ""
                    }`}
                    onClick={() => setSelectedPlan(plan._id)}
                  >
                    <CardContent>
                      <Typography variant="h6" className="plan-title">
                        {plan.pt}
                      </Typography>
                      <Typography className="plan-duration">
                        {plan.duration}
                      </Typography>
                      <Typography className="plan-price">
                        {t("price")}: {plan.value}€
                      </Typography>
                    </CardContent>
                  </Card>
                ))
              ) : (
                <Typography className="no-plans">
                  {t("noPlansAvailable")}
                </Typography>
              )}
            </div>
          )}
          {activeStep === 1 && (
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div className="photoColumn">
                <a className="photoLabel">{t("mainPhoto")}</a>

                <Dropzone onDrop={onDrop} accept="image/*" multiple={false}>
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      <div className="addPosterBig" {...getRootProps()}>
                        <input {...getInputProps()} />
                        {!preview ? (
                          <div
                            className="addPhotoBig"
                            style={{ marginRight: "0px" }}
                          >
                            <FontAwesomeIcon
                              style={{
                                fontSize: "16px",
                                color: "rgba(15, 17, 17, 0.60)",
                              }}
                              icon={faPlus}
                            />
                          </div>
                        ) : (
                          <div className="addPosterEditBig">
                            <img src={preview} className="addPosterBig" />
                            <div className="overlay">
                              <FontAwesomeIcon
                                className="hoverIcon"
                                icon={faTrashCan}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </section>
                  )}
                </Dropzone>
              </div>

              <div className="morePhotos2" style={{ marginTop: "30px" }}>
                <a className="photoLabel">{t("photosvideos")}</a>
                <div className="photoRow">
                  <>
                    <div
                      style={{ marginRight: "10px" }}
                      onClick={() => {
                        setPreview1(null);
                      }}
                    >
                      <Dropzone
                        onDrop={onDrop1}
                        disabled={preview1 ? true : false}
                        accept="image/*"
                        multiple={false}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <section>
                            <div className="addPoster" {...getRootProps()}>
                              <input {...getInputProps()} />
                              {!preview1 ? (
                                <div
                                  className="addPhoto"
                                  style={{ marginRight: "10px" }}
                                >
                                  <FontAwesomeIcon
                                    style={{
                                      fontSize: "16px",
                                      color: "rgba(15, 17, 17, 0.60)",
                                    }}
                                    icon={faPlus}
                                  />
                                </div>
                              ) : (
                                <div
                                  className="addPosterEdit"
                                  style={{
                                    marginRight: "10px !important",
                                  }}
                                >
                                  <img src={preview1} className="addPoster" />
                                  <div className="overlay">
                                    <FontAwesomeIcon
                                      className="hoverIcon"
                                      icon={faTrashCan}
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                          </section>
                        )}
                      </Dropzone>
                    </div>
                    <div
                      style={{ marginRight: "10px" }}
                      onClick={() => {
                        setPreview2(null);
                      }}
                    >
                      <Dropzone
                        onDrop={onDrop2}
                        disabled={preview2 ? true : false}
                        accept="image/*"
                        multiple={false}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <section>
                            <div className="addPoster" {...getRootProps()}>
                              <input {...getInputProps()} />
                              {!preview2 ? (
                                <div
                                  className="addPhoto"
                                  style={{ marginRight: "10px" }}
                                >
                                  <FontAwesomeIcon
                                    style={{
                                      fontSize: "16px",
                                      color: "rgba(15, 17, 17, 0.60)",
                                    }}
                                    icon={faPlus}
                                  />
                                </div>
                              ) : (
                                <div
                                  className="addPosterEdit"
                                  style={{
                                    marginRight: "10px !important",
                                  }}
                                >
                                  <img src={preview2} className="addPoster" />
                                  <div className="overlay">
                                    <FontAwesomeIcon
                                      className="hoverIcon"
                                      icon={faTrashCan}
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                          </section>
                        )}
                      </Dropzone>
                    </div>
                    <div
                      style={{ marginRight: "10px" }}
                      onClick={() => {
                        setPreview3(null);
                      }}
                    >
                      <Dropzone
                        onDrop={onDrop3}
                        accept="image/*"
                        disabled={preview3 ? true : false}
                        multiple={false}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <section>
                            <div className="addPoster" {...getRootProps()}>
                              <input {...getInputProps()} />
                              {!preview3 ? (
                                <div
                                  className="addPhoto"
                                  style={{ marginRight: "10px" }}
                                >
                                  <FontAwesomeIcon
                                    style={{
                                      fontSize: "16px",
                                      color: "rgba(15, 17, 17, 0.60)",
                                    }}
                                    icon={faPlus}
                                  />
                                </div>
                              ) : (
                                <div
                                  className="addPosterEdit"
                                  style={{
                                    marginRight: "10px !important",
                                  }}
                                >
                                  <img src={preview3} className="addPoster" />
                                  <div className="overlay">
                                    <FontAwesomeIcon
                                      className="hoverIcon"
                                      icon={faTrashCan}
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                          </section>
                        )}
                      </Dropzone>
                    </div>
                    <div
                      style={{ marginRight: "10px" }}
                      onClick={() => {
                        setPreview4(null);
                      }}
                    >
                      <Dropzone
                        onDrop={onDrop4}
                        accept="image/*"
                        multiple={false}
                        disabled={preview4 ? true : false}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <section>
                            <div className="addPoster" {...getRootProps()}>
                              <input {...getInputProps()} />
                              {!preview4 ? (
                                <div
                                  className="addPhoto"
                                  style={{ marginRight: "10px" }}
                                >
                                  <FontAwesomeIcon
                                    style={{
                                      fontSize: "16px",
                                      color: "rgba(15, 17, 17, 0.60)",
                                    }}
                                    icon={faPlus}
                                  />
                                </div>
                              ) : (
                                <div
                                  className="addPosterEdit"
                                  style={{
                                    marginRight: "10px !important",
                                  }}
                                >
                                  <img src={preview4} className="addPoster" />
                                  <div className="overlay">
                                    <FontAwesomeIcon
                                      className="hoverIcon"
                                      icon={faTrashCan}
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                          </section>
                        )}
                      </Dropzone>
                    </div>
                  </>
                </div>
              </div>

              <div
                style={{
                  marginTop: "30px",
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <a className="photoLabel">{t("name")}</a>

                <TextField
                  fullWidth
                  InputProps={{
                    disableUnderline: true,
                    style: inputPropsStyle,
                  }}
                  InputLabelProps={{
                    shrink: true,
                    style: inputLabelPropsStyle,
                  }}
                  sx={{
                    "& .Mui-disabled": {
                      backgroundColor: "white !important",
                      color: "#044535 !important",
                    },
                    "& .MuiInputLabel-root ": {
                      color: "#044535 !important",
                    },
                  }}
                  variant="standard"
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  value={name}
                />
              </div>
              <div
                style={{
                  marginTop: "30px",
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <a className="photoLabel">{t("email")}</a>

                <TextField
                  fullWidth
                  InputProps={{
                    disableUnderline: true,
                    style: inputPropsStyle,
                  }}
                  InputLabelProps={{
                    shrink: true,
                    style: inputLabelPropsStyle,
                  }}
                  sx={{
                    "& .Mui-disabled": {
                      backgroundColor: "white !important",
                      color: "#044535 !important",
                    },
                    "& .MuiInputLabel-root ": {
                      color: "#044535 !important",
                    },
                  }}
                  variant="standard"
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  value={email}
                />
              </div>
              <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <a className="photoLabel">{t("password")}</a>
                  <TextField
                    fullWidth
                    type={showPassword ? "text" : "password"}
                    InputProps={{
                      disableUnderline: true,
                      style: inputPropsStyle,
                      endAdornment: (
                        <InputAdornment position="end">
                          <button
                            className="toggle-password"
                            onClick={toggleShowPassword}
                            style={{
                              background: "transparent",
                              border: "none",
                            }}
                          >
                            {showPassword ? (
                              <Visibility size={"small"} />
                            ) : (
                              <VisibilityOff size={"small"} />
                            )}
                          </button>
                        </InputAdornment>
                      ),
                    }}
                    InputLabelProps={{
                      shrink: true,
                      style: inputLabelPropsStyle,
                    }}
                    sx={{
                      "& .Mui-disabled": {
                        backgroundColor: "white !important",
                        color: "#044535 !important",
                      },
                      "& .MuiInputLabel-root ": {
                        color: "#044535 !important",
                      },
                    }}
                    variant="standard"
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                    required
                  />
                </div>

              <div
                style={{
                  marginTop: "30px",
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <a className="photoLabel">{t("type")}</a>

                <TextField
                  fullWidth
                  select
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      ...inputPropsStyle,
                      textAlign: "left",
                      borderRadius: "8px",
                    },
                  }}
                  InputLabelProps={{
                    shrink: true,
                    style: inputLabelPropsStyle,
                  }}
                  sx={{
                    "& .Mui-disabled": {
                      backgroundColor: "white !important",
                      color: "#044535 !important",
                    },
                    "& .MuiInputLabel-root": {
                      color: "#044535 !important",
                    },
                  }}
                  variant="standard"
                  value={typeId}
                  onChange={handleChangeType}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                >
                  {types.map((type) => (
                    <MenuItem key={type._id} value={type.id}>
                      {type[i18next.language]}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              <div
                style={{
                  marginTop: "30px",
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <a className="photoLabel">{t("VAT")}</a>

                <TextField
                  fullWidth
                  InputProps={{
                    disableUnderline: true,
                    style: inputPropsStyle,
                  }}
                  InputLabelProps={{
                    shrink: true,
                    style: inputLabelPropsStyle,
                  }}
                  sx={{
                    "& .Mui-disabled": {
                      backgroundColor: "white !important",
                      color: "#044535 !important",
                    },
                    "& .MuiInputLabel-root ": {
                      color: "#044535 !important",
                    },
                  }}
                  variant="standard"
                  onChange={(e) => {
                    setVAT(e.target.value);
                  }}
                  value={VAT}
                />
              </div>
              <div
                style={{
                  marginTop: "30px",
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <a className="photoLabel">{t("postalCode")}</a>

                <TextField
                  fullWidth
                  InputProps={{
                    disableUnderline: true,
                    style: inputPropsStyle,
                  }}
                  InputLabelProps={{
                    shrink: true,
                    style: inputLabelPropsStyle,
                  }}
                  sx={{
                    "& .Mui-disabled": {
                      backgroundColor: "white !important",
                      color: "#044535 !important",
                    },
                    "& .MuiInputLabel-root ": {
                      color: "#044535 !important",
                    },
                  }}
                  variant="standard"
                  onChange={(e) => {
                    setPostalCode(e.target.value);
                  }}
                  value={postalCode}
                />
              </div>

              {/* <div
                style={{
                  marginTop: "30px",
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <a className="photoLabel">{t("manager")}</a>

                <TextField
                  fullWidth
                  select
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      ...inputPropsStyle,
                      textAlign: "left",
                      borderRadius: "8px",
                    },
                  }}
                  InputLabelProps={{
                    shrink: true,
                    style: inputLabelPropsStyle,
                  }}
                  sx={{
                    "& .Mui-disabled": {
                      backgroundColor: "white !important",
                      color: "#044535 !important",
                    },
                    "& .MuiInputLabel-root ": {
                      color: "#044535 !important",
                    },
                  }}
                  variant="standard"
                  value={managerId}
                  onChange={handleManagerChange}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                >
                  {managers.map((manager) => (
                    <MenuItem key={manager._id} value={manager._id}>
                      {manager.name}
                    </MenuItem>
                  ))}
                </TextField>
              </div> */}
              <div
                style={{
                  marginTop: "30px",
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <a className="photoLabel">
                  {t("enddate")} - {t("contract")}*
                </a>

                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale={i18next?.language}
                >
                  <DateTimePicker
                    variant="standard"
                    showTimeSelect
                    value={endDate}
                    onChange={(e) => {
                      setEndDate(e);
                    }}
                    slotProps={{
                      tabs: {
                        hidden: false,
                      },

                      day: {
                        sx: {
                          "&.MuiPickersDay-root.Mui-selected": {
                            backgroundColor: "#13BB56 !important",
                          },
                        },
                      },

                      desktopPaper: {
                        sx: {
                          borderRadius: "8px",
                          ".MuiPickersYear-yearButton.Mui-selected": {
                            backgroundColor: "#13BB56 !important",
                          },
                          ".MuiPickersMonth-monthButton.Mui-selected": {
                            backgroundColor: "#13BB56 !important",
                          },
                          "& .css-1e3wlyl-MuiButtonBase-root-MuiMenuItem-root-MuiMultiSectionDigitalClockSection-item.Mui-selected":
                            {
                              backgroundColor: "#13BB56 !important",
                            },
                          "& .css-y0fr9s-MuiButtonBase-root-MuiTab-root.Mui-selected":
                            {
                              color: "#13BB56 !important",
                            },
                          "& .css-1aquho2-MuiTabs-indicator": {
                            backgroundColor: "#13BB56 !important",
                          },
                          "& .css-1e6y48t-MuiButtonBase-root-MuiButton-root": {
                            color: "#13BB56 !important",
                          },
                        },
                      },
                    }}
                    sx={{
                      // width: "60%",

                      "& .MuiInputBase-input.Mui-disabled": {
                        backgroundColor: "white",
                      },
                      "& .MuiPickersDay-root.Mui-selected": {
                        backgroundColor: "#44b887 !important",
                        color: "white",
                      },
                      "& .MuiPickersCalendar-root": {
                        borderRadius: "8px !important",
                      },

                      "& .MuiInputLabel-root ": {
                        color: "#044535 !important",

                        fontFamily: "Montserrat",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: 600,
                        lineHeight: "15px",
                        borderColor: "transparent !important",
                        position: "absolute",
                        marginTop: "-5px",
                        marginLeft: "-10px",
                      },
                      "& .MuiOutlinedInput-root": {
                        color: "#0F111199 !important",
                        textAlign: "right",
                        fontFamily: "Montserrat",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "15px",
                        borderRadius: "8px",
                        // background: !edicao ? "#0f11110d" : "white",
                        borderColor: "transparent !important",
                        width: "100%",
                        height: "34.88px",
                        padding: "16.5px 10px",
                      },
                      "& .MuiInputLabel-root.Mui-disabled ": {
                        color: "#044535 !important",
                        fontFamily: "Montserrat",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: 600,
                        lineHeight: "15px",
                        borderColor: "transparent !important",
                      },
                      "& .MuiOutlinedInput-notchedOutline ": {
                        border: "0px",
                      },
                      "& .MuiOutlinedInput-input": {
                        padding: "16.5px 0px",
                        width: "100%",
                      },
                    }}
                  ></DateTimePicker>
                </LocalizationProvider>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "30px",
                }}
              >
                <a className="photoLabel">{t("contract")}*</a>

                <Dropzone
                  onDrop={onDropFile2}
                  accept="image/*,application/pdf"
                  multiple={false}
                >
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      <div className="addPosterBig" {...getRootProps()}>
                        <input {...getInputProps()} />
                        {!contractUrl ? (
                          <div className="addPhotoBig">
                            <FontAwesomeIcon
                              style={{
                                fontSize: "16px",
                                color: "rgba(15, 17, 17, 0.60)",
                              }}
                              icon={faPlus}
                            />
                          </div>
                        ) : contractType === "image" ? (
                          <div className="addPosterEditBig">
                            <img
                              src={contractUrl}
                              alt="Preview"
                              className="addPosterBig"
                            />
                            <div className="overlay">
                              <FontAwesomeIcon
                                className="hoverIcon"
                                icon={faPen}
                              />
                            </div>
                          </div>
                        ) : (
                          <object
                            data={contractUrl}
                            type="application/pdf"
                            width="100%"
                            height="500px"
                          >
                            <p>PDF preview not available</p>
                          </object>
                        )}
                      </div>
                    </section>
                  )}
                </Dropzone>
              </div>
              {/* <div
                style={{
                  marginTop: "30px",
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <a className="photoLabel">{t("cityCouncil")}</a>

                <TextField
                  fullWidth
                  select
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      ...inputPropsStyle,
                      textAlign: "left",
                      borderRadius: "8px",
                    },
                  }}
                  InputLabelProps={{
                    shrink: true,
                    style: inputLabelPropsStyle,
                  }}
                  sx={{
                    "& .Mui-disabled": {
                      backgroundColor: "white !important",
                      color: "#044535 !important",
                    },
                    "& .MuiInputLabel-root ": {
                      color: "#044535 !important",
                    },
                  }}
                  variant="standard"
                  value={councilId}
                  onChange={handleCouncilChange}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                >
                  {cityCouncils.map((council) => (
                    <MenuItem key={council._id} value={council._id}>
                      {council.name}
                    </MenuItem>
                  ))}
                </TextField>
              </div> */}

              <div
                style={{
                  marginTop: "30px",
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <a className="photoLabel">{t("district")}</a>

                <Autocomplete
                  options={districts}
                  getOptionLabel={(option) =>
                    option[i18next.language] || option.en
                  }
                  value={district}
                  onChange={handleDistrictChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                        style: {
                          ...inputPropsStyle,
                          textAlign: "left",
                          borderRadius: "8px",
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                    />
                  )}
                  renderOption={(props, option) => (
                    <li {...props} key={option.id}>
                      {option[i18next.language] || option.en}
                    </li>
                  )}
                  sx={{
                    "& .MuiAutocomplete-popupIndicator": {
                      display: "none",
                    },
                    "& .MuiAutocomplete-clearIndicator": {
                      display: "none",
                    },
                    "& .MuiOutlinedInput-root": {
                      padding: 0,
                    },
                    "& .MuiAutocomplete-listbox": {
                      fontFamily: "Montserrat",
                      fontSize: "14px",
                    },
                    "& .MuiAutocomplete-inputRoot": {
                      padding: "0 !important",
                    },
                  }}
                />
              </div>
              <div
                style={{
                  marginTop: "30px",
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <a className="photoLabel">{t("coordinates")}</a>

                <TextField
                  fullWidth
                  InputProps={{
                    disableUnderline: true,
                    style: inputPropsStyle,
                  }}
                  InputLabelProps={{
                    shrink: true,
                    style: inputLabelPropsStyle,
                  }}
                  sx={{
                    "& .Mui-disabled": {
                      backgroundColor: "white !important",
                      color: "#044535 !important",
                    },
                    "& .MuiInputLabel-root ": {
                      color: "#044535 !important",
                    },
                  }}
                  variant="standard"
                  onChange={(e) => {
                    setCoordinates(e.target.value);
                  }}
                  value={coordinates}
                />
              </div>
              <div
                style={{
                  marginTop: "30px",
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <a className="photoLabel">{t("modalities")}*</a>

                <Autocomplete
                  multiple
                  options={getActivitiesTypes()}
                  getOptionLabel={(option) =>
                    option[i18next.language] || option.en
                  }
                  value={Array.isArray(modalities) ? modalities : []}
                  onChange={handleModalitiesChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                        style: {
                          textAlign: "left",
                          borderRadius: "8px",
                          color: "#0F111199",
                          fontFamily: "Montserrat",
                          fontSize: "18px",
                          fontStyle: "normal",
                          fontWeight: 500,
                          lineHeight: "22px",
                          borderRadius: "8px",
                          background: "#0f11110d",
                          paddingLeft: "20px",
                          width: "100%",
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        width: "100%",
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      margin="normal"
                      // placeholder={t("modality")}
                    />
                  )}
                  renderOption={(props, option) => (
                    <li {...props} key={option.id}>
                      {option[i18next.language] || option.en}
                    </li>
                  )}
                  sx={{
                    "& .MuiAutocomplete-popupIndicator": {
                      display: "none",
                    },
                    "& .MuiAutocomplete-clearIndicator": {
                      display: "none",
                    },
                    "& .MuiOutlinedInput-root": {
                      padding: 0,
                    },
                    "& .MuiAutocomplete-listbox": {
                      fontFamily: "Montserrat",
                      fontSize: "14px",
                    },
                    "& .MuiAutocomplete-inputRoot": {
                      padding: "0 !important",
                    },
                  }}
                />
              </div>
              <div
                style={{
                  marginTop: "30px",
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <a className="photoLabel">{t("urlCouncil")}</a>

                <TextField
                  fullWidth
                  InputProps={{
                    disableUnderline: true,
                    style: inputPropsStyle,
                  }}
                  InputLabelProps={{
                    shrink: true,
                    style: inputLabelPropsStyle,
                  }}
                  sx={{
                    "& .Mui-disabled": {
                      backgroundColor: "white !important",
                      color: "#044535 !important",
                    },
                    "& .MuiInputLabel-root ": {
                      color: "#044535 !important",
                    },
                  }}
                  variant="standard"
                  onChange={(e) => {
                    setCouncilUrl(e.target.value);
                  }}
                  value={councilUrl}
                />
              </div>
              <div
                style={{
                  marginTop: "30px",
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <a className="photoLabel">{t("equipmentFacebook")}</a>

                <TextField
                  fullWidth
                  InputProps={{
                    disableUnderline: true,
                    style: inputPropsStyle,
                  }}
                  InputLabelProps={{
                    shrink: true,
                    style: inputLabelPropsStyle,
                  }}
                  sx={{
                    "& .Mui-disabled": {
                      backgroundColor: "white !important",
                      color: "#044535 !important",
                    },
                    "& .MuiInputLabel-root ": {
                      color: "#044535 !important",
                    },
                  }}
                  variant="standard"
                  onChange={(e) => {
                    setUrlFacebook(e.target.value);
                  }}
                  value={urlFacebook}
                />
              </div>
              <div
                style={{
                  marginTop: "30px",
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <a className="photoLabel">{t("equipmentInstagram")}</a>

                <TextField
                  fullWidth
                  InputProps={{
                    disableUnderline: true,
                    style: inputPropsStyle,
                  }}
                  InputLabelProps={{
                    shrink: true,
                    style: inputLabelPropsStyle,
                  }}
                  sx={{
                    "& .Mui-disabled": {
                      backgroundColor: "white !important",
                      color: "#044535 !important",
                    },
                    "& .MuiInputLabel-root ": {
                      color: "#044535 !important",
                    },
                  }}
                  variant="standard"
                  onChange={(e) => {
                    setUrlInstagram(e.target.value);
                  }}
                  value={urlInstagram}
                />
              </div>
              <div
                style={{
                  marginTop: "30px",
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <a className="photoLabel">{t("equipmentYoutube")}</a>

                <TextField
                  fullWidth
                  InputProps={{
                    disableUnderline: true,
                    style: inputPropsStyle,
                  }}
                  InputLabelProps={{
                    shrink: true,
                    style: inputLabelPropsStyle,
                  }}
                  sx={{
                    "& .Mui-disabled": {
                      backgroundColor: "white !important",
                      color: "#044535 !important",
                    },
                    "& .MuiInputLabel-root ": {
                      color: "#044535 !important",
                    },
                  }}
                  variant="standard"
                  onChange={(e) => {
                    setUrlYoutube(e.target.value);
                  }}
                  value={urlYoutube}
                />
              </div>
              <div
                style={{
                  marginTop: "30px",
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <a className="photoLabel">{t("equipmentLinkedin")}</a>

                <TextField
                  fullWidth
                  InputProps={{
                    disableUnderline: true,
                    style: inputPropsStyle,
                  }}
                  InputLabelProps={{
                    shrink: true,
                    style: inputLabelPropsStyle,
                  }}
                  sx={{
                    "& .Mui-disabled": {
                      backgroundColor: "white !important",
                      color: "#044535 !important",
                    },
                    "& .MuiInputLabel-root ": {
                      color: "#044535 !important",
                    },
                  }}
                  variant="standard"
                  onChange={(e) => {
                    setUrlLinkedin(e.target.value);
                  }}
                  value={urlLinkedin}
                />
              </div>
              <div
                style={{
                  marginTop: "30px",
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <a className="photoLabel">{t("equipmentX")}</a>

                <TextField
                  fullWidth
                  InputProps={{
                    disableUnderline: true,
                    style: inputPropsStyle,
                  }}
                  InputLabelProps={{
                    shrink: true,
                    style: inputLabelPropsStyle,
                  }}
                  sx={{
                    "& .Mui-disabled": {
                      backgroundColor: "white !important",
                      color: "#044535 !important",
                    },
                    "& .MuiInputLabel-root ": {
                      color: "#044535 !important",
                    },
                  }}
                  variant="standard"
                  onChange={(e) => {
                    setUrlTwitter(e.target.value);
                  }}
                  value={urlTwitter}
                />
              </div>
            </div>
          )}

          {activeStep === 2 && (
            <Typography variant="h6">{t("confirmationMessage")}</Typography>
          )}
        </div>

        {/* Terceiro passo: Confirmação */}

        <div className="stepper-buttons">
          {activeStep === 0 ? (
            <></>
          ) : (
            <button
              disabled={activeStep === 0}
              onClick={handleBack}
              className="buttonStep"
            >
              {t("back")}
            </button>
          )}
          {selectedPlan && (
            <button
              variant="contained"
              onClick={activeStep === 1 ? () => create() : handleNext}
              className="buttonStep"
            >
              {activeStep === 2 ? t("finish") : t("register")}
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default RegisterPromoter;
