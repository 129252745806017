import {
  Avatar,
  BottomNavigation,
  BottomNavigationAction,
} from "@mui/material";
import { useNavigate } from "react-router";
import homeIcon from "../images/navIcons/home100client.svg";
import homeIconSmooth from "../images/navIcons/home40.svg";
import mapIcon from "../images/navIcons/map100.svg";
import mapIconSmooth from "../images/navIcons/map40.svg";
import ticketIcon from "../images/navIcons/ticket100.svg";
import ticketIconSmooth from "../images/navIcons/ticket40.svg";
import helpIcon from "../images/navIcons/help60.svg";
import helpIconWhite from "../images/navIcons/help100.svg";

function NavClient() {
  const navigate = useNavigate();

  function getPageIndex(route) {
    switch (route) {
      case "/trips":
        return 0;
      case "/dashboardclient":
        return 1;
      case "/ticketsclient":
        return 2;
      case "/helpclient":
        return 3;

      default:
        return 1;
    }
  }

  const value = getPageIndex(window.location.pathname);

  const handleChange = (event, newValue) => {
    if (newValue == 0) {
      navigate("/trips");
    } else if (newValue == 1) {
      navigate("/dashboardclient");
    } else if (newValue == 2) {
      navigate("/ticketsclient");
    } else if (newValue == 3) {
      navigate("/helpclient");
    }
  };

  return (
    <>
      <div className="navbarclient">
        <span
          className="logonavclient"
          onClick={() => {
            navigate("/dashboardclient");
          }}
        ></span>
        <div style={{ position: "absolute", marginLeft: "-10px"}}>
          <BottomNavigation
            disableRipple
            value={value}
            style={{
              backgroundColor: "transparent",
              flexDirection: "column",
              position: "absolute",
            }}
            onChange={handleChange}
            showLabels
          >
            <BottomNavigationAction
              disableRipple
              icon={
                <span
                  style={{
                    width: 25,
                    height: 25,
                    backgroundImage:
                      value == 0 ? `url(${mapIcon})` : `url(${mapIconSmooth})`,
                  }}
                />
              }
              classes={{
                root: "bottomNavigationAction",
              }}
            />
            <BottomNavigationAction
              disableRipple
              icon={
                <span
                  style={{
                    width: 23,
                    height: 25,
                    backgroundImage:
                      value == 1
                        ? `url(${homeIcon})`
                        : `url(${homeIconSmooth})`,
                  }}
                />
              }
              classes={{
                root: "bottomNavigationAction",
              }}
            />
            <BottomNavigationAction
              disableRipple
              icon={
                <span
                  style={{
                    width: 32,
                    height: 25,
                    backgroundImage:
                      value == 2
                        ? `url(${ticketIcon})`
                        : `url(${ticketIconSmooth})`,
                  }}
                />
              }
              classes={{
                root: "bottomNavigationAction",
              }}
            />

            <BottomNavigationAction
              disableRipple
              icon={
                <span
                  style={{
                    width: 22,
                    height: 22,
                    backgroundImage:
                      value != 3
                        ? `url(${helpIcon})`
                        : `url(${helpIconWhite})`,
                  }}
                />
              }
              // classes={{
              //   root: "bottomNavigationAction",

              // }}
              style={{
                position: "fixed",
                bottom: 10,
              }}
            />
          </BottomNavigation>
        </div>
      </div>
    </>
  );
}

export default NavClient;
