import React, { useEffect, useState } from "react";
import logo from "../../images/logo.svg";
import background from "../../images/background.png";
import { Link, useNavigate } from "react-router-dom";
import { TextField } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "../../App.css";
import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";

import i18next from "i18next";
import Header from "../../components/header";
import NavManager from "../../components/navManager";
import HeaderManager from "../../components/headerManager";
import NavClient from "../../components/navClient";
import HeaderClient from "../../components/headerClient";
import NavClientHorizontal from "../../components/navClientHorizontal";

function TermsClient() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="background">
      <HeaderClient />
      {isMobile ? <NavClientHorizontal /> : <NavClient />}
      <div className="centerpage">
        <a className="greenTitle">{t("terms")}</a>
        <div className="whiteBoard">
          <div className="scrollableContent">
            <p className="helpTitle">1. Aceitação dos Termos</p>
            <p className="helpText">
              Ao utilizar o Trainsport, você concorda com cumprir e estar
              vinculado aos presentes Termos e Condições. Caso não concorde com
              qualquer um dos termos aqui estabelecidos, por favor, não utilize
              o Trainsport.
            </p>

            <p className="helpTitle">2. Descrição dos Serviços</p>
            <p className="helpText">
              O Trainsport oferece aos utilizadores a capacidade de:
            </p>
            <p className="helpText">
              - Consultar horários e aceder a plataformas de compra de bilhetes
              para viagens.
            </p>
            <p className="helpText">
              {" "}
              -Aceder informações de eventos desportivos e culturais.
            </p>
            <p className="helpText"> -Planear Viagens.</p>

            <p className="helpTitle">3. Elegibilidade</p>
            <p className="helpText">
              O uso desta aplicação está disponível apenas para indivíduos com
              capacidade legal para contratar conforme as leis aplicáveis. Ao
              usar o aplicativo, você declara ter, no mínimo, X anos.
            </p>

            <p className="helpTitle">4. Registo de Conta</p>
            <p className="helpText">
              Para aceder a determinados serviços, poderá ser solicitado a
              criação de uma conta na aplicação. Você é responsável por manter a
              confidencialidade das informações de login e por todas as
              atividades que ocorrerem na sua conta. O Trainsport não se
              responsabiliza por qualquer uso não autorizado da sua conta.
            </p>

            <p className="helpTitle">5. Compra de Bilhetes </p>
            <p className="helpText">
              <strong>5.1. Pagamento:</strong> A compra de bilhetes para viagens
              ou para eventos não são da responsabilidade do Triansport e sim
              das respetivas entidades.
            </p>
            <p className="helpText">
              <strong>5.2. Política de Cancelamento e Reembolso:</strong> As
              condições para cancelamento e reembolso de bilhetes de comboio ou
              ingressos de eventos são estabelecidas de acordo com as políticas
              das transportadoras ou organizadores dos eventos. Recomendamos que
              consulte as políticas específicas antes de concluir sua compra.
            </p>

            <p className="helpTitle">6. Alterações nos Serviços</p>
            <p className="helpText">
              Ao Trainsport reserva-se o direito de modificar, suspender ou
              descontinuar qualquer aspecto da aplicação, incluindo a
              disponibilidade de qualquer recurso, base de dados ou conteúdo, a
              qualquer momento e sem aviso prévio.
            </p>

            <p className="helpTitle">7. Limitação de Responsabilidade</p>
            <p className="helpText">
              O Trainsport não se responsabiliza por qualquer dano direto,
              indireto, incidental, especial, consequencial ou exemplar
              resultante de (i) qualquer uso ou incapacidade de usar a aplicação
              (ii) quaisquer alterações á aplicação; ou (iii) qualquer outra
              questão relacionada á aplicação.
            </p>

            <p className="helpTitle">8. Privacidade</p>
            <p className="helpText">
              A utilização da aplicação está sujeita à nossa Política de
              Privacidade, que faz parte destes Termos e Condições. Esta
              política descreve como coletamos, usamos e protegemos as suas
              informações pessoais.
            </p>

            <p className="helpTitle">9. Propriedade Intelectual</p>
            <p className="helpText">
              Todo o conteúdo, software, gráficos, e outros materiais presentes
              na aplicação são de propriedade do Trainsport ou dos seus
              licenciadores e estão protegidos por leis de direitos autorais e
              outras leis de propriedade intelectual. Você não deve copiar,
              modificar, distribuir, vender ou alugar qualquer parte do
              trainsport ou do software contido nele.
            </p>

            <p className="helpTitle">10. Alterações aos Termos e Condições</p>
            <p className="helpText">
              Ao Trainsport reserva-se o direito de alterar estes Termos e
              Condições a qualquer momento. Alterações substanciais serão
              notificadas por meio da própria aplicação ou outros meios
              apropriados. O uso contínuo da aplicação após tais alterações
              constitui sua aceitação dos novos termos.
            </p>

            <p className="helpTitle">11. Lei Aplicável e Jurisdição</p>
            <p className="helpText">
              Estes Termos e Condições serão regidos e interpretados de acordo
              com as leis xxxxxxxxxxxx. Qualquer disputa decorrente ou
              relacionada com estes termos será resolvida nos tribunais
              competentes dessa jurisdição.
            </p>

            <p className="helpTitle">12. Contacto</p>
            <p className="helpText">
              Caso tenha dúvidas ou preocupações sobre estes Termos e Condições,
              entre em contato connosco através do xxxxxxxxxxxxx.
            </p>

            <p className="helpText">
              Ao utilizar a aplicação, você reconhece que leu, compreendeu e
              concorda com estes Termos e Condições.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TermsClient;
