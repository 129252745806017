import React, { useEffect, useState } from "react";
import logo from "../../images/logo.svg";
import background from "../../images/background.png";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardContent,
  Skeleton,
  Step,
  StepLabel,
  MenuItem,
  Stepper,
  TextField,
  Typography,
  Autocomplete,
  FormControlLabel,
  Switch,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "../../App.css";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faPen, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import IconButton from "@mui/material/IconButton";
import i18next from "i18next";
import bcrypt from "bcryptjs";
import {
  getSaltAdmin,
  getSaltPromoter,
  loginAdmin,
  loginPromoter,
} from "../../api/requests/Auth.js";
import toast from "react-hot-toast";
import Cookies from "js-cookie";
import { getPlans, getPlansAll } from "../../api/requests/PaymentPlans.js";
import Dropzone from "react-dropzone";
import {
  getActivities,
  getDistricts,
  getServicesTypes,
  getSubActivities,
  getTrainStations,
  getTypes,
} from "../../api/requests/Data.js";
import { useLocation } from "react-router-dom";
import { getPromoters } from "../../api/requests/Promoters.js";
import { getCouncils } from "../../api/requests/Council.js";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
import { allOffers, getOffers } from "../../api/requests/Offers.js";
import { postPartner, registerPartner } from "../../api/requests/Partner.js";


function RegisterPartners() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [plans, setPlans] = useState([]);
  const [activeStep, setActiveStep] = useState(0); // Stepper step control
  const [selectedPlan, setSelectedPlan] = useState(null); // Selected plan state
  const steps = [t("selectPlan"), t("promoterInfo"), t("confirmation")];
  const [visible, setVisible] = useState(true);
  const [districts, setDistricts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [promoters, setPromoters] = useState([]);
  const [managers, setManagers] = useState([]);
  const [types, setTypes] = useState([]);
  const [partnerUrl, setPartnerUrl] = useState("");
  const [typeId, setTypeId] = useState("");
  const [VAT, setVAT] = useState("");
  const [postalCode, setPostalCode] = useState([]);
  const [urlFacebook, setUrlFacebook] = useState("");
  const [urlInstagram, setUrlInstagram] = useState("");
  const [urlLinkedin, setUrlLinkedin] = useState("");
  const [urlTwitter, setUrlTwitter] = useState("");
  const [urlYoutube, setUrlYoutube] = useState("");
  const [managerId, setManagerId] = useState("");
  const [coordinates, setCoordinates] = useState([]);
  const [councilId, setCouncilId] = useState("");
  const [district, setDistrict] = useState({
    _id: '667bddedfd5c20e9661cf7c2',
    id: 1,
    id_country: 1,
    pt: 'Aveiro',
    en: 'Aveiro',
    es: 'Aveiro',
    fr: 'Aveiro',
    zones: [8, 2],
    __v: 0,
  });
  const today = dayjs();

  const [audience, setAudience] = useState(true);
  const [audienceCapacity, setAudienceCapacity] = useState(0);
  const [audienceUrl, setAudienceUrl] = useState([]);
  const [booking, setBooking] = useState(true);

  const [bookingLimit, setBookingLimit] = useState(0);
  const [cancel, setCancel] = useState(false);
  const [cancelObs, setCancelObs] = useState("");

  const [co2, setCo2] = useState([]);

  const [name, setName] = useState("");
  const [contact, setContact] = useState("");
  const [address, setAddress] = useState("");

  const [tourLink, setTourLink] = useState("");

  const [urlBooking, setUrlBooking] = useState("");
  const [urlTripAdvisor, setUrlTripAdvisor] = useState("");

  const [schedules, setSchedules] = useState([]);
  const [discount, setDiscount] = useState([]);
  const [photo, setPhoto] = useState("");
  const [certification, setCertification] = useState("");
  const [avatarData, setAvatarData] = useState(null);
  const [status, setStatus] = useState(0);
  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState(today);
  const [plan, setPlan] = useState("");
  const [offer, setOffer] = useState("");
  const [offers, setOffers] = useState([]);

  const handleCouncilChange = (equipment) => {
    setCouncilId(equipment.target.value);
  };

  const handleManagerChange = (equipment) => {
    setManagerId(equipment.target.value);
  };

  const handleChangeType = (event) => {
    setTypeId(event.target.value);
  };

  const handleDistrictChange = (event, newValue) => {
   
    setDistrict(newValue);
  };

  const handleChangeOffer = (event) => {
    setOffer(event.target.value);
  };

  const handleChangePlan = (event) => {
    setPlan(event.target.value);
  };

  const handleSwitchChange = (event) => {
    setVisible(event.target.checked);
  };
  const handleBookingChange = (event) => {
    setBooking(event.target.checked);
  };

  const [preview, setPreview] = useState("");

  const [councilUrl, setCouncilUrl] = useState("");
  const [cityCouncils, setCityCouncils] = useState([]);
  const [culturalActivities, setCulturalActivities] = useState([]);
  const [sportsActivities, setSportsActivities] = useState([]);
  const [isLoadingDistricts, setLoadingDistricts] = useState(true);
  const [isLoadingTrainStations, setLoadingTrainStations] = useState(true);

  function goBack() {
    navigate("/");
  }

  const [trainstations, setTrainstations] = useState([]);
  

  const inputLabelPropsStyle = {
    color: "#044535 !important",
    textAlign: "right",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "22px",
  };

  const inputPropsStyle = {
    color: "#0F111199",
    textAlign: "right",
    fontFamily: "Montserrat",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "22px",
    borderRadius: "8px",
    background: "#0f11110d",
    paddingLeft: "10px",
    // width: "60%",
  };

  const inputTrainStyle = {
    color: "#0F1111",
    textAlign: "right",
    fontFamily: "Montserrat",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "22px",
    borderRadius: "8px",
    background: "white",
    width: "60%",
  };

  const handleNext = () => setActiveStep((prevStep) => prevStep + 1);
  const handleBack = () => setActiveStep((prevStep) => prevStep - 1);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleEmail = (event) => {
    setEmail(event.target.value);
  };
  const handlePwd = (event) => {
    setPassword(event.target.value);
  };

  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    getPlansAll()
      .then((res) => {
        if (res.status === 200 && res.success) {
          setPlans(res.data);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error("500");
      });
  }, []);

  const [file, setFile] = useState(null);
  const [contract, setContract] = useState(null);
  const [fileType, setFileType] = useState("");
  const [contractType, setContractType] = useState("");
  const [fileUrl, setFileUrl] = useState(null);
  const [contractUrl, setContractUrl] = useState(null);

  const onDropFile = (acceptedFiles) => {
    const file = acceptedFiles[0];
    setFile(file);

    // Determine o tipo do arquivo
    const fileType = file.type.includes("pdf") ? "pdf" : "image";
    setFileType(fileType);
  };

  const onDropFile2 = (acceptedFiles) => {
    const file = acceptedFiles[0];
    setContract(file);

    // Determine o tipo do arquivo
    const fileType = file.type.includes("pdf") ? "pdf" : "image";
    setContractType(fileType);
  };

 
  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreview(reader.result);
    };
    reader.readAsDataURL(file);
  };

  useEffect(() => {
    if (file) {
      // Converte o arquivo para base64
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result;
        setFileUrl(base64String); // Armazena o base64String em fileUrl
      };

      reader.readAsDataURL(file); // Inicia a leitura do arquivo
    }
  }, [file]);

  useEffect(() => {
    if (contract) {
      // Converte o arquivo para base64
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result;
        setContractUrl(base64String); // Armazena o base64String em fileUrl
      };

      reader.readAsDataURL(contract); // Inicia a leitura do arquivo
    }
  }, [contract]);

  const dropzoneStyle = {
    border: "2px dashed #0087F7",
    borderRadius: "5px",
    padding: "20px",
    textAlign: "center",
    cursor: "pointer",
    width: "60%",
  };

  const imageStyle = {
    maxWidth: "60%",
    height: "100px",
  };

  useEffect(() => {
    getTrainStations()
      .then((res) => {
        if (res.status === 200 && res.success) {
          setTrainstations(res.data);
          setLoadingTrainStations(false);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error(t("500"));
      });
    getDistricts()
      .then((res) => {
        if (res.status === 200 && res.success) {
          setDistricts(res.data);
          setLoadingDistricts(false);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error("500");
      });
    getCouncils()
      .then((res) => {
        if (res.status === 200 && res.success) {
          setCityCouncils(res.data);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error("500");
      });
    getServicesTypes()
      .then((res) => {
        if (res.status === 200 && res.success) {
          setTypes(res.data);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error("500");
      });
    allOffers()
      .then((res) => {

        if (res.status === 200 && res.success) {
          setOffers(res.data);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error("500");
      });
    getPlans()
      .then((res) => {
        if (res.status === 200 && res.success) {
          setPlans(res.data);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error("500");
      });
  }, []);

  function create() {
    if (!preview) {
      toast.error(t("photoValidation"));
      return;
    }
    if (!name) {
      toast.error(t("nameEqValidation"));
      return;
    }
    if (!contact) {
      toast.error(t("contactEqValidation"));
      return;
    }
    if (!email) {
      toast.error(t("emailValidation"));
      return;
    }
    if (!certification) {
      toast.error(t("certificationValidation"));
      return;
    }
    if (!address) {
      toast.error(t("addressValidation"));
      return;
    }
    if (!coordinates) {
      toast.error(t("coordinatesValidation"));
      return;
    }
    if (!typeId) {
      toast.error(t("typeIdEqValidation"));
      return;
    }
    if (!fileUrl) {
      toast.error(t("schedulesEqValidation"));
      return;
    }
    if (!district) {
      toast.error(t("districtValidation"));
      return;
    }
    if (!startDate) {
      toast.error(t("startDateValidation"));
      return;
    }
    if (!endDate) {
      toast.error(t("endDateValidation"));
      return;
    }
    if (!contractUrl) {
      toast.error(t("contractValidation"));
      return;
    }
    if (!selectedPlan) {
      toast.error(t("planValidation"));
      return;
    }
    if (!offer) {
      toast.error(t("offerValidation"));
      return;
    }

    registerPartner({
      name: name,
      contact: contact,
      email: email,
      service_type: typeId,
      schedules: fileUrl,
      url_partner: partnerUrl,
      url_booking: urlBooking,
      url_tripadvisor: urlTripAdvisor,
      url_facebook: urlFacebook,
      url_instagram: urlInstagram,
      url_youtube: urlYoutube,
      start_contract: startDate,
      end_contract: endDate,
      contract: contractUrl,
      plan_id: selectedPlan,
      permanent_certificate: certification,
      offer_id: offer[0],
      reservation: booking,
      district_id: district.id,
      zip_code: postalCode,
      coordinates: coordinates,
      visible: visible,
      address: address,
      photo: preview,
    })
      .then((res) => {
        if (res.success && res.status === 200) {
          toast.success(t("successMessage"));
          navigate("/loginpartner");
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error(t("500"));
      });
  }

  return (
    <div className="background">
      <div className="backgroundImageSmall">
        <div className="topcenter">
          <div className="logo"></div>
          <p className="titlesmall">{t("partner")}</p>
        </div>
      </div>
      <div className="bottomcenterregister">
        <div
          style={{
            overflowY: "auto",
            maxHeight: "50vh",
            padding: "10px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: activeStep=== 0?"100%": "21%",
          }}
        >
          {activeStep === 0 && (
            <div className="plan-selection">
              {plans.length > 0 ? (
                plans.map((plan) => (
                  <Card
                    key={plan.id}
                    className={`plan-card ${
                      selectedPlan === plan._id ? "selected" : ""
                    }`}
                    onClick={() => setSelectedPlan(plan._id)}
                  >
                    <CardContent>
                      <Typography variant="h6" className="plan-title">
                        {plan.pt}
                      </Typography>
                      <Typography className="plan-duration">
                        {plan.duration}
                      </Typography>
                      <Typography className="plan-price">
                        {t("price")}: {plan.value}€
                      </Typography>
                    </CardContent>
                  </Card>
                ))
              ) : (
                <Typography className="no-plans">
                  {t("noPlansAvailable")}
                </Typography>
              )}
            </div>
          )}
          {activeStep === 1 && (
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
             <div className="photoColumn">
                <a className="photoLabel">{t("mainPhoto")}</a>

                <Dropzone onDrop={onDrop} accept="image/*" multiple={false}>
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      <div className="addPosterBig" {...getRootProps()}>
                        <input {...getInputProps()} />
                        {!preview ? (
                          <div
                            className="addPhotoBig"
                            style={{ marginRight: "0px" }}
                          >
                            <FontAwesomeIcon
                              style={{
                                fontSize: "16px",
                                color: "rgba(15, 17, 17, 0.60)",
                              }}
                              icon={faPlus}
                            />
                          </div>
                        ) : (
                          <div className="addPosterEditBig">
                            <img src={preview} className="addPosterBig" />
                            <div className="overlay">
                              <FontAwesomeIcon
                                className="hoverIcon"
                                icon={faTrashCan}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </section>
                  )}
                </Dropzone>
              </div>
              <div
                style={{
                  marginTop: "30px",
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <a className="photoLabel">{t("name")}*</a>

                <TextField
                  fullWidth
                  InputProps={{
                    disableUnderline: true,
                    style: inputPropsStyle,
                  }}
                  InputLabelProps={{
                    shrink: true,
                    style: inputLabelPropsStyle,
                  }}
                  sx={{
                    "& .Mui-disabled": {
                      backgroundColor: "white !important",
                      color: "#044535 !important",
                    },
                    "& .MuiInputLabel-root ": {
                      color: "#044535 !important",
                    },
                  }}
                  variant="standard"
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  value={name}
                />
              </div>
              <div
                style={{
                  marginTop: "30px",
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <a className="photoLabel">{t("contact")}*</a>

                <TextField
                  fullWidth
                  InputProps={{
                    disableUnderline: true,
                    style: inputPropsStyle,
                  }}
                  InputLabelProps={{
                    shrink: true,
                    style: inputLabelPropsStyle,
                  }}
                  sx={{
                    "& .Mui-disabled": {
                      backgroundColor: "white !important",
                      color: "#044535 !important",
                    },
                    "& .MuiInputLabel-root ": {
                      color: "#044535 !important",
                    },
                  }}
                  variant="standard"
                  onChange={(e) => {
                    setContact(e.target.value);
                  }}
                  value={contact}
                />
              </div>
              <div
                style={{
                  marginTop: "30px",
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <a className="photoLabel">{t("email")}*</a>

                <TextField
                  fullWidth
                  InputProps={{
                    disableUnderline: true,
                    style: inputPropsStyle,
                  }}
                  InputLabelProps={{
                    shrink: true,
                    style: inputLabelPropsStyle,
                  }}
                  sx={{
                    "& .Mui-disabled": {
                      backgroundColor: "white !important",
                      color: "#044535 !important",
                    },
                    "& .MuiInputLabel-root ": {
                      color: "#044535 !important",
                    },
                  }}
                  variant="standard"
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  value={email}
                />
              </div>
              <div
                style={{
                  marginTop: "30px",
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <a className="photoLabel">{t("certificationP")}*</a>

                <TextField
                  fullWidth
                  InputProps={{
                    disableUnderline: true,
                    style: inputPropsStyle,
                  }}
                  InputLabelProps={{
                    shrink: true,
                    style: inputLabelPropsStyle,
                  }}
                  sx={{
                    "& .Mui-disabled": {
                      backgroundColor: "white !important",
                      color: "#044535 !important",
                    },
                    "& .MuiInputLabel-root ": {
                      color: "#044535 !important",
                    },
                  }}
                  variant="standard"
                  onChange={(e) => {
                    setCertification(e.target.value);
                  }}
                  value={certification}
                />
              </div>
              <div
                style={{
                  marginTop: "30px",
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <a className="photoLabel">{t("address")}*</a>

                <TextField
                  fullWidth
                  InputProps={{
                    disableUnderline: true,
                    style: inputPropsStyle,
                  }}
                  InputLabelProps={{
                    shrink: true,
                    style: inputLabelPropsStyle,
                  }}
                  onChange={(e) => {
                    setAddress(e.target.value);
                  }}
                  sx={{
                    "& .Mui-disabled": {
                      backgroundColor: "white !important",
                      color: "#044535 !important",
                    },
                    "& .MuiInputLabel-root ": {
                      color: "#044535 !important",
                    },
                  }}
                  variant="standard"
                  value={address}
                />
              </div>
              <div
                style={{
                  marginTop: "30px",
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <a className="photoLabel">{t("coordinates")}*</a>

                <TextField
                  fullWidth
                  InputProps={{
                    disableUnderline: true,
                    style: inputPropsStyle,
                  }}
                  InputLabelProps={{
                    shrink: true,
                    style: inputLabelPropsStyle,
                  }}
                  sx={{
                    "& .Mui-disabled": {
                      backgroundColor: "white !important",
                      color: "#044535 !important",
                    },
                    "& .MuiInputLabel-root ": {
                      color: "#044535 !important",
                    },
                  }}
                  variant="standard"
                  value={coordinates}
                  onChange={(e) => {
                    setCoordinates(e.target.value);
                  }}
                />
              </div>
              <div
                style={{
                  marginTop: "30px",
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <a className="photoLabel">{t("postalCode")}</a>

                <TextField
                  fullWidth
                  InputProps={{
                    disableUnderline: true,
                    style: inputPropsStyle,
                  }}
                  InputLabelProps={{
                    shrink: true,
                    style: inputLabelPropsStyle,
                  }}
                  sx={{
                    "& .Mui-disabled": {
                      backgroundColor: "white !important",
                      color: "#044535 !important",
                    },
                    "& .MuiInputLabel-root ": {
                      color: "#044535 !important",
                    },
                  }}
                  variant="standard"
                  onChange={(e) => {
                    setPostalCode(e.target.value);
                  }}
                  value={postalCode}
                />
              </div>
              <div
                style={{
                  marginTop: "30px",
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <a className="photoLabel">{t("type")}*</a>

                <TextField
                  fullWidth
                  select
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      ...inputPropsStyle,
                      textAlign: "left",
                      borderRadius: "8px",
                    },
                  }}
                  InputLabelProps={{
                    shrink: true,
                    style: inputLabelPropsStyle,
                  }}
                  sx={{
                    "& .Mui-disabled": {
                      backgroundColor: "white !important",
                      color: "#044535 !important",
                    },
                    "& .MuiInputLabel-root": {
                      color: "#044535 !important",
                    },
                  }}
                  variant="standard"
                  value={typeId}
                  onChange={handleChangeType}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                >
                  {types.map((type) => (
                    <MenuItem key={type._id} value={type.id}>
                      {type[i18next.language]}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "30px",
                }}
              >
                <a className="photoLabel">{t("schedule")}*</a>

                <Dropzone onDrop={onDropFile} accept="image/*" multiple={false}>
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      <div className="addPosterBig" {...getRootProps()}>
                        <input {...getInputProps()} />
                        {!fileUrl ? (
                          <div className="addPhotoBig">
                            <FontAwesomeIcon
                              style={{
                                fontSize: "16px",
                                color: "rgba(15, 17, 17, 0.60)",
                              }}
                              icon={faPlus}
                            />
                          </div>
                        ) : (
                          <div className="addPosterEditBig">
                            <img
                              src={fileUrl}
                              alt="Preview"
                              className="addPosterBig"
                            />
                            <div className="overlay">
                              <FontAwesomeIcon
                                className="hoverIcon"
                                icon={faPen}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </section>
                  )}
                </Dropzone>

                {/* {fileType === "image" && fileUrl && (
    <object
      data={fileUrl}
      type="application/pdf"
      width="100%"
      height="500px"
    >
      <p>Preview not available</p>
    </object>
  )} */}
              </div>

              <div
                style={{
                  marginTop: "30px",
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <a className="photoLabel">{t("district")}*</a>

                <Autocomplete
                  options={districts}
                  getOptionLabel={(option) =>
                    option[i18next.language] || option.en
                  }
                  value={district}
                  onChange={handleDistrictChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                        style: {
                          ...inputPropsStyle,
                          textAlign: "left",
                          borderRadius: "8px",
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                    />
                  )}
                  renderOption={(props, option) => (
                    <li {...props} key={option.id}>
                      {option[i18next.language] || option.en}
                    </li>
                  )}
                  sx={{
                    "& .MuiAutocomplete-popupIndicator": {
                      display: "none",
                    },
                    "& .MuiAutocomplete-clearIndicator": {
                      display: "none",
                    },
                    "& .MuiOutlinedInput-root": {
                      padding: 0,
                    },
                    "& .MuiAutocomplete-listbox": {
                      fontFamily: "Montserrat",
                      fontSize: "14px",
                    },
                    "& .MuiAutocomplete-inputRoot": {
                      padding: "0 !important",
                    },
                  }}
                />
              </div>
              <div
                style={{
                  marginTop: "30px",
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <a className="photoLabel">{t("booking")}</a>

                <FormControlLabel
                  control={
                    <Switch
                      checked={booking}
                      onChange={handleBookingChange}
                      color="primary"
                      sx={{
                        "& .MuiSwitch-switchBase.Mui-checked": {
                          color: "#FFFFFF !important",
                        },
                        "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                          {
                            backgroundColor: "#13BB56 !important",
                            opacity: "1 !important",
                          },
                      }}
                    />
                  }
                  label={t("booking")}
                  style={{ marginTop: "20px" }}
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      flexGrow: 1,
                      textAlign: "left",
                      marginLeft: "10px",
                      color: "#0F1111",
                      fontFamily: "Montserrat",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: 600,
                      lineHeight: "normal",
                    },
                  }}
                />
              </div>

              <div
                style={{
                  marginTop: "30px",
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <a className="photoLabel">{t("partnerLink")}</a>

                <TextField
                  fullWidth
                  InputProps={{
                    disableUnderline: true,
                    style: inputPropsStyle,
                  }}
                  InputLabelProps={{
                    shrink: true,
                    style: inputLabelPropsStyle,
                  }}
                  sx={{
                    "& .Mui-disabled": {
                      backgroundColor: "white !important",
                      color: "#044535 !important",
                    },
                    "& .MuiInputLabel-root ": {
                      color: "#044535 !important",
                    },
                  }}
                  variant="standard"
                  onChange={(e) => {
                    setPartnerUrl(e.target.value);
                  }}
                  value={partnerUrl}
                />
              </div>
              <div
                style={{
                  marginTop: "30px",
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <a className="photoLabel">{t("equipmentBooking")}</a>

                <TextField
                  fullWidth
                  InputProps={{
                    disableUnderline: true,
                    style: inputPropsStyle,
                  }}
                  InputLabelProps={{
                    shrink: true,
                    style: inputLabelPropsStyle,
                  }}
                  sx={{
                    "& .Mui-disabled": {
                      backgroundColor: "white !important",
                      color: "#044535 !important",
                    },
                    "& .MuiInputLabel-root ": {
                      color: "#044535 !important",
                    },
                  }}
                  variant="standard"
                  onChange={(e) => {
                    setUrlBooking(e.target.value);
                  }}
                  value={urlBooking}
                />
              </div>
              <div
                style={{
                  marginTop: "30px",
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <a className="photoLabel">{t("equipmentTripAdvisor")}</a>

                <TextField
                  fullWidth
                  InputProps={{
                    disableUnderline: true,
                    style: inputPropsStyle,
                  }}
                  InputLabelProps={{
                    shrink: true,
                    style: inputLabelPropsStyle,
                  }}
                  sx={{
                    "& .Mui-disabled": {
                      backgroundColor: "white !important",
                      color: "#044535 !important",
                    },
                    "& .MuiInputLabel-root ": {
                      color: "#044535 !important",
                    },
                  }}
                  variant="standard"
                  onChange={(e) => {
                    setUrlTripAdvisor(e.target.value);
                  }}
                  value={urlTripAdvisor}
                />
              </div>

              <div
                style={{
                  marginTop: "30px",
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <a className="photoLabel">{t("equipmentFacebook")}</a>

                <TextField
                  fullWidth
                  InputProps={{
                    disableUnderline: true,
                    style: inputPropsStyle,
                  }}
                  InputLabelProps={{
                    shrink: true,
                    style: inputLabelPropsStyle,
                  }}
                  sx={{
                    "& .Mui-disabled": {
                      backgroundColor: "white !important",
                      color: "#044535 !important",
                    },
                    "& .MuiInputLabel-root ": {
                      color: "#044535 !important",
                    },
                  }}
                  variant="standard"
                  onChange={(e) => {
                    setUrlFacebook(e.target.value);
                  }}
                  value={urlFacebook}
                />
              </div>
              <div
                style={{
                  marginTop: "30px",
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <a className="photoLabel">{t("equipmentInstagram")}</a>

                <TextField
                  fullWidth
                  InputProps={{
                    disableUnderline: true,
                    style: inputPropsStyle,
                  }}
                  InputLabelProps={{
                    shrink: true,
                    style: inputLabelPropsStyle,
                  }}
                  sx={{
                    "& .Mui-disabled": {
                      backgroundColor: "white !important",
                      color: "#044535 !important",
                    },
                    "& .MuiInputLabel-root ": {
                      color: "#044535 !important",
                    },
                  }}
                  variant="standard"
                  onChange={(e) => {
                    setUrlInstagram(e.target.value);
                  }}
                  value={urlInstagram}
                />
              </div>
              <div
                style={{
                  marginTop: "30px",
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <a className="photoLabel">{t("equipmentYoutube")}</a>

                <TextField
                  fullWidth
                  InputProps={{
                    disableUnderline: true,
                    style: inputPropsStyle,
                  }}
                  InputLabelProps={{
                    shrink: true,
                    style: inputLabelPropsStyle,
                  }}
                  sx={{
                    "& .Mui-disabled": {
                      backgroundColor: "white !important",
                      color: "#044535 !important",
                    },
                    "& .MuiInputLabel-root ": {
                      color: "#044535 !important",
                    },
                  }}
                  variant="standard"
                  onChange={(e) => {
                    setUrlYoutube(e.target.value);
                  }}
                  value={urlYoutube}
                />
              </div>
              <div
                style={{
                  marginTop: "30px",
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <a className="photoLabel">
                  {t("startdate")} - {t("contract")}*
                </a>

                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale={i18next?.language}
                >
                  <DateTimePicker
                    variant="standard"
                    showTimeSelect
                    value={startDate}
                    onChange={(e) => {
                      setStartDate(e);
                    }}
                    slotProps={{
                      tabs: {
                        hidden: false,
                      },

                      day: {
                        sx: {
                          "&.MuiPickersDay-root.Mui-selected": {
                            backgroundColor: "#13BB56 !important",
                          },
                        },
                      },

                      desktopPaper: {
                        sx: {
                          borderRadius: "8px",
                          ".MuiPickersYear-yearButton.Mui-selected": {
                            backgroundColor: "#13BB56 !important",
                          },
                          ".MuiPickersMonth-monthButton.Mui-selected": {
                            backgroundColor: "#13BB56 !important",
                          },
                          "& .css-1e3wlyl-MuiButtonBase-root-MuiMenuItem-root-MuiMultiSectionDigitalClockSection-item.Mui-selected":
                            {
                              backgroundColor: "#13BB56 !important",
                            },
                          "& .css-y0fr9s-MuiButtonBase-root-MuiTab-root.Mui-selected":
                            {
                              color: "#13BB56 !important",
                            },
                          "& .css-1aquho2-MuiTabs-indicator": {
                            backgroundColor: "#13BB56 !important",
                          },
                          "& .css-1e6y48t-MuiButtonBase-root-MuiButton-root": {
                            color: "#13BB56 !important",
                          },
                        },
                      },
                    }}
                    sx={{
                      width: "100%",

                      "& .MuiInputBase-input.Mui-disabled": {
                        backgroundColor: "white",
                      },
                      "& .MuiPickersDay-root.Mui-selected": {
                        backgroundColor: "#44b887 !important",
                        color: "white",
                      },
                      "& .MuiPickersCalendar-root": {
                        borderRadius: "8px !important",
                      },

                      "& .MuiInputLabel-root ": {
                        color: "#044535 !important",

                        fontFamily: "Montserrat",
                        fontSize: "18px !important",
                        fontStyle: "normal",
                        fontWeight: 600,
                        lineHeight: "15px",
                        borderColor: "transparent !important",
                        position: "absolute",
                        marginTop: "-5px",
                        marginLeft: "-10px",
                      },
                      "& .MuiOutlinedInput-root": {
                        color: "#0F111199 !important",
                        textAlign: "right",
                        fontFamily: "Montserrat",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "15px",
                        borderRadius: "8px",
                        // background: !edicao ? "#0f11110d" : "white",
                        borderColor: "transparent !important",
                        width: "100%",
                        height: "34.88px",
                        padding: "16.5px 10px",
                      },
                      "& .MuiInputLabel-root.Mui-disabled ": {
                        color: "#044535 !important",
                        fontFamily: "Montserrat",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: 600,
                        lineHeight: "15px",
                        borderColor: "transparent !important",
                      },
                      "& .MuiOutlinedInput-notchedOutline ": {
                        border: "0px",
                      },
                      "& .MuiOutlinedInput-input": {
                        padding: "16.5px 0px",
                        width: "100%",
                      },
                    }}
                  ></DateTimePicker>
                </LocalizationProvider>
              </div>
              <div
                style={{
                  marginTop: "30px",
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <a className="photoLabel">
                  {t("enddate")} - {t("contract")}*
                </a>

                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale={i18next?.language}
                >
                  <DateTimePicker
                    variant="standard"
                    showTimeSelect
                    value={endDate}
                    onChange={(e) => {
                      setEndDate(e);
                    }}
                    slotProps={{
                      tabs: {
                        hidden: false,
                      },

                      day: {
                        sx: {
                          "&.MuiPickersDay-root.Mui-selected": {
                            backgroundColor: "#13BB56 !important",
                          },
                        },
                      },

                      desktopPaper: {
                        sx: {
                          borderRadius: "8px",
                          ".MuiPickersYear-yearButton.Mui-selected": {
                            backgroundColor: "#13BB56 !important",
                          },
                          ".MuiPickersMonth-monthButton.Mui-selected": {
                            backgroundColor: "#13BB56 !important",
                          },
                          "& .css-1e3wlyl-MuiButtonBase-root-MuiMenuItem-root-MuiMultiSectionDigitalClockSection-item.Mui-selected":
                            {
                              backgroundColor: "#13BB56 !important",
                            },
                          "& .css-y0fr9s-MuiButtonBase-root-MuiTab-root.Mui-selected":
                            {
                              color: "#13BB56 !important",
                            },
                          "& .css-1aquho2-MuiTabs-indicator": {
                            backgroundColor: "#13BB56 !important",
                          },
                          "& .css-1e6y48t-MuiButtonBase-root-MuiButton-root": {
                            color: "#13BB56 !important",
                          },
                        },
                      },
                    }}
                    sx={{
                      width: "100%",

                      "& .MuiInputBase-input.Mui-disabled": {
                        backgroundColor: "white",
                      },
                      "& .MuiPickersDay-root.Mui-selected": {
                        backgroundColor: "#44b887 !important",
                        color: "white",
                      },
                      "& .MuiPickersCalendar-root": {
                        borderRadius: "8px !important",
                      },

                      "& .MuiInputLabel-root ": {
                        color: "#044535 !important",

                        fontFamily: "Montserrat",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: 600,
                        lineHeight: "15px",
                        borderColor: "transparent !important",
                        position: "absolute",
                        marginTop: "-5px",
                        marginLeft: "-10px",
                      },
                      "& .MuiOutlinedInput-root": {
                        color: "#0F111199 !important",
                        textAlign: "right",
                        fontFamily: "Montserrat",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "15px",
                        borderRadius: "8px",
                        // background: !edicao ? "#0f11110d" : "white",
                        borderColor: "transparent !important",
                        width: "100%",
                        height: "34.88px",
                        padding: "16.5px 10px",
                      },
                      "& .MuiInputLabel-root.Mui-disabled ": {
                        color: "#044535 !important",
                        fontFamily: "Montserrat",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: 600,
                        lineHeight: "15px",
                        borderColor: "transparent !important",
                      },
                      "& .MuiOutlinedInput-notchedOutline ": {
                        border: "0px",
                      },
                      "& .MuiOutlinedInput-input": {
                        padding: "16.5px 0px",
                        width: "100%",
                      },
                    }}
                  ></DateTimePicker>
                </LocalizationProvider>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",

                  marginTop: "30px",
                }}
              >
                <a className="photoLabel">{t("contract")}*</a>

                <Dropzone
                  onDrop={onDropFile2}
                  accept="image/*"
                  multiple={false}
                >
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      <div className="addPosterBig" {...getRootProps()}>
                        <input {...getInputProps()} />
                        {!contractUrl ? (
                          <div className="addPhotoBig">
                            <FontAwesomeIcon
                              style={{
                                fontSize: "16px",
                                color: "rgba(15, 17, 17, 0.60)",
                              }}
                              icon={faPlus}
                            />
                          </div>
                        ) : (
                          <div className="addPosterEditBig">
                            <img
                              src={contractUrl}
                              alt="Preview"
                              className="addPosterBig"
                            />
                            <div className="overlay">
                              <FontAwesomeIcon
                                className="hoverIcon"
                                icon={faPen}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </section>
                  )}
                </Dropzone>

                {contractType === "pdf" && contractUrl && (
                  <object
                    data={contractUrl}
                    type="application/pdf"
                    width="100%"
                    height="500px"
                  >
                    <p>PDF preview not available</p>
                  </object>
                )}
              </div>

              <div
                style={{
                  marginTop: "30px",
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <a className="photoLabel">{t("offer")}*</a>

                <TextField
                  fullWidth
                  select
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      ...inputPropsStyle,
                      textAlign: "left",
                      borderRadius: "8px",
                    },
                  }}
                  InputLabelProps={{
                    shrink: true,
                    style: inputLabelPropsStyle,
                  }}
                  sx={{
                    "& .Mui-disabled": {
                      backgroundColor: "white !important",
                      color: "#044535 !important",
                    },
                    "& .MuiInputLabel-root": {
                      color: "#044535 !important",
                    },
                  }}
                  variant="standard"
                  value={offer._id}
                  onChange={handleChangeOffer}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                >
                  {offers.map((offer) => (
                    <MenuItem key={offer._id} value={offer._id}>
                      {offer.name} - {offer.discount}%
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            </div>
          )}

          {activeStep === 2 && (
            <Typography variant="h6">{t("confirmationMessage")}</Typography>
          )}
        </div>

        {/* Terceiro passo: Confirmação */}

        <div className="stepper-buttons">
          {activeStep === 0 ? (
            <></>
          ) : (
            <button
              disabled={activeStep === 0}
              onClick={handleBack}
              className="buttonStep"
            >
              {t("back")}
            </button>
          )}
          {selectedPlan && (
            <button
              variant="contained"
              onClick={activeStep === 1 ? () => create() : handleNext}
              className="buttonStep"
            >
              {activeStep === 2 ? t("finish") : t("register")}
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default RegisterPartners;
