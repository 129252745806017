import React, { useEffect, useState, useRef } from "react";

import { useNavigate } from "react-router-dom";
import "../../App.css";
import { useTranslation } from "react-i18next";
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';
import Header from "../../components/header";
import NavClient from "../../components/navClient";
import { APIProvider, Map, Marker } from "@vis.gl/react-google-maps";
import NavClientHorizontal from "../../components/navClientHorizontal";

function ClientMap() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000); // 1 segundo para exibir o esqueleto

    return () => clearTimeout(timer);
  }, []);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 950);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 950);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <div className="background">
     {isMobile ? <NavClientHorizontal /> : <NavClient />}

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          paddingTop: "2rem",
          marginLeft:"3.45rem"
          
        }}
      >
        <div className="mapStyleBig">
        {loading ? (
          <Box sx={{ width: "100%", height: "100%" }}>
            <Skeleton variant="rectangular" width="100%" height="100%"   sx={{ borderRadius: '0.9375rem' }}/>
          </Box>
        ) : (
          <APIProvider apiKey={"AIzaSyAju_Bdgsx_fY_VUJNTJTzKdw5P2z2A9ts"}>
            <Map
              style={{
                height: "100%",
                width: "100%",
                borderRadius: "15px",
                overflow: "hidden",
              }}
              mapId="17c9b3c0488e85ae"
              defaultCenter={{
                lat: 41.70311203750535,
                lng: -8.828393507213907,
              }}
              defaultZoom={12}
              gestureHandling={"greedy"}
              disableDefaultUI={true}
            >
              <Marker
                position={{
                  lat:41.455401, 
                  lng: -8.544985
                }}
                icon={"http://maps.google.com/mapfiles/ms/icons/orange.png"}
                title={"nine"}
              />
              <Marker
                position={{
                  lat:41.48680576078187, 
                  lng: -8.563688354831152
                }}
                icon={"http://maps.google.com/mapfiles/ms/icons/orange.png"}
                title={"carreira"}
              />
              <Marker
                position={{
                  lat:41.50816767745752,
                  lng: -8.58171905201627
                }}
                icon={"http://maps.google.com/mapfiles/ms/icons/orange.png"}
                title={"midoes"}
              />
              <Marker
                position={{
                  lat:41.53694812071865, 
                  lng:-8.608920235944383
                }}
                icon={"http://maps.google.com/mapfiles/ms/icons/orange.png"}
                title={"barcelos"}
              />
              <Marker
                position={{
                  lat:41.566083477671164,
                  lng:   -8.627642526785879
                }}
                icon={"http://maps.google.com/mapfiles/ms/icons/orange.png"}
                title={"silva"}
              />
              <Marker
                position={{
                  lat:41.58592773614524,
                  lng:  -8.632746525388946
                }}
                icon={"http://maps.google.com/mapfiles/ms/icons/orange.png"}
                title={"carapecos"}
              />
              <Marker
                position={{
                  lat:41.60948794089012, 
                  lng: -8.634945142757443
                }}
                icon={"http://maps.google.com/mapfiles/ms/icons/orange.png"}
                title={"tamel"}
              />
               <Marker
                position={{
                  lat:41.63954584503154, 
                  lng: -8.66710965444593
                }}
                icon={"http://maps.google.com/mapfiles/ms/icons/orange.png"}
                title={"durraes"}
              />
              <Marker
                position={{
                  lat:41.646964157399154, 
                  lng: -8.700944629265111
                }}
                icon={"http://maps.google.com/mapfiles/ms/icons/orange.png"}
                title={"barroselas"}
              />
              <Marker
                position={{
                  lat: 41.647232875842725, 
                  lng: -8.720382984554716
                }}
                icon={"http://maps.google.com/mapfiles/ms/icons/orange.png"}
                title={"sra das neves"}
              />
              <Marker
                position={{
                  lat: 41.680446,
                  lng: -8.781912,
                }}
                icon={"http://maps.google.com/mapfiles/ms/icons/orange.png"}
                title={"darque"}
              />
              <Marker
                position={{
                  lat: 41.684127,
                  lng: -8.799464,
                }}
                icon={"http://maps.google.com/mapfiles/ms/icons/orange.png"}
                title={"areiadarque"}
              />
              <Marker
                position={{
                  lat: 41.69467,
                  lng: -8.832418,
                }}
                icon={"http://maps.google.com/mapfiles/ms/icons/orange.png"}
                title={"estaçãoviana"}
              />
              <Marker
                position={{
                  lat: 41.721239,
                  lng: -8.855586,
                }}
                icon={"http://maps.google.com/mapfiles/ms/icons/orange.png"}
                title={"areosa"}
              />
               <Marker
                position={{
                  lat: 41.75205461240495,
                  lng:  -8.863720957905358
                }}
                icon={"http://maps.google.com/mapfiles/ms/icons/orange.png"}
                title={"carreco"}
              />
              <Marker
                position={{
                  lat: 41.775932680550355,
                  lng:  -8.861290121704535
                }}
                icon={"http://maps.google.com/mapfiles/ms/icons/orange.png"}
                title={"afife"}
              />
              <Marker
                position={{
                  lat: 41.81667253517862,
                  lng: -8.866705823190724
                }}
                icon={"http://maps.google.com/mapfiles/ms/icons/orange.png"}
                title={"ancorapraia"}
              />
               <Marker
                position={{
                  lat: 41.84820635453708, 
                  lng: -8.864305088692598
                }}
                icon={"http://maps.google.com/mapfiles/ms/icons/orange.png"}
                title={"moledo"}
              />
              <Marker
                position={{
                  lat: 41.87337857005317, 
                  lng: -8.836744387990432
                }}
                icon={"http://maps.google.com/mapfiles/ms/icons/orange.png"}
                title={"senhoradaagonia-caminha"}
              />
              <Marker
                position={{
                  lat:41.874195898780776,
                  lng:  -8.834491819591008
                }}
                icon={"http://maps.google.com/mapfiles/ms/icons/orange.png"}
                title={"caminha"}
              />
              <Marker
                position={{
                  lat:41.89654010367557,
                  lng: -8.819236961621405
                }}
                icon={"http://maps.google.com/mapfiles/ms/icons/orange.png"}
                title={"seixas"}
              />
              <Marker
                position={{
                  lat:41.90985813259309,
                  lng:  -8.792759064086011
                }}
                icon={"http://maps.google.com/mapfiles/ms/icons/orange.png"}
                title={"esqueiro"}
              />
              <Marker
                position={{
                  lat:41.9203603543124, 
                  lng:-8.763017274450016
                }}
                icon={"http://maps.google.com/mapfiles/ms/icons/orange.png"}
                title={"Gondarem"}
              />
              <Marker
                position={{
                  lat:41.93639222211892, 
                  lng:-8.748034146467903
                }}
                icon={"http://maps.google.com/mapfiles/ms/icons/orange.png"}
                title={"Vila Nova de Cerveira"}
              />
              <Marker
                position={{
                  lat:41.98436854513741, 
                  lng:-8.688477898872717
                }}
                icon={"http://maps.google.com/mapfiles/ms/icons/orange.png"}
                title={"Carvalha"}
              />
              <Marker
                position={{
                  lat:41.9936544390081,
                  lng:-8.666181809858081
                }}
                icon={"http://maps.google.com/mapfiles/ms/icons/orange.png"}
                title={"São Pedro da Torre"}
              />
              <Marker
                position={{
                  lat:42.02504556163195,
                  lng: -8.639425931174385
                }}
                icon={"http://maps.google.com/mapfiles/ms/icons/orange.png"}
                title={"Valença"}
              />
            </Map>
          </APIProvider>
          )}
        </div>
      </div>
    </div>
  );
}

export default ClientMap;
